/**
 * 		File:		./src/context/exports/newOnesReducer.js
 * 
 * 		Subject:	New contracts' export state reducer
 */
import {
  GET_EXPORTS_NEW_ONES,  
} from "../types";

export default (state, action) => {
  
  switch (action.type) {
	
	case GET_EXPORTS_NEW_ONES:
		return {
			...state,
			dataChange: action.payload.changeExports,
			dataEntry: action.payload.entryExports,
		}

    //  Default Path
    default:
      return state;
  }
};
