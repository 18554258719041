/**
 *  File:   ./src/Pages/Main/index.js
 * 
 *  Subject:  The file where all the states and routes
 *            are defined
 */

import React, { Fragment } from "react";
import { connect } from "react-redux";
import cx from "classnames";
import { withRouter } from "react-router-dom";
import ResizeDetector from "react-resize-detector";

/*------------------------------
//  Non-Administration
-------------------------------*/

//  Customers
import CustomersState from "../../context/customers/CustomersState";

//  Leads
import LeadsState from "../../context/leads/LeadsState";

//  Campaigns
import CampaignsState from "../../context/campaigns/CampaignsState";
import IndividualsState from "../../context/campaigns/IndividualsState";

import ImportsState from '../../context/imports/ImportsState';

//	Exports
import NewOnesState from "../../context/exports/NewOnesState";
import TerminatesState from "../../context/exports/TerminatesState";
import ExistingOnesState from "../../context/exports/ExistingOnesState";
import PortalState from "../../context/exports/PortalState";
import NavigationState from "../../context/exports/NavigationState";
import ExportPGsState from "../../context/exports/ExportPGsState";
import ExportProductsState from "../../context/exports/ExportProductsState";

/*------------------------------
//  Administration
-------------------------------*/

//  Energy
import RegulationZonesState from "../../context/administration/energy/RegulationZonesState";
import NetworksState from "../../context/administration/energy/NetworksState";
import SuppliersState from "../../context/administration/energy/SuppliersState";
import AdministrationsState from "../../context/administration/energy/AdministrationsState";
import EnergySupplierState from "../../context/administration/energy/EnergySupplierState";
import MarketsState from "../../context/administration/energy/MarketsState";
import LoadProfilesState from "../../context/administration/energy/LoadProfilesState";
import EnergyAssignmentsState from "../../context/administration/energy/EnergyAssignmentsState";
import LegalFormsState from "../../context/administration/energy/LegalFormsState";
import ReadingTypesState from "../../context/administration/energy/ReadingTypesState";
import ImsState from "../../context/administration/energy/ImsState";

//  Transactions
import ContractTerminationReasonsState from "../../context/administration/transactions/ContractTerminationReasonsState";
import TransactionReasonsState from "../../context/administration/transactions/TransactionReasonsState";

//  Order Criterias
import DivisionsState from "../../context/administration/order-criterias/DivisionsState";
import BalancingGroupsState from "../../context/administration/order-criterias/BalancingGroupsState";
import SalutationsState from "../../context/administration/order-criterias/SalutationsState";
import BusinessPartnerTypesState from "../../context/administration/order-criterias/businessPartnerTypesState";

//  Products
import TarifTypesState from "../../context/administration/products/TarifTypesState";
import ProductTypesState from "../../context/administration/products/ProductTypesState";
import ProductGroupsState from "../../context/administration/products/ProductGroupsState";
import ProductsState from "../../context/administration/products/ProductsState";
import ProductAssignmentsState from "../../context/administration/products/ProductAssignmentsState";

//  System
import ParametersState from "../../context/administration/system/ParametersState";
import UsersState from "../../context/administration/system/UsersState";
import UserRolesState from "../../context/administration/system/UserRolesState";

import AppMain from "../../Layout/AppMain";

class Main extends React.Component {
	constructor(props) {
		super(props);

		//  Handle login
		if (sessionStorage.loggedIn === "true") {
			this.state = {
				closedSmallerSidebar: false,
				loggedIn: true,
				jwt: sessionStorage.jwt ? sessionStorage.jwt : "",
			};
		} else {
			this.state = {
				closedSmallerSidebar: false,
				loggedIn: false,
				jwt: "",
			};
		}
	}

	render() {
		let {
			colorScheme,
			enableFixedHeader,
			enableFixedSidebar,
			enableFixedFooter,
			enableClosedSidebar,
			closedSmallerSidebar,
			enableMobileMenu,
			enablePageTabsAlt,
		} = this.props;

		const { loggedIn } = this.state;

		const setLogin = (loginData) => {
			this.setState({ loggedIn: true });
			sessionStorage.loggedIn = true;
			sessionStorage.jwt = loginData.jwt;
			sessionStorage.userName = loginData.user.name;
			sessionStorage.userRole = loginData.user.userRole;
			sessionStorage.avatar = loginData.user.avatar;
			sessionStorage.company = loginData.user.company;
		};

		const setLogout = () => {
			sessionStorage.loggedIn = false;
			sessionStorage.jwt = false;
			sessionStorage.userName = '';
			sessionStorage.userRole = '';
			this.setState({ loggedIn: false });
		};

		return (
			<ResizeDetector
				handleWidth
				render={({ width }) => (
					<Fragment>
						<div
							className={cx(
								"app-container app-theme-" + colorScheme,
								{ "fixed-header": enableFixedHeader },
								{ "fixed-sidebar": enableFixedSidebar || width < 1250 },
								{ "fixed-footer": enableFixedFooter },
								{ "closed-sidebar": enableClosedSidebar || width < 1250 },
								{
									"closed-sidebar-mobile": closedSmallerSidebar || width < 1250,
								},
								{ "sidebar-mobile-open": enableMobileMenu },
								{ "body-tabs-shadow-btn": enablePageTabsAlt }
							)}
						>
							<CustomersState>
								<LeadsState>
									<CampaignsState>
										<IndividualsState>
											<ImportsState>
												<NewOnesState>
													<TerminatesState>
														<ExistingOnesState>
															<PortalState>
																<NavigationState>
																	<ExportPGsState>
																		<ExportProductsState>
																			<RegulationZonesState>
																				<NetworksState>
																					<SuppliersState>
																						<AdministrationsState>
																							<EnergySupplierState>
																								<MarketsState>
																									<LoadProfilesState>
																										<EnergyAssignmentsState>
																											<LegalFormsState>
																												<ReadingTypesState>
																													<ImsState>
																														<ContractTerminationReasonsState>
																															<TransactionReasonsState>
																																<DivisionsState>
																																	<BalancingGroupsState>
																																		<SalutationsState>
																																			<BusinessPartnerTypesState>
																																				<TarifTypesState>
																																					<ProductTypesState>
																																						<ProductGroupsState>
																																							<ProductsState>
																																								<ProductAssignmentsState>
																																									<ParametersState>
																																										<UsersState>
																																											<UserRolesState>
																																												<AppMain
																																													loggedIn={loggedIn}
																																													setLogin={setLogin}
																																													setLogout={setLogout}
																																												/>
																																											</UserRolesState>
																																										</UsersState>
																																									</ParametersState>
																																								</ProductAssignmentsState>
																																							</ProductsState>
																																						</ProductGroupsState>
																																					</ProductTypesState>
																																				</TarifTypesState>
																																			</BusinessPartnerTypesState>
																																		</SalutationsState>
																																	</BalancingGroupsState>
																																</DivisionsState>
																															</TransactionReasonsState>
																														</ContractTerminationReasonsState>
																													</ImsState>
																												</ReadingTypesState>
																											</LegalFormsState>
																										</EnergyAssignmentsState>
																									</LoadProfilesState>
																								</MarketsState>
																							</EnergySupplierState>
																						</AdministrationsState>
																					</SuppliersState>
																				</NetworksState>
																			</RegulationZonesState>
																		</ExportProductsState>
																	</ExportPGsState>
																</NavigationState>
															</PortalState>
														</ExistingOnesState>
													</TerminatesState>
												</NewOnesState>
											</ImportsState>
										</IndividualsState>
									</CampaignsState>
								</LeadsState>
							</CustomersState>
						</div>
					</Fragment>
				)}
			/>
		);
	}
}

const mapStateToProp = (state) => ({
	colorScheme: state.ThemeOptions.colorScheme,
	enableFixedHeader: state.ThemeOptions.enableFixedHeader,
	enableMobileMenu: state.ThemeOptions.enableMobileMenu,
	enableFixedFooter: state.ThemeOptions.enableFixedFooter,
	enableFixedSidebar: state.ThemeOptions.enableFixedSidebar,
	enableClosedSidebar: state.ThemeOptions.enableClosedSidebar,
	enablePageTabsAlt: state.ThemeOptions.enablePageTabsAlt,
});

export default withRouter(connect(mapStateToProp)(Main));
