/**
 * 		File:		./src/context/exports/TerminatesReducer.js
 * 
 * 		Subject:	Customer contract terminations' export state reducer
 */
import {
	GET_EXPORTS_TERMINATES,
} from "../types";

export default (state, action) => {
  
  switch (action.type) {
	
	case GET_EXPORTS_TERMINATES:
		return {
			...state,
			dataTerminate: action.payload.terminateExports,
		}

    //  Default Path
    default:
      return state;
  }
};
