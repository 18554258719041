/**
 *      File:       ./src/context/exports/portalContext .js
 * 
 *      Subject:    customer portal's export context
 */
import { createContext } from "react";

const portalContext  = createContext();

export default portalContext ;
