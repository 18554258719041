/**
 *      File:       ./src/context/administration/energy/readingTypesContext.js
 * 
 *      Subject:    Reading Types' Context
 */
import { createContext } from "react";

const readingTypesContext = createContext();

export default readingTypesContext;
