/**
 * 		File:		./src/context/exports/NewOnesState.js
 * 
 * 		Subject:	State for new customers' exports
 */

import React, { useReducer } from "react";
import axios from 'axios';
import NewOnesContext from "./newOnesContext";
import NewOnesReducer from "./newOnesReducer";

import {
	GET_EXPORTS_NEW_ONES,
  } from '../types';


const NewOnesState = (props) => {

  const initialState = {

	dataChange: [],
	dataEntry:	[],
    
  };
  const [state, dispatch] = useReducer(NewOnesReducer, initialState);


  //------------------------------
  //	GET THE EXPORTS
  //------------------------------
  const getExports = () => {

	//	Set call
	const params = new URLSearchParams();
    params.append('jwt', sessionStorage.jwt);
    params.append('exportPage', "newOnes");

	let url = process.env.REACT_APP_API_URI + '/exports/get_exports.php';
    let axiosConfig = {
        headers: {
            "Content-Type": "application/x-www-form-urlencoded"
        }
    };

	//	Call backend
    axios.post(url,params,axiosConfig)
    .then(response => {
        if(response.status === 200) {

            // console.log(response.data);
            
            dispatch({
                type: GET_EXPORTS_NEW_ONES,
                payload: {
					changeExports: response.data.changeExports,
					entryExports: response.data.entryExports
				},
            });

        } else if(response.status === 401) {
            window.location.href ='#/logout';
        } else {
            console.log('Anderer Fehler');
        }
    })
    .catch(function(error) {
        if(error.response){
            // The server answered with an error
            if(error.response.status === 401) {
                window.location.href = '#/logout';
            } else {
                console.log(error.response.data);
                console.log(error.response.status);
                console.log(error.response.headers);
            }
        } else if(error.request) {
            //  The request was made but the server didn't respond
            console.log(error.request);
        } else {
            //  Something else went wrong
            console.log('Error', error.message);
        }
    })
  }

  const exportEntry = () => {

	//	Set call
	const params = new URLSearchParams();
    params.append('jwt', sessionStorage.jwt);

	let url = process.env.REACT_APP_API_URI + '/exports/start_export_entry.php';
    let axiosConfig = {
        headers: {
            "Content-Type": "application/x-www-form-urlencoded"
        }
	};
	
	let entryInProgress = state.dataEntry;
	entryInProgress[0].id = 0;
	entryInProgress[0].filename = "Export wird erstellt...";
	dispatch({
		type: GET_EXPORTS_NEW_ONES,
		payload: {
			changeExports: state.dataChange,
			entryExports: entryInProgress,
		},
	});

	//	Call backend
    axios.post(url,params,axiosConfig)
    .then(response => {
        if(response.status === 200) {

			// console.log(response.data);
			window.setTimeout(getExports, 1000);

        } else if(response.status === 401) {
            window.location.href ='#/logout';
        } else {
            console.log('Anderer Fehler');
        }
    })
    .catch(function(error) {
        if(error.response){
            // The server answered with an error
            if(error.response.status === 401) {
                window.location.href = '#/logout';
            } else {
                console.log(error.response.data);
                console.log(error.response.status);
                console.log(error.response.headers);
            }
        } else if(error.request) {
            //  The request was made but the server didn't respond
            console.log(error.request);
        } else {
            //  Something else went wrong
            console.log('Error', error.message);
        }
    })
  }

  const exportChange = () => {

	//	Set call
	const params = new URLSearchParams();
    params.append('jwt', sessionStorage.jwt);

	let url = process.env.REACT_APP_API_URI + '/exports/start_export_supplier_change.php';
    let axiosConfig = { headers: { "Content-Type": "application/x-www-form-urlencoded" }};
	
	let changeInProgress = state.dataChange;
	changeInProgress[0].id = 0;
	changeInProgress[0].filename = "Export wird erstellt...";
	dispatch({
		type: GET_EXPORTS_NEW_ONES,
		payload: {
			changeExports: changeInProgress,
			entryExports: state.dataEntry,
		},
	});

	//	Call backend
    axios.post(url,params,axiosConfig)
    .then(response => {
        if(response.status === 200) {

			// console.log(response.data);
			window.setTimeout(getExports, 1000);

        } else if(response.status === 401) {
            window.location.href ='#/logout';
        } else {
            console.log('Anderer Fehler');
        }
    })
    .catch(function(error) {
        if(error.response){
            // The server answered with an error
            if(error.response.status === 401) {
                window.location.href = '#/logout';
            } else {
                console.log(error.response.data);
                console.log(error.response.status);
                console.log(error.response.headers);
            }
        } else if(error.request) {
            //  The request was made but the server didn't respond
            console.log(error.request);
        } else {
            //  Something else went wrong
            console.log('Error', error.message);
        }
    })

  }

  return (
    <NewOnesContext.Provider
      value={{

		dataChange: state.dataChange,
		dataEntry:	state.dataEntry,

		getExports,
		exportEntry,
		exportChange,
        
      }}
    >
      {props.children}
    </NewOnesContext.Provider>
  );
};

export default NewOnesState;
