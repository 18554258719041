/**
 * 		File:		./src/context/administration/energy/LoadProfilesState.js
 * 
 * 		Subject:	Load Profiles' State
 */

import React, { useReducer, useEffect } from "react";
import axios from "axios";
import LoadProfilesContext from "./loadProfilesContext";
import LoadProfilesReducer from "./loadProfilesReducer";
import FormInput from "../../../Components/FormInput";

import {
  GET_ENTITIES,
  SET_ACTIVE_ENTITY,
  SET_DELETE_ENTITY,
  SET_DELETE_MODAL_ENTITY_NAME,
  SET_EDIT_DIALOG_TITLE,
  SET_EDIT_ENTITY,
  SET_ENTITY_DIALOG_FIELDS,
  SET_ERROR,
  LOAD_PROFILE_NAME_CHANGE,
  LOAD_PROFILE_SHORTCUT_CHANGE,
} from "../../types";

const LoadProfilesState = (props) => {
  const initialState = {
    entities: [],
    activeEntity: 0,
    deleteModalEntityName: "",
    deleteModalState: false,
    deleteModalTitle: "Lastprofil löschen",
    entityDeleteDialog: false,
    entityDeleteEntityName: "",
    editDialogTitle: "Neues Lastprofil anlegen",
    editDialogError: "",
    editDialogFields: [],
    editState: false,
    editDialogLoadProfileName: "",
    editDialogLoadProfileShortcut: "",
  };
  const [state, dispatch] = useReducer(LoadProfilesReducer, initialState);

  useEffect(() => {
    const edf = [
      {
        id: 1,
        fieldType: (
          <FormInput
            context={LoadProfilesContext}
            id="load_profile_name"
            label="Lastprofil"
            onChangeFunction={loadProfileNameChange}
            placeholder="Lastprofil"
            required
            type="text"
            value="editDialogLoadProfileName"
          />
        ),
      },
      {
        id: 2,
        fieldType: (
          <FormInput
            context={LoadProfilesContext}
            id="load_profile_shortcut"
            label="Kennung"
            onChangeFunction={loadProfileShortcutChange}
            placeholder="Kennung"
            type="text"
            value="editDialogLoadProfileShortcut"
          />
        ),
      },
    ];

    dispatch({
      type: SET_ENTITY_DIALOG_FIELDS,
      payload: edf,
    });

    getEntities();

    // eslint-disable-next-line
  }, [state.editDialogError]);

  const getEntities = () => {
    const params = new URLSearchParams();
    params.append("jwt", sessionStorage.jwt);

    let axiosConfig = {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    };

    let url =
      process.env.REACT_APP_API_URI +
      "/administration/energy/get_load_profiles.php";

    axios
      .post(url, params, axiosConfig)
      .then((response) => {
        if (response.status === 200) {
		  //   console.log(response.data);
		  const entities = response.data.entities;
		  for(var i in entities) {
			  entities[i].id = (isNaN(entities[i].id) || entities[i].id === "") ? 0 : Number.parseInt(entities[i].id);
		  }

          dispatch({
            type: GET_ENTITIES,
            payload: entities,
          });
        } else if (response.status === 401) {
          window.location.href = "#/logout";
        } else {
          console.log("Anderer Fehler");
        }
      })
      .catch(function(error) {
        if (error.response) {
          // The server answered with an error
          if (error.response.status === 401) {
            window.location.href = "#/logout";
          } else {
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          }
        } else if (error.request) {
          //  The request was made but the server didn't respond
          console.log(error.request);
        } else {
          //  Something else went wrong
          console.log("Error", error.message);
        }
      });
  };

  /*--------------------------------
  //    function editEntity()
  --------------------------------*/
  const editEntity = (id) => {
    if (id > 0) {
      const params = new URLSearchParams();
      params.append("jwt", sessionStorage.jwt);
      params.append("id", id);

      let axiosConfig = {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      };

      let url =
        process.env.REACT_APP_API_URI +
        "/administration/energy/get_load_profile.php";

      axios
        .post(url, params, axiosConfig)
        .then((response) => {
          if (response.status === 200) {
            // console.log(response.data);

            dispatch({
              type: SET_ACTIVE_ENTITY,
              payload: response.data.loadProfile,
            });

            dispatch({
              type: SET_EDIT_ENTITY,
              payload: {
                id: id,
                editState: true,
              },
            });

            dispatch({
              type: SET_EDIT_DIALOG_TITLE,
              payload: "Lastprofil bearbeiten",
            });
          } else if (response.status === 401) {
            window.location.href = "#/logout";
          } else {
            console.log("Anderer Fehler");
          }
        })
        .catch(function(error) {
          if (error.response) {
            // The server answered with an error
            if (error.response.status === 401) {
              window.location.href = "#/logout";
            } else {
              console.log(error.response.data);
              console.log(error.response.status);
              console.log(error.response.headers);
            }
          } else if (error.request) {
            //  The request was made but the server didn't respond
            console.log(error.request);
          } else {
            //  Something else went wrong
            console.log("Error", error.message);
          }
        });
    } else {
      dispatch({
        type: SET_EDIT_DIALOG_TITLE,
        payload: "Neue Verwaltung anlegen",
      });
    }

    dispatch({
      type: SET_EDIT_ENTITY,
      payload: {
        id: id,
        editState: true,
      },
    });
  };

  const setError = (errMsg) => {
    dispatch({
      type: SET_ERROR,
      payload: errMsg,
    });
  };

  /*------------------------------
  //    function saveEntity()
  ------------------------------*/
  const saveEntity = async () => {
    if (state.editDialogLoadProfileName === "") {
      setError("Bitte geben Sie eine Bezeichnung für das Lastprofil ein.");
      return false;
    }

    const params = new URLSearchParams();
    params.append("jwt", sessionStorage.jwt);
    params.append("id", state.activeEntity);
    params.append("loadProfileName", state.editDialogLoadProfileName);
    params.append("loadProfileShortcut", state.editDialogLoadProfileShortcut);

    let axiosConfig = {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    };

    let url =
      process.env.REACT_APP_API_URI +
      "/administration/energy/set_load_profile.php";

    axios
      .post(url, params, axiosConfig)
      .then((response) => {
        if (response.status === 200) {
          //   console.log(response.data);

          const loadProfile = {
            load_profile_name: "",
            load_profile_shortcut: "",
          };
          getEntities();

          dispatch({
            type: SET_ACTIVE_ENTITY,
            payload: loadProfile,
          });
          dispatch({
            type: SET_EDIT_ENTITY,
            payload: {
              id: 0,
              editState: false,
            },
          });
          setError("");
          return true;
        } else if (response.status === 401) {
          window.location.href = "#/logout";
        } else {
          console.log("Anderer Fehler");
          return false;
        }
      })
      .catch(function(error) {
        if (error.response) {
          // The server answered with an error
          if (error.response.status === 401) {
            window.location.href = "#/logout";
          } else {
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
            return false;
          }
        } else if (error.request) {
          //  The request was made but the server didn't respond
          console.log(error.request);
          return false;
        } else {
          //  Something else went wrong
          console.log("Error", error.message);
          return false;
        }
      });
    return true;
  };

  const closeEdit = () => {
    dispatch({
      type: SET_ACTIVE_ENTITY,
      payload: {
        load_profile_name: "",
        load_profile_shortcut: "",
      },
    });

    dispatch({
      type: SET_EDIT_ENTITY,
      payload: {
        id: 0,
        editState: false,
      },
    });
    setError("");
  };

  const deleteEntity = (id) => {
    const params = new URLSearchParams();
    params.append("jwt", sessionStorage.jwt);
    params.append("id", id);

    let axiosConfig = {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    };

    let url =
      process.env.REACT_APP_API_URI +
      "/administration/energy/get_load_profile.php";

    axios
      .post(url, params, axiosConfig)
      .then((response) => {
        if (response.status === 200) {
          // console.log(response.data);

          dispatch({
            type: SET_ACTIVE_ENTITY,
            payload: response.data.loadProfile,
          });

          dispatch({
            type: SET_DELETE_ENTITY,
            payload: {
              id: id,
              deleteModalState: true,
            },
          });

          dispatch({
            type: SET_DELETE_MODAL_ENTITY_NAME,
            payload:
              'Möchten Sie das Lastprofil "' +
              response.data.loadProfile.load_profile_name +
              '" wirklich löschen?',
          });
        } else if (response.status === 401) {
          window.location.href = "#/logout";
        } else {
          console.log("Anderer Fehler");
        }
      })
      .catch(function(error) {
        if (error.response) {
          // The server answered with an error
          if (error.response.status === 401) {
            window.location.href = "#/logout";
          } else {
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          }
        } else if (error.request) {
          //  The request was made but the server didn't respond
          console.log(error.request);
        } else {
          //  Something else went wrong
          console.log("Error", error.message);
        }
      });
  };

  const closeDelete = () => {
    dispatch({
      type: SET_ACTIVE_ENTITY,
      payload: {
        load_profile_name: "",
        load_profile_shortcut: "",
      },
    });

    dispatch({
      type: SET_EDIT_ENTITY,
      payload: {
        id: 0,
        editState: false,
      },
    });

    dispatch({
      type: SET_DELETE_ENTITY,
      payload: {
        id: 0,
        deleteModalState: false,
      },
    });

    dispatch({
      type: SET_DELETE_MODAL_ENTITY_NAME,
      payload: "",
    });
  };

  const deleteEntityConfirmed = () => {
    const params = new URLSearchParams();
    params.append("jwt", sessionStorage.jwt);
    params.append("id", state.activeEntity);

    let axiosConfig = {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    };

    let url =
      process.env.REACT_APP_API_URI +
      "/administration/energy/delete_load_profile.php";

    axios
      .post(url, params, axiosConfig)
      .then((response) => {
        if (response.status === 200) {
          // console.log(response.data);
          getEntities();
          closeDelete();
        } else if (response.status === 401) {
          window.location.href = "#/logout";
        } else {
          console.log("Anderer Fehler");
        }
      })
      .catch(function(error) {
        if (error.response) {
          // The server answered with an error
          if (error.response.status === 401) {
            window.location.href = "#/logout";
          } else {
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          }
        } else if (error.request) {
          //  The request was made but the server didn't respond
          console.log(error.request);
        } else {
          //  Something else went wrong
          console.log("Error", error.message);
        }
      });
  };

  /*-------------------------------------
    //  PAGE SPECIFIC
    -------------------------------------*/

  const loadProfileNameChange = (e) => {
    if (state.editDialogError !== "" && state.editDialogLoadProfileName === "")
      setError("");
    dispatch({
      type: LOAD_PROFILE_NAME_CHANGE,
      payload: e.target.value,
    });
  };

  const loadProfileShortcutChange = (e) => {
    dispatch({
      type: LOAD_PROFILE_SHORTCUT_CHANGE,
      payload: e.target.value,
    });
  };

  return (
    <LoadProfilesContext.Provider
      value={{
        entities: state.entities,
        editDialogFields: state.editDialogFields,
        editDialogError: state.editDialogError,
        activeEntity: state.activeEntity,
        editState: state.editState,
        userRoleLabel: state.userRoleLabel,
        editDialogTitle: state.editDialogTitle,
        deleteModalTitle: state.deleteModalTitle,
        deleteModalEntityName: state.deleteModalEntityName,
        deleteModalState: state.deleteModalState,

        editDialogLoadProfileName: state.editDialogLoadProfileName,
        editDialogLoadProfileShortcut: state.editDialogLoadProfileShortcut,

        editEntity,
        setError,
        saveEntity,
        getEntities,
        closeEdit,
        deleteEntity,
        closeDelete,
        deleteEntityConfirmed,

        loadProfileNameChange,
        loadProfileShortcutChange,
      }}
    >
      {props.children}
    </LoadProfilesContext.Provider>
  );
};

export default LoadProfilesState;
