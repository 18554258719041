/**
 *      File:       ./src/Components/FormCheckbox.js
 * 
 *      Subject:    Common checkbox for universal use
 */

import React, { useContext } from 'react'
import {
    FormGroup,
    Label, Input
} from 'reactstrap';

const FormCheckbox = (props) => {

    const {
        id,
        disabled,
        label,
        value,
        onChangeFunction,
        required,
        context
    } = props;

    const innerContext = useContext(context);
    const contextValue = innerContext[value];

    return (
        <FormGroup check>
            {disabled && <Input type="checkbox" id={id} checked={contextValue} onChange={onChangeFunction} disabled />}
            {!disabled && <Input type="checkbox" id={id} checked={contextValue} onChange={onChangeFunction} />}
            <Label for={id} check>
                {label}
                <span>&nbsp;<sup>{required ? '*' : ''}</sup></span>
            </Label>
        </FormGroup>
    )
}

export default FormCheckbox
