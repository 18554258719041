/**
 *      File:       ./src/context/administration/energy/energySupplierContext.js
 * 
 *      Subject:    Energy Suppliers' context
 */

import { createContext } from 'react';

const energySupplierContext = createContext();

export default energySupplierContext;