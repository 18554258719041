/**
 *      File:       ./src/context/administration/energy/marketsReducer.js
 * 
 *      Subject:    Markets' State Reducer
 */

import {
    GET_ENTITIES,
    SET_ACTIVE_ENTITY,
    SET_DELETE_ENTITY,
    SET_DELETE_MODAL_ENTITY_NAME,
    SET_EDIT_DIALOG_TITLE,
    SET_EDIT_ENTITY,
    SET_ENTITY_DIALOG_FIELDS,
    SET_ERROR,

    SET_MARKET_FOREIGNS,
    MARKET_ID_CHANGE,
    MARKET_NAME_CHANGE,
    DIVISIONS_ID_CHANGE

} from '../../types';

export default(state, action) => {

    switch(action.type)
    {
        //  General section

        case GET_ENTITIES:
            return {
                ...state,
                entities: action.payload
            }

        case SET_ACTIVE_ENTITY:
            return {
                ...state,
                editDialogMarketId: action.payload.market_id,
                editDialogMarketName: action.payload.market_name,
                editDialogDivisionsId: action.payload.divisions_id
            }

        case SET_DELETE_ENTITY:
            return {
                ...state,
                activeEntity: action.payload.id,
                deleteModalState: action.payload.deleteModalState
            }

        case SET_DELETE_MODAL_ENTITY_NAME:
            return {
                ...state,
                deleteModalEntityName: action.payload
            }

        case SET_EDIT_DIALOG_TITLE:
            return {
                ...state,
                editDialogTitle: action.payload
            }

        case SET_EDIT_ENTITY:
            return {
                ...state,
                activeEntity: action.payload.id,
                editState: action.payload.editState
            }

        case SET_ENTITY_DIALOG_FIELDS:
            return {
                ...state,
                editDialogFields: action.payload
            }

        case SET_ERROR:
            return {
                ...state,
                editDialogError: action.payload
            }


        //  Individual Section

        case SET_MARKET_FOREIGNS:
            return {
                ...state,
                divisions: action.payload.divisions
            }

        case MARKET_ID_CHANGE:
            return {
                ...state,
                editDialogMarketId: action.payload
            }
            
        case MARKET_NAME_CHANGE:
            return {
                ...state,
                editDialogMarketName: action.payload
            }

        case DIVISIONS_ID_CHANGE:
            return {
                ...state,
                editDialogDivisionsId: action.payload
            }

            
        //  Default Path
        default:
            return state;
    }
}