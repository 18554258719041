/**
 *      File:       ./src/context/administration/products/ProductGroupsState.js
 * 
 *      Subject:    Product Groups' State
 */
import React, { useReducer, useEffect } from 'react';
import axios from 'axios';
import ProductGroupsContext from './productGroupsContext';
import ProductGroupsReducer from './productGroupsReducer';
import FormInput from '../../../Components/FormInput';
import FormSelect from '../../../Components/FormSelect';

import {

	GET_ENTITIES,
	NO_CHANGE_STATE,
    SET_ACTIVE_ENTITY,
    SET_DELETE_ENTITY,
    SET_DELETE_MODAL_ENTITY_NAME,
    SET_EDIT_DIALOG_TITLE,
    SET_EDIT_ENTITY,
    SET_ENTITY_DIALOG_FIELDS,
    SET_ERROR,

    SET_USER_FOREIGNS,
    PRODUCT_GROUP_NAME_CHANGE,
	DESCRIPTION_CHANGE,
	TARIF_TYPES_ID_CHANGE,
    ENERGY_SUPPLIER_ID_CHANGE

} from '../../types';

const ProductGroupsState = (props) => {

    const initialState = {

        entities:               [],
        activeEntity:           0,
        deleteModalEntityName:  '',
        deleteModalState:       false,
        deleteModalTitle:       'Produktgruppe löschen',
        entityDeleteDialog:     false,
        entityDeleteEntityName: '',
        editDialogTitle:        'Neue Produktgruppe anlegen',
        editDialogError:        '',
        editDialogFields:       [],
        editState:              false,   
        editDialogProductGroupName:		'',
		editDialogDescription: 			'',
		editDialogTarifTypesId:			-1,
		editDialogEnergySupplierId: 	-1,
		tarifTypes:						[],
		energySuppliers: 				[],
		noChangeState:					false,

    };
    const [state,dispatch] = useReducer(ProductGroupsReducer, initialState);

    useEffect(() => {

        async function fetchData() {
            
            const params = new URLSearchParams();
            params.append('jwt', sessionStorage.jwt);

			let url = process.env.REACT_APP_API_URI + '/administration/products/get_product_group_foreigns.php';
            let axiosConfig = {
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded"
                }
            };

            axios.post(url,params,axiosConfig)
            .then(response => {
                if(response.status === 200) {
                    // console.log(response.data);
                    
                    dispatch({
                        type: SET_USER_FOREIGNS,
                        payload: {
							energySuppliers:	response.data.energySuppliers,
							tarifTypes: 		response.data.tarifTypes,
                        }
                    });

                } else if(response.status === 401) {
                    window.location.href ='#/logout';
                } else {
                    console.log('Anderer Fehler');
                }
            })
            .catch(function(error) {
                if(error.response){
                    // The server answered with an error
                    if(error.response.status === 401) {
                        window.location.href = '#/logout';
                    } else {
                        console.log(error.response.data);
                        console.log(error.response.status);
                        console.log(error.response.headers);
                    }
                } else if(error.request) {
                    //  The request was made but the server didn't respond
                    console.log(error.request);
                } else {
                    //  Something else went wrong
                    console.log('Error', error.message);
                }
            })
        }
		fetchData();
		
        const edf = [
            {
                id: 1,
                fieldType: (<FormInput
                    context             = {ProductGroupsContext}
                    id                  = "product_group_name"
                    label               = "Produktgruppe"
                    onChangeFunction    = {productGroupNameChange}
                    placeholder         = "Produktgruppe"
                    required
                    type                = "text"
                    value               = "editDialogProductGroupName"
                />),
            },
            {
                id: 2,
                fieldType: (<FormInput
                    context             = {ProductGroupsContext}
                    id                  = "description"
                    label               = "Beschreibung"
                    onChangeFunction    = {descriptionChange}
                    placeholder         = "Beschreibung"
                    type                = "text"
                    value               = "editDialogDescription"
                />),
			},
			{
				id: 3,
                fieldType: (<FormSelect
                    context             = {ProductGroupsContext}
                    id                  = "tarif_types_id"
                    label               = "Tariftyp"
                    onChangeFunction    = {tarifTypesIdChange}
                    required            
                    selectOptions       = "tarifTypes"
                    value               = "editDialogTarifTypesId"
                />),
			},
            {
                id: 4,
                fieldType: (<FormSelect
                    context             = {ProductGroupsContext}
                    id                  = "energy_supplier_id"
                    label               = "Mandant"
                    onChangeFunction    = {energySupplierIdChange}
                    required            
                    selectOptions       = "energySuppliers"
                    value               = "editDialogEnergySupplierId"
                />),
            }
        ];

        dispatch({
            type: SET_ENTITY_DIALOG_FIELDS,
            payload: edf
        });

        getEntities();

        // eslint-disable-next-line
    }, [state.editDialogError, state.activeEntity, state.editDialogEnergySupplierId, state.editDialogTarifTypesId]);

    const getEntities = () => {

        const params = new URLSearchParams();
        params.append('jwt', sessionStorage.jwt);

		let url = process.env.REACT_APP_API_URI + '/administration/products/get_product_groups.php';
        let axiosConfig = {
            headers: {
                "Content-Type": "application/x-www-form-urlencoded"
            }
        };


        axios.post(url,params,axiosConfig)
        .then(response => {
            if(response.status === 200) {

                // console.log(response.data.entities);
                
                dispatch({
                    type: GET_ENTITIES,
                    payload: response.data.entities
                });

            } else if(response.status === 401) {
                window.location.href ='#/logout';
            } else {
                console.log('Anderer Fehler');
            }
        })
        .catch(function(error) {
            if(error.response){
                // The server answered with an error
                if(error.response.status === 401) {
                    window.location.href = '#/logout';
                } else {
                    console.log(error.response.data);
                    console.log(error.response.status);
                    console.log(error.response.headers);
                }
            } else if(error.request) {
                //  The request was made but the server didn't respond
                console.log(error.request);
            } else {
                //  Something else went wrong
                console.log('Error', error.message);
            }
        })
    }

    const editEntity = (id) => {

        if(id > 0)
        {
            const params = new URLSearchParams();
            params.append('jwt', sessionStorage.jwt);
            params.append('id', id);

			let url = process.env.REACT_APP_API_URI + '/administration/products/get_product_group.php';
            let axiosConfig = {
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded"
                }
            };

            axios.post(url,params,axiosConfig)
            .then(response => {
                if(response.status === 200) {
					// console.log(response.data);
					
                    dispatch({
                        type: SET_ACTIVE_ENTITY,
                        payload: response.data.productGroup
					});

                    dispatch({
                        type: SET_EDIT_ENTITY,
                        payload: {
                            id: id,
                            editState: true
                        }
					});

                    dispatch({
                        type: SET_EDIT_DIALOG_TITLE,
                        payload: 'Produktgruppe bearbeiten'
					});

                } else if(response.status === 401) {
                    window.location.href ='#/logout';
                } else {
                    console.log('Anderer Fehler');
                }
            })
            .catch(function(error) {
                if(error.response){
                    // The server answered with an error
                    if(error.response.status === 401) {
                        window.location.href = '#/logout';
                    } else {
                        console.log(error.response.data);
                        console.log(error.response.status);
                        console.log(error.response.headers);
                    }
                } else if(error.request) {
                    //  The request was made but the server didn't respond
                    console.log(error.request);
                } else {
                    //  Something else went wrong
                    console.log('Error', error.message);
                }
            })
        } else {
            dispatch({
                type: SET_EDIT_DIALOG_TITLE,
                payload: 'Neue Produktgruppe anlegen'
            });
        }

        dispatch({
            type: SET_EDIT_ENTITY,
            payload: {
                id: id,
                editState: true
            }
        });        
    }

    const setError = (errMsg) => {
        dispatch({
            type: SET_ERROR,
            payload: errMsg
        });
    }

    const saveEntity = async () => {

        if(state.editDialogProductGroupName === '') {
            setError('Bitte geben Sie eine Bezeichnung für die Produktgruppe ein.');
            return false;
        }

        if(state.editDialogEnergySupplierId < 0) {
            setError('Bitte wählen Sie einen Mandanten für die Produktgruppe an');
            return false;
        }

        const params = new URLSearchParams();
        params.append('jwt', sessionStorage.jwt);
        params.append('id', state.activeEntity);
        params.append('productGroupName', state.editDialogProductGroupName);
		params.append('description', state.editDialogDescription);
		params.append('tarifTypesId', state.editDialogTarifTypesId);
        params.append('energySupplierId', state.editDialogEnergySupplierId);
		params.append('url', state.editDialogUrl);

        let axiosConfig = {
            headers: {
                "Content-Type": "application/x-www-form-urlencoded"
            }
        };

        let url = process.env.REACT_APP_API_URI + '/administration/products/set_product_group.php';
        axios.post(url,params,axiosConfig)
        .then(response => {
            if(response.status === 200) {

                // console.log(response.data);

                const productGroup = {
                    product_group_name: '',
					description: '',
					tarif_types_id: -1,
                    energy_supplier_id: -1
                };
                getEntities();

                dispatch({
                    type: SET_ACTIVE_ENTITY,
                    payload: productGroup
                });
                dispatch({
                    type: SET_EDIT_ENTITY,
                    payload: {
                        id: 0,
                        editState: false
                    }
                });
                return true;

            } else if(response.status === 401) {
                window.location.href ='#/logout';
            } else {
                console.log('Anderer Fehler');
                return false;
            }
        })
        .catch(function(error) {
            if(error.response){
                // The server answered with an error
                if(error.response.status === 401) {
                    window.location.href = '#/logout';
                } else {
                    console.log(error.response.data);
                    console.log(error.response.status);
                    console.log(error.response.headers);
                    return false;
                }
            } else if(error.request) {
                //  The request was made but the server didn't respond
                console.log(error.request);
                return false;
            } else {
                //  Something else went wrong
                console.log('Error', error.message);
                return false;
            }
        })
        return true;
    }

    const closeEdit = () => {
        dispatch({
            type: SET_ACTIVE_ENTITY,
            payload: {
                product_group_name: '',
				description: '',
				tarif_types_id: -1,
                energy_supplier_id: -1
            }
        });
        
        dispatch({
            type: SET_EDIT_ENTITY,
            payload: {
                id: 0,
                editState: false
            }
        });
    }

    const deleteEntity = (id) => {
        
        const params = new URLSearchParams();
        params.append('jwt', sessionStorage.jwt);
        params.append('id', id);

		let url = process.env.REACT_APP_API_URI + '/administration/products/get_product_group.php';
        let axiosConfig = {
            headers: {
                "Content-Type": "application/x-www-form-urlencoded"
            }
        };


        axios.post(url,params,axiosConfig)
        .then(response => {
            if(response.status === 200) {
                // console.log(response.data);
				
				if(response.data.bInUse === 0)
				{
					dispatch({
						type: SET_ACTIVE_ENTITY,
						payload: response.data.productGroup
					});
	
					dispatch({
						type: SET_DELETE_ENTITY,
						payload: {
							id: id,
							deleteModalState: true
						}
					});
	
					dispatch({
						type: SET_DELETE_MODAL_ENTITY_NAME,
						payload: 'Möchten Sie die Produktgruppe "' + response.data.productGroup.product_group_name + '" wirklich löschen?'
					});
				}
				else {
					dispatch({
						type: NO_CHANGE_STATE,
						payload: true,
					});
				}

            } else if(response.status === 401) {
                window.location.href ='#/logout';
            } else {
                console.log('Anderer Fehler');
            }
        })
        .catch(function(error) {
            if(error.response){
                // The server answered with an error
                if(error.response.status === 401) {
                    window.location.href = '#/logout';
                } else {
                    console.log(error.response.data);
                    console.log(error.response.status);
                    console.log(error.response.headers);
                }
            } else if(error.request) {
                //  The request was made but the server didn't respond
                console.log(error.request);
            } else {
                //  Something else went wrong
                console.log('Error', error.message);
            }
        })
    }

    const closeDelete = () => {

        dispatch({
            type: SET_ACTIVE_ENTITY,
            payload: {
                product_group_name: '',
				description: '',
				tarif_types_id: -1,
                energy_supplier_id: -1
            }
        });
        
        dispatch({
            type: SET_EDIT_ENTITY,
            payload: {
                id: 0,
                editState: false
            }
        });

        dispatch({
            type: SET_DELETE_ENTITY,
            payload: {
                id: 0,
                deleteModalState: false
            }
        });

        dispatch({
            type: SET_DELETE_MODAL_ENTITY_NAME,
            payload: ''
        });
    }

    const deleteEntityConfirmed = () => {

        const params = new URLSearchParams();
        params.append('jwt', sessionStorage.jwt);
        params.append('id', state.activeEntity);

		let url = process.env.REACT_APP_API_URI + '/administration/products/delete_product_group.php';
        let axiosConfig = {
            headers: {
                "Content-Type": "application/x-www-form-urlencoded"
            }
        };

        axios.post(url,params,axiosConfig)
        .then(response => {
            if(response.status === 200) {
                // console.log(response.data);
                
                getEntities();
                closeDelete();

            } else if(response.status === 401) {
                window.location.href ='#/logout';
            } else {
                console.log('Anderer Fehler');
            }
        })
        .catch(function(error) {
            if(error.response){
                // The server answered with an error
                if(error.response.status === 401) {
                    window.location.href = '#/logout';
                } else {
                    console.log(error.response.data);
                    console.log(error.response.status);
                    console.log(error.response.headers);
                }
            } else if(error.request) {
                //  The request was made but the server didn't respond
                console.log(error.request);
            } else {
                //  Something else went wrong
                console.log('Error', error.message);
            }
        })
    }

    /*-------------------------------------
    //  PAGE SPECIFIC
    -------------------------------------*/

    const productGroupNameChange = (e) => {

        if(state.editDialogError !== '' && state.editDialogProductGroupName === '') {
            setError('');
        }

        dispatch({
            type: PRODUCT_GROUP_NAME_CHANGE,
            payload: e.target.value
        });
    }
    
    const descriptionChange = (e) => {

        dispatch({
            type: DESCRIPTION_CHANGE,
            payload: e.target.value
        });
	}
	
	const tarifTypesIdChange = (e) => {
		dispatch({
			type: TARIF_TYPES_ID_CHANGE,
			payload: e.target.value,
		});
	}

    const energySupplierIdChange = (e) => {

        if(state.editDialogError !== '' && state.editDialogEnergySupplierId * 1 === -1) {
            setError('');
		}

		const newValue = e.target.value;

		const params = new URLSearchParams();
		params.append('jwt', sessionStorage.jwt);
		params.append('id', state.activeEntity);

		let url = process.env.REACT_APP_API_URI + '/administration/products/check_product_group_usage.php';
		let axiosConfig = {
			headers: {
				"Content-Type": "application/x-www-form-urlencoded"
			}
		};

		axios.post(url,params,axiosConfig)
		.then(response => {
			if(response.status === 200) {
				// console.log(response.data);

				if(response.data.bChange === 0) {
					dispatch({
						type: NO_CHANGE_STATE,
						payload: true,
					});
				}
				else {
		
					dispatch({
						type: ENERGY_SUPPLIER_ID_CHANGE,
						payload: newValue,
					});
				}				

			} else if(response.status === 401) {
				window.location.href ='#/logout';
			} else {
				console.log('Anderer Fehler');
			}
		})
		.catch(function(error) {
			if(error.response){
				// The server answered with an error
				if(error.response.status === 401) {
					window.location.href = '#/logout';
				} else {
					console.log(error.response.data);
					console.log(error.response.status);
					console.log(error.response.headers);
				}
			} else if(error.request) {
				//  The request was made but the server didn't respond
				console.log(error.request);
			} else {
				//  Something else went wrong
				console.log('Error', error.message);
			}
		})

	}
	
	const closeNoChange = () => {
		dispatch({
			type: NO_CHANGE_STATE,
			payload: false
		});
	}

    return <ProductGroupsContext.Provider value={{

        entities: state.entities,
        editDialogFields: state.editDialogFields,
        editDialogError: state.editDialogError,
        activeEntity: state.activeEntity,
        editState: state.editState,
        userRoleLabel: state.userRoleLabel,
        editDialogTitle: state.editDialogTitle,
        deleteModalTitle: state.deleteModalTitle,
        deleteModalEntityName: state.deleteModalEntityName,
        deleteModalState: state.deleteModalState,

        editDialogProductGroupName: state.editDialogProductGroupName,
		editDialogDescription: state.editDialogDescription,
		editDialogTarifTypesId: state.editDialogTarifTypesId,
        editDialogEnergySupplierId: state.editDialogEnergySupplierId,
		energySuppliers: state.energySuppliers,
		tarifTypes: state.tarifTypes,
		noChangeState: state.noChangeState,

		closeNoChange,
        editEntity,
        setError,
        saveEntity,
        getEntities,
        closeEdit,
        deleteEntity,
        closeDelete,
        deleteEntityConfirmed,

        productGroupNameChange,
		descriptionChange,
		tarifTypesIdChange,
        energySupplierIdChange

}}>
        {props.children}
    </ProductGroupsContext.Provider>
}

export default ProductGroupsState;