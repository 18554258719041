/**
 * 		File:		./src/context/exports/existingOnesReducer.js
 * 
 * 		Subject:	Existing customers' export state reducer
 */
import {
	GET_EXPORTS_EXISTING_ONES,  
} from "../types";

export default (state, action) => {
  
  switch (action.type) {
    
    case GET_EXPORTS_EXISTING_ONES:
		return {
			...state,
			dataProduct: action.payload.productExports,
			dataExtension: action.payload.extensionExports,
		}

    //  Default Path
    default:
      return state;
  }
};
