/**
 *      File:       ./src/context/administration/energy/EnergyAssignmentsState.js
 * 
 *      Subject:    Energy Assignments' State
 */

import React, { useReducer, useEffect } from 'react';
import axios from 'axios';
import EnergyAssignmentsContext from './energyAssignmentsContext';
import EnergyAssignmentsReducer from './energyAssignmentsReducer';
import FormSelect from '../../../Components/FormSelect';

import {

    GET_ENTITIES,
    SET_ACTIVE_ENTITY,
    SET_DELETE_ENTITY,
    SET_DELETE_MODAL_ENTITY_NAME,
    SET_EDIT_DIALOG_TITLE,
    SET_EDIT_ENTITY,
    SET_ENTITY_DIALOG_FIELDS,
    SET_ERROR,

    SET_ENERGY_ASSIGNMENT_FOREIGNS,
    ENERGY_SUPPLIER_ID_CHANGE,
    MARKETS_ID_CHANGE,
    BALANCING_GROUPS_ID_CHANGE

} from '../../types';

const EnergyAssignmentsState = props => {

    const initialState = {

        entities:               [],
        activeEntity:           0,
        deleteModalEntityName:  '',
        deleteModalState:       false,
        deleteModalTitle:       'Zuordnung löschen',
        entityDeleteDialog:     false,
        entityDeleteEntityName: '',
        editDialogTitle:        'Neue Zuordnung anlegen',
        editDialogError:        '',
        editDialogFields:       [],
        editState:              false,
        editDialogEnergySupplierId: -1,
        editDialogMarketsId: -1,
        editDialogBalancingGroupsId: -1,
        energySuppliers: [],
        markets: [],
        balancingGroups: []

    }
    const [state,dispatch] = useReducer(EnergyAssignmentsReducer, initialState);

    useEffect(() => {

        async function fetchData() {
            
            const params = new URLSearchParams();
            params.append('jwt', sessionStorage.jwt);

            let axiosConfig = {
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded"
                }
            };

            let url = process.env.REACT_APP_API_URI + '/administration/energy/get_energy_assignment_foreigns.php';

            axios.post(url,params,axiosConfig)
            .then(response => {
                if(response.status === 200) {
                    // console.log(response.data);
                    
                    dispatch({
                        type: SET_ENERGY_ASSIGNMENT_FOREIGNS,
                        payload: {
                            energySuppliers: response.data.energySupplier,
                            markets: response.data.markets,
                            balancingGroups: response.data.balancingGroups
                        }
                    });

                } else if(response.status === 401) {
                    window.location.href ='#/logout';
                } else {
                    console.log('Anderer Fehler');
                }
            })
            .catch(function(error) {
                if(error.response){
                    // The server answered with an error
                    if(error.response.status === 401) {
                        window.location.href = '#/logout';
                    } else {
                        console.log(error.response.data);
                        console.log(error.response.status);
                        console.log(error.response.headers);
                    }
                } else if(error.request) {
                    //  The request was made but the server didn't respond
                    console.log(error.request);
                } else {
                    //  Something else went wrong
                    console.log('Error', error.message);
                }
            })
        }
        fetchData();

        const edf = [
            {
                id: 1,
                fieldType: <FormSelect
                    context             = {EnergyAssignmentsContext}
                    id                  = "energy_supplier_id"
                    label               = "Versorger"
                    onChangeFunction    = {energySupplierIdChange}
                    placeholder         = "Versorger"
                    required    
                    selectOptions       = "energySuppliers"
                    value               = "editDialogEnergySupplierId"
                />
            },
            {
                id: 2,
                fieldType: <FormSelect
                    context             = {EnergyAssignmentsContext}
                    id                  = "markets_id"
                    label               = "Marktgebiet"
                    onChangeFunction    = {marketsIdChange}
                    placeholder         = "Marktgebiet"
                    required       
                    selectOptions       = "markets"
                    value               = "editDialogMarketsId"
                />
            },
            {
                id: 3,
                fieldType: <FormSelect
                    context             = {EnergyAssignmentsContext}
                    id                  = "balancing_groups_id"
                    label               = "Bilanzkreis"
                    onChangeFunction    = {balancingGroupsIdChange}
                    placeholder         = "Bilanzkreis"
                    required
                    selectOptions       = "balancingGroups"
                    value               = "editDialogBalancingGroupsId"
                />
            }
        ];

        dispatch({
            type: SET_ENTITY_DIALOG_FIELDS,
            payload: edf
        });

        getEntities();

        // eslint-disable-next-line
    }, [state.editDialogError]);


    const getEntities = () => {

        const params = new URLSearchParams();
        params.append('jwt', sessionStorage.jwt);

        let axiosConfig = {
            headers: {
                "Content-Type": "application/x-www-form-urlencoded"
            }
        };

        let url = process.env.REACT_APP_API_URI + '/administration/energy/get_energy_assignments.php';

        axios.post(url,params,axiosConfig)
        .then(response => {
            if(response.status === 200) {

                // console.log(response.data);
                
                dispatch({
                    type: GET_ENTITIES,
                    payload: response.data.entities
                });

            } else if(response.status === 401) {
                window.location.href ='#/logout';
            } else {
                console.log('Anderer Fehler');
            }
        })
        .catch(function(error) {
            if(error.response){
                // The server answered with an error
                if(error.response.status === 401) {
                    window.location.href = '#/logout';
                } else {
                    console.log(error.response.data);
                    console.log(error.response.status);
                    console.log(error.response.headers);
                }
            } else if(error.request) {
                //  The request was made but the server didn't respond
                console.log(error.request);
            } else {
                //  Something else went wrong
                console.log('Error', error.message);
            }
        })
    }

    const editEntity = (id) => {

        if(id > 0)
        {
            const params = new URLSearchParams();
            params.append('jwt', sessionStorage.jwt);
            params.append('id', id);

            let axiosConfig = {
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded"
                }
            };

            let url = process.env.REACT_APP_API_URI + '/administration/energy/get_energy_assignment.php';

            axios.post(url,params,axiosConfig)
            .then(response => {
                if(response.status === 200) {
                    
                    console.log(response.data);
                    
                    dispatch({
                        type: SET_ACTIVE_ENTITY,
                        payload: response.data.energyAssignment
                    });

                    dispatch({
                        type: SET_EDIT_ENTITY,
                        payload: {
                            id: id,
                            editState: true
                        }
                    });

                    dispatch({
                        type: SET_EDIT_DIALOG_TITLE,
                        payload: 'Zuordnungen Versorger - Marktgebiete - Bilanzkreise bearbeiten'
                    });

                } else if(response.status === 401) {
                    window.location.href ='#/logout';
                } else {
                    console.log('Anderer Fehler');
                }
            })
            .catch(function(error) {
                if(error.response){
                    // The server answered with an error
                    if(error.response.status === 401) {
                        window.location.href = '#/logout';
                    } else {
                        console.log(error.response.data);
                        console.log(error.response.status);
                        console.log(error.response.headers);
                    }
                } else if(error.request) {
                    //  The request was made but the server didn't respond
                    console.log(error.request);
                } else {
                    //  Something else went wrong
                    console.log('Error', error.message);
                }
            })
        } else {
            dispatch({
                type: SET_EDIT_DIALOG_TITLE,
                payload: 'Neues Zuordnung Versorger - Marktgebiete - Bilanzkreise anlegen'
            });
        }

        dispatch({
            type: SET_EDIT_ENTITY,
            payload: {
                id: id,
                editState: true
            }
        });        
    }

    const setError = (errMsg) => {
        dispatch({
            type: SET_ERROR,
            payload: errMsg
        });
    }

    const saveEntity = async () => {

        if(state.editDialogEnergySupplierId === -1) {
            setError('Bitte wählen Sie einen Versorger für die Zuordnung aus.');
            return false;
        }
        
        if(state.editDialogMarketsId === -1) {
            setError('Bitte wählen Sie ein Marktgebiet für die Zuordnung aus.');
            return false;
        }

        if(state.editDialogBalancingGroupsId === -1) {
            setError('Bitte wählen Sie einen Bilanzkreis für die Zuordnung aus.');
            return false;
        }

        const params = new URLSearchParams();
        params.append('jwt', sessionStorage.jwt);
        params.append('id', state.activeEntity);
        params.append('energySupplierId', state.editDialogEnergySupplierId);
        params.append('marketsId', state.editDialogMarketsId);
        params.append('balancingGroupsId', state.editDialogBalancingGroupsId);

        let axiosConfig = {
            headers: {
                "Content-Type": "application/x-www-form-urlencoded"
            }
        };

        let url = process.env.REACT_APP_API_URI + '/administration/energy/set_energy_assignment.php';

        axios.post(url,params,axiosConfig)
        .then(response => {
            if(response.status === 200) {

                // console.log(response.data);

                const energyAssignment = {
                    energy_supplier_id: -1,
                    markets_id: -1,
                    balancing_groups_id: -1
                };
                getEntities();

                dispatch({
                    type: SET_ACTIVE_ENTITY,
                    payload: energyAssignment
                });
                dispatch({
                    type: SET_EDIT_ENTITY,
                    payload: {
                        id: 0,
                        editState: false
                    }
                });
                return true;

            } else if(response.status === 401) {
                window.location.href ='#/logout';
            } else {
                console.log('Anderer Fehler');
                return false;
            }
        })
        .catch(function(error) {
            if(error.response){
                // The server answered with an error
                if(error.response.status === 401) {
                    window.location.href = '#/logout';
                } else {
                    console.log(error.response.data);
                    console.log(error.response.status);
                    console.log(error.response.headers);
                    return false;
                }
            } else if(error.request) {
                //  The request was made but the server didn't respond
                console.log(error.request);
                return false;
            } else {
                //  Something else went wrong
                console.log('Error', error.message);
                return false;
            }
        })
        return true;
    }

    const closeEdit = () => {
        dispatch({
            type: SET_ACTIVE_ENTITY,
            payload: {
                energy_supplier_id: -1,
                markets_id: -1,
                balancing_groups_id: -1
            }
        });
        
        dispatch({
            type: SET_EDIT_ENTITY,
            payload: {
                id: 0,
                editState: false
            }
        });
    }

    const deleteEntity = (id) => {
        
        const params = new URLSearchParams();
        params.append('jwt', sessionStorage.jwt);
        params.append('id', id);

        let axiosConfig = {
            headers: {
                "Content-Type": "application/x-www-form-urlencoded"
            }
        };

        let url = process.env.REACT_APP_API_URI + '/administration/energy/get_energy_assignment_named.php';

        axios.post(url,params,axiosConfig)
        .then(response => {
            if(response.status === 200) {

                // console.log(response.data);
                
                dispatch({
                    type: SET_ACTIVE_ENTITY,
                    payload: response.data.energyAssignment
                });

                dispatch({
                    type: SET_DELETE_ENTITY,
                    payload: {
                        id: id,
                        deleteModalState: true
                    }
                });

                dispatch({
                    type: SET_DELETE_MODAL_ENTITY_NAME,
                    payload: 'Möchten Sie die Zuordnung "' + response.data.energyAssignment.energy_supplier_name + ' - ' + response.data.energyAssignment.market_name + ' - ' + response.data.energyAssignment.balancing_group_name + '" wirklich löschen?'
                });

            } else if(response.status === 401) {
                window.location.href ='#/logout';
            } else {
                console.log('Anderer Fehler');
            }
        })
        .catch(function(error) {
            if(error.response){
                // The server answered with an error
                if(error.response.status === 401) {
                    window.location.href = '#/logout';
                } else {
                    console.log(error.response.data);
                    console.log(error.response.status);
                    console.log(error.response.headers);
                }
            } else if(error.request) {
                //  The request was made but the server didn't respond
                console.log(error.request);
            } else {
                //  Something else went wrong
                console.log('Error', error.message);
            }
        })
    }

    const closeDelete = () => {

        dispatch({
            type: SET_ACTIVE_ENTITY,
            payload: {
                energy_supplier_id: -1,
                markets_id: -1,
                balancing_groups_id: -1
            }
        });
        
        dispatch({
            type: SET_EDIT_ENTITY,
            payload: {
                id: 0,
                editState: false
            }
        });

        dispatch({
            type: SET_DELETE_ENTITY,
            payload: {
                id: 0,
                deleteModalState: false
            }
        });

        dispatch({
            type: SET_DELETE_MODAL_ENTITY_NAME,
            payload: ''
        });
    }

    const deleteEntityConfirmed = () => {

        const params = new URLSearchParams();
        params.append('jwt', sessionStorage.jwt);
        params.append('id', state.activeEntity);

        let axiosConfig = {
            headers: {
                "Content-Type": "application/x-www-form-urlencoded"
            }
        };

        let url = process.env.REACT_APP_API_URI + '/administration/energy/delete_energy_assignment.php';

        axios.post(url,params,axiosConfig)
        .then(response => {
            if(response.status === 200) {
                // console.log(response.data);
                
                getEntities();
                closeDelete();

            } else if(response.status === 401) {
                window.location.href ='#/logout';
            } else {
                console.log('Anderer Fehler');
            }
        })
        .catch(function(error) {
            if(error.response){
                // The server answered with an error
                if(error.response.status === 401) {
                    window.location.href = '#/logout';
                } else {
                    console.log(error.response.data);
                    console.log(error.response.status);
                    console.log(error.response.headers);
                }
            } else if(error.request) {
                //  The request was made but the server didn't respond
                console.log(error.request);
            } else {
                //  Something else went wrong
                console.log('Error', error.message);
            }
        })
    }

    /*-------------------------------------
    //  PAGE SPECIFIC
    -------------------------------------*/

    const energySupplierIdChange = (e) => {
        if(state.editDialogEnergySupplierId === -1 && state.editDialogError !== '') {
            setError('');
        }

        dispatch({
            type: ENERGY_SUPPLIER_ID_CHANGE,
            payload: e.target.value
        });
    }

    const marketsIdChange = (e) => {
        if(state.editDialogMarketId === -1 && state.editDialogError !== '') {
            setError('');
        }

        dispatch({
            type: MARKETS_ID_CHANGE,
            payload: e.target.value
        });
    }

    const balancingGroupsIdChange = (e) => {
        if(state.editDialogBalancingGroupsId === -1 && state.editDialogError !== '') {
            setError('');
        }

        dispatch({
            type: BALANCING_GROUPS_ID_CHANGE,
            payload: e.target.value
        });
    }

    return <EnergyAssignmentsContext.Provider value={{

        entities: state.entities,
        editDialogFields: state.editDialogFields,
        editDialogError: state.editDialogError,
        activeEntity: state.activeEntity,
        editState: state.editState,
        userRoleLabel: state.userRoleLabel,
        editDialogTitle: state.editDialogTitle,
        deleteModalTitle: state.deleteModalTitle,
        deleteModalEntityName: state.deleteModalEntityName,
        deleteModalState: state.deleteModalState,

        editDialogEnergySupplierId: state.editDialogEnergySupplierId,
        editDialogMarketsId: state.editDialogMarketsId,
        editDialogBalancingGroupsId: state.editDialogBalancingGroupsId,
        energySuppliers: state.energySuppliers,
        markets: state.markets,
        balancingGroups: state.balancingGroups,

        editEntity,
        setError,
        saveEntity,
        getEntities,
        closeEdit,
        deleteEntity,
        closeDelete,
        deleteEntityConfirmed,

        energySupplierIdChange,
        marketsIdChange,
        balancingGroupsIdChange

}}>
        {props.children}
    </EnergyAssignmentsContext.Provider>
}

export default EnergyAssignmentsState;