/**
 * 		File:		./src/context/exports/exportProductsReducer.js
 * 
 * 		Subject:	Products' export state reducer
 */
import {

	GET_EXPORTS_PRODUCTS,
  
} from "../types";

export default (state, action) => {
  
  switch (action.type) {
    
    case GET_EXPORTS_PRODUCTS:
      return{
		...state,
		dataProducts: action.payload.productsExports,
      }

    //  Default Path
    default:
      return state;
  }
};
