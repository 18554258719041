/**
 *      File:       ./src/context/exports/terminatesContext .js
 * 
 *      Subject:    customer contract terminations' export context
 */
import { createContext } from "react";

const terminatesContext  = createContext();

export default terminatesContext ;
