/**
 *      File:       ./src/context/administration/transactions/transactionReasonsReducer.js
 * 
 *      Subject:    Transaction Reasons' State Reducer
 */
import {
    GET_ENTITIES,
    SET_ACTIVE_ENTITY,
    SET_DELETE_ENTITY,
    SET_DELETE_MODAL_ENTITY_NAME,
    SET_EDIT_DIALOG_TITLE,
    SET_EDIT_ENTITY,
    SET_ENTITY_DIALOG_FIELDS,
    SET_ERROR,

    REASON_NAME_CHANGE,
	REASON_SHORTCUT_CHANGE,
	T_SWITCH_TYPE_CHANGE,
	IS_NETWORK_OPERATOR_NEEDED_CHANGE

} from '../../types';

export default(state, action) => {

    switch(action.type)
    {
        //  General section

        case GET_ENTITIES:
            return {
                ...state,
                entities: action.payload
            }

        case SET_ACTIVE_ENTITY:
            return {
                ...state,
                editDialogReasonName: action.payload.reason_name,
				editDialogReasonShortcut: action.payload.reason_shortcut,
				editDialogTSwitchType: action.payload.t_switch_type,
				editDialogIsNetworkOperatorNeeded: action.payload.is_network_operator_needed
            }

        case SET_DELETE_ENTITY:
            return {
                ...state,
                activeEntity: action.payload.id,
                deleteModalState: action.payload.deleteModalState
            }

        case SET_DELETE_MODAL_ENTITY_NAME:
            return {
                ...state,
                deleteModalEntityName: action.payload
            }

        case SET_EDIT_DIALOG_TITLE:
            return {
                ...state,
                editDialogTitle: action.payload
            }

        case SET_EDIT_ENTITY:
            return {
                ...state,
                activeEntity: action.payload.id,
                editState: action.payload.editState
            }

        case SET_ENTITY_DIALOG_FIELDS:
            return {
                ...state,
                editDialogFields: action.payload
            }

        case SET_ERROR:
            return {
                ...state,
                editDialogError: action.payload
            }


        //  Individual Section

        case REASON_NAME_CHANGE:
            return {
                ...state,
                editDialogReasonName: action.payload
            }
            
        case REASON_SHORTCUT_CHANGE:
            return {
                ...state,
                editDialogReasonShortcut: action.payload
			}
			
		case T_SWITCH_TYPE_CHANGE:
			return {
				...state,
				editDialogTSwitchType: action.payload,
			}

		case IS_NETWORK_OPERATOR_NEEDED_CHANGE:
			return {
				...state,
				editDialogIsNetworkOperatorNeeded: action.payload
			}

            
        //  Default Path
        default:
            return state;
    }
}