/**
 *      File:       ./src/Components/FormSelect.js
 * 
 *      Subject:    Common select for universal use
 */

import React, { useContext } from 'react'
import {
    FormGroup,
    Label, Input
} from 'reactstrap';

const FormSelect = (props) => {

    const {
        context,
        id,
        label,
        onChangeFunction,
        required,
        selectOptions,
        value,
    } = props;

    const innerContext = useContext(context);
    const contextValue = innerContext[value];
    const contextOptions = innerContext[selectOptions];

    return (
        <FormGroup>
            <Label for={id}>
                {label}
                <span>&nbsp;<sup>{required ? '*' : ''}</sup></span>
            </Label>
            <Input type="select" id={id} value={contextValue} onChange={onChangeFunction}>
                {contextOptions && contextOptions.map(map => (
                    <option key={map.key} value={map.key}>{map.value}</option>
                ))}
            </Input>
        </FormGroup>
    )
}

export default FormSelect
