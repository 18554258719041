/**
 *      File:       ./src/context/administration/transactions/TransactionReasonsState.js
 * 
 *      Subject:    Transaction Reasons' Staet
 */

import React, { useReducer, useEffect } from 'react';
import axios from 'axios';
import TransactionReasonsContext from './transactionReasonsContext';
import TransactionReasonsReducer from './transactionReasonsReducer';
import FormInput from '../../../Components/FormInput';
import FormCheckbox from '../../../Components/FormCheckbox';

import {

    GET_ENTITIES,
    SET_ACTIVE_ENTITY,
    SET_DELETE_ENTITY,
    SET_DELETE_MODAL_ENTITY_NAME,
    SET_EDIT_DIALOG_TITLE,
    SET_EDIT_ENTITY,
    SET_ENTITY_DIALOG_FIELDS,
    SET_ERROR,

    REASON_NAME_CHANGE,
	REASON_SHORTCUT_CHANGE,
	T_SWITCH_TYPE_CHANGE,
	IS_NETWORK_OPERATOR_NEEDED_CHANGE

} from '../../types';


const TransactionReasonsState = props => {

    const initialState = {

        entities:               [],
        activeEntity:           0,
        deleteModalEntityName:  '',
        deleteModalState:       false,
        deleteModalTitle:       'Transaktionsgrund löschen',
        entityDeleteDialog:     false,
        entityDeleteEntityName: '',
        editDialogTitle:        'Neuen Transaktionsgrund anlegen',
        editDialogError:        '',
        editDialogFields:       [],
        editState:              false,
        editDialogReasonName: '',
		editDialogReasonShortcut: '',
		editDialogTSwitchType: '',
		editDialogIsNetworkOperatorNeeded: false,

    }
    const [state,dispatch] = useReducer(TransactionReasonsReducer, initialState);

    useEffect(() => {

        const edf = [
            {
                id: 1,
                fieldType: <FormInput
                    context             = {TransactionReasonsContext}
                    id                  = "reason_name"
                    label               = "Transaktionsgrund"
                    onChangeFunction    = {reasonNameChange}
                    placeholder         = "Transaktionsgrund"
                    required
                    type                = "text"
                    value               = "editDialogReasonName"
                />
            },
            {
                id: 2,
                fieldType: <FormInput
                    context             = {TransactionReasonsContext}
                    id                  = "reason_shortcut"
                    label               = "TReason"
                    onChangeFunction    = {reasonShortcutChange}
                    placeholder         = "TReason"
                    type                = "text"
                    value               = "editDialogReasonShortcut"
                />
			},
			{
				id: 3,
				fieldType: <FormInput
					context				= {TransactionReasonsContext}
					id					= "t_switch_type"
					label				= "TSwitchType"
					onChangeFunction	= {tSwitchTypeChange}
					placeholder			= "TSwitchType"
					type				= "text"
					value				= "editDialogTSwitchType"
				/>
			},
			{
				id: 4,
				fieldType: <FormCheckbox
					context				= {TransactionReasonsContext}
					id					= "is_network_operator_needed"
					label				= "Netzbetreiber zuordnen?"
					onChangeFunction	= {isNetworkOperatorNeededChange}
					value				= "editDialogIsNetworkOperatorNeeded"
				/>
			}
        ];

        dispatch({
            type: SET_ENTITY_DIALOG_FIELDS,
            payload: edf
        });

        getEntities();

        // eslint-disable-next-line
    }, [state.editDialogError]);


    const getEntities = () => {

        const params = new URLSearchParams();
        params.append('jwt', sessionStorage.jwt);

        let axiosConfig = {
            headers: {
                "Content-Type": "application/x-www-form-urlencoded"
            }
        };

        let url = process.env.REACT_APP_API_URI + '/administration/transactions/get_transaction_reasons.php';

        axios.post(url,params,axiosConfig)
        .then(response => {
            if(response.status === 200) {

				// console.log(response.data);
				const entities = response.data.entities;
				for(var i in entities) {
					entities[i].id = (isNaN(entities[i].id) || entities[i].id === "") ? 0 : Number.parseInt(entities[i].id);
				}
                
                dispatch({
                    type: GET_ENTITIES,
                    payload: entities
                });

            } else if(response.status === 401) {
                window.location.href ='#/logout';
            } else {
                console.log('Anderer Fehler');
            }
        })
        .catch(function(error) {
            if(error.response){
                // The server answered with an error
                if(error.response.status === 401) {
                    window.location.href = '#/logout';
                } else {
                    console.log(error.response.data);
                    console.log(error.response.status);
                    console.log(error.response.headers);
                }
            } else if(error.request) {
                //  The request was made but the server didn't respond
                console.log(error.request);
            } else {
                //  Something else went wrong
                console.log('Error', error.message);
            }
        })
    }

    const editEntity = (id) => {

        if(id > 0)
        {
            const params = new URLSearchParams();
            params.append('jwt', sessionStorage.jwt);
            params.append('id', id);

            let axiosConfig = {
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded"
                }
            };

            let url = process.env.REACT_APP_API_URI + '/administration/transactions/get_transaction_reason.php';

            axios.post(url,params,axiosConfig)
            .then(response => {
                if(response.status === 200) {
                    
                    // console.log(response.data);
                    
                    dispatch({
                        type: SET_ACTIVE_ENTITY,
                        payload: response.data.reason
                    });

                    dispatch({
                        type: SET_EDIT_ENTITY,
                        payload: {
                            id: id,
                            editState: true
                        }
                    });

                    dispatch({
                        type: SET_EDIT_DIALOG_TITLE,
                        payload: 'Transaktionsgrund bearbeiten'
                    });

                } else if(response.status === 401) {
                    window.location.href ='#/logout';
                } else {
                    console.log('Anderer Fehler');
                }
            })
            .catch(function(error) {
                if(error.response){
                    // The server answered with an error
                    if(error.response.status === 401) {
                        window.location.href = '#/logout';
                    } else {
                        console.log(error.response.data);
                        console.log(error.response.status);
                        console.log(error.response.headers);
                    }
                } else if(error.request) {
                    //  The request was made but the server didn't respond
                    console.log(error.request);
                } else {
                    //  Something else went wrong
                    console.log('Error', error.message);
                }
            })
        } else {
            dispatch({
                type: SET_EDIT_DIALOG_TITLE,
                payload: 'Neuen Transaktionsgrund anlegen'
            });
        }

        dispatch({
            type: SET_EDIT_ENTITY,
            payload: {
                id: id,
                editState: true
            }
        });        
    }

    const setError = (errMsg) => {
        dispatch({
            type: SET_ERROR,
            payload: errMsg
        });
    }

    const saveEntity = async () => {

        if(state.editDialogReasonName === '') {
            setError('Bitte geben Sie eine Bezeichnung für den Transaktionsgrund ein.');
            return false;
        }

        const params = new URLSearchParams();
        params.append('jwt', sessionStorage.jwt);
		params.append('id', state.activeEntity);
		params.append('table', "transaction_reasons")
        params.append('reason_name', state.editDialogReasonName);
		params.append('reason_shortcut', state.editDialogReasonShortcut);
		params.append('t_switch_type', state.editDialogTSwitchType);
		params.append('is_network_operator_needed', state.editDialogIsNetworkOperatorNeeded);
		console.log(state.editDialogIsNetworkOperatorNeeded);

        let axiosConfig = {
            headers: {
                "Content-Type": "application/x-www-form-urlencoded"
            }
        };

        let url = process.env.REACT_APP_API_URI + '/administration/transactions/set_transaction_reason.php';

        axios.post(url,params,axiosConfig)
        .then(response => {
            if(response.status === 200) {

                // console.log(response.data);

                const reason = {
                    reason_name: '',
					reason_shortcut: '',
					t_switch_type: '',
					is_network_operator_needed: false
                };
                getEntities();

                dispatch({
                    type: SET_ACTIVE_ENTITY,
                    payload: reason
                });
                dispatch({
                    type: SET_EDIT_ENTITY,
                    payload: {
                        id: 0,
                        editState: false
                    }
                });
                return true;

            } else if(response.status === 401) {
                window.location.href ='#/logout';
            } else {
                console.log('Anderer Fehler');
                return false;
            }
        })
        .catch(function(error) {
            if(error.response){
                // The server answered with an error
                if(error.response.status === 401) {
                    window.location.href = '#/logout';
                } else {
                    console.log(error.response.data);
                    console.log(error.response.status);
                    console.log(error.response.headers);
                    return false;
                }
            } else if(error.request) {
                //  The request was made but the server didn't respond
                console.log(error.request);
                return false;
            } else {
                //  Something else went wrong
                console.log('Error', error.message);
                return false;
            }
        })
        return true;
    }

    const closeEdit = () => {
        dispatch({
            type: SET_ACTIVE_ENTITY,
            payload: {
                reason_name: '',
				reason_shortcut: '',
				t_switch_type: '',
				is_network_operator_needed: false
            }
        });
        
        dispatch({
            type: SET_EDIT_ENTITY,
            payload: {
                id: 0,
                editState: false
            }
        });
    }

    const deleteEntity = (id) => {
        
        const params = new URLSearchParams();
        params.append('jwt', sessionStorage.jwt);
        params.append('id', id);

        let axiosConfig = {
            headers: {
                "Content-Type": "application/x-www-form-urlencoded"
            }
        };

        let url = process.env.REACT_APP_API_URI + '/administration/transactions/get_transaction_reason.php';

        axios.post(url,params,axiosConfig)
        .then(response => {
            if(response.status === 200) {

                // console.log(response.data);
                
                dispatch({
                    type: SET_ACTIVE_ENTITY,
                    payload: response.data.reason
                });

                dispatch({
                    type: SET_DELETE_ENTITY,
                    payload: {
                        id: id,
                        deleteModalState: true
                    }
                });

                dispatch({
                    type: SET_DELETE_MODAL_ENTITY_NAME,
                    payload: 'Möchten Sie den Transaktionsgrund "' + response.data.reason.reason_name + '" wirklich löschen?'
                });

            } else if(response.status === 401) {
                window.location.href ='#/logout';
            } else {
                console.log('Anderer Fehler');
            }
        })
        .catch(function(error) {
            if(error.response){
                // The server answered with an error
                if(error.response.status === 401) {
                    window.location.href = '#/logout';
                } else {
                    console.log(error.response.data);
                    console.log(error.response.status);
                    console.log(error.response.headers);
                }
            } else if(error.request) {
                //  The request was made but the server didn't respond
                console.log(error.request);
            } else {
                //  Something else went wrong
                console.log('Error', error.message);
            }
        })
    }

    const closeDelete = () => {

        dispatch({
            type: SET_ACTIVE_ENTITY,
            payload: {
                reason_name: '',
				reason_shortcut: '',
				t_switch_type: '',
				is_network_operator_needed: false
            }
        });
        
        dispatch({
            type: SET_EDIT_ENTITY,
            payload: {
                id: 0,
                editState: false
            }
        });

        dispatch({
            type: SET_DELETE_ENTITY,
            payload: {
                id: 0,
                deleteModalState: false
            }
        });

        dispatch({
            type: SET_DELETE_MODAL_ENTITY_NAME,
            payload: ''
        });
    }

    const deleteEntityConfirmed = () => {

        const params = new URLSearchParams();
        params.append('jwt', sessionStorage.jwt);
        params.append('id', state.activeEntity);

        let axiosConfig = {
            headers: {
                "Content-Type": "application/x-www-form-urlencoded"
            }
        };

        let url = process.env.REACT_APP_API_URI + '/administration/transactions/delete_transaction_reason.php';

        axios.post(url,params,axiosConfig)
        .then(response => {
            if(response.status === 200) {
                // console.log(response.data);
                
                getEntities();
                closeDelete();

            } else if(response.status === 401) {
                window.location.href ='#/logout';
            } else {
                console.log('Anderer Fehler');
            }
        })
        .catch(function(error) {
            if(error.response){
                // The server answered with an error
                if(error.response.status === 401) {
                    window.location.href = '#/logout';
                } else {
                    console.log(error.response.data);
                    console.log(error.response.status);
                    console.log(error.response.headers);
                }
            } else if(error.request) {
                //  The request was made but the server didn't respond
                console.log(error.request);
            } else {
                //  Something else went wrong
                console.log('Error', error.message);
            }
        })
    }

    /*-------------------------------------
    //  PAGE SPECIFIC
    -------------------------------------*/

    const reasonNameChange = (e) => {
        if(state.editDialogReasonName === '' && state.editDialogError !== '') {
            setError('');
        }

        dispatch({
            type: REASON_NAME_CHANGE,
            payload: e.target.value
        });
    }
    
    const reasonShortcutChange = (e) => {
        dispatch({
            type: REASON_SHORTCUT_CHANGE,
            payload: e.target.value
        });
	}
	
	const tSwitchTypeChange = (e) => {
		dispatch({
			type: T_SWITCH_TYPE_CHANGE,
			payload: e.target.value,
		});
	}

	const isNetworkOperatorNeededChange = (e) => {
		dispatch({
			type: IS_NETWORK_OPERATOR_NEEDED_CHANGE,
			payload: e.target.checked,
		});
	}

    return <TransactionReasonsContext.Provider value={{

        entities: state.entities,
        editDialogFields: state.editDialogFields,
        editDialogError: state.editDialogError,
        activeEntity: state.activeEntity,
        editState: state.editState,
        userRoleLabel: state.userRoleLabel,
        editDialogTitle: state.editDialogTitle,
        deleteModalTitle: state.deleteModalTitle,
        deleteModalEntityName: state.deleteModalEntityName,
        deleteModalState: state.deleteModalState,

        editDialogReasonName: state.editDialogReasonName,
		editDialogReasonShortcut: state.editDialogReasonShortcut,
		editDialogTSwitchType: state.editDialogTSwitchType,
		editDialogIsNetworkOperatorNeeded: state.editDialogIsNetworkOperatorNeeded,

        editEntity,
        setError,
        saveEntity,
        getEntities,
        closeEdit,
        deleteEntity,
        closeDelete,
        deleteEntityConfirmed,

        reasonNameChange,
		reasonShortcutChange,
		tSwitchTypeChange,
		isNetworkOperatorNeededChange,

}}>
        {props.children}
    </TransactionReasonsContext.Provider>
}

export default TransactionReasonsState;