/**
 * 		File:		./src/context/exports/ExistingOnesState.js
 * 
 * 		Subject:	State for existing customers' exports
 */
import React, { useReducer } from "react";
import axios from "axios";
import ExistingOnesContext from "./existingOnesContext";
import ExistingOnesReducer from "./existingOnesReducer";

import {
	GET_EXPORTS_EXISTING_ONES,
} from "../types";

const ExistingOnesState = (props) => {

  const initialState = {

	dataProduct:	[],
	dataExtension:	[],
    
  };
  const [state, dispatch] = useReducer(ExistingOnesReducer, initialState);


  //------------------------------
  //	GET THE EXPORTS
  //------------------------------
  const getExports = () => {

	//	Set call
	const params = new URLSearchParams();
    params.append('jwt', sessionStorage.jwt);
    params.append('exportPage', "existingOnes");

	let url = process.env.REACT_APP_API_URI + '/exports/get_exports.php';
    let axiosConfig = {
        headers: {
            "Content-Type": "application/x-www-form-urlencoded"
        }
    };

	//	Call backend
    axios.post(url,params,axiosConfig)
    .then(response => {
        if(response.status === 200) {

            // console.log(response.data);
            
            dispatch({
                type: GET_EXPORTS_EXISTING_ONES,
                payload: {
					productExports: response.data.productExports,
					extensionExports: response.data.extensionExports
				},
            });

        } else if(response.status === 401) {
            window.location.href ='#/logout';
        } else {
            console.log('Anderer Fehler');
        }
    })
    .catch(function(error) {
        if(error.response){
            // The server answered with an error
            if(error.response.status === 401) {
                window.location.href = '#/logout';
            } else {
                console.log(error.response.data);
                console.log(error.response.status);
                console.log(error.response.headers);
            }
        } else if(error.request) {
            //  The request was made but the server didn't respond
            console.log(error.request);
        } else {
            //  Something else went wrong
            console.log('Error', error.message);
        }
    })
  }

  const exportProduct = () => {

	//	Set call
	const params = new URLSearchParams();
    params.append('jwt', sessionStorage.jwt);

	let url = process.env.REACT_APP_API_URI + '/exports/start_export_product_change.php';
    let axiosConfig = {
        headers: {
            "Content-Type": "application/x-www-form-urlencoded"
        }
	};
	
	let productInProgress = state.dataProduct;
	productInProgress[0].id = 0;
	productInProgress[0].filename = "Export wird erstellt...";
	dispatch({
		type: GET_EXPORTS_EXISTING_ONES,
		payload: {
			productExports: productInProgress,
			extensionExports: state.dataExtension,
		},
	});

	//	Call backend
    axios.post(url,params,axiosConfig)
    .then(response => {
        if(response.status === 200) {

			// console.log(response.data);
			window.setTimeout(getExports, 1000);

        } else if(response.status === 401) {
            window.location.href ='#/logout';
        } else {
            console.log('Anderer Fehler');
        }
    })
    .catch(function(error) {
        if(error.response){
            // The server answered with an error
            if(error.response.status === 401) {
                window.location.href = '#/logout';
            } else {
                console.log(error.response.data);
                console.log(error.response.status);
                console.log(error.response.headers);
            }
        } else if(error.request) {
            //  The request was made but the server didn't respond
            console.log(error.request);
        } else {
            //  Something else went wrong
            console.log('Error', error.message);
        }
    })
  }

  const exportExtension = () => {

	//	Set call
	const params = new URLSearchParams();
    params.append('jwt', sessionStorage.jwt);

	let url = process.env.REACT_APP_API_URI + '/exports/start_export_extension.php';
    let axiosConfig = {
        headers: {
            "Content-Type": "application/x-www-form-urlencoded"
        }
	};
	
	let extensionInProgress = state.dataExtension;
	extensionInProgress[0].id = 0;
	extensionInProgress[0].filename = "Export wird erstellt...";
	dispatch({
		type: GET_EXPORTS_EXISTING_ONES,
		payload: {
			productExports: state.dataProduct,
			extensionExports: extensionInProgress,
		},
	});

	//	Call backend
    axios.post(url,params,axiosConfig)
    .then(response => {
        if(response.status === 200) {

			// console.log(response.data);
			window.setTimeout(getExports, 1000);

        } else if(response.status === 401) {
            window.location.href ='#/logout';
        } else {
            console.log('Anderer Fehler');
        }
    })
    .catch(function(error) {
        if(error.response){
            // The server answered with an error
            if(error.response.status === 401) {
                window.location.href = '#/logout';
            } else {
                console.log(error.response.data);
                console.log(error.response.status);
                console.log(error.response.headers);
            }
        } else if(error.request) {
            //  The request was made but the server didn't respond
            console.log(error.request);
        } else {
            //  Something else went wrong
            console.log('Error', error.message);
        }
    })

  }

  return (
    <ExistingOnesContext.Provider
      value={{
		  
		dataProduct:	state.dataProduct,
		dataExtension:	state.dataExtension,

		getExports,
		exportProduct,
		exportExtension,
        
      }}
    >
      {props.children}
    </ExistingOnesContext.Provider>
  );
};

export default ExistingOnesState;
