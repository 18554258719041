/**
 *      File:       ./src/context/administration/products/ProductsState.js
 * 
 *      Subject:    Products' State
 */
import React, { useReducer, useEffect } from 'react';
import axios from 'axios';
import ProductsContext from './productsContext';
import ProductsReducer from './productsReducer';
import FormInput from '../../../Components/FormInput';
import FormSelect from '../../../Components/FormSelect';

import {

    GET_ENTITIES,
    SET_ACTIVE_ENTITY,
    SET_DELETE_ENTITY,
    SET_DELETE_MODAL_ENTITY_NAME,
    SET_EDIT_DIALOG_TITLE,
    SET_EDIT_ENTITY,
    SET_ENTITY_DIALOG_FIELDS,
    SET_ERROR,

    SET_USER_FOREIGNS,
    PRODUCT_NAME_CHANGE,
    DESCRIPTION_CHANGE,
    DIVISIONS_ID_CHANGE,
    ENERGY_SUPPLIER_ID_CHANGE,
	PRODUCT_GROUPS_ID_CHANGE,

} from '../../types';

const ProductsState = props => {

    const initialState = {

        entities:               [],
        activeEntity:           0,
        deleteModalEntityName:  '',
        deleteModalState:       false,
        deleteModalTitle:       'Produkt löschen',
        entityDeleteDialog:     false,
        entityDeleteEntityName: '',
        editDialogTitle:        'Neues Produkt anlegen',
        editDialogError:        '',
        editDialogFields:       [],
        editState:              false,
        editDialogProductName: '',
        editDialogDescription: '',
        editDialogDivisionsId: -1,
		editDialogEnergySupplierId: -1,
		editDialogProductGroupsId: -1,
        energySuppliers: [],
		divisions: [],
		productGroups: [],

    }
    const [state,dispatch] = useReducer(ProductsReducer, initialState);

    useEffect(() => {

        async function fetchData() {
            
            const params = new URLSearchParams();
            params.append('jwt', sessionStorage.jwt);

            let axiosConfig = {
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded"
                }
            };

            let url = process.env.REACT_APP_API_URI + '/administration/products/get_product_foreigns.php';
            
            axios.post(url,params,axiosConfig)
            .then(response => {
                if(response.status === 200) {
                    // console.log(response.data);
                    
                    dispatch({
                        type: SET_USER_FOREIGNS,
                        payload: {
                            energySupplier: response.data.energySupplier,
                            divisions: response.data.divisions,
                        }
                    });

                } else if(response.status === 401) {
                    window.location.href ='#/logout';
                } else {
                    console.log('Anderer Fehler');
                }
            })
            .catch(function(error) {
                if(error.response){
                    // The server answered with an error
                    if(error.response.status === 401) {
                        window.location.href = '#/logout';
                    } else {
                        console.log(error.response.data);
                        console.log(error.response.status);
                        console.log(error.response.headers);
                    }
                } else if(error.request) {
                    //  The request was made but the server didn't respond
                    console.log(error.request);
                } else {
                    //  Something else went wrong
                    console.log('Error', error.message);
                }
            })
        }
        fetchData();

        const edf = [
            {
                id: 1,
                fieldType: <FormInput
                    context             = {ProductsContext}
                    id                  = "product_name"
                    label               = "Name des Produkts"
                    onChangeFunction    = {productNameChange}
                    placeholder         = "Produkt"
                    required
                    type                = "text"
                    value               = "editDialogProductName"
                />
            },
            {
                id: 2,
                fieldType: <FormInput
                    context             = {ProductsContext}
                    id                  = "description"
                    label               = "Beschreibung"
                    onChangeFunction    = {descriptionChange}
                    placeholder         = "Beschreibung"
                    type                = "text"
                    value               = "editDialogDescription"
                />
			},
			{
				id: 3,
				fieldType: <FormSelect
					context				= {ProductsContext}
					id					= "product_groups_id"
					label				= "Produkt-Gruppe"
					onChangeFunction	= {productGroupsIdChange}
					selectOptions		= "productGroups"
					value				= "editDialogProductGroupsId"
				/>
			},
            {
                id: 4,
                fieldType: <FormSelect
                    context             = {ProductsContext}
                    id                  = "divisions_id"
                    label               = "Sparte"
                    onChangeFunction    = {divisionsIdChange}
                    required
                    selectOptions       = "divisions"
                    value               = "editDialogDivisionsId"
                />
            },
            {
                id: 5,
                fieldType: <FormSelect
                    context             = {ProductsContext}
                    id                  = "energy_supplier_id"
                    label               = "Mandant"
                    onChangeFunction    = {energySupplierIdChange}
                    required            
                    selectOptions       = "energySuppliers"
                    value               = "editDialogEnergySupplierId"
                />
            }
        ];

        dispatch({
            type: SET_ENTITY_DIALOG_FIELDS,
            payload: edf
        });

        getEntities();

        // eslint-disable-next-line
    }, [state.editDialogError, state.editDialogProductGroupsId]);


    const getEntities = () => {

        const params = new URLSearchParams();
        params.append('jwt', sessionStorage.jwt);

        let axiosConfig = {
            headers: {
                "Content-Type": "application/x-www-form-urlencoded"
            }
        };

        let url = process.env.REACT_APP_API_URI + '/administration/products/get_products.php';

        axios.post(url,params,axiosConfig)
        .then(response => {
            if(response.status === 200) {

                // console.log(response.data.entities);
                
                dispatch({
                    type: GET_ENTITIES,
                    payload: response.data.entities
                });

            } else if(response.status === 401) {
                window.location.href ='#/logout';
            } else {
                console.log('Anderer Fehler');
            }
        })
        .catch(function(error) {
            if(error.response){
                // The server answered with an error
                if(error.response.status === 401) {
                    window.location.href = '#/logout';
                } else {
                    console.log(error.response.data);
                    console.log(error.response.status);
                    console.log(error.response.headers);
                }
            } else if(error.request) {
                //  The request was made but the server didn't respond
                console.log(error.request);
            } else {
                //  Something else went wrong
                console.log('Error', error.message);
            }
        })
    }

    const editEntity = (id) => {

		const params = new URLSearchParams();
		params.append('jwt', sessionStorage.jwt);
		params.append('id', id);

		let axiosConfig = {
			headers: {
				"Content-Type": "application/x-www-form-urlencoded"
			}
		};

		let url = process.env.REACT_APP_API_URI + '/administration/products/get_product.php';

		axios.post(url,params,axiosConfig)
		.then(response => {
			if(response.status === 200) {
				// console.log(response.data);
				
				dispatch({
					type: SET_ACTIVE_ENTITY,
					payload: {
						product: response.data.product,
						productGroups: response.data.productGroups,
					}
				});

				dispatch({
					type: SET_EDIT_ENTITY,
					payload: {
						id: id,
						editState: true
					}
				});

				const title = (id > 0) ? 'Produkt bearbeiten' : 'Neues Produkt anlegen';

				dispatch({
					type: SET_EDIT_DIALOG_TITLE,
					payload: title
				});

			} else if(response.status === 401) {
				window.location.href ='#/logout';
			} else {
				console.log('Anderer Fehler');
			}
		})
		.catch(function(error) {
			if(error.response){
				// The server answered with an error
				if(error.response.status === 401) {
					window.location.href = '#/logout';
				} else {
					console.log(error.response.data);
					console.log(error.response.status);
					console.log(error.response.headers);
				}
			} else if(error.request) {
				//  The request was made but the server didn't respond
				console.log(error.request);
			} else {
				//  Something else went wrong
				console.log('Error', error.message);
			}
		})	
    }

    const setError = (errMsg) => {
        dispatch({
            type: SET_ERROR,
            payload: errMsg
        });
    }

    const saveEntity = async () => {

        if(state.editDialogProductName === '') {
            setError('Bitte geben Sie eine Bezeichnung für das Produkt ein.');
            return false;
        }

        if(state.editDialogDivisionsId< 0 ) {
            setError('Bitte wählen Sie eine Sparte für das Produkt aus.');
            return false;
        }

        if(state.editDialogEnergySupplierId < 0) {
            setError('Bitte wählen Sie einen Mandanten für das Produkt an.');
            return false;
        }

        const params = new URLSearchParams();
        params.append('jwt', sessionStorage.jwt);
		params.append('id', state.activeEntity);
		params.append('table', "products");
        params.append('product_name', state.editDialogProductName);
        params.append('description', state.editDialogDescription);
        params.append('divisions_id', state.editDialogDivisionsId);
		params.append('energy_suppliers_id', state.editDialogEnergySupplierId);
		params.append('product_groups_id', state.editDialogProductGroupsId);

        let axiosConfig = {
            headers: {
                "Content-Type": "application/x-www-form-urlencoded"
            }
        };

        let url = process.env.REACT_APP_API_URI + '/administration/products/set_product.php';

        axios.post(url,params,axiosConfig)
        .then(response => {
            if(response.status === 200) {

                // console.log(response.data);

                const product = {
                    product_name: '',
                    description: '',
                    divisions_id: -1,
					energy_supplier_id: -1,
					product_groups_id: -1,
                };
                getEntities();

                dispatch({
                    type: SET_ACTIVE_ENTITY,
                    payload: {
						product: product,
						productGroups: []
					}
                });
                dispatch({
                    type: SET_EDIT_ENTITY,
                    payload: {
                        id: 0,
                        editState: false
                    }
                });
                return true;

            } else if(response.status === 401) {
                window.location.href ='#/logout';
            } else {
                console.log('Anderer Fehler');
                return false;
            }
        })
        .catch(function(error) {
            if(error.response){
                // The server answered with an error
                if(error.response.status === 401) {
                    window.location.href = '#/logout';
                } else {
                    console.log(error.response.data);
                    console.log(error.response.status);
                    console.log(error.response.headers);
                    return false;
                }
            } else if(error.request) {
                //  The request was made but the server didn't respond
                console.log(error.request);
                return false;
            } else {
                //  Something else went wrong
                console.log('Error', error.message);
                return false;
            }
        })
        return true;
    }

    const closeEdit = () => {
        dispatch({
            type: SET_ACTIVE_ENTITY,
            payload: {
				product: {
					product_name: '',
					description: '',
					divisions_id: -1,
					energy_supplier_id: -1,
					product_groups_id: -1
				},
				productGroups: []
            }
        });
        
        dispatch({
            type: SET_EDIT_ENTITY,
            payload: {
                id: 0,
                editState: false
            }
        });
    }

    const deleteEntity = (id) => {
        
        const params = new URLSearchParams();
        params.append('jwt', sessionStorage.jwt);
        params.append('id', id);

        let axiosConfig = {
            headers: {
                "Content-Type": "application/x-www-form-urlencoded"
            }
        };

        let url = process.env.REACT_APP_API_URI + '/administration/products/get_product.php';

        axios.post(url,params,axiosConfig)
        .then(response => {
            if(response.status === 200) {
                // console.log(response.data);
                
                dispatch({
                    type: SET_ACTIVE_ENTITY,
                    payload: {
						product: response.data.product,
						productGroups: state.productGroups,
					}
                });

                dispatch({
                    type: SET_DELETE_ENTITY,
                    payload: {
                        id: id,
                        deleteModalState: true
                    }
                });

                dispatch({
                    type: SET_DELETE_MODAL_ENTITY_NAME,
                    payload: 'Möchten Sie das Produkt "' + response.data.product.product_name + '" wirklich löschen?'
                });

            } else if(response.status === 401) {
                window.location.href ='#/logout';
            } else {
                console.log('Anderer Fehler');
            }
        })
        .catch(function(error) {
            if(error.response){
                // The server answered with an error
                if(error.response.status === 401) {
                    window.location.href = '#/logout';
                } else {
                    console.log(error.response.data);
                    console.log(error.response.status);
                    console.log(error.response.headers);
                }
            } else if(error.request) {
                //  The request was made but the server didn't respond
                console.log(error.request);
            } else {
                //  Something else went wrong
                console.log('Error', error.message);
            }
        })
    }

    const closeDelete = () => {

        dispatch({
            type: SET_ACTIVE_ENTITY,
            payload: {
				product : {
					product_name: '',
					description: '',
					divisions_id: -1,
					energy_supplier_id: -1
				},
				productGroups: state.productGroups,
            }
        });
        
        dispatch({
            type: SET_EDIT_ENTITY,
            payload: {
                id: 0,
                editState: false
            }
        });

        dispatch({
            type: SET_DELETE_ENTITY,
            payload: {
                id: 0,
                deleteModalState: false
            }
        });

        dispatch({
            type: SET_DELETE_MODAL_ENTITY_NAME,
            payload: ''
        });
    }

    const deleteEntityConfirmed = () => {

        const params = new URLSearchParams();
        params.append('jwt', sessionStorage.jwt);
        params.append('id', state.activeEntity);

        let axiosConfig = {
            headers: {
                "Content-Type": "application/x-www-form-urlencoded"
            }
        };

        let url = process.env.REACT_APP_API_URI + '/administration/products/delete_product.php';

        axios.post(url,params,axiosConfig)
        .then(response => {
            if(response.status === 200) {
                // console.log(response.data);
                
                getEntities();
                closeDelete();

            } else if(response.status === 401) {
                window.location.href ='#/logout';
            } else {
                console.log('Anderer Fehler');
            }
        })
        .catch(function(error) {
            if(error.response){
                // The server answered with an error
                if(error.response.status === 401) {
                    window.location.href = '#/logout';
                } else {
                    console.log(error.response.data);
                    console.log(error.response.status);
                    console.log(error.response.headers);
                }
            } else if(error.request) {
                //  The request was made but the server didn't respond
                console.log(error.request);
            } else {
                //  Something else went wrong
                console.log('Error', error.message);
            }
        })
    }

    /*-------------------------------------
    //  PAGE SPECIFIC
    -------------------------------------*/

    const productNameChange = (e) => {

        if(state.editDialogError !== '' && state.editDialogProductName === '') {
            setError('');
        }

        dispatch({
            type: PRODUCT_NAME_CHANGE,
            payload: e.target.value
        });
    }
    
    const descriptionChange = (e) => {

        dispatch({
            type: DESCRIPTION_CHANGE,
            payload: e.target.value
        });
    }

    const divisionsIdChange = (e) => {

        if(state.editDialogDivisionsId === -1 && state.editDialogError !== '')
        {
            setError('');
        }

        dispatch({
            type: DIVISIONS_ID_CHANGE,
            payload: e.target.value,
        });
    }

    const energySupplierIdChange = (e) => {

        if(state.editDialogError !== '' && state.editDialogEnergySupplierId === -1) {
            setError('');
		}

		const newEnergySuppliersId = e.target.value;
		
		//	get product groups for the new supplier
		const params = new URLSearchParams();
        params.append('jwt', sessionStorage.jwt);
		params.append('productGroupsId', state.editDialogProductGroupsId);
		params.append('energySuppliersId', newEnergySuppliersId);

        let axiosConfig = {
            headers: {
                "Content-Type": "application/x-www-form-urlencoded"
            }
        };

        let url = process.env.REACT_APP_API_URI + '/administration/products/get_product_product_groups_update.php';

        axios.post(url,params,axiosConfig)
        .then(response => {
            if(response.status === 200) {
                // console.log(response.data);
                
                

				dispatch({
					type: ENERGY_SUPPLIER_ID_CHANGE,
					payload: 
					{
						energySuppliersId: newEnergySuppliersId,
						productGroups: response.data.productGroups,
						productGroupsId: response.data.productGroupsId
					}
				});

            } else if(response.status === 401) {
                window.location.href ='#/logout';
            } else {
                console.log('Anderer Fehler');
            }
        })
        .catch(function(error) {
            if(error.response){
                // The server answered with an error
                if(error.response.status === 401) {
                    window.location.href = '#/logout';
                } else {
                    console.log(error.response.data);
                    console.log(error.response.status);
                    console.log(error.response.headers);
                }
            } else if(error.request) {
                //  The request was made but the server didn't respond
                console.log(error.request);
            } else {
                //  Something else went wrong
                console.log('Error', error.message);
            }
        })
	}
	

	const productGroupsIdChange = (e) => {
		dispatch({
			type: PRODUCT_GROUPS_ID_CHANGE,
			payload: e.target.value,
		});
	}

    return <ProductsContext.Provider value={{

        entities: state.entities,
        editDialogFields: state.editDialogFields,
        editDialogError: state.editDialogError,
        activeEntity: state.activeEntity,
        editState: state.editState,
        userRoleLabel: state.userRoleLabel,
        editDialogTitle: state.editDialogTitle,
        deleteModalTitle: state.deleteModalTitle,
        deleteModalEntityName: state.deleteModalEntityName,
        deleteModalState: state.deleteModalState,

        editDialogProductName: state.editDialogProductName,
        editDialogDescription: state.editDialogDescription,
        editDialogDivisionsId: state.editDialogDivisionsId,
		editDialogEnergySupplierId: state.editDialogEnergySupplierId,
		editDialogProductGroupsId: state.editDialogProductGroupsId,
        energySuppliers: state.energySuppliers,
		divisions: state.divisions,
		productGroups: state.productGroups,

        editEntity,
        setError,
        saveEntity,
        getEntities,
        closeEdit,
        deleteEntity,
        closeDelete,
        deleteEntityConfirmed,

        productNameChange,
        descriptionChange,
		energySupplierIdChange,
		productGroupsIdChange,

}}>
        {props.children}
    </ProductsContext.Provider>
}

export default ProductsState;