/**
 *      File:       ./src/context/administration/products/ProductTypesState.js
 * 
 *      Subject:    Product Types' State
 */
import React, { useReducer, useEffect } from 'react';
import axios from 'axios';
import ProductTypesContext from './productTypesContext';
import ProductTypesReducer from './productTypesReducer';
import FormCheckBox from '../../../Components/FormCheckbox';
import FormInput from '../../../Components/FormInput';
import FormSelect from '../../../Components/FormSelect';

import {

    DESCRIPTION_CHANGE,
    DIVISIONS_ID_CHANGE,
	GET_ENTITIES,
	IS_BIO_5_CHANGE,
    IS_BIO_10_CHANGE,
    IS_CLIMATE_PLUS_CHANGE,
    IS_CO2_CHANGE,
    PRODUCT_TYPE_NAME_CHANGE,
    SET_ACTIVE_ENTITY,
    SET_DELETE_ENTITY,
    SET_DELETE_MODAL_ENTITY_NAME,
    SET_EDIT_DIALOG_TITLE,
    SET_EDIT_ENTITY,
    SET_ENTITY_DIALOG_FIELDS,
    SET_ERROR,
    SET_FOREIGNS,

} from '../../types';
import FormCheckbox from '../../../Components/FormCheckbox';

const ProductTypesState = props => {

    const initialState = {

        activeEntity: 0,
        deleteModalEntityName: '',
        deleteModalState: false,
        divisions: [],
        editDialogDescription: '',
        editDialogDivisionsId: -1,
        editDialogError: '',
		editDialogFields: [],
		editDialogIsBio5: false,
        editDialogIsBio10: false,
        editDialogIsClimatePlus: false,
        editDialogIsCo2: false,
        editDialogProductTypeName: '',
        editDialogTitle: '',
        editState: false,
        entities: [],
        options: [],

    }
	const [state,dispatch] = useReducer(ProductTypesReducer, initialState);
	
    useEffect(() => {

        async function fetchData() {
            
            const params = new URLSearchParams();
            params.append('jwt', sessionStorage.jwt);

            let axiosConfig = {
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded"
                }
            };

            let url = process.env.REACT_APP_API_URI + '/administration/products/get_product_type_foreigns.php';

            axios.post(url,params,axiosConfig)
            .then(response => {
                if(response.status === 200) {
                    // console.log(response.data);
                    
                    dispatch({
                        type: SET_FOREIGNS,
                        payload: {
                            divisions: response.data.divisions
                        }
                    });

                } else if(response.status === 401) {
                    window.location.href ='#/logout';
                } else {
                    console.log('Anderer Fehler');
                }
            })
            .catch(function(error) {
                if(error.response){
                    // The server answered with an error
                    if(error.response.status === 401) {
                        window.location.href = '#/logout';
                    } else {
                        console.log(error.response.data);
                        console.log(error.response.status);
                        console.log(error.response.headers);
                    }
                } else if(error.request) {
                    //  The request was made but the server didn't respond
                    console.log(error.request);
                } else {
                    //  Something else went wrong
                    console.log('Error', error.message);
                }
            })
        }
        fetchData();

        const edf = [
            {
                id: 1,
                fieldType: <FormInput
                    context             = {ProductTypesContext}
                    id                  = "product_type_name"
                    label               = "Name der Produktart"
                    onChangeFunction    = {productTypeNameChange}
                    placeholder         = "Produktart"
                    required
                    type                = "text"
                    value               = "editDialogProductTypeName"
                />
            },
            {
                id: 2,
                fieldType: <FormInput
                    context             = {ProductTypesContext}
                    id                  = "description"
                    label               = "Beschreibung"
                    onChangeFunction    = {descriptionChange}
                    placeholder         = "Beschreibung"
                    type                = "text"
                    value               = "editDialogDescription"
                />
            },
            {
                id: 3,
                fieldType: <FormSelect
                    context             = {ProductTypesContext}
                    id                  = "divisions_id"
                    label               = "Sparte"
                    onChangeFunction    = {divisionsIdChange}
                    required            
                    selectOptions       = "divisions"
                    value               = "editDialogDivisionsId"
                />
            },
            {
                id: 4,
                fieldType: <FormCheckbox
                    context             = {ProductTypesContext}
                    id                  = "is_climate_plus"
                    label               = "KlimaPlus"
                    onChangeFunction    = {isClimatePlusChange}
                    value               = "editDialogIsClimatePlus"
                    disabled
                />
			},
			{
				id: 5,
				fieldType: <FormCheckBox 
					context				= {ProductTypesContext}
					id					= "is_bio5"
					label				= "Bio5"
					onChangeFunction	= {isBio5Change}
					value				= "editDialogIsBio5"
					disabled
				/>
			},
            {
                id: 6,
                fieldType: <FormCheckbox
                    context             = {ProductTypesContext}
                    id                  = "is_bio10"
                    label               = "Bio10"
                    onChangeFunction    = {isBio10Change}
                    value               = "editDialogIsBio10"
                    disabled
                />
            }
        ];

        dispatch({
            type: SET_ENTITY_DIALOG_FIELDS,
            payload: edf
        });

        getEntities();

        // eslint-disable-next-line
    }, [state.editDialogError], [state.userRoleLabel]);


    const getEntities = () => {

        const params = new URLSearchParams();
        params.append('jwt', sessionStorage.jwt);

        let axiosConfig = {
            headers: {
                "Content-Type": "application/x-www-form-urlencoded"
            }
        };

        let url = process.env.REACT_APP_API_URI + '/administration/products/get_product_types.php';

        axios.post(url,params,axiosConfig)
        .then(response => {
            if(response.status === 200) {

                // console.log(response.data.entities);
                
                dispatch({
                    type: GET_ENTITIES,
                    payload: response.data.entities
                });

            } else if(response.status === 401) {
                window.location.href ='#/logout';
            } else {
                console.log('Anderer Fehler');
            }
        })
        .catch(function(error) {
            if(error.response){
                // The server answered with an error
                if(error.response.status === 401) {
                    window.location.href = '#/logout';
                } else {
                    console.log(error.response.data);
                    console.log(error.response.status);
                    console.log(error.response.headers);
                }
            } else if(error.request) {
                //  The request was made but the server didn't respond
                console.log(error.request);
            } else {
                //  Something else went wrong
                console.log('Error', error.message);
            }
        })
    }

    const editEntity = (id) => {

        if(id > 0)
        {
            const params = new URLSearchParams();
            params.append('jwt', sessionStorage.jwt);
            params.append('id', id);

            let axiosConfig = {
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded"
                }
            };

            let url = process.env.REACT_APP_API_URI + '/administration/products/get_product_type.php';

            axios.post(url,params,axiosConfig)
            .then(response => {
                if(response.status === 200) {
                    // console.log(response.data);

                    dispatch({
                        type: SET_ACTIVE_ENTITY,
                        payload: response.data.productType
                    });

                    dispatch({
                        type: SET_EDIT_ENTITY,
                        payload: {
                            editState: true
                        }
                    });

                    dispatch({
                        type: SET_EDIT_DIALOG_TITLE,
                        payload: 'Produktart bearbeiten'
                    });

                    if(response.data.productType.divisionsId === "1") {
						document.getElementById('is_bio5').disabled = false;
                        document.getElementById('is_bio10').disabled = false;
						document.getElementById('is_co2').disabled = false;
						document.getElementById('is_climate_plus').disabled = false;
                    } else if(response.data.productType.divisionsId === "2"){
                        document.getElementById('is_climate_plus').disabled = false;
                    }

                } else if(response.status === 401) {
                    window.location.href ='#/logout';
                } else {
                    console.log('Anderer Fehler');
                }
            })
            .catch(function(error) {
                if(error.response){
                    // The server answered with an error
                    if(error.response.status === 401) {
                        window.location.href = '#/logout';
                    } else {
                        console.log(error.response.data);
                        console.log(error.response.status);
                        console.log(error.response.headers);
                    }
                } else if(error.request) {
                    //  The request was made but the server didn't respond
                    console.log(error.request);
                } else {
                    //  Something else went wrong
                    console.log('Error', error.message);
                }
            })
        } 
        else {
            dispatch({
                type: SET_EDIT_DIALOG_TITLE,
                payload: 'Neue Produktart anlegen'
            });
        }

        dispatch({
            type: SET_EDIT_ENTITY,
            payload: {
                id: id,
                editState: true
            }
        });
        
    }

    const setError = (errMsg) => {
        dispatch({
            type: SET_ERROR,
            payload: errMsg
        });
    }

    const saveEntity = async () => {

        if(state.editDialogProductTypeName === '') {
            setError('Bitte geben Sie eine Bezeichnung für die Produktart ein.');
            return false;
        }

        if(state.editDialogDivisionsId < 0) {
            setError('Bitte wählen Sie eine Sparte für die Produktart aus');
            return false;
        }

        const params = new URLSearchParams();
        params.append('jwt', sessionStorage.jwt);
        params.append('id', state.activeEntity);
        params.append('productTypeName', state.editDialogProductTypeName);
        params.append('description', state.editDialogDescription);
        params.append('divisionsId', state.editDialogDivisionsId);
		params.append('isClimatePlus', state.editDialogIsClimatePlus);
		params.append('isBio5', state.editDialogIsBio5);
        params.append('isBio10', state.editDialogIsBio10);
        params.append('isCo2', state.editDialogIsCo2);

        let axiosConfig = {
            headers: {
                "Content-Type": "application/x-www-form-urlencoded"
            }
        };

        let url = process.env.REACT_APP_API_URI + '/administration/products/set_product_type.php';

        axios.post(url,params,axiosConfig)
        .then(response => {
            if(response.status === 200) {

                // console.log(response.data);

                const productType = {
                    productTypeName: '',
                    description: '',
					divisionsId: -1,
					isBio5: false,
                    isBio10: false,
                    isClimatePlus: false,
                    isCo2: false,
                };
                getEntities();

                dispatch({
                    type: SET_ACTIVE_ENTITY,
                    payload: productType
                });
                dispatch({
                    type: SET_EDIT_ENTITY,
                    payload: {
                        editState: false
                    }
                });
                return true;

            } else if(response.status === 401) {
                window.location.href ='#/logout';
            } else {
                console.log('Anderer Fehler');
                return false;
            }
        })
        .catch(function(error) {
            if(error.response){
                // The server answered with an error
                if(error.response.status === 401) {
                    window.location.href = '#/logout';
                } else {
                    console.log(error.response.data);
                    console.log(error.response.status);
                    console.log(error.response.headers);
                    return false;
                }
            } else if(error.request) {
                //  The request was made but the server didn't respond
                console.log(error.request);
                return false;
            } else {
                //  Something else went wrong
                console.log('Error', error.message);
                return false;
            }
        })
        return true;
    }

    const closeEdit = () => {
        dispatch({
            type: SET_ACTIVE_ENTITY,
            payload: {
                productTypeName: '',
                description: '',
				divisionsId: -1,
				isBio5: false,
                isBio10: false,
                isClimatePlus: false,
                isCo2: false,
            }
        });
        
        dispatch({
            type: SET_EDIT_ENTITY,
            payload: {
                editState: false
            }
        });
    }

    const deleteEntity = (id) => {
        
        const params = new URLSearchParams();
        params.append('jwt', sessionStorage.jwt);
        params.append('id', id);

        let axiosConfig = {
            headers: {
                "Content-Type": "application/x-www-form-urlencoded"
            }
        };

        let url = process.env.REACT_APP_API_URI + '/administration/products/get_product_type.php';

        axios.post(url,params,axiosConfig)
        .then(response => {
            if(response.status === 200) {
                // console.log(response.data);
                
                dispatch({
                    type: SET_ACTIVE_ENTITY,
                    payload: response.data.productType
                });

                dispatch({
                    type: SET_DELETE_ENTITY,
                    payload: {
                        id: id,
                        deleteModalState: true
                    }
                });

                dispatch({
                    type: SET_DELETE_MODAL_ENTITY_NAME,
                    payload: 'Möchten Sie die Produktart "' + response.data.productType.productTypeName + '" wirklich löschen?'
                });

            } else if(response.status === 401) {
                window.location.href ='#/logout';
            } else {
                console.log('Anderer Fehler');
            }
        })
        .catch(function(error) {
            if(error.response){
                // The server answered with an error
                if(error.response.status === 401) {
                    window.location.href = '#/logout';
                } else {
                    console.log(error.response.data);
                    console.log(error.response.status);
                    console.log(error.response.headers);
                }
            } else if(error.request) {
                //  The request was made but the server didn't respond
                console.log(error.request);
            } else {
                //  Something else went wrong
                console.log('Error', error.message);
            }
        })
    }

    const closeDelete = () => {

        dispatch({
            type: SET_ACTIVE_ENTITY,
            payload: {
                productTypeName: '',
                description: '',
				divisionsId: -1,
				isBio5: false,
                isBio10: false,
                isClimatePlus: false,
                isCo2: false,
            }
        });
        
        dispatch({
            type: SET_EDIT_ENTITY,
            payload: {
                editState: false
            }
        });

        dispatch({
            type: SET_DELETE_ENTITY,
            payload: {
                id: 0,
                deleteModalState: false
            }
        });

        dispatch({
            type: SET_DELETE_MODAL_ENTITY_NAME,
            payload: ''
        });
    }

    const deleteEntityConfirmed = () => {

        const params = new URLSearchParams();
        params.append('jwt', sessionStorage.jwt);
        params.append('id', state.activeEntity);

        let axiosConfig = {
            headers: {
                "Content-Type": "application/x-www-form-urlencoded"
            }
        };

        let url = process.env.REACT_APP_API_URI + '/administration/products/delete_product_type.php';

        axios.post(url,params,axiosConfig)
        .then(response => {
            if(response.status === 200) {
                // console.log(response.data);
                
                getEntities();
                closeDelete();

            } else if(response.status === 401) {
                window.location.href ='#/logout';
            } else {
                console.log('Anderer Fehler');
            }
        })
        .catch(function(error) {
            if(error.response){
                // The server answered with an error
                if(error.response.status === 401) {
                    window.location.href = '#/logout';
                } else {
                    console.log(error.response.data);
                    console.log(error.response.status);
                    console.log(error.response.headers);
                }
            } else if(error.request) {
                //  The request was made but the server didn't respond
                console.log(error.request);
            } else {
                //  Something else went wrong
                console.log('Error', error.message);
            }
        })
    }

    /*-------------------------------------
    //  PAGE SPECIFIC
    -------------------------------------*/

    const descriptionChange = (e) => {
        dispatch({
            type: DESCRIPTION_CHANGE,
            payload: e.target.value,
        });
    }

    const divisionsIdChange = (e) => {
        if(state.divisionsId === -1 && state.editDialogError !== '')
            setError('');

        if(e.target.value === "2") {

			document.getElementById('is_climate_plus').disabled = false;
			document.getElementById('is_bio5').disabled = true;
            document.getElementById('is_bio10').disabled = true;
			// document.getElementById('is_co2').disabled = true;
			
			dispatch({
				type: IS_BIO_5_CHANGE,
				payload: false,
			});

            dispatch({
                type: IS_BIO_10_CHANGE,
                payload: false,
            });

            dispatch({
                type: IS_CO2_CHANGE,
                payload: false,
            });
        } else if(e.target.value === "1") {

			document.getElementById('is_climate_plus').disabled = false;
			document.getElementById('is_bio5').disabled = false;
            document.getElementById('is_bio10').disabled = false;
            // document.getElementById('is_co2').disabled = false;

            dispatch({
                type: IS_CLIMATE_PLUS_CHANGE,
                payload: false,
            });
        } else {

			document.getElementById('is_climate_plus').disabled = true;
			document.getElementById('is_bio5').disabled = true;
            document.getElementById('is_bio10').disabled = true;
            // document.getElementById('is_co2').disabled = true;

            dispatch({
                type: IS_CLIMATE_PLUS_CHANGE,
                payload: false,
			});
			
			dispatch({
				type: IS_BIO_5_CHANGE,
				payload: false,
			});

            dispatch({
                type: IS_BIO_10_CHANGE,
                payload: false,
            });

            dispatch({
                type: IS_CO2_CHANGE,
                payload: false,
            });
        }

        dispatch({
            type: DIVISIONS_ID_CHANGE,
            payload: e.target.value,
        });
	}
	
	const isBio5Change = (e) => {
		dispatch({
			type: IS_BIO_5_CHANGE,
			payload: e.target.checked,
		});
	}

    const isBio10Change = (e) => {
        dispatch({
            type: IS_BIO_10_CHANGE,
            payload: e.target.checked,
        });
    }

    const isClimatePlusChange = (e) => {
        dispatch({
            type: IS_CLIMATE_PLUS_CHANGE,
            payload: e.target.checked,
        });
    }

    const isCo2Change = (e) => {
        dispatch({
            type: IS_CO2_CHANGE,
            payload: e.target.checked,
        });
    }

    const productTypeNameChange = (e) => {
        if(state.editDialogError !== '' && state.editDialogProductTypeName === '')
            setError('');

        dispatch({
            type: PRODUCT_TYPE_NAME_CHANGE,
            payload: e.target.value,
        });
    }

    return <ProductTypesContext.Provider value={{

        activeEntity: state.activeEntity,
        deleteModalEntityName: state.deleteModalEntityName,
        deleteModalState: state.deleteModalState,
        divisions: state.divisions,
        editDialogDescription: state.editDialogDescription,
        editDialogDivisionsId: state.editDialogDivisionsId,
        editDialogError: state.editDialogError,
		editDialogFields: state.editDialogFields,
		editDialogIsBio5: state.editDialogIsBio5,
        editDialogIsBio10: state.editDialogIsBio10,
        editDialogIsClimatePlus: state.editDialogIsClimatePlus,
        editDialogIsCo2: state.editDialogIsCo2,
        editDialogProductTypeName: state.editDialogProductTypeName,
        editDialogTitle: state.editDialogTitle,
        editState: state.editState,
        entities: state.entities,
        options: state.options,

        getEntities,
        editEntity,
        setError,
        saveEntity,
        closeEdit,
        deleteEntity,
        closeDelete,
        deleteEntityConfirmed,
        descriptionChange,
		divisionsIdChange,
		isBio5Change,
        isBio10Change,
        isClimatePlusChange,
        isCo2Change,
        productTypeNameChange,

}}>
        {props.children}
    </ProductTypesContext.Provider>
}

export default ProductTypesState;