/**
 *      File:       ./src/context/administration/products/productsReducer.js
 * 
 *      Subject:    Products' State Reducer
 */
import {
    GET_ENTITIES,
    SET_ACTIVE_ENTITY,
    SET_DELETE_ENTITY,
    SET_DELETE_MODAL_ENTITY_NAME,
    SET_EDIT_DIALOG_TITLE,
    SET_EDIT_ENTITY,
    SET_ENTITY_DIALOG_FIELDS,
    SET_ERROR,

    SET_USER_FOREIGNS,
    PRODUCT_NAME_CHANGE,
    DESCRIPTION_CHANGE,
    DIVISIONS_ID_CHANGE,
	ENERGY_SUPPLIER_ID_CHANGE,
	PRODUCT_GROUPS_ID_CHANGE

} from '../../types';

export default(state, action) => {

    switch(action.type)
    {
        //  General section

        case GET_ENTITIES:
            return {
                ...state,
                entities: action.payload
            }

        case SET_ACTIVE_ENTITY:
            return {
                ...state,
                editDialogProductName: action.payload.product.product_name,
                editDialogDescription: action.payload.product.description,
                editDialogDivisionsId: action.payload.product.divisions_id,
				editDialogEnergySupplierId: action.payload.product.energy_suppliers_id,
				editDialogProductGroupsId: action.payload.product.product_groups_id,
				productGroups: action.payload.productGroups,
                editDialogError: '',
            }

        case SET_DELETE_ENTITY:
            return {
                ...state,
                activeEntity: action.payload.id,
                deleteModalState: action.payload.deleteModalState
            }

        case SET_DELETE_MODAL_ENTITY_NAME:
            return {
                ...state,
                deleteModalEntityName: action.payload
            }

        case SET_EDIT_DIALOG_TITLE:
            return {
                ...state,
                editDialogTitle: action.payload
            }

        case SET_EDIT_ENTITY:
            return {
                ...state,
                activeEntity: action.payload.id,
                editState: action.payload.editState
            }

        case SET_ENTITY_DIALOG_FIELDS:
            return {
                ...state,
                editDialogFields: action.payload
            }

        case SET_ERROR:
            return {
                ...state,
                editDialogError: action.payload
            }


        //  Individual Section

        case SET_USER_FOREIGNS:
            return {
                ...state,
                energySuppliers: action.payload.energySupplier,
                divisions: action.payload.divisions,
            }
            
        case PRODUCT_NAME_CHANGE:
            return {
                ...state,
                editDialogProductName: action.payload
            }

        case DESCRIPTION_CHANGE:
            return {
                ...state,
                editDialogDescription: action.payload
            }

        case DIVISIONS_ID_CHANGE:
            return{
                ...state,
                editDialogDivisionsId: action.payload,
            }

        case ENERGY_SUPPLIER_ID_CHANGE:
            return {
                ...state,
				editDialogEnergySupplierId: action.payload.energySuppliersId,
				productGroups: action.payload.productGroups,
				editDialogProductGroupsId: action.payload.productGroupsId,
			}
			
		case PRODUCT_GROUPS_ID_CHANGE:
			return {
				...state,
				editDialogProductGroupsId: action.payload,
			}

            
        //  Default Path
        default:
            return state;
    }
}