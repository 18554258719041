/**
 *      File:       ./src/context/exports/newOnesContext.js
 * 
 *      Subject:    new contracts' export context
 */
import { createContext } from "react";

const newOnesContext  = createContext();

export default newOnesContext ;
