/**
 *      File:       ./src/Pages/Pages/LoginBoxed/index.js
 * 
 *      Subject:    LoginBoxed handles the whole login process
 */

import React, { Fragment, useState } from 'react';
import { Col, Row, Button, Form, FormGroup, Input } from 'reactstrap';

import axios from 'axios';
import Logo from '../../../assets/utils/images/logo-inverse.svg';


const LoginBoxed = ({ setLogin }) => {

	const [errMsg, setErrMsg] = useState('');
	const [user, setUser] = useState('');
	const [pw, setPw] = useState('');

	//  Enter User Name
	const changeUser = (e) => {

		if (errMsg !== '' && user === '')
			setErrMsg('');
		setUser(e.target.value);
	}

	//  Enter Password
	const changePw = (e) => {
		if (errMsg !== '' && pw === '')
			setErrMsg('');

		setPw(e.target.value);
	}

	//  Submit the form
	const onSubmit = async (e) => {

		e.preventDefault();

		//  Check mandatories
		if (user === '')
			setErrMsg('Bitte geben Sie eine E-Mail-Adresse ein');
		else if (pw === '')
			setErrMsg('Bitte geben Sie ein Passwort ein');
		else {

			//  Check login
			const params = new URLSearchParams();
			params.append('user', user);
			params.append('pw', pw);

			let url = process.env.REACT_APP_API_URI + '/login/check_login.php';
			let axiosConfig = {
				headers: {
					"Content-Type": "application/x-www-form-urlencoded"
				}
			}

			try {

				//  Handle credentials check response
				const res = await axios.post(url, params, axiosConfig);
				// console.log(res.data);
				setLogin(res.data);

			} catch (error) {
				console.log('Fehler bei der Anmeldung');
				setErrMsg('Die Zugangsdaten sind nicht korrekt.');
			}
		}
	}

	return (
		<Fragment>
			<div className="h-100 bg-plum-plate bg-animation">
				<div className="d-flex h-100 justify-content-center align-items-center">
					<Col md="8" className="mx-auto app-login-box">
						<div className="modal-dialog w-100 mx-auto">
							<div className="modal-content">
								<img src={Logo} alt="KSE Energie - Vor Kirche für Kirche" className="login-logo" style={{ width: "50%", marginLeft: "20px" }} />
								<Form onSubmit={onSubmit}>
									<div className="modal-body">
										<div className="h5 modal-title text-center">
											<h4 className="mt-2">
												<div>Willkommen zurück!</div>
												<span>Bitte melden Sie sich mit Ihren Zugangsdaten an.</span>
											</h4>
										</div>
										<div className="divider" />
										<div className="login-error-div">
											{(errMsg !== '') ? (<div className="login-error">
												{errMsg}
											</div>
											) : ''}
										</div>
										<Row form>
											<Col md={12}>
												<FormGroup>
													<Input type="email" name="email"
														placeholder="E-Mail-Adresse" value={user} onChange={changeUser} />
												</FormGroup>
											</Col>
											<Col md={12}>
												<FormGroup>
													<Input type="password" name="password"
														placeholder="Passwort" value={pw} onChange={changePw} />
												</FormGroup>
											</Col>
										</Row>
									</div>
									<div className="modal-footer clearfix">
										<div className="float-left">
											<a href="https://colorlib.com/" onClick={(e) => e.preventDefault()} className="btn-lg btn btn-link">Passwort vergessen</a>
										</div>
										<div className="float-right">
											<Button type="submit" color="primary" className="kse-buttons-primary" size="lg">Anmelden</Button>
										</div>
									</div>
								</Form>
							</div>
						</div>
					</Col>
				</div>
			</div>
		</Fragment>
	);
};

export default LoginBoxed;
