/**
 *  File:   ./src/Layout/AppMain/index.js
 * 
 *  Subject:    AppMain does the central routing for the
 *              whole application.
 */
import React, {Suspense, lazy, Fragment}    from 'react';
import { Route, Redirect}                   from 'react-router-dom';
import Loader                               from 'react-loaders';
import { ToastContainer }                   from 'react-toastify';

import LoginBoxed                 from '../../Pages/Pages/LoginBoxed';
const  Pages         = lazy(() => import('../../Pages/Pages'));
const  Dashboards    = lazy(() => import('../../Pages/Dashboards'));

const AppMain = (props) => {

    const { loggedIn, setLogin, setLogout } = props;

    return (
        <Fragment>

            {/* Pages */}

            {loggedIn && (
                <Suspense fallback={
                    <div className="loader-container">
                        <div className="loader-container-inner">
                            <div className="text-center">
                                <Loader type="line-scale-party"/>
                            </div>
                            <h6 className="mt-3">
                                Die Daten werden geladen, einen kleinen Augenblick noch...
                            </h6>
                        </div>
                    </div>
                }>
                    <Route path="/pages" component={Pages}/>
                </Suspense>
            )}

            {/* Dashboards */}

            {loggedIn && (
                <Suspense fallback={
                    <div className="loader-container">
                        <div className="loader-container-inner">
                            <div className="text-center">
                                <Loader type="ball-grid-cy"/>
                            </div>
                            <h6 className="mt-3">
                                Die Daten werden geladen, einen kleinen Augenblick noch...
                            </h6>
                        </div>
                    </div>
                }>
                    <Route path="/dashboards" component={Dashboards}/>
                </Suspense>
            )}

            {loggedIn && (
                <Route exact path="/" render={() => (
                    <Redirect to="/dashboards/kse"/>
                )}/>
            )}
            {loggedIn && (
                <Route exact path="/logout" render={() => {
                    setLogout();
                    window.location.href = '/';
                }} />
            )}
            {!loggedIn && (
                <Route render={() => <LoginBoxed setLogin={setLogin} />} />
            )}
            <ToastContainer/>
        </Fragment>
    )
};

export default AppMain;