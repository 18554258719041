/**
 *      File:       ./src/context/customers/customersContext.js
 * 
 *      Subject:    Customer's context definition
 */

import { createContext } from "react";

const customersContext = createContext();

export default customersContext;
