/**
 * 		File:		./src/context/exports/PortalState.js
 * 
 * 		Subject:	State for portal exports
 */

import React, { useReducer } from "react";
import axios from "axios";
import PortalContext from "./portalContext";
import PortalReducer from "./portalReducer";

import {
	GET_EXPORTS_PORTAL
} from "../types";

const PortalState = (props) => {

  const initialState = {

	dataAdministration: [],
	dataCustomer: [],
	dataObject: [],
	dataCustomerContactPerson: [],
	dataLeadContactPerson: [],
    
  };
  const [state, dispatch] = useReducer(PortalReducer, initialState);


  //------------------------------
  //	GET THE EXPORTS
  //------------------------------
  const getExports = () => {

	//	Set call
	const params = new URLSearchParams();
    params.append('jwt', sessionStorage.jwt);
    params.append('exportPage', "portal");

	let url = process.env.REACT_APP_API_URI + '/exports/get_exports.php';
    let axiosConfig = {
        headers: {
            "Content-Type": "application/x-www-form-urlencoded"
        }
    };

	//	Call backend
    axios.post(url,params,axiosConfig)
    .then(response => {
        if(response.status === 200) {

            // console.log(response.data);
            
            dispatch({
                type: GET_EXPORTS_PORTAL,
                payload: {
					administrationExports:			response.data.administrationExports,
					customerExports: 				response.data.customerExports,
					objectExports: 					response.data.objectExports,
					customerContactPersonExports: 	response.data.customerContactPersonExports,
					leadContactPersonExports:		response.data.leadContactPersonExports,
				},
			});
			
			if(
				(response.data.administrationExports.length > 0 && response.data.administrationExports[0]['id'] === 0) ||
				(response.data.customerExports.length > 0 && response.data.customerExports[0]['id'] === 0) ||
				(response.data.objectExports.length > 0 && response.data.objectExports[0]['id'] === 0) ||
				(response.data.customerContactPersonExports.length > 0 && response.data.customerContactPersonExports[0]['id'] === 0) ||
				(response.data.leadContactPersonExports.length > 0 && response.data.leadContactPersonExports[0]['id'] === 0)) {

					window.setTimeout(getExports, 1000);
				}

        } else if(response.status === 401) {
            window.location.href ='#/logout';
        } else {
            console.log('Anderer Fehler');
        }
    })
    .catch(function(error) {
        if(error.response){
            // The server answered with an error
            if(error.response.status === 401) {
                window.location.href = '#/logout';
            } else {
                console.log(error.response.data);
                console.log(error.response.status);
                console.log(error.response.headers);
            }
        } else if(error.request) {
            //  The request was made but the server didn't respond
            console.log(error.request);
        } else {
            //  Something else went wrong
            console.log('Error', error.message);
        }
    })
  }

  const exportAdministration = () => {

	//	Set call
	const params = new URLSearchParams();
    params.append('jwt', sessionStorage.jwt);

	let url = process.env.REACT_APP_API_URI + '/exports/start_export_administration.php';
    let axiosConfig = {
        headers: {
            "Content-Type": "application/x-www-form-urlencoded"
        }
	};
	
	let administrationInProgress = state.dataAdministration;
	administrationInProgress[0].id = 0;
	administrationInProgress[0].filename = "Export wird erstellt...";
	dispatch({
		type: GET_EXPORTS_PORTAL,
		payload: {
			administrationExports: administrationInProgress,
			customerExports: state.customerExports,
			objectExports: state.objectExports,
			customerContactPersonExports: state.customerContactPersonExports,
			leadContactPersonExports: state.leadContactPersonExports,
		},
	});

	//	Call backend
    axios.post(url,params,axiosConfig)
    .then(response => {
        if(response.status === 200) {

			// console.log(response.data);
			window.setTimeout(getExports, 1000);

        } else if(response.status === 401) {
            window.location.href ='#/logout';
        } else {
            console.log('Anderer Fehler');
        }
    })
    .catch(function(error) {
        if(error.response){
            // The server answered with an error
            if(error.response.status === 401) {
                window.location.href = '#/logout';
            } else {
                console.log(error.response.data);
                console.log(error.response.status);
                console.log(error.response.headers);
            }
        } else if(error.request) {
            //  The request was made but the server didn't respond
            console.log(error.request);
        } else {
            //  Something else went wrong
            console.log('Error', error.message);
        }
    })
  }

  const exportCustomer = () => {

	//	Set call
	const params = new URLSearchParams();
    params.append('jwt', sessionStorage.jwt);

	let url = process.env.REACT_APP_API_URI + '/exports/start_export_customer.php';
    let axiosConfig = {
        headers: {
            "Content-Type": "application/x-www-form-urlencoded"
        }
	};
	
	let customerInProgress = state.dataCustomer;
	customerInProgress[0].id = 0;
	customerInProgress[0].filename = "Export wird erstellt...";
	dispatch({
		type: GET_EXPORTS_PORTAL,
		payload: {
			administrationExports: state.administrationExports,
			customerExports: customerInProgress,
			objectExports: state.objectExports,
			customerContactPersonExports: state.customerContactPersonExports,
			leadContactPersonExports: state.leadContactPersonExports,
		},
	});

	//	Call backend
    axios.post(url,params,axiosConfig)
    .then(response => {
        if(response.status === 200) {

			// console.log(response.data);
			window.setTimeout(getExports, 1000);

        } else if(response.status === 401) {
            window.location.href ='#/logout';
        } else {
            console.log('Anderer Fehler');
        }
    })
    .catch(function(error) {
        if(error.response){
            // The server answered with an error
            if(error.response.status === 401) {
                window.location.href = '#/logout';
            } else {
                console.log(error.response.data);
                console.log(error.response.status);
                console.log(error.response.headers);
            }
        } else if(error.request) {
            //  The request was made but the server didn't respond
            console.log(error.request);
        } else {
            //  Something else went wrong
            console.log('Error', error.message);
        }
    })
  }

  const exportObject = () => {

	//	Set call
	const params = new URLSearchParams();
    params.append('jwt', sessionStorage.jwt);

	let url = process.env.REACT_APP_API_URI + '/exports/start_export_object.php';
    let axiosConfig = {
        headers: {
            "Content-Type": "application/x-www-form-urlencoded"
        }
	};
	
	let objectInProgress = state.dataObject;
	objectInProgress[0].id = 0;
	objectInProgress[0].filename = "Export wird erstellt...";
	dispatch({
		type: GET_EXPORTS_PORTAL,
		payload: {
			administrationExports: state.administrationExports,
			customerExports: state.customerExports,
			objectExports: objectInProgress,
			customerContactPersonExports: state.customerContactPersonExports,
			leadContactPersonExports: state.leadContactPersonExports,
		},
	});

	//	Call backend
    axios.post(url,params,axiosConfig)
    .then(response => {
        if(response.status === 200) {

			// console.log(response.data);
			window.setTimeout(getExports, 1000);

        } else if(response.status === 401) {
            window.location.href ='#/logout';
        } else {
            console.log('Anderer Fehler');
        }
    })
    .catch(function(error) {
        if(error.response){
            // The server answered with an error
            if(error.response.status === 401) {
                window.location.href = '#/logout';
            } else {
                console.log(error.response.data);
                console.log(error.response.status);
                console.log(error.response.headers);
            }
        } else if(error.request) {
            //  The request was made but the server didn't respond
            console.log(error.request);
        } else {
            //  Something else went wrong
            console.log('Error', error.message);
        }
    })
  }

  const exportCustomerContactPerson = () => {

	//	Set call
	const params = new URLSearchParams();
    params.append('jwt', sessionStorage.jwt);

	let url = process.env.REACT_APP_API_URI + '/exports/start_export_customer_contact_person.php';
    let axiosConfig = {
        headers: {
            "Content-Type": "application/x-www-form-urlencoded"
        }
	};
	
	let customerContactPersonInProgress = state.dataCustomerContactPerson;
	customerContactPersonInProgress[0].id = 0;
	customerContactPersonInProgress[0].filename = "Export wird erstellt...";
	dispatch({
		type: GET_EXPORTS_PORTAL,
		payload: {
			administrationExports: state.administrationExports,
			customerExports: state.customerExports,
			objectExports: state.objectExports,
			customerContactPersonExports: customerContactPersonInProgress,
			leadContactPersonExports: state.leadContactPersonExports,
		},
	});

	//	Call backend
    axios.post(url,params,axiosConfig)
    .then(response => {
        if(response.status === 200) {

			// console.log(response.data);
			window.setTimeout(getExports, 1000);

        } else if(response.status === 401) {
            window.location.href ='#/logout';
        } else {
            console.log('Anderer Fehler');
        }
    })
    .catch(function(error) {
        if(error.response){
            // The server answered with an error
            if(error.response.status === 401) {
                window.location.href = '#/logout';
            } else {
                console.log(error.response.data);
                console.log(error.response.status);
                console.log(error.response.headers);
            }
        } else if(error.request) {
            //  The request was made but the server didn't respond
            console.log(error.request);
        } else {
            //  Something else went wrong
            console.log('Error', error.message);
        }
    })
  }

  const exportLeadContactPerson = () => {

	//	Set call
	const params = new URLSearchParams();
    params.append('jwt', sessionStorage.jwt);

	let url = process.env.REACT_APP_API_URI + '/exports/start_export_lead_contact_person.php';
    let axiosConfig = {
        headers: {
            "Content-Type": "application/x-www-form-urlencoded"
        }
	};
	
	let leadContactPersonInProgress = state.dataLeadContactPerson;
	leadContactPersonInProgress[0].id = 0;
	leadContactPersonInProgress[0].filename = "Export wird erstellt...";
	dispatch({
		type: GET_EXPORTS_PORTAL,
		payload: {
			administrationExports: state.administrationExports,
			customerExports: state.customerExports,
			objectExports: state.objectExports,
			customerContactPersonExports: state.customerContactPersonExports,
			leadContactPersonExports: leadContactPersonInProgress,
		},
	});

	//	Call backend
    axios.post(url,params,axiosConfig)
    .then(response => {
        if(response.status === 200) {

			// console.log(response.data);
			window.setTimeout(getExports, 1000);

        } else if(response.status === 401) {
            window.location.href ='#/logout';
        } else {
            console.log('Anderer Fehler');
        }
    })
    .catch(function(error) {
        if(error.response){
            // The server answered with an error
            if(error.response.status === 401) {
                window.location.href = '#/logout';
            } else {
                console.log(error.response.data);
                console.log(error.response.status);
                console.log(error.response.headers);
            }
        } else if(error.request) {
            //  The request was made but the server didn't respond
            console.log(error.request);
        } else {
            //  Something else went wrong
            console.log('Error', error.message);
        }
    })
  }

  return (
    <PortalContext.Provider
      value={{

		dataAdministration: state.dataAdministration,
		dataCustomer: state.dataCustomer,
		dataObject: state.dataObject,
		dataCustomerContactPerson: state.dataCustomerContactPerson,
		dataLeadContactPerson: state.dataLeadContactPerson,

		getExports,
		exportAdministration,
		exportCustomer,
		exportObject,
		exportCustomerContactPerson,
		exportLeadContactPerson,
        
      }}
    >
      {props.children}
    </PortalContext.Provider>
  );
};

export default PortalState;
