/**
 *      File:       ./src/context/administration/products/tarifTypesReducer.js
 * 
 *      Subject:    Tarif Types' State Reducer
 */
import {

    DESCRIPTION_CHANGE,
	GET_ENTITIES,
	TARIF_TYPE_NAME_CHANGE,
	SHORTCUT_CHANGE,
    SET_ACTIVE_ENTITY,
    SET_DELETE_ENTITY,
    SET_DELETE_MODAL_ENTITY_NAME,
    SET_EDIT_DIALOG_TITLE,
    SET_EDIT_ENTITY,
    SET_ENTITY_DIALOG_FIELDS,
    SET_ERROR,

} from '../../types';

export default(state, action) => {
    
    switch(action.type)
    {
        case DESCRIPTION_CHANGE:
            return {
                ...state,
                editDialogDescription: action.payload,
            }

        case GET_ENTITIES:
            return {
                ...state,
                entities: action.payload,
			}

        case TARIF_TYPE_NAME_CHANGE:
            return {
                ...state,
                editDialogTarifTypeName: action.payload,
			}
			
		case SHORTCUT_CHANGE:
			return {
				...state,
				editDialogShortcut: action.payload,
			}

        case SET_ACTIVE_ENTITY:
            return {
                ...state,
				activeEntity: action.payload.id,
				editDialogTarifTypeName: action.payload.tarifTypeName,
				editDialogDescription: action.payload.description,
				editDialogShortcut: action.payload.shortcut,
            }

        case SET_DELETE_ENTITY:
            return {
                ...state,
                activeEntity: action.payload.id,
                deleteModalState: action.payload.deleteModalState
            }

        case SET_DELETE_MODAL_ENTITY_NAME:
            return {
                ...state,
                deleteModalEntityName: action.payload
            }

        case SET_EDIT_DIALOG_TITLE:
            return {
                ...state,
                editDialogTitle: action.payload
            }

        case SET_EDIT_ENTITY:
            return {
                ...state,
                editState: action.payload.editState,
            }

        case SET_ENTITY_DIALOG_FIELDS:
            return {
                ...state,
                editDialogFields: action.payload
            }

        case SET_ERROR:
            return {
                ...state,
                editDialogError: action.payload
            }

        //  Default Path
        default:
            return state;
    }
}