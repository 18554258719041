/**
 *      File:       ./src/context/administration/products/productGroupsReducer.js
 * 
 *      Subject:    Product Groups' State Reducer
 */
import {
	GET_ENTITIES,
	NO_CHANGE_STATE,
    SET_ACTIVE_ENTITY,
    SET_DELETE_ENTITY,
    SET_DELETE_MODAL_ENTITY_NAME,
    SET_EDIT_DIALOG_TITLE,
    SET_EDIT_ENTITY,
    SET_ENTITY_DIALOG_FIELDS,
    SET_ERROR,

    SET_USER_FOREIGNS,
    PRODUCT_GROUP_NAME_CHANGE,
	DESCRIPTION_CHANGE,
	TARIF_TYPES_ID_CHANGE,
    ENERGY_SUPPLIER_ID_CHANGE

} from '../../types';

export default(state, action) => {

    switch(action.type)
    {
        //  General section

        case GET_ENTITIES:
            return {
                ...state,
                entities: action.payload
			}
			
		case NO_CHANGE_STATE:
			return {
				...state,
				noChangeState: action.payload,
			}

        case SET_ACTIVE_ENTITY:
            return {
                ...state,
                editDialogProductGroupName: action.payload.product_group_name,
				editDialogDescription: action.payload.description,
				editDialogTarifTypesId: action.payload.tarif_types_id,
                editDialogEnergySupplierId: action.payload.energy_supplier_id
            }

        case SET_DELETE_ENTITY:
            return {
                ...state,
                activeEntity: action.payload.id,
                deleteModalState: action.payload.deleteModalState
            }

        case SET_DELETE_MODAL_ENTITY_NAME:
            return {
                ...state,
                deleteModalEntityName: action.payload
            }

        case SET_EDIT_DIALOG_TITLE:
            return {
                ...state,
                editDialogTitle: action.payload
            }

        case SET_EDIT_ENTITY:
            return {
                ...state,
                activeEntity: action.payload.id,
                editState: action.payload.editState
            }

		case SET_ENTITY_DIALOG_FIELDS:
			return {
				...state,
				editDialogFields: action.payload,
		};

        case SET_ERROR:
            return {
                ...state,
                editDialogError: action.payload
            }


        //  Individual Section

        case SET_USER_FOREIGNS:
            return {
                ...state,
				energySuppliers: action.payload.energySuppliers,
				tarifTypes: action.payload.tarifTypes,
            }
            
        case PRODUCT_GROUP_NAME_CHANGE:
            return {
                ...state,
                editDialogProductGroupName: action.payload
            }

        case DESCRIPTION_CHANGE:
            return {
                ...state,
                editDialogDescription: action.payload
			}
			
		case TARIF_TYPES_ID_CHANGE:
			return {
				...state,
				editDialogTarifTypesId: action.payload,
			}

        case ENERGY_SUPPLIER_ID_CHANGE:
            return {
                ...state,
                editDialogEnergySupplierId: action.payload
            }

            
        //  Default Path
        default:
            return state;
    }
}