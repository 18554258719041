/**
 *      File:       ./src/context/administration/energy/EnergySupplierState.js
 * 
 *      Subject:    Energy Suppliers' State
 */

import React, { useReducer, useEffect } from 'react';
import axios from 'axios';
import EnergySupplierContext from './energySupplierContext';
import EnergySupplierReducer from './energySupplierReducer';
import FormInput from '../../../Components/FormInput';
import FormSelect from '../../../Components/FormSelect';

import {

    GET_ENTITIES,
    SET_ACTIVE_ENTITY,
    SET_DELETE_ENTITY,
    SET_DELETE_MODAL_ENTITY_NAME,
    SET_EDIT_DIALOG_TITLE,
    SET_EDIT_ENTITY,
    SET_ENTITY_DIALOG_FIELDS,
    SET_ERROR,

    SET_ENERGY_SUPPLIER_FOREIGNS,
    SUPPLIER_NAME_CHANGE,
    BALANCING_GROUPS_ID_GAS_CHANGE,
    BALANCING_GROUPS_ID_POWER_CHANGE

} from '../../types';

const EnergySupplierState = props => {

    const initialState = {

        entities:               [],
        activeEntity:           0,
        deleteModalEntityName:  '',
        deleteModalState:       false,
        deleteModalTitle:       'Versorger löschen',
        entityDeleteDialog:     false,
        entityDeleteEntityName: '',
        editDialogTitle:        'Neuen Versorger anlegen',
        editDialogError:        '',
        editDialogFields:       [],
        editState:              false,
        

        tableCols: [
            {
                Header: "Versorger",
                accessor: "supplier_name"
            },
            {
                Header: "Bilanzkreis Erdgas",
                accessor: "balancing_group_gas"
            },
            {
                Header: "Bilanzkreis Strom",
                accessor: "balancing_group_power"
            }
        ],

        editDialogSupplierName: '',
        editDialogBalancingGroupsIdGas : '',
        editDialogBalancingGroupsIdPower: '',
        balancingGroups: []

    }
    const [state,dispatch] = useReducer(EnergySupplierReducer, initialState);

    useEffect(() => {

        async function fetchData() {
            
            const params = new URLSearchParams();
            params.append('jwt', sessionStorage.jwt);

            let axiosConfig = {
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded"
                }
            };

            let url = process.env.REACT_APP_API_URI + '/administration/energy/get_energy_supplier_foreigns.php';

            axios.post(url,params,axiosConfig)
            .then(response => {
                if(response.status === 200) {
                    // console.log(response.data);
                    
                    dispatch({
                        type: SET_ENERGY_SUPPLIER_FOREIGNS,
                        payload: {
                            balancingGroups: response.data.balancingGroups
                        }
                    });

                } else if(response.status === 401) {
                    window.location.href ='#/logout';
                } else {
                    console.log('Anderer Fehler');
                }
            })
            .catch(function(error) {
                if(error.response){
                    // The server answered with an error
                    if(error.response.status === 401) {
                        window.location.href = '#/logout';
                    } else {
                        console.log(error.response.data);
                        console.log(error.response.status);
                        console.log(error.response.headers);
                    }
                } else if(error.request) {
                    //  The request was made but the server didn't respond
                    console.log(error.request);
                } else {
                    //  Something else went wrong
                    console.log('Error', error.message);
                }
            })
        }
        fetchData();

        const edf = [
            {
                id: 1,
                fieldType: <FormInput
                    context             = {EnergySupplierContext}
                    id                  = "supplier_name"
                    label               = "Versorger"
                    onChangeFunction    = {supplierNameChange}
                    placeholder         = "Versorger"
                    required
                    type                = "text"
                    value               = "editDialogSupplierName"
                />
            },
            {
                id: 2,
                fieldType: <FormSelect
                    context             = {EnergySupplierContext}
                    id                  = "balancing_groups_id_gas"
                    label               = "Bilanzkreis Erdgas"
                    onChangeFunction    = {balancingGroupsIdGasChange}
                    placeholder         = "Bilanzkreis Erdgas"
                    selectOptions       = "balancingGroups"
                    value               = "editDialogBalancingGroupsIdGas"
                />
            },
            {
                id: 3,
                fieldType: <FormSelect
                    context             = {EnergySupplierContext}
                    id                  = "balancing_groups_id_power"
                    label               = "Bilanzkreis Strom"
                    onChangeFunction    = {balancingGroupsIdPowerChange}
                    placeholder         = "Bilanzkreis Strom"
                    selectOptions       = "balancingGroups"
                    value               = "editDialogBalancingGroupsIdPower"
                />
            }
        ];

        dispatch({
            type: SET_ENTITY_DIALOG_FIELDS,
            payload: edf
        });

        getEntities();

        // eslint-disable-next-line
    }, [state.editDialogError], [state.userRoleLabel]);

    const getEntities = () => {

        const params = new URLSearchParams();
        params.append('jwt', sessionStorage.jwt);

        let axiosConfig = {
            headers: {
                "Content-Type": "application/x-www-form-urlencoded"
            }
        };

        let url = process.env.REACT_APP_API_URI + '/administration/energy/get_energy_suppliers.php';

        axios.post(url,params,axiosConfig)
        .then(response => {
            if(response.status === 200) {

                // console.log(response.data.entities);
                
                dispatch({
                    type: GET_ENTITIES,
                    payload: response.data.entities
                });

            } else if(response.status === 401) {
                window.location.href ='#/logout';
            } else {
                console.log('Anderer Fehler');
            }
        })
        .catch(function(error) {
            if(error.response){
                // The server answered with an error
                if(error.response.status === 401) {
                    window.location.href = '#/logout';
                } else {
                    console.log(error.response.data);
                    console.log(error.response.status);
                    console.log(error.response.headers);
                }
            } else if(error.request) {
                //  The request was made but the server didn't respond
                console.log(error.request);
            } else {
                //  Something else went wrong
                console.log('Error', error.message);
            }
        })
    }

    const editEntity = (id) => {

        if(id > 0)
        {
            const params = new URLSearchParams();
            params.append('jwt', sessionStorage.jwt);
            params.append('id', id);

            let axiosConfig = {
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded"
                }
            };

            let url = process.env.REACT_APP_API_URI + '/administration/energy/get_energy_supplier.php';

            axios.post(url,params,axiosConfig)
            .then(response => {
                if(response.status === 200) {
                    // console.log(response.data);
                    
                    dispatch({
                        type: SET_ACTIVE_ENTITY,
                        payload: response.data.energySupplier
                    });

                    dispatch({
                        type: SET_EDIT_ENTITY,
                        payload: {
                            id: id,
                            editState: true
                        }
                    });

                    dispatch({
                        type: SET_EDIT_DIALOG_TITLE,
                        payload: 'Versorger bearbeiten'
                    });

                } else if(response.status === 401) {
                    window.location.href ='#/logout';
                } else {
                    console.log('Anderer Fehler');
                }
            })
            .catch(function(error) {
                if(error.response){
                    // The server answered with an error
                    if(error.response.status === 401) {
                        window.location.href = '#/logout';
                    } else {
                        console.log(error.response.data);
                        console.log(error.response.status);
                        console.log(error.response.headers);
                    }
                } else if(error.request) {
                    //  The request was made but the server didn't respond
                    console.log(error.request);
                } else {
                    //  Something else went wrong
                    console.log('Error', error.message);
                }
            })
        } else {
            dispatch({
                type: SET_EDIT_DIALOG_TITLE,
                payload: 'Neuen Versorger anlegen'
            });
        }

        dispatch({
            type: SET_EDIT_ENTITY,
            payload: {
                id: id,
                editState: true
            }
        });        
    }

    const setError = (errMsg) => {
        dispatch({
            type: SET_ERROR,
            payload: errMsg
        });
    }

    const saveEntity = async () => {

        if(state.editDialogSupplierName === '') {
            setError('Bitte geben Sie eine Bezeichnung für den Versorger ein.');
            return false;
        }

        const params = new URLSearchParams();
        params.append('jwt', sessionStorage.jwt);
        params.append('id', state.activeEntity);
        params.append('supplierName', state.editDialogSupplierName);
        params.append('balancingGroupsIdGas', state.editDialogBalancingGroupsIdGas);
        params.append('balancingGroupsIdPower', state.editDialogBalancingGroupsIdPower);
        params.append('url', state.editDialogUrl);

        let axiosConfig = {
            headers: {
                "Content-Type": "application/x-www-form-urlencoded"
            }
        };

        let url = process.env.REACT_APP_API_URI + '/administration/energy/set_energy_supplier.php';

        axios.post(url,params,axiosConfig)
        .then(response => {
            if(response.status === 200) {

                // console.log(response.data);

                const energySupplier = {
                    energy_supplier_name: '',
                    balancing_groups_id_gas: '',
                    balancing_groups_id_power: ''
                };
                getEntities();

                dispatch({
                    type: SET_ACTIVE_ENTITY,
                    payload: energySupplier
                });
                dispatch({
                    type: SET_EDIT_ENTITY,
                    payload: {
                        id: 0,
                        editState: false
                    }
                });
                return true;

            } else if(response.status === 401) {
                window.location.href ='#/logout';
            } else {
                console.log('Anderer Fehler');
                return false;
            }
        })
        .catch(function(error) {
            if(error.response){
                // The server answered with an error
                if(error.response.status === 401) {
                    window.location.href = '#/logout';
                } else {
                    console.log(error.response.data);
                    console.log(error.response.status);
                    console.log(error.response.headers);
                    return false;
                }
            } else if(error.request) {
                //  The request was made but the server didn't respond
                console.log(error.request);
                return false;
            } else {
                //  Something else went wrong
                console.log('Error', error.message);
                return false;
            }
        })
        return true;
    }

    const closeEdit = () => {
        dispatch({
            type: SET_ACTIVE_ENTITY,
            payload: {
                energy_supplier_name: '',
                balancing_groups_id_gas: '',
                balancing_groups_id_power: ''
            }
        });
        
        dispatch({
            type: SET_EDIT_ENTITY,
            payload: {
                id: 0,
                editState: false
            }
        });
    }

    const deleteEntity = (id) => {
        
        const params = new URLSearchParams();
        params.append('jwt', sessionStorage.jwt);
        params.append('id', id);

        let axiosConfig = {
            headers: {
                "Content-Type": "application/x-www-form-urlencoded"
            }
        };

        let url = process.env.REACT_APP_API_URI + '/administration/energy/get_energy_supplier.php';

        axios.post(url,params,axiosConfig)
        .then(response => {
            if(response.status === 200) {
                // console.log(response.data);
                
                dispatch({
                    type: SET_ACTIVE_ENTITY,
                    payload: response.data.energySupplier
                });

                dispatch({
                    type: SET_DELETE_ENTITY,
                    payload: {
                        id: id,
                        deleteModalState: true
                    }
                });

                dispatch({
                    type: SET_DELETE_MODAL_ENTITY_NAME,
                    payload: 'Möchten Sie den Versorger "' + response.data.energySupplier.energy_supplier_name + '" wirklich löschen?'
                });

            } else if(response.status === 401) {
                window.location.href ='#/logout';
            } else {
                console.log('Anderer Fehler');
            }
        })
        .catch(function(error) {
            if(error.response){
                // The server answered with an error
                if(error.response.status === 401) {
                    window.location.href = '#/logout';
                } else {
                    console.log(error.response.data);
                    console.log(error.response.status);
                    console.log(error.response.headers);
                }
            } else if(error.request) {
                //  The request was made but the server didn't respond
                console.log(error.request);
            } else {
                //  Something else went wrong
                console.log('Error', error.message);
            }
        })
    }

    const closeDelete = () => {

        dispatch({
            type: SET_ACTIVE_ENTITY,
            payload: {
                energy_supplier_name: '',
                balancing_groups_id_gas: '',
                balancing_groups_id_power: ''
            }
        });
        
        dispatch({
            type: SET_EDIT_ENTITY,
            payload: {
                id: 0,
                editState: false
            }
        });

        dispatch({
            type: SET_DELETE_ENTITY,
            payload: {
                id: 0,
                deleteModalState: false
            }
        });

        dispatch({
            type: SET_DELETE_MODAL_ENTITY_NAME,
            payload: ''
        });
    }

    const deleteEntityConfirmed = () => {

        const params = new URLSearchParams();
        params.append('jwt', sessionStorage.jwt);
        params.append('id', state.activeEntity);

        let axiosConfig = {
            headers: {
                "Content-Type": "application/x-www-form-urlencoded"
            }
        };

        let url = process.env.REACT_APP_API_URI + '/administration/energy/delete_energy_supplier.php';

        axios.post(url,params,axiosConfig)
        .then(response => {
            if(response.status === 200) {
                // console.log(response.data);
                
                getEntities();
                closeDelete();

            } else if(response.status === 401) {
                window.location.href ='#/logout';
            } else {
                console.log('Anderer Fehler');
            }
        })
        .catch(function(error) {
            if(error.response){
                // The server answered with an error
                if(error.response.status === 401) {
                    window.location.href = '#/logout';
                } else {
                    console.log(error.response.data);
                    console.log(error.response.status);
                    console.log(error.response.headers);
                }
            } else if(error.request) {
                //  The request was made but the server didn't respond
                console.log(error.request);
            } else {
                //  Something else went wrong
                console.log('Error', error.message);
            }
        })

        getEntities();
    }

    /*-------------------------------------
    //  PAGE SPECIFIC
    -------------------------------------*/

    const supplierNameChange = (e) => {

        if(state.editDialogError !== '' && state.editDialogSupplierName === '') {
            setError('');
        }

        dispatch({
            type: SUPPLIER_NAME_CHANGE,
            payload: e.target.value
        });
    }

    const balancingGroupsIdGasChange = (e) => {

        dispatch({
            type: BALANCING_GROUPS_ID_GAS_CHANGE,
            payload: e.target.value
        });
    }

    const balancingGroupsIdPowerChange = (e) => {

        dispatch({
            type: BALANCING_GROUPS_ID_POWER_CHANGE,
            payload: e.target.value
        });
    }

    return <EnergySupplierContext.Provider value={{

        tableCols: state.tableCols,
        entities: state.entities,
        editDialogFields: state.editDialogFields,
        editDialogError: state.editDialogError,
        activeEntity: state.activeEntity,
        editState: state.editState,
        userRoleLabel: state.userRoleLabel,
        editDialogTitle: state.editDialogTitle,
        deleteModalTitle: state.deleteModalTitle,
        deleteModalEntityName: state.deleteModalEntityName,
        deleteModalState: state.deleteModalState,

        editDialogSupplierName: state.editDialogSupplierName,
        editDialogBalancingGroupsIdGas: state.editDialogBalancingGroupsIdGas,
        editDialogBalancingGroupsIdPower: state.editDialogBalancingGroupsIdPower,
        balancingGroups: state.balancingGroups,

        editEntity,
        setError,
        saveEntity,
        getEntities,
        closeEdit,
        deleteEntity,
        closeDelete,
        deleteEntityConfirmed,

        supplierNameChange,
        balancingGroupsIdGasChange,
        balancingGroupsIdPowerChange

}}>
        {props.children}
    </EnergySupplierContext.Provider>
}

export default EnergySupplierState;