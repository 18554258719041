/**
 *      File:       ./src/context/administration/order-criterias/BusinessPartnerTypesState.js
 * 
 *      Subject:    Business Partner Types' State
 */
import React, { useReducer, useEffect } from 'react';
import axios from 'axios';
import BusinessPartnerTypesContext from './businessPartnerTypesContext';
import BusinessPartnerTypesReducer from './businessPartnerTypesReducer';
import FormInput from '../../../Components/FormInput';

import {

    GET_ENTITIES,
    SET_ACTIVE_ENTITY,
    SET_DELETE_ENTITY,
    SET_DELETE_MODAL_ENTITY_NAME,
    SET_EDIT_DIALOG_TITLE,
    SET_EDIT_ENTITY,
    SET_ENTITY_DIALOG_FIELDS,
    SET_ERROR,

    TYPE_INDEX_SAP_CHANGE,
    TYPE_NAME_CHANGE,
    TYPE_ABBREVIATION_CHANGE

} from '../../types';

const BusinessPartnerTypesState = props => {

    const initialState = {

        entities:               [],
        activeEntity:           0,
        deleteModalEntityName:  '',
        deleteModalState:       false,
        deleteModalTitle:       'Anredeschlüssel löschen',
        entityDeleteDialog:     false,
        entityDeleteEntityName: '',
        editDialogTitle:        'Neuen Anredeschlüssel anlegen',
        editDialogError:        '',
        editDialogFields:       [],
        editState:              false,
        editDialogTypeIndexSap: '',
        editDialogTypeName: '',
        editDialogTypeAbbreviation: ''

    }
    const [state,dispatch] = useReducer(BusinessPartnerTypesReducer, initialState);

    useEffect(() => {

        const edf = [
            {
                id: 1,
                fieldType: <FormInput
                    context             = {BusinessPartnerTypesContext}
                    id                  = "type_index_sap"
                    label               = "Typ-Schlüssel"
                    onChangeFunction    = {typeIndexSapChange}
                    placeholder         = "Typ-Schlüssel"
                    type                = "text"
                    value               = "editDialogTypeIndexSap"
                />
            },
            {
                id: 2,
                fieldType: <FormInput
                    context             = {BusinessPartnerTypesContext}
                    id                  = "type_name"
                    label               = "Geschäftspartner-Typ"
                    onChangeFunction    = {typeNameChange}
                    placeholder         = "Typ-Bezeichnung"
                    required
                    type                = "text"
                    value               = "editDialogTypeName"
                />
            },
            {
                id: 3,
                fieldType: <FormInput
                    context             = {BusinessPartnerTypesContext}
                    id                  = "type_abbreviation"
                    label               = "Typ-Kürzel"
                    onChangeFunction    = {typeAbbreviationChange}
                    placeholder         = "Typ-Kürzel"
                    type                = "text"
                    value               = "editDialogTypeAbbreviation"
                />
            }
        ];

        dispatch({
            type: SET_ENTITY_DIALOG_FIELDS,
            payload: edf
        });

        getEntities();

        // eslint-disable-next-line
    }, [state.editDialogError]);


    const getEntities = () => {

        const params = new URLSearchParams();
        params.append('jwt', sessionStorage.jwt);

        let axiosConfig = {
            headers: {
                "Content-Type": "application/x-www-form-urlencoded"
            }
        };

        let url = process.env.REACT_APP_API_URI + '/administration/order-criterias/get_business_partner_types.php';

        axios.post(url,params,axiosConfig)
        .then(response => {
            if(response.status === 200) {

				// console.log(response.data);
				const entities = response.data.entities;
				for(var i in entities){
					entities[i].type_index_sap = (isNaN(entities[i].type_index_sap) || entities[i].type_index_sap === "") ? 0 : Number.parseInt(entities[i].type_index_sap);
				}
                
                dispatch({
                    type: GET_ENTITIES,
                    payload: entities
                });

            } else if(response.status === 401) {
                window.location.href ='#/logout';
            } else {
                console.log('Anderer Fehler');
            }
        })
        .catch(function(error) {
            if(error.response){
                // The server answered with an error
                if(error.response.status === 401) {
                    window.location.href = '#/logout';
                } else {
                    console.log(error.response.data);
                    console.log(error.response.status);
                    console.log(error.response.headers);
                }
            } else if(error.request) {
                //  The request was made but the server didn't respond
                console.log(error.request);
            } else {
                //  Something else went wrong
                console.log('Error', error.message);
            }
        })
    }

    const editEntity = (id) => {

        if(id > 0)
        {
            const params = new URLSearchParams();
            params.append('jwt', sessionStorage.jwt);
            params.append('id', id);

            let axiosConfig = {
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded"
                }
            };

            let url = process.env.REACT_APP_API_URI + '/administration/order-criterias/get_business_partner_type.php';

            axios.post(url,params,axiosConfig)
            .then(response => {
                if(response.status === 200) {
                    
                    // console.log(response.data);
                    
                    dispatch({
                        type: SET_ACTIVE_ENTITY,
                        payload: response.data.type
                    });

                    dispatch({
                        type: SET_EDIT_ENTITY,
                        payload: {
                            id: id,
                            editState: true
                        }
                    });

                    dispatch({
                        type: SET_EDIT_DIALOG_TITLE,
                        payload: 'Geschäftspartner-Typ bearbeiten'
                    });

                } else if(response.status === 401) {
                    window.location.href ='#/logout';
                } else {
                    console.log('Anderer Fehler');
                }
            })
            .catch(function(error) {
                if(error.response){
                    // The server answered with an error
                    if(error.response.status === 401) {
                        window.location.href = '#/logout';
                    } else {
                        console.log(error.response.data);
                        console.log(error.response.status);
                        console.log(error.response.headers);
                    }
                } else if(error.request) {
                    //  The request was made but the server didn't respond
                    console.log(error.request);
                } else {
                    //  Something else went wrong
                    console.log('Error', error.message);
                }
            })
        } else {
            dispatch({
                type: SET_EDIT_DIALOG_TITLE,
                payload: 'Neuen Geschäftspartner-Typ anlegen'
            });
        }

        dispatch({
            type: SET_EDIT_ENTITY,
            payload: {
                id: id,
                editState: true
            }
        });        
    }

    const setError = (errMsg) => {
        dispatch({
            type: SET_ERROR,
            payload: errMsg
        });
    }

    const saveEntity = async () => {

        if(state.editDialogTypeName === '') {
            setError('Bitte geben Sie eine Bezeichnung für den Geschäftspartner-Typ ein.');
            return false;
        }

        const params = new URLSearchParams();
        params.append('jwt', sessionStorage.jwt);
        params.append('id', state.activeEntity);
        params.append('typeIndexSap', state.editDialogTypeIndexSap);
        params.append('typeName', state.editDialogTypeName);
        params.append('typeAbbreviation', state.editDialogTypeAbbreviation);

        let axiosConfig = {
            headers: {
                "Content-Type": "application/x-www-form-urlencoded"
            }
        };

        let url = process.env.REACT_APP_API_URI + '/administration/order-criterias/set_business_partner_type.php';

        axios.post(url,params,axiosConfig)
        .then(response => {
            if(response.status === 200) {

                // console.log(response.data);

                const type = {
                    type_index_sap: '',
                    type_name: '',
                    type_abbreviation: ''
                };
                getEntities();

                dispatch({
                    type: SET_ACTIVE_ENTITY,
                    payload: type
                });
                dispatch({
                    type: SET_EDIT_ENTITY,
                    payload: {
                        id: 0,
                        editState: false
                    }
                });
                return true;

            } else if(response.status === 401) {
                window.location.href ='#/logout';
            } else {
                console.log('Anderer Fehler');
                return false;
            }
        })
        .catch(function(error) {
            if(error.response){
                // The server answered with an error
                if(error.response.status === 401) {
                    window.location.href = '#/logout';
                } else {
                    console.log(error.response.data);
                    console.log(error.response.status);
                    console.log(error.response.headers);
                    return false;
                }
            } else if(error.request) {
                //  The request was made but the server didn't respond
                console.log(error.request);
                return false;
            } else {
                //  Something else went wrong
                console.log('Error', error.message);
                return false;
            }
        })
        return true;
    }

    const closeEdit = () => {
        dispatch({
            type: SET_ACTIVE_ENTITY,
            payload: {
                type_index_sap: '',
                type_name: '',
                type_abbreviation: ''
            }
        });
        
        dispatch({
            type: SET_EDIT_ENTITY,
            payload: {
                id: 0,
                editState: false
            }
        });
    }

    const deleteEntity = (id) => {
        
        const params = new URLSearchParams();
        params.append('jwt', sessionStorage.jwt);
        params.append('id', id);

        let axiosConfig = {
            headers: {
                "Content-Type": "application/x-www-form-urlencoded"
            }
        };

        let url = process.env.REACT_APP_API_URI + '/administration/order-criterias/get_business_partner_type.php';

        axios.post(url,params,axiosConfig)
        .then(response => {
            if(response.status === 200) {

                // console.log(response.data);
                
                dispatch({
                    type: SET_ACTIVE_ENTITY,
                    payload: response.data.type
                });

                dispatch({
                    type: SET_DELETE_ENTITY,
                    payload: {
                        id: id,
                        deleteModalState: true
                    }
                });

                dispatch({
                    type: SET_DELETE_MODAL_ENTITY_NAME,
                    payload: 'Möchten Sie den Geschäftspartner-Typ "' + response.data.type.type_name + '" wirklich löschen?'
                });

            } else if(response.status === 401) {
                window.location.href ='#/logout';
            } else {
                console.log('Anderer Fehler');
            }
        })
        .catch(function(error) {
            if(error.response){
                // The server answered with an error
                if(error.response.status === 401) {
                    window.location.href = '#/logout';
                } else {
                    console.log(error.response.data);
                    console.log(error.response.status);
                    console.log(error.response.headers);
                }
            } else if(error.request) {
                //  The request was made but the server didn't respond
                console.log(error.request);
            } else {
                //  Something else went wrong
                console.log('Error', error.message);
            }
        })
    }

    const closeDelete = () => {

        dispatch({
            type: SET_ACTIVE_ENTITY,
            payload: {
                type_index_sap: '',
                type_name: '',
                type_abbreviation: ''
            }
        });
        
        dispatch({
            type: SET_EDIT_ENTITY,
            payload: {
                id: 0,
                editState: false
            }
        });

        dispatch({
            type: SET_DELETE_ENTITY,
            payload: {
                id: 0,
                deleteModalState: false
            }
        });

        dispatch({
            type: SET_DELETE_MODAL_ENTITY_NAME,
            payload: ''
        });
    }

    const deleteEntityConfirmed = () => {

        const params = new URLSearchParams();
        params.append('jwt', sessionStorage.jwt);
        params.append('id', state.activeEntity);

        let axiosConfig = {
            headers: {
                "Content-Type": "application/x-www-form-urlencoded"
            }
        };

        let url = process.env.REACT_APP_API_URI + '/administration/order-criterias/delete_business_partner_type.php';

        axios.post(url,params,axiosConfig)
        .then(response => {
            if(response.status === 200) {
                // console.log(response.data);
                
                getEntities();
                closeDelete();

            } else if(response.status === 401) {
                window.location.href ='#/logout';
            } else {
                console.log('Anderer Fehler');
            }
        })
        .catch(function(error) {
            if(error.response){
                // The server answered with an error
                if(error.response.status === 401) {
                    window.location.href = '#/logout';
                } else {
                    console.log(error.response.data);
                    console.log(error.response.status);
                    console.log(error.response.headers);
                }
            } else if(error.request) {
                //  The request was made but the server didn't respond
                console.log(error.request);
            } else {
                //  Something else went wrong
                console.log('Error', error.message);
            }
        })
    }

    /*-------------------------------------
    //  PAGE SPECIFIC
    -------------------------------------*/

    const typeIndexSapChange = (e) => {
        dispatch({
            type: TYPE_INDEX_SAP_CHANGE,
            payload: e.target.value,
        });
    }

    const typeNameChange = (e) => {
        if(state.editDialogTypeName === '' && state.editDialogError !== '') {
            setError('');
        }

        dispatch({
            type: TYPE_NAME_CHANGE,
            payload: e.target.value
        });
    }
    
    const typeAbbreviationChange = (e) => {
        dispatch({
            type: TYPE_ABBREVIATION_CHANGE,
            payload: e.target.value
        });
    }

    return <BusinessPartnerTypesContext.Provider value={{

        entities: state.entities,
        editDialogFields: state.editDialogFields,
        editDialogError: state.editDialogError,
        activeEntity: state.activeEntity,
        editState: state.editState,
        userRoleLabel: state.userRoleLabel,
        editDialogTitle: state.editDialogTitle,
        deleteModalTitle: state.deleteModalTitle,
        deleteModalEntityName: state.deleteModalEntityName,
        deleteModalState: state.deleteModalState,

        editDialogTypeIndexSap: state.editDialogTypeIndexSap,
        editDialogTypeName: state.editDialogTypeName,
        editDialogTypeAbbreviation: state.editDialogTypeAbbreviation,

        editEntity,
        setError,
        saveEntity,
        getEntities,
        closeEdit,
        deleteEntity,
        closeDelete,
        deleteEntityConfirmed,

        typeIndexSapChange,
        typeNameChange,
        typeAbbreviationChange

}}>
        {props.children}
    </BusinessPartnerTypesContext.Provider>
}

export default BusinessPartnerTypesState;