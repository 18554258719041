/**
 * 		File:		./src/context/leads/leadsReducer.js
 *
 * 		Subject:	Lead's state reducer
 */
import {
  //  Leads general
  CLOSE_LEAD_DETAIL_DIALOG,
  EDIT_LEAD,
  SET_FOREIGNS,
  SET_LEAD_DETAIL_DIALOG_ACTIVE_TAB,
  SET_ERR_MSG,

  //  Leads address
  ABC_ID_CHANGE,
  BUSINESS_PARTNER_TYPES_ID_CHANGE,
  FOLLOW_UP_DATE_CHANGE,
  FOLLOW_UP_DONE_CHANGE,
  IS_PORTAL_ACCESS_ADMINISTRATION_CHANGE,
  SET_ACTIVE_ADDRESS_TAB,
  SUPERVISORS_ID_CHANGE,
  SUPERVISORS_PV_ID_CHANGE,

  //	LEAD OBJECT LIST DIALOG
  LEAD_OBJECT_LIST_DIALOG,
  LEAD_OBJECT_LIST_IS_FINISHED,

  //  Leads address address
  ADDRESS_ADDITION_CHANGE,
  CITY_CHANGE,
  COUNTRY_CHANGE,
  DISTRICT_CHANGE,
  HOUSE_NUMBER_CHANGE,
  NAME_1_CHANGE,
  NAME_2_CHANGE,
  NAME_3_CHANGE,
  LEGAL_FORMS_ID_CHANGE,
  POSTAL_CODE_CHANGE,
  SALUTATIONS_ID_CHANGE,
  STREET_CHANGE,

  //  Lead address contact
  EMAIL_CHANGE,
  FAX_CHANGE,
  PHONE_CHANGE,
  URL_CHANGE,

  //  Lead address postbox
  POSTBOX_CHANGE,
  POSTBOX_CITY_CHANGE,
  POSTBOX_POSTAL_CODE_CHANGE,

  //  Lead address standard data
  ADMINISTRATIONS_1_ID_CHANGE,
  ADMINISTRATIONS_2_ID_CHANGE,
  ADMINISTRATIONS_3_ID_CHANGE,
  OWNER_ID_CHANGE,
  COMMUNITY_CODE_CHANGE,
  GET_ADMINISTRATIONS_1,
  GET_ADMINISTRATIONS_2,
  GET_ADMINISTRATIONS_3,

  //  Lead Contact Persons
  CLOSE_CONTACT_PERSON_DIALOG,
  CLOSE_DELETE,
  CONTACT_PERSON_DIVISION_CHANGE,
  CONTACT_PERSON_EMAIL_CHANGE,
  CONTACT_PERSON_FAX_CHANGE,
  CONTACT_PERSON_FIRSTNAME_CHANGE,
  CONTACT_PERSON_FUNCTION_CHANGE,
  CONTACT_PERSON_IS_PORTAL_ACCESS_CHANGE,
  CONTACT_PERSON_LASTNAME_CHANGE,
  CONTACT_PERSON_MOBILE_CHANGE,
  CONTACT_PERSON_PHONE_CHANGE,
  CONTACT_PERSON_PORTAL_ACCESS_VALID_TO_CHANGE,
  CONTACT_PERSON_SALUTATIONS_ID_CHANGE,
  CONTACT_PERSON_TITLE_CHANGE,
  DELETE_CONTACT_PERSON,
  DELETE_CONTACT_PERSON_CONFIRM,
  EDIT_CONTACT_PERSON,
  GET_CONTACT_PERSONS,
  SET_ACTIVE_CONTACT_PERSON,
  SET_CONTACT_PERSON_ERROR,

  //  Bank Account
  ACCOUNT_NO_CHANGE,
  ACCOUNT_OWNER_CHANGE,
  BANK_CODE_CHANGE,
  IBAN_CHANGE,

  //  Products
  ENERGY_CAMPAIGNS_ID_CHANGE,
  IS_LEAD_ENERGY_TRANSITION_CHANGE, 
  LEAD_ENERGY_TRANSITION_DATE_CHANGE,
  ENERGY_POTS_ID_CHANGE,
  GAS_CAMPAIGNS_ID_CHANGE,
  IS_LEAD_GAS_TRANSITION_CHANGE,
  LEAD_GAS_TRANSITION_DATE_CHANGE,
  GAS_POTS_ID_CHANGE,
  SET_ACTIVE_PRODUCT_TAB,

  //  Contracts
  EDIT_LEAD_CONTRACT,
  LEAD_CONTRACT_BILLING_NAME1_CHANGE,
  LEAD_CONTRACT_BILLING_NAME2_CHANGE,
  LEAD_CONTRACT_BILLING_NAME3_CHANGE,
  LEAD_CONTRACT_CUSTOMER_TYPES_ID_CHANGE,
  LEAD_CONTRACT_DIVISIONS_ID_CHANGE,
  LEAD_CONTRACT_IS_BIO5_CHANGE,
  LEAD_CONTRACT_BIO5_START_DATE_CHANGE,
  LEAD_CONTRACT_IS_BIO10_CHANGE,
  LEAD_CONTRACT_BIO10_START_DATE_CHANGE,
  LEAD_CONTRACT_IS_CLIMATE_PLUS_CHANGE,
  LEAD_CONTRACT_IS_CO2_CHANGE,
  LEAD_CONTRACT_PERIOD_CONSUMPTION_CHANGE,
  LEAD_CONTRACT_POTS_ID_CHANGE,
  LEAD_CONTRACT_PRODUCTS_ID_CHANGE,
  LEAD_CONTRACT_SALUTATIONS_ID_CHANGE,
  LEAD_CONTRACT_VALID_FROM_CHANGE,
  LEAD_CONTRACT_VALID_TO_CHANGE,
  IS_LEAD_CONTRACT_TRANSITION_CHANGE,
  LEAD_CONTRACT_TRANSITION_DATE_CHANGE,
  IS_LEAD_CONTRACT_TRANSITION_TEN_PERCENT_CHANGE,
  LEAD_CONTRACT_TRANSITION_TEN_PERCENT_DATE_CHANGE,
  LEAD_COUNTER_ACCOUNT_NO_CHANGE,
  LEAD_COUNTER_ACCOUNT_OWNER_CHANGE,
  LEAD_COUNTER_BANK_CODE_CHANGE,
  LEAD_COUNTER_BHKW,
  LEAD_COUNTER_CHANGE_REASON_ID_CHANGE,
  LEAD_COUNTER_CHANGE_SUPPLY_START_CHANGE,
  LEAD_COUNTER_CONTRACT_ACCOUNT_CHANGE,
  LEAD_COUNTER_FORMER_SUPPLIER_ID_CHANGE,
  LEAD_COUNTER_IBAN_CHANGE,
  LEAD_COUNTER_ID_CHANGE,
  LEAD_COUNTER_INSTALLMENT_CYCLE_CHANGE,
  LEAD_COUNTER_INTENDED_USE_CHANGE,
  LEAD_COUNTRY_LOCATION_ADDITIONAL_CHANGE,
  LEAD_COUNTER_LOCATION_CITY_CHANGE,
  LEAD_COUNTER_LOCATION_COUNTRY_CHANGE,
  LEAD_COUNTER_LOCATION_DISTRICT_CHANGE,
  LEAD_COUNTER_LOCATION_HOUSE_NUMBER_CHANGE,
  LEAD_COUNTER_LOCATION_MARKET_LOCATION_CHANGE,
  LEAD_COUNTER_LOCATION_MEASURE_LOCATION_CHANGE,
  LEAD_COUNTER_LOCATION_POSTAL_CODE_CHANGE,
  LEAD_COUNTER_LOCATION_STREET_CHANGE,
  LEAD_COUNTER_MEDIUM_VOLTAGE_CHANGE,
  LEAD_COUNTER_NETWORKS_ID_CHANGE,
  LEAD_COUNTER_NUMBER_CURRENT_CHANGE,
  LEAD_COUNTER_LOAD_PROFILES_ID_CHANGE,
  LEAD_COUNTER_VALUE_CHANGE,
  SET_ACTIVE_CONTRACT_COUNTER_TAB,
  SET_ACTIVE_LEAD_CONTRACT_TAB,
  SET_CONTRACT_ERR_MSG,
  UPDATE_CONTRACT_EXTERNALS,
  LEAD_CONTRACT_BILLING_STREET_CHANGE,
  LEAD_CONTRACT_BILLING_HOUSE_NUMBER_CHANGE,
  LEAD_CONTRACT_BILLING_POSTAL_CODE_CHANGE,
  LEAD_CONTRACT_BILLING_CITY_CHANGE,
  LEAD_CONTRACT_BILLING_DISTRICT_CHANGE,
  LEAD_CONTRACT_BILLING_COUNTRY_CHANGE,
  LEAD_CONTRACT_BILLING_ADDITIONAL_CHANGE,
  LEAD_CONTRACT_BILLING_POSTBOX_NO_CHANGE,
  LEAD_CONTRACT_BILLING_POSTBOX_POSTAL_CODE_CHANGE,
  LEAD_CONTRACT_BILLING_POSTBOX_CITY_CHANGE,
  LEAD_CONTRACT_BILLING_ADDRESS_SAVED,
  ACCEPT_OFFER,
  CHANGE_CONTACT_HISTORY_DETAIL_OBJECT_ID,
  CHANGE_CONTACT_HISTORY_DETAIL_REMARKS,
  CHANGE_CONTACT_HISTORY_DETAIL_TOPIC,
  CONTACT_HISTORY_DETAIL_DOCUMENT_CHANGE,
  CONTACT_HISTORY_DETAIL_DOCUMENT_DELETE,
  CLOSE_CONTACT_HISTORY_DIALOG,
  CLOSE_DELETE_LEAD_CONTACT_HISTORY_CONFIRM,
  CONFIRM_ACCEPT_OFFER,
  CLOSE_LEAD_CONTRACT_OFFER_ACCEPT_DIALOG,
  DELETE_LEAD_CONTACT_HISTORY_CONFIRM,
  EDIT_CONTACT_HISTORY,
  GET_CONTACT_HISTORIES,
  SET_CONTACT_HISTORY_ERROR,
  CLOSE_LEAD_CONTRACTS_DIALOG,
  GET_CONTRACTS,
  GET_LEADS,
  DELETE_CONTRACT_CONFIRM,
  CLOSE_DELETE_CONTRACT,
  DELETE_LEAD_CONFIRM,
  CLOSE_DELETE_LEAD_CONFIRM,

  //	COUNTER DOUBLINGS
  LEAD_COUNTER_DOUBLINGS_LIST,
  LEAD_DOUBLE_COUNTER_DIALOG_CLOSE,

  CLOSE_LEAD_CONTRACT_BILLING_ADDRESS_SAVED_DIALOG,
  CLOSE_LEAD_CONTRACT_BILLING_ADDRESS_PASTE_DIALOG,
  LEAD_CONTRACT_PASTE_BILLING_ADDRESS,
  LEAD_CONTRACT_DELETE_BILLING_ADDRESS,
  LEAD_CONTRACT_TAKEOVER_BILLING_ADDRESS,
  LEAD_ENERGY_TRANSITION_TEN_PERCENT_DATE_CHANGE,
  LEAD_GAS_TRANSITION_TEN_PERCENT_DATE_CHANGE,
  IS_LEAD_ENERGY_TRANSITION_TEN_PERCENT_CHANGE,
  IS_LEAD_GAS_TRANSITION_TEN_PERCENT_CHANGE,
} from "../types";

export default (state, action) => {
  switch (action.type) {
    //  Lead general section
    case CLOSE_LEAD_DETAIL_DIALOG:
      return {
        ...state,
        leadDetailDialogState: false,
        leadDetailDialogTitle: action.payload.dialogTitle,
        activeLeadId: -1,
		isLeadEnergyTransitionInitialized: 2,
		isLeadEnergyInitialTransition: 0,
		isLeadEnergyTransitionOpen: 0,
		isLeadGasTransitionInitialized: 2,
		isLeadGasInitialTransition: 0,
		isLeadGasTransitionOpen: 0,
		bIsLeadEnergyTransitionChange: false,
		bIsLeadEnergyTransitionTenPercentChange: false,
		bIsLeadGasTransitionChange: false,
		bIsLeadGasTransitionTenPercentChange: false,
      };

    case EDIT_LEAD:
      return {
        ...state,
        leadDetailDialogState: true,
        leadDetailDialogTitle: action.payload.dialogTitle,
        activeLeadId: action.payload.lead.id,

        businessPartnerTypesId: action.payload.lead.businessPartnerTypesId,
        followUpDate: action.payload.lead.followUpDate,
        followUpDone: action.payload.lead.followUpDone,
        abcId: action.payload.lead.abcId,
        isPortalAccessAdministration:
          action.payload.lead.isPortalAccessAdministration,
        supervisorsId: action.payload.lead.supervisorsId,
		supervisorsPvId: action.payload.lead.supervisorsPvId,
        salutationsId: action.payload.lead.salutationsId,
        name1: action.payload.lead.name1,
        name2: action.payload.lead.name2,
        name3: action.payload.lead.name3,
		legalFormsId: action.payload.lead.legalFormsId,
        street: action.payload.lead.street,
        houseNumber: action.payload.lead.houseNumber,
        addressAddition: action.payload.lead.addressAddition,
        postalCode: action.payload.lead.postalCode,
        city: action.payload.lead.city,
        district: action.payload.lead.district,
        country: action.payload.lead.country,
        phone: action.payload.lead.phone,
        fax: action.payload.lead.fax,
        email: action.payload.lead.email,
        url: action.payload.lead.url,
        postbox: action.payload.lead.postbox,
        postboxPostalCode: action.payload.lead.postboxPostalCode,
        postboxCity: action.payload.lead.postboxCity,
        ownerId: action.payload.lead.ownerId,
        administrations1Id: action.payload.lead.administrations1Id,
        administrations2Id: action.payload.lead.administrations2Id,
        administrations3Id: action.payload.lead.administrations3Id,
        communityCode: action.payload.lead.communityCode,
        bankCode: action.payload.lead.bankCode,
        accountNo: action.payload.lead.accountNo,
        accountOwner: action.payload.lead.accountOwner,
        iban: action.payload.lead.iban,
		isTransition: action.payload.isTransition,
        energyCampaignsId: action.payload.lead.energyCampaignsId,
		isLeadEnergyTransitionInitialized: action.payload.lead.isLeadEnergyTransitionInitialized,
		isLeadEnergyTransition: action.payload.lead.isLeadEnergyTransition,
		leadEnergyTransitionDate: action.payload.lead.leadEnergyTransitionDate,
		isLeadEnergyTransitionTenPercent: action.payload.lead.isLeadEnergyTransitionTenPercent,
		leadEnergyTransitionTenPercentDate: action.payload.lead.leadEnergyTransitionTenPercentDate,
        energyPotsId: action.payload.lead.energyPotsId,
        gasCampaignsId: action.payload.lead.gasCampaignsId,
		isLeadGasTransitionInitialized: action.payload.lead.isLeadGasTransitionInitialized,
		isLeadGasTransition: action.payload.lead.isLeadGasTransition,
		leadGasTransitionDate: action.payload.lead.leadGasTransitionDate,
		isLeadGasTransitionTenPercent: action.payload.lead.isLeadGasTransitionTenPercent,
		leadGasTransitionTenPercentDate: action.payload.lead.leadGasTransitionTenPercentDate,
        gasPotsId: action.payload.lead.gasPotsId,
        isOfferAccepted: action.payload.lead.isOfferAccepted,

        contactPersons: action.payload.contactPersons,
        leadContracts: action.payload.leadContracts,
		copiedCustomer: action.payload.copiedCustomer,
        leadObjects: action.payload.leadObjects,
        administrations1: action.payload.administrations1,
        administrations2: action.payload.administrations2,
        administrations3: action.payload.administrations3,
        energyCampaigns: action.payload.energyCampaigns,
        gasCampaigns: action.payload.gasCampaigns,
      };

    case SET_FOREIGNS:
      return {
        ...state,
        businessPartnerTypes: action.payload.businessPartnerTypes,
        abc: action.payload.abc,
        supervisors: action.payload.supervisors,
		supervisorsPv: action.payload.supervisorsPv,
        salutations: action.payload.salutations,
        yesNos: action.payload.yesNos,
        administrations1: action.payload.administrations1,
        administrations2: action.payload.administrations2,
        administrations3: action.payload.administrations3,
        energyCampaigns: action.payload.energyCampaigns,
        gasCampaigns: action.payload.gasCampaigns,
        divisions: action.payload.divisions,
        changeReasons: action.payload.changeReasons,
        suppliers: action.payload.suppliers,
        networks: action.payload.networks,
        loadProfiles: action.payload.loadProfiles,
		legalForms: action.payload.legalForms,
        customerTypes: action.payload.customerTypes,
      };

    case SET_LEAD_DETAIL_DIALOG_ACTIVE_TAB:
      return {
        ...state,
        leadDetailDialogActiveTab: action.payload,
      };

    case SET_ERR_MSG:
      return {
        ...state,
        leadDetailDialogError: action.payload.leadDetailDialogError,
        leadDetailDialogActiveTab: action.payload.leadDetailDialogActiveTab,
        activeAddressTab: action.payload.activeAddressTab,
      };

    //  Lead address
    case ABC_ID_CHANGE:
      return {
        ...state,
        abcId: action.payload,
        leadDetailDialogError: "",
      };

    case BUSINESS_PARTNER_TYPES_ID_CHANGE:
      return {
        ...state,
        businessPartnerTypesId: action.payload,
        leadDetailDialogError: "",
      };

    case FOLLOW_UP_DATE_CHANGE:
      return {
        ...state,
        followUpDate: action.payload,
      };

    case FOLLOW_UP_DONE_CHANGE:
      return {
        ...state,
        followUpDone: action.payload,
      };

    case IS_PORTAL_ACCESS_ADMINISTRATION_CHANGE:
      return {
        ...state,
        isPortalAccessAdministration: action.payload,
      };

    case SET_ACTIVE_ADDRESS_TAB:
      return {
        ...state,
        activeAddressTab: action.payload,
      };

    case SUPERVISORS_ID_CHANGE:
      return {
        ...state,
        supervisorsId: action.payload,
        leadDetailDialogError: "",
      };

	case SUPERVISORS_PV_ID_CHANGE:
		return {
			...state,
			supervisorsPvId: action.payload,
		}

    //	LEAD OBJECT LIST DIALOG
    case LEAD_OBJECT_LIST_DIALOG:
      return {
        ...state,
        leadObjectListDialogState: action.payload,
        leadObjectListIsFinished: false,
      };

    case LEAD_OBJECT_LIST_IS_FINISHED:
      return {
        ...state,
        leadObjectListIsFinished: action.payload.leadObjectListIsFinished,
		leadObjectListIsEmpty: action.payload.leadObjectListIsEmpty
      };

    //  Leads address address
    case ADDRESS_ADDITION_CHANGE:
      return {
        ...state,
        addressAddition: action.payload,
      };

    case CITY_CHANGE:
      return {
        ...state,
        city: action.payload,
      };

    case COUNTRY_CHANGE:
      return {
        ...state,
        country: action.payload,
        leadDetailDialogError: "",
      };

    case DISTRICT_CHANGE:
      return {
        ...state,
        district: action.payload,
      };

    case HOUSE_NUMBER_CHANGE:
      return {
        ...state,
        houseNumber: action.payload,
      };

    case NAME_1_CHANGE:
      return {
        ...state,
        name1: action.payload,
        leadDetailDialogError: "",
      };

    case NAME_2_CHANGE:
      return {
        ...state,
        name2: action.payload,
      };

    case NAME_3_CHANGE:
      return {
        ...state,
        name3: action.payload,
      };

	case LEGAL_FORMS_ID_CHANGE:
		return {
			...state,
			legalFormsId: action.payload,
		}

    case POSTAL_CODE_CHANGE:
      return {
        ...state,
        postalCode: action.payload,
      };

    case SALUTATIONS_ID_CHANGE:
      return {
        ...state,
        salutationsId: action.payload,
        leadDetailDialogError: "",
      };

    case STREET_CHANGE:
      return {
        ...state,
        street: action.payload,
      };

    //  Lead Address Contact
    case EMAIL_CHANGE:
      return {
        ...state,
        email: action.payload,
      };

    case FAX_CHANGE:
      return {
        ...state,
        fax: action.payload,
      };

    case PHONE_CHANGE:
      return {
        ...state,
        phone: action.payload,
      };

    case URL_CHANGE:
      return {
        ...state,
        url: action.payload,
      };

    //  Lead address postbox
    case POSTBOX_CHANGE:
      return {
        ...state,
        postbox: action.payload,
      };

    case POSTBOX_CITY_CHANGE:
      return {
        ...state,
        postboxCity: action.payload,
      };

    case POSTBOX_POSTAL_CODE_CHANGE:
      return {
        ...state,
        postboxPostalCode: action.payload,
      };

    case ADMINISTRATIONS_1_ID_CHANGE:
      return {
        ...state,
        administrations1Id: action.payload,
        leadDetailDialogError: "",
      };

    case ADMINISTRATIONS_2_ID_CHANGE:
      return {
        ...state,
        administrations2Id: action.payload,
      };

    case ADMINISTRATIONS_3_ID_CHANGE:
      return {
        ...state,
        administrations3Id: action.payload,
      };

    case COMMUNITY_CODE_CHANGE:
      return {
        ...state,
        communityCode: action.payload,
      };

    case OWNER_ID_CHANGE:
      return {
        ...state,
        ownerId: action.payload,
        leadDetailDialogError: "",
      };

    case GET_ADMINISTRATIONS_1:
      return {
        ...state,
        administrations1: action.payload.administrations1,
        administrations2: action.payload.administrations2,
        administrations3: action.payload.administrations3,
        administrations1Id: -1,
        administrations2Id: -1,
        administrations3Id: -1,
      };

    case GET_ADMINISTRATIONS_2:
      return {
        ...state,
        administrations2: action.payload.administrations2,
        administrations3: action.payload.administrations3,
        administrations2Id: -1,
        administrations3Id: -1,
      };

    case GET_ADMINISTRATIONS_3:
      return {
        ...state,
        administrations3: action.payload.administrations3,
        administrations3Id: -1,
      };

    //  Lead Contact Persons

    case CLOSE_CONTACT_PERSON_DIALOG:
      return {
        ...state,
        contactPersonDetailsDialogState: false,
        activeContactPerson: 0,
        contactPersonDetailsDialogError: "",
        contactPersonDivision: "",
        contactPersonEmail: "",
        contactPersonFax: "",
        contactPersonFirstname: "",
        contactPersonFunction: "",
        contactPersonIsPortalAccess: false,
        contactPersonLastname: "",
        contactPersonMobile: "",
        contactPersonPhone: "",
        contactPersonPortalAccessValidTo: "",
        contactPersonSalutationsId: -1,
        contactPersonTitle: "",
      };

    case CLOSE_DELETE:
      return {
        ...state,
        deleteModalState: false,
      };

    case CONTACT_PERSON_DIVISION_CHANGE:
      return {
        ...state,
        contactPersonDivision: action.payload,
      };

    case CONTACT_PERSON_EMAIL_CHANGE:
      return {
        ...state,
        contactPersonEmail: action.payload,
      };

    case CONTACT_PERSON_FAX_CHANGE:
      return {
        ...state,
        contactPersonFax: action.payload,
      };

    case CONTACT_PERSON_FIRSTNAME_CHANGE:
      return {
        ...state,
        contactPersonFirstname: action.payload,
      };

    case CONTACT_PERSON_FUNCTION_CHANGE:
      return {
        ...state,
        contactPersonFunction: action.payload,
      };

    case CONTACT_PERSON_IS_PORTAL_ACCESS_CHANGE:
      return {
        ...state,
        contactPersonIsPortalAccess: action.payload,
      };

    case CONTACT_PERSON_LASTNAME_CHANGE:
      return {
        ...state,
        contactPersonLastname: action.payload,
      };

    case CONTACT_PERSON_MOBILE_CHANGE:
      return {
        ...state,
        contactPersonMobile: action.payload,
      };

    case CONTACT_PERSON_PHONE_CHANGE:
      return {
        ...state,
        contactPersonPhone: action.payload,
      };

    case CONTACT_PERSON_PORTAL_ACCESS_VALID_TO_CHANGE:
      return {
        ...state,
        contactPersonPortalAccessValidTo: action.payload,
      };

    case CONTACT_PERSON_SALUTATIONS_ID_CHANGE:
      return {
        ...state,
        contactPersonSalutationsId: action.payload,
      };

    case CONTACT_PERSON_TITLE_CHANGE:
      return {
        ...state,
        contactPersonTitle: action.payload,
      };

    case DELETE_CONTACT_PERSON:
      return {
        ...state,
        deleteModalState: false,
        deleteModalTitle: "",
        deleteModalEntityName: "",
        activeContactPerson: 0,
      };

    case DELETE_CONTACT_PERSON_CONFIRM:
      return {
        ...state,
        deleteModalState: true,
        deleteModalTitle: action.payload.title,
        deleteModalEntityName: action.payload.entityName,
        activeContactPerson: action.payload.id,
      };

    case DELETE_CONTRACT_CONFIRM:
      return {
        ...state,
        deleteContractState: true,
        deleteContractName: action.payload.entityName,
        activeLeadContract: action.payload.id,
      };

    case CLOSE_DELETE_CONTRACT:
      return {
        ...state,
        deleteContractState: false,
        deleteContractName: "",
        activeLeadContract: 0,
      };

    case DELETE_LEAD_CONFIRM:
      return {
        ...state,
        deleteLeadConfirmState: true,
        deleteLeadConfirmName: action.payload.entityName,
        activeLeadId: action.payload.id,
      };

    case CLOSE_DELETE_LEAD_CONFIRM:
      return {
        ...state,
        deleteLeadConfirmState: false,
        deleteLeadConfirmName: "",
        activeLeadId: -1,
      };

    case EDIT_CONTACT_PERSON:
      return {
        ...state,
        activeContactPerson: action.payload.activeContactPerson,
        contactPersonDetailsDialogState:
          action.payload.contactPersonDetailsDialogState,
        contactPersonDetailsDialogTitle:
          action.payload.contactPersonDetailsDialogTitle,
      };

    case GET_CONTACT_PERSONS:
      return {
        ...state,
        contactPersons: action.payload,
      };

    case SET_ACTIVE_CONTACT_PERSON:
      return {
        ...state,
        activeContactPerson: action.payload.id,
        contactPersonSalutationsId: action.payload.salutationsId,
        contactPersonTitle: action.payload.title,
        contactPersonFirstname: action.payload.firstname,
        contactPersonLastname: action.payload.lastname,
        contactPersonFunction: action.payload.function,
        contactPersonDivision: action.payload.division,
        contactPersonEmail: action.payload.email,
        contactPersonPhone: action.payload.phone,
        contactPersonFax: action.payload.fax,
        contactPersonMobile: action.payload.mobile,
        contactPersonIsPortalAccess: action.payload.isPortalAccess,
        contactPersonPortalAccessValidTo: action.payload.portalAccessValidTo,
      };

    case SET_CONTACT_PERSON_ERROR:
      return {
        ...state,
        contactPersonDetailsDialogError: action.payload,
      };

    //  Bank Account
    case ACCOUNT_NO_CHANGE:
      return {
        ...state,
        accountNo: action.payload,
      };

    case ACCOUNT_OWNER_CHANGE:
      return {
        ...state,
        accountOwner: action.payload,
      };

    case BANK_CODE_CHANGE:
      return {
        ...state,
        bankCode: action.payload,
      };

    case IBAN_CHANGE:
      return {
        ...state,
        iban: action.payload,
      };

    //  Products
    case ENERGY_CAMPAIGNS_ID_CHANGE:
      return {
        ...state,
        energyCampaignsId: action.payload.energyCampaignsId,
        energyPots: action.payload.energyPots,
		isLeadEnergyTransitionInitialized: action.payload.isLeadEnergyTransitionInitialized,
		isLeadEnergyInitialTransition: action.payload.isLeadEnergyInitialTransition,
		isLeadEnergyTransition: action.payload.isLeadEnergyTransition,
		isLeadEnergyTransitionTenPercent: action.payload.isLeadEnergyTransitionTenPercent,
      };

	case IS_LEAD_ENERGY_TRANSITION_CHANGE:
		return {
			...state,
			isLeadEnergyTransition: action.payload,
			leadEnergyTransitionDate: '',
			bIsLeadEnergyTransitionChange: true,
		}

	case LEAD_ENERGY_TRANSITION_DATE_CHANGE:
		return {
			...state,
			leadEnergyTransitionDate: action.payload,
			bIsLeadEnergyTransitionChange: true,
		}

	case IS_LEAD_ENERGY_TRANSITION_TEN_PERCENT_CHANGE:
		return {
			...state,
			isLeadEnergyTransitionTenPercent: action.payload,
			leadEnergyTransitionTenPercentDate: '',
			bIsLeadEnergyTransitionTenPercentChange: true,
		}

	case LEAD_ENERGY_TRANSITION_TEN_PERCENT_DATE_CHANGE:
		return {
			...state,
			leadEnergyTransitionTenPercentDate: action.payload,
			bIsLeadEnergyTransitionTenPercentChange: true,
		}

    case ENERGY_POTS_ID_CHANGE:
      return {
        ...state,
        energyPotsId: action.payload.energyPotsId,
        energyCampaignsId: action.payload.energyCampaignsId,
      };

    case GAS_CAMPAIGNS_ID_CHANGE:
      return {
        ...state,
        gasCampaignsId: action.payload.gasCampaignsId,
        gasPots: action.payload.gasPots,
		isLeadGasTransitionInitialized: action.payload.isLeadGasTransitionInitialized,
		isLeadGasInitialTransition: action.payload.isLeadGasInitialTransition,
		isLeadGasTransition: action.payload.isLeadGasTransition,
		isLeadGasTransitionTenPercent: action.payload.isLeadGasTransitionTenPercent
      };

	  case IS_LEAD_GAS_TRANSITION_CHANGE:
		return {
			...state,
			isLeadGasTransition: action.payload,
			leadGasTransitionDate: '',
			bIsLeadGasTransitionChange: true,
		}

	  case LEAD_GAS_TRANSITION_DATE_CHANGE:
		return {
			...state,
			leadGasTransitionDate: action.payload,
			bIsLeadGasTransitionChange: true,
		}

	case IS_LEAD_GAS_TRANSITION_TEN_PERCENT_CHANGE:
		return {
			...state,
			isLeadGasTransitionTenPercent: action.payload,
			leadGasTransitionTenPercentDate: '',
			bIsLeadGasTransitionTenPercentChange: true,
		}

	case LEAD_GAS_TRANSITION_TEN_PERCENT_DATE_CHANGE:
		return {
			...state,
			leadGasTransitionTenPercentDate: action.payload,
			bIsLeadGasTransitionTenPercentChange: true,
		}

    case GAS_POTS_ID_CHANGE:
      return {
        ...state,
        gasPotsId: action.payload.gasPotsId,
        gasCampaignsId: action.payload.gasCampaignsId,
      };

    case SET_ACTIVE_PRODUCT_TAB:
      return {
        ...state,
        activeProductTab: action.payload,
      };

    //  Contracts
    case EDIT_LEAD_CONTRACT:

      return {
        ...state,
        activeLeadContract: action.payload.id,
        // activeLeadContractTab: 1,
        leadContractsDialogTitle: action.payload.title,
        leadContractsDialogState: true,
        leadContractDivisionsId: action.payload.divisionsId,
        leadContractProductsId: action.payload.leadContractProductsId,
        leadContractValidFrom: action.payload.leadContractValidFrom,
        leadContractValidTo: action.payload.leadContractValidTo,
		isLeadContractTransitionInitialized: action.payload.isLeadContractTransitionInitialized,
		isLeadContractTransition: action.payload.isLeadContractTransition,
		leadContractTransitionDate: action.payload.leadContractTransitionDate,
		isLeadContractTransitionTenPercent: action.payload.isLeadContractTransitionTenPercent,
		leadContractTransitionTenPercentDate: action.payload.leadContractTransitionTenPercentDate,
        leadContractPotsId: action.payload.leadContractPotsId,
        leadContractIsClimatePlus: action.payload.leadContractIsClimatePlus,
        leadContractIsBio5: action.payload.leadContractIsBio5,
        leadContractBio5StartDate: action.payload.leadContractBio5StartDate,
        leadContractIsBio10: action.payload.leadContractIsBio10,
        leadContractBio10StartDate: action.payload.leadContractBio10StartDate,
        leadContractIsCo2: action.payload.leadContractIsCo2,
        leadContractPeriodConsumption:
          action.payload.leadContractPeriodConsumption,
        leadCounterId: action.payload.leadCounterId,
        leadCounterNumberCurrent: action.payload.leadCounterNumberCurrent,
        leadCounterNumberDoubling: action.payload.leadCounterNumberDoubling,
        leadCounterMediumVoltage: action.payload.leadCounterMediumVoltage,
        leadCounterBhkw: action.payload.leadCounterBhkw,
        leadCounterContractAccount: action.payload.leadCounterContractAccount,
        leadCounterIntendedUse: action.payload.leadCounterIntendedUse,

        leadCounterChangeReasonsId: action.payload.leadCounterChangeReasonsId,
        leadCounterChangeSupplyStart:
          action.payload.leadCounterChangeSupplyStart,
        leadCounterFormerSupplierId:
          action.payload.leadCounterFormerSuppliersId,
        leadCounterInstallmentCycle: action.payload.leadCounterInstallmentCycle,
        leadCounterNetworksId: action.payload.leadCounterNetworksId,
        leadCounterLoadProfilesId: action.payload.leadCounterLoadProfilesId,
        leadCounterValue: action.payload.leadCounterValue,
        leadCounterLocationStreet: action.payload.leadCounterLocationStreet,
        leadCounterLocationHouseNumber:
          action.payload.leadCounterLocationHouseNumber,
        leadCounterLocationPostalCode:
          action.payload.leadCounterLocationPostalCode,
        leadCounterLocationCity: action.payload.leadCounterLocationCity,
        leadCounterLocationDistrict: action.payload.leadCounterLocationDistrict,
        leadCounterLocationCountry: action.payload.leadCounterLocationCountry,
        leadCounterLocationAdditional:
          action.payload.leadCounterLocationAdditional,
        leadCounterLocationMarketLocation:
          action.payload.leadCounterLocationMarketLocation,
        leadCounterLocationMeasureLocation:
          action.payload.leadCounterLocationMeasureLocation,
        leadCounterAccountNo: action.payload.leadCounterAccountNo,
        leadCounterAccountOwner: action.payload.leadCounterAccountOwner,
        leadCounterBankCode: action.payload.leadCounterBankCode,
        leadCounterIban: action.payload.leadCounterIban,
        leadContractSalutationsId: action.payload.leadContractSalutationsId,
        leadContractCustomerTypesId: action.payload.leadContractCustomerTypesId,
        leadContractBillingName1: action.payload.leadContractBillingName1,
        leadContractBillingName2: action.payload.leadContractBillingName2,
        leadContractBillingName3: action.payload.leadContractBillingName3,
        leadContractBillingStreet: action.payload.leadContractBillingStreet,
        leadContractBillingHouseNumber:
          action.payload.leadContractBillingHouseNumber,
        leadContractBillingPostalCode:
          action.payload.leadContractBillingPostalCode,
        leadContractBillingCity: action.payload.leadContractBillingCity,
        leadContractBillingDistrict: action.payload.leadContractBillingDistrict,
        leadContractBillingCountry: action.payload.leadContractBillingCountry,
        leadContractBillingAdditional:
          action.payload.leadContractBillingAdditional,
        leadContractBillingPostboxNo:
          action.payload.leadContractBillingPostboxNo,
        leadContractBillingPostboxPostalCode:
          action.payload.leadContractBillingPostboxPostalCode,
        leadContractBillingPostboxCity:
          action.payload.leadContractBillingPostboxCity,

        isPots: action.payload.isPots,
        pots: action.payload.pots,
        potsId: action.payload.potsId,
        isProduct: action.payload.isProduct,
        leadContractProducts: action.payload.products,
        productsId: action.payload.productsId,
        isValidFrom: action.payload.isValidFrom,
        validFrom: action.payload.validFrom,
        isValidTo: action.payload.isValidTo,
        validTo: action.payload.validTo,
        isBio5: action.payload.isBio5,
        isBio10: action.payload.isBio10,
        isCo2: action.payload.isCo2,
        isClimatePlus: action.payload.isClimatePlus,
        loadProfiles: action.payload.loadProfiles,
        loadProfilesId: action.payload.loadProfilesId,
        isOptionen: action.payload.isOptionen,
        divisionsId: action.payload.divisionsId,
      };

    case ACCEPT_OFFER:
      return {
        ...state,
        leadContractOfferAcceptDialogState: false,
        isOfferAccepted: true,
      };

    case LEAD_CONTRACT_BILLING_NAME1_CHANGE:
      return {
        ...state,
        leadContractBillingName1: action.payload,
      };

    case LEAD_CONTRACT_BILLING_NAME2_CHANGE:
      return {
        ...state,
        leadContractBillingName2: action.payload,
      };

    case LEAD_CONTRACT_BILLING_NAME3_CHANGE:
      return {
        ...state,
        leadContractBillingName3: action.payload,
      };

    case LEAD_CONTRACT_CUSTOMER_TYPES_ID_CHANGE:
      return {
        ...state,
        leadContractCustomerTypesId: action.payload,
      };

    case LEAD_CONTRACT_DIVISIONS_ID_CHANGE:
      return {
        ...state,
        leadContractDivisionsId: action.payload.divisionsId,
        isPots: action.payload.isPots,
        pots: action.payload.pots,
        leadContractPotsId: action.payload.potsId,
        isProduct: action.payload.isProduct,
        leadContractProducts: action.payload.products,
        leadContractProductsId: action.payload.productsId,
        isValidFrom: action.payload.isValidFrom,
        leadContractValidFrom: action.payload.validFrom,
        isValidTo: action.payload.isValidTo,
        leadContractValidTo: action.payload.validTo,
        leadContractIsBio5: action.payload.isBio5,
        leadContractBio5StartDate: action.payload.bio5StartDate,
        leadContractIsBio10: action.payload.isBio10,
        leadContractBio10StartDate: action.payload.bio10StartDate,
        leadContractIsCo2: action.payload.isCo2,
        leadContractIsClimatePlus: action.payload.isClimatePlus,
        loadProfiles: action.payload.loadProfiles,
        leadCounterLoadProfilesId: action.payload.loadProfilesId,
        isOptionen: action.payload.isOptionen,
        divisionsId: action.payload.divisionsId,
        suppliers: action.payload.suppliers,
        networks: action.payload.networks,
      };

    case CONFIRM_ACCEPT_OFFER:
      return {
        ...state,
        leadContractOfferAcceptDialogState: true,
      };

    case CLOSE_LEAD_CONTRACT_OFFER_ACCEPT_DIALOG:
      return {
        ...state,
        leadContractOfferAcceptDialogState: false,
      };

    case LEAD_CONTRACT_IS_BIO5_CHANGE:
      return {
        ...state,
        leadContractIsBio5: action.payload,
      };

    case LEAD_CONTRACT_BIO5_START_DATE_CHANGE:
      return {
        ...state,
        leadContractBio5StartDate: action.payload,
      };

    case LEAD_CONTRACT_BIO10_START_DATE_CHANGE:
      return {
        ...state,
        leadContractBio10StartDate: action.payload,
      };

    case LEAD_CONTRACT_IS_BIO10_CHANGE:
      return {
        ...state,
        leadContractIsBio10: action.payload,
      };

    case LEAD_CONTRACT_IS_CLIMATE_PLUS_CHANGE:
      return {
        ...state,
        leadContractIsClimatePlus: action.payload,
      };

    case LEAD_CONTRACT_IS_CO2_CHANGE:
      return {
        ...state,
        leadContractIsCo2: action.payload,
      };

    case LEAD_CONTRACT_PERIOD_CONSUMPTION_CHANGE:
      return {
        ...state,
        leadContractPeriodConsumption: action.payload,
      };

    case LEAD_CONTRACT_POTS_ID_CHANGE:
      return {
        ...state,
        leadContractPotsId: action.payload.potsId,
		isLeadContractTransitionInitialized: action.payload.isLeadContractTransitionInitialized,
		isLeadContractInitialTransition: action.payload.isLeadContractInitialTransition,
		isLeadContractTransition: action.payload.isLeadContractTransition,
		leadContractTransitionDate: action.payload.leadContractTransitionDate,
		isLeadContractTransitionTenPercent: action.payload.isLeadContractTransitionTenPercent,
		leadContractTransitionTenPercentDate: action.payload.leadContractTransitionTenPercentDate,
      };

    case LEAD_CONTRACT_PRODUCTS_ID_CHANGE:
      return {
        ...state,
        leadContractProductsId: action.payload,
      };

    case LEAD_CONTRACT_SALUTATIONS_ID_CHANGE:
      return {
        ...state,
        leadContractSalutationsId: action.payload,
      };

    case LEAD_CONTRACT_VALID_FROM_CHANGE:
      return {
        ...state,
        leadContractValidFrom: action.payload,
      };

    case LEAD_CONTRACT_VALID_TO_CHANGE:
      return {
        ...state,
        leadContractValidTo: action.payload,
      };

	case IS_LEAD_CONTRACT_TRANSITION_CHANGE:
		return {
			...state,
			isLeadContractTransition: action.payload,
			leadContractTransitionDate: '',
		}

	case LEAD_CONTRACT_TRANSITION_DATE_CHANGE:
		return {
			...state,
			leadContractTransitionDate: action.payload,
		}

	case IS_LEAD_CONTRACT_TRANSITION_TEN_PERCENT_CHANGE:
		return {
			...state,
			isLeadContractTransitionTenPercent: action.payload,
			leadContractTransitionTenPercentDate: '',
		}

	case LEAD_CONTRACT_TRANSITION_TEN_PERCENT_DATE_CHANGE:
		return {
			...state,
			leadContractTransitionTenPercentDate: action.payload,
		}

    case LEAD_COUNTER_ACCOUNT_NO_CHANGE:
      return {
        ...state,
        leadCounterAccountNo: action.payload,
      };

    case LEAD_COUNTER_ACCOUNT_OWNER_CHANGE:
      return {
        ...state,
        leadCounterAccountOwner: action.payload,
      };

    case LEAD_COUNTER_BANK_CODE_CHANGE:
      return {
        ...state,
        leadCounterBankCode: action.payload,
      };

    case LEAD_COUNTER_BHKW:
      return {
        ...state,
        leadCounterBhkw: action.payload,
      };

    case LEAD_COUNTER_CHANGE_REASON_ID_CHANGE:
      return {
        ...state,
        leadCounterChangeReasonsId: action.payload,
      };

    case LEAD_COUNTER_CHANGE_SUPPLY_START_CHANGE:
      return {
        ...state,
        leadCounterChangeSupplyStart: action.payload,
      };

    case LEAD_COUNTER_CONTRACT_ACCOUNT_CHANGE:
      return {
        ...state,
        leadCounterContractAccount: action.payload,
      };

    case LEAD_COUNTER_FORMER_SUPPLIER_ID_CHANGE:
      return {
        ...state,
        leadCounterFormerSupplierId: action.payload,
      };

    case LEAD_COUNTER_IBAN_CHANGE:
      return {
        ...state,
        leadCounterIban: action.payload,
      };

    case LEAD_COUNTER_ID_CHANGE:
      return {
        ...state,
        leadCounterId: action.payload,
      };

    case LEAD_COUNTER_INSTALLMENT_CYCLE_CHANGE:
      return {
        ...state,
        leadCounterInstallmentCycle: action.payload,
      };

    case LEAD_COUNTER_INTENDED_USE_CHANGE:
      return {
        ...state,
        leadCounterIntendedUse: action.payload,
      };

    case LEAD_COUNTRY_LOCATION_ADDITIONAL_CHANGE:
      return {
        ...state,
        leadCountryLocationAdditional: action.payload,
      };

    case LEAD_COUNTER_LOCATION_CITY_CHANGE:
      return {
        ...state,
        leadCounterLocationCity: action.payload,
      };

    case LEAD_COUNTER_LOCATION_COUNTRY_CHANGE:
      return {
        ...state,
        leadCounterLocationCountry: action.payload,
      };

    case LEAD_COUNTER_LOCATION_DISTRICT_CHANGE:
      return {
        ...state,
        leadCounterLocationDistrict: action.payload,
      };

    case LEAD_COUNTER_LOCATION_HOUSE_NUMBER_CHANGE:
      return {
        ...state,
        leadCounterLocationHouseNumber: action.payload,
      };

    case LEAD_COUNTER_LOCATION_MARKET_LOCATION_CHANGE:
      return {
        ...state,
        leadCounterLocationMarketLocation: action.payload,
      };

    case LEAD_COUNTER_LOCATION_MEASURE_LOCATION_CHANGE:
      return {
        ...state,
        leadCounterLocationMeasureLocation: action.payload,
      };

    case LEAD_COUNTER_LOCATION_POSTAL_CODE_CHANGE:
      return {
        ...state,
        leadCounterLocationPostalCode: action.payload,
      };

    case LEAD_COUNTER_LOCATION_STREET_CHANGE:
      return {
        ...state,
        leadCounterLocationStreet: action.payload,
      };

    case LEAD_COUNTER_MEDIUM_VOLTAGE_CHANGE:
      return {
        ...state,
        leadCounterMediumVoltage: action.payload,
      };

    case LEAD_COUNTER_NETWORKS_ID_CHANGE:
      return {
        ...state,
        leadCounterNetworksId: action.payload,
      };

    case LEAD_COUNTER_NUMBER_CURRENT_CHANGE:
      return {
        ...state,
        leadCounterNumberCurrent: action.payload,
      };

    case LEAD_COUNTER_LOAD_PROFILES_ID_CHANGE:
      return {
        ...state,
        leadCounterLoadProfilesId: action.payload,
      };

    case LEAD_COUNTER_VALUE_CHANGE:
      return {
        ...state,
        leadCounterValue: action.payload,
      };

    case SET_ACTIVE_CONTRACT_COUNTER_TAB:
      return {
        ...state,
        activeContractCounterTab: action.payload,
      };

    case SET_ACTIVE_LEAD_CONTRACT_TAB:
      return {
        ...state,
        activeLeadContractTab: action.payload,
      };

	case SET_CONTRACT_ERR_MSG:
		return {
			...state,
			leadContractsDialogError: action.payload.leadContractsDialogError,
			activeLeadContractTab: action.payload.activeLeadContractTab,
		}

    case UPDATE_CONTRACT_EXTERNALS:
      return {
        ...state,
        suppliers: action.payload.suppliers,
        networks: action.payload.networks,
      };

    case LEAD_CONTRACT_BILLING_STREET_CHANGE:
      return {
        ...state,
        leadContractBillingStreet: action.payload,
      };

    case LEAD_CONTRACT_BILLING_HOUSE_NUMBER_CHANGE:
      return {
        ...state,
        leadContractBillingHouseNumber: action.payload,
      };

    case LEAD_CONTRACT_BILLING_POSTAL_CODE_CHANGE:
      return {
        ...state,
        leadContractBillingPostalCode: action.payload,
      };

    case LEAD_CONTRACT_BILLING_CITY_CHANGE:
      return {
        ...state,
        leadContractBillingCity: action.payload,
      };

    case LEAD_CONTRACT_BILLING_DISTRICT_CHANGE:
      return {
        ...state,
        leadContractBillingDistrict: action.payload,
      };

    case LEAD_CONTRACT_BILLING_COUNTRY_CHANGE:
      return {
        ...state,
        leadContractBillingCountry: action.payload,
      };

    case LEAD_CONTRACT_BILLING_ADDITIONAL_CHANGE:
      return {
        ...state,
        leadContractBillingAdditional: action.payload,
      };

    case LEAD_CONTRACT_BILLING_POSTBOX_NO_CHANGE:
      return {
        ...state,
        leadContractBillingPostboxNo: action.payload,
      };

    case LEAD_CONTRACT_BILLING_POSTBOX_POSTAL_CODE_CHANGE:
      return {
        ...state,
        leadContractBillingPostboxPostalCode: action.payload,
      };

    case LEAD_CONTRACT_BILLING_POSTBOX_CITY_CHANGE:
      return {
        ...state,
        leadContractBillingPostboxCity: action.payload,
      };

	case LEAD_CONTRACT_BILLING_ADDRESS_SAVED:
		return {
			...state,
			leadContractBillingAddressSavedDialogState: true
		}

    case CHANGE_CONTACT_HISTORY_DETAIL_OBJECT_ID:
      return {
        ...state,
        contactHistoryDetailObjectId: action.payload,
      };

    case CHANGE_CONTACT_HISTORY_DETAIL_REMARKS:
      return {
        ...state,
        contactHistoryDetailRemarks: action.payload,
      };

    case CHANGE_CONTACT_HISTORY_DETAIL_TOPIC:
      return {
        ...state,
        contactHistoryDetailTopic: action.payload,
      };

    case CONTACT_HISTORY_DETAIL_DOCUMENT_CHANGE:
      return {
        ...state,
        contactHistoryDetailDocument: action.payload,
      };

    case CONTACT_HISTORY_DETAIL_DOCUMENT_DELETE:
      return {
        ...state,
        contactHistoryDetailDocument: "",
      };

    case CLOSE_CONTACT_HISTORY_DIALOG:
      return {
        ...state,
        contactHistoryDialogState: false,
        activeContactHistory: 0,
        contactHistoryError: "",
        contactHistoryDetailObjectId: -1,
        contactHistoryDetailRemarks: "",
        contactHistoryDetailTopic: "",
        contactHistoryDetailDocument: "",
      };

    case EDIT_CONTACT_HISTORY:
      return {
        ...state,
        contactHistoryDialogState: true,
        contactHistoryError: "",
        contactHistoryDialogTitle: action.payload.title,
        activeContactHistory: action.payload.id,
        contactHistoryDetailObjectId: action.payload.objectId,
        contactHistoryDetailRemarks: action.payload.remarks,
        contactHistoryDetailTopic: action.payload.topic,
        contactHistoryDetailDocument: action.payload.document,
        contactHistoryIsUser: action.payload.isUser === "1" ? true : false,
      };

    case GET_CONTACT_HISTORIES:
      return {
        ...state,
        contactHistories: action.payload,
      };

    case SET_CONTACT_HISTORY_ERROR:
      return {
        ...state,
        contactHistoryError: action.payload,
      };

    case CLOSE_DELETE_LEAD_CONTACT_HISTORY_CONFIRM:
      return {
        ...state,
        deleteLeadContactHistoryConfirmState: false,
        deleteLeadContactHistoryConfirmName: "",
        activeContactHistory: -1,
      };

    case DELETE_LEAD_CONTACT_HISTORY_CONFIRM:
      return {
        ...state,
        deleteLeadContactHistoryConfirmName: action.payload.entityName,
        deleteLeadContactHistoryConfirmState: true,
        activeContactHistory: action.payload.id,
      };

    case CLOSE_LEAD_CONTRACTS_DIALOG:
      return {
        ...state,
        activeLeadContractTab: 1,
		leadContractsDialogError: "",
        leadContractsDialogState: false,
        leadContractDivisionsId: -1,
        leadContractProducts: [{ id: -1, value: "Keine Sparte gewählt" }],
        leadContractProductsId: -1,
        leadContractValidFrom: "Kein Produkt gewählt",
        leadContractValidTo: "Kein Produkt gewählt",
		isLeadContractTransitionOpen: 0,
		isLeadContractTransitionInitialized: 0,
		isLeadContractInitialTransition: 0,
		isLeadContractTransition: 0,
		leadContractTransitionDate: '',
		isLeadContractTransitionTenPercent: 0,
		isLeadContractTransitionTenPercentOpen: 0,
		leadContractTransitionTenPercentDate: '',
        pots: [{ id: 0, value: "Kein Produkt gewählt" }],
        leadContractPotsId: 0,
        leadContractIsClimatePlus: false,
        leadContractIsBio5: false,
        leadContractBio5StartDate: "",
        leadContractIsBio10: false,
        leadConractBio10StartDate: "",
        leadContractIsCo2: false,
        leadContractPeriodConsumption: "",
        leadCounterNumberCurrent: "",
        leadCounterMediumVoltage: false,
        leadCounterBhkw: false,
        leadCounterContractAccount: "",
        leadCounterIntendedUse: "*",
        leadCounterChangeSupplyStart: "",
        leadCounterChangeReasonsId: -1,
        leadCounterFormerSupplierId: -1,
        leadCounterNetworksId: -1,
        leadCounterValue: "",
        leadCounterLoadProfilesId: -1,
        leadCounterInstallmentCycle: "",
        leadCounterLocationStreet: "",
        leadCounterLocationHouseNumber: "",
        leadCounterLocationPostalCode: "",
        leadCounterLocationCity: "",
        leadCountryLocationAdditional: "",
        leadCounterLocationCountry: "DE",
        leadCounterLocationDistrict: "",
        leadCounterLocationMarketLocation: "",
        leadCounterLocationMeasureLocation: "",
        leadCounterAccountNo: "",
        leadCounterAccountOwner: "",
        leadCounterBankCode: "",
        leadCounterIban: "",
      };

    case GET_CONTRACTS:
      return {
        ...state,
        leadContracts: action.payload,
      };

    case GET_LEADS:
      return {
        ...state,
        leads: action.payload,
      };

    case LEAD_COUNTER_DOUBLINGS_LIST:
      return {
        ...state,
        leadCounterDoublingsList: action.payload,
        leadDoubleCounterDialogState: true,
      };

    case LEAD_DOUBLE_COUNTER_DIALOG_CLOSE:
      return {
        ...state,
        leadDoubleCounterDialogState: action.payload,
        leadCounterDoublingsList: [],
      };

	case CLOSE_LEAD_CONTRACT_BILLING_ADDRESS_SAVED_DIALOG:
		return {
			...state,
			leadContractBillingAddressSavedDialogState: false
		}

	case CLOSE_LEAD_CONTRACT_BILLING_ADDRESS_PASTE_DIALOG:
		return {
			...state,
			leadContractBillingAddressPasteDialogState: false
		}

	case LEAD_CONTRACT_PASTE_BILLING_ADDRESS:
		return {
			...state,
			leadContractBillingAddressPasteDialogState: true,
			leadContractBillingAddresses: action.payload
		}

	case LEAD_CONTRACT_DELETE_BILLING_ADDRESS:
		return {
			...state,
			leadContractBillingAddresses: action.payload
		}

	case LEAD_CONTRACT_TAKEOVER_BILLING_ADDRESS:
		return {
			...state,
			leadContractCustomerTypesId: action.payload.customerTypesId,
			leadContractSalutationsId: action.payload.salutationsId,
			leadContractBillingName1: action.payload.billingName1,
			leadContractBillingName2: action.payload.billingName2,
			leadContractBillingName3: action.payload.billingName3,
			leadContractBillingStreet: action.payload.billingStreet,
			leadContractBillingHouseNumber: action.payload.billingHouseNumber,
			leadContractBillingPostalCode: action.payload.billingPostalCode,
			leadContractBillingCity: action.payload.billingCity,
			leadContractBillingDistrict: action.payload.billingDistrict,
			leadContractBillingCountry: action.payload.billingCountry,
			leadContractBillingAdditional: action.payload.billingAdditional,
			leadContractBillingPostboxNo: action.payload.billingPostboxNo,
			leadContractBillingPostboxPostalCode: action.payload.billingPostboxPostalCode,
			leadContractBillingPostboxCity: action.payload.billingPostboxCity,
			leadContractBillingAddressPasteDialogState: false,
		}

    //  Default Path
    default:
      return state;
  }
};
