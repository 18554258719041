/**
 *      File:       ./src/context/administration/energy/regulationZonesReducer.js
 * 
 *      Subject:    Regulation Zones' State Reducer
 */
import {
	GET_ENTITIES,
	SET_ACTIVE_ENTITY,
	SET_DELETE_ENTITY,
	SET_DELETE_MODAL_ENTITY_NAME,
	SET_EDIT_DIALOG_TITLE,
	SET_EDIT_ENTITY,
	SET_ENTITY_DIALOG_FIELDS,
	SET_ERROR,

	ZONE_NAME_CHANGE,
	ZONE_SHORTCUT_CHANGE

} from '../../types';

export default (state, action) => {

	switch (action.type) {
		//  General section

		case GET_ENTITIES:
			return {
				...state,
				entities: action.payload
			}

		case SET_ACTIVE_ENTITY:
			return {
				...state,
				editDialogZoneName: action.payload.zone_name,
				editDialogZoneShortcut: action.payload.zone_shortcut
			}

		case SET_DELETE_ENTITY:
			return {
				...state,
				activeEntity: action.payload.id,
				deleteModalState: action.payload.deleteModalState
			}

		case SET_DELETE_MODAL_ENTITY_NAME:
			return {
				...state,
				deleteModalEntityName: action.payload
			}

		case SET_EDIT_DIALOG_TITLE:
			return {
				...state,
				editDialogTitle: action.payload
			}

		case SET_EDIT_ENTITY:
			return {
				...state,
				activeEntity: action.payload.id,
				editState: action.payload.editState
			}

		case SET_ENTITY_DIALOG_FIELDS:
			return {
				...state,
				editDialogFields: action.payload
			}

		case SET_ERROR:
			return {
				...state,
				editDialogError: action.payload
			}


		//  Individual Section

		case ZONE_NAME_CHANGE:
			return {
				...state,
				editDialogZoneName: action.payload
			}

		case ZONE_SHORTCUT_CHANGE:
			return {
				...state,
				editDialogZoneShortcut: action.payload
			}


		//  Default Path
		default:
			return state;
	}
}