/**
 *      File:       ./src/context/administration/energy/suppliersReducer.js
 * 
 *      Subject:    Suppliers' State Reducer
 */
import {
    GET_ENTITIES,
    SET_ACTIVE_ENTITY,
    SET_DELETE_ENTITY,
    SET_DELETE_MODAL_ENTITY_NAME,
    SET_EDIT_DIALOG_TITLE,
    SET_EDIT_ENTITY,
    SET_ENTITY_DIALOG_FIELDS,
    SET_ERROR,

    SET_SUPPLIER_FOREIGNS,
    SUPPLIER_SHORTCUT_CHANGE,
    SUPPLIER_NAME_CHANGE,
    NAME_ADDITION_1_CHANGE,
    NAME_ADDITION_2_CHANGE,
    DIVISIONS_ID_CHANGE,
    STREET_CHANGE,
    // HOUSE_NUMBER_CHANGE,
    POSTAL_CODE_CHANGE,
    CITY_CHANGE

} from '../../types';

export default(state, action) => {

    switch(action.type)
    {
        //  General section

        case GET_ENTITIES:
            return {
                ...state,
                entities: action.payload
            }

        case SET_ACTIVE_ENTITY:
            return {
                ...state,
                editDialogSupplierShortcut: action.payload.supplier_shortcut,
                editDialogSupplierName: action.payload.supplier_name,
                editDialogNameAddition1: action.payload.name_addition_1,
                editDialogNameAddition2: action.payload.name_addition_2,
                editDialogDivisionsId: action.payload.divisions_id,
                editDialogStreet: action.payload.street,
                // editDialogHouseNumber: action.payload.house_number,
                editDialogPostalCode: action.payload.postal_code,
                editDialogCity: action.payload.city
            }

        case SET_DELETE_ENTITY:
            return {
                ...state,
                activeEntity: action.payload.id,
                deleteModalState: action.payload.deleteModalState
            }

        case SET_DELETE_MODAL_ENTITY_NAME:
            return {
                ...state,
                deleteModalEntityName: action.payload
            }

        case SET_EDIT_DIALOG_TITLE:
            return {
                ...state,
                editDialogTitle: action.payload
            }

        case SET_EDIT_ENTITY:
            return {
                ...state,
                activeEntity: action.payload.id,
                editState: action.payload.editState
            }

        case SET_ENTITY_DIALOG_FIELDS:
            return {
                ...state,
                editDialogFields: action.payload
            }

        case SET_ERROR:
            return {
                ...state,
                editDialogError: action.payload
            }


        //  Individual Section

        case SET_SUPPLIER_FOREIGNS:
            return {
                ...state,
                divisions: action.payload.divisions,
            }

        case SUPPLIER_SHORTCUT_CHANGE:
            return {
                ...state,
                editDialogSupplierShortcut: action.payload
            }

        case SUPPLIER_NAME_CHANGE:
            return {
                ...state,
                editDialogSupplierName: action.payload
            }

        case NAME_ADDITION_1_CHANGE:
            return {
                ...state,
                editDialogNameAddition1: action.payload
            }

        case NAME_ADDITION_2_CHANGE:
            return {
                ...state,
                editDialogNameAddition2: action.payload
            }

        case DIVISIONS_ID_CHANGE:
            return {
                ...state,
                editDialogDivisionsId: action.payload,
            }

        case STREET_CHANGE:
            return {
                ...state,
                editDialogStreet: action.payload
            }

        // case HOUSE_NUMBER_CHANGE:
        //     return {
        //         ...state,
        //         editDialogHouseNumber: action.payload
        //     }

        case POSTAL_CODE_CHANGE:
            return {
                ...state,
                editDialogPostalCode: action.payload
            }

        case CITY_CHANGE:
            return {
                ...state,
                editDialogCity: action.payload
            }

            
        //  Default Path
        default:
            return state;
    }
}