/**
 *      File:       ./src/context/exports/existingOnesContext .js
 * 
 *      Subject:    existing cutomers' export context
 */
import { createContext } from "react";

const existingOnesContext  = createContext();

export default existingOnesContext ;
