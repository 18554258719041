/**
 *      File:       ./src/context/leads/leadsContext.js
 * 
 *      Subject:    Lead's context
 */
import { createContext } from "react";

const leadsContext = createContext();

export default leadsContext;
