/**
 * 		File:		./src/context/leads/LeadsState.js
 *
 * 		Subject:	Lead's state
 */
import React, { useReducer } from "react";
import axios from "axios";
import LeadsContext from "./leadsContext";
import leadsReducer from "./leadsReducer";

import {
  //	LEADS
  CLOSE_DELETE_LEAD_CONFIRM,
  CLOSE_LEAD_DETAIL_DIALOG,
  DELETE_LEAD_CONFIRM,
  EDIT_LEAD,
  GET_LEADS,
  SET_LEAD_DETAIL_DIALOG_ACTIVE_TAB,
  SET_FOREIGNS,
  SET_ERR_MSG,

  //	LEADS ADDRESS COMMON
  ABC_ID_CHANGE,
  BUSINESS_PARTNER_TYPES_ID_CHANGE,
  FOLLOW_UP_DATE_CHANGE,
  FOLLOW_UP_DONE_CHANGE,
  IS_PORTAL_ACCESS_ADMINISTRATION_CHANGE,
  SET_ACTIVE_ADDRESS_TAB,
  SUPERVISORS_ID_CHANGE,
  SUPERVISORS_PV_ID_CHANGE,

  //	LEAD OBJECT LIST
  LEAD_OBJECT_LIST_DIALOG,
  LEAD_OBJECT_LIST_IS_FINISHED,

  //	LEADS ADDRESS ADDRESS
  ADDRESS_ADDITION_CHANGE,
  CITY_CHANGE,
  COUNTRY_CHANGE,
  DISTRICT_CHANGE,
  HOUSE_NUMBER_CHANGE,
  NAME_1_CHANGE,
  NAME_2_CHANGE,
  NAME_3_CHANGE,
  LEGAL_FORMS_ID_CHANGE,
  POSTAL_CODE_CHANGE,
  SALUTATIONS_ID_CHANGE,
  STREET_CHANGE,

  //	LEAD ADDRESS CONTACT
  FAX_CHANGE,
  PHONE_CHANGE,
  EMAIL_CHANGE,
  URL_CHANGE,

  //	LEAD ADDRESS POSTBOX
  POSTBOX_CHANGE,
  POSTBOX_CITY_CHANGE,
  POSTBOX_POSTAL_CODE_CHANGE,

  //	LEAD ADDRESS STANDARD
  ADMINISTRATIONS_1_ID_CHANGE,
  ADMINISTRATIONS_2_ID_CHANGE,
  ADMINISTRATIONS_3_ID_CHANGE,
  COMMUNITY_CODE_CHANGE,
  GET_ADMINISTRATIONS_1,
  GET_ADMINISTRATIONS_2,
  GET_ADMINISTRATIONS_3,
  OWNER_ID_CHANGE,

  //	LEAD CONTACT PERSONS
  CLOSE_CONTACT_PERSON_DIALOG,
  CLOSE_DELETE,
  CONTACT_PERSON_DIVISION_CHANGE,
  CONTACT_PERSON_EMAIL_CHANGE,
  CONTACT_PERSON_FAX_CHANGE,
  CONTACT_PERSON_FIRSTNAME_CHANGE,
  CONTACT_PERSON_FUNCTION_CHANGE,
  CONTACT_PERSON_IS_PORTAL_ACCESS_CHANGE,
  CONTACT_PERSON_LASTNAME_CHANGE,
  CONTACT_PERSON_MOBILE_CHANGE,
  CONTACT_PERSON_PHONE_CHANGE,
  CONTACT_PERSON_PORTAL_ACCESS_VALID_TO_CHANGE,
  CONTACT_PERSON_SALUTATIONS_ID_CHANGE,
  CONTACT_PERSON_TITLE_CHANGE,
  GET_CONTACT_PERSONS,
  DELETE_CONTACT_PERSON,
  DELETE_CONTACT_PERSON_CONFIRM,
  EDIT_CONTACT_PERSON,
  SET_ACTIVE_CONTACT_PERSON,
  SET_CONTACT_PERSON_ERROR,

  //	LEAD BANK
  ACCOUNT_NO_CHANGE,
  ACCOUNT_OWNER_CHANGE,
  BANK_CODE_CHANGE,
  IBAN_CHANGE,

  //	LEAD PRODUCTS
  ENERGY_CAMPAIGNS_ID_CHANGE,
  IS_LEAD_ENERGY_TRANSITION_CHANGE,
  LEAD_ENERGY_TRANSITION_DATE_CHANGE,
  IS_LEAD_ENERGY_TRANSITION_TEN_PERCENT_CHANGE,
  LEAD_ENERGY_TRANSITION_TEN_PERCENT_DATE_CHANGE,
  ENERGY_POTS_ID_CHANGE,
  GAS_CAMPAIGNS_ID_CHANGE,
  IS_LEAD_GAS_TRANSITION_CHANGE,
  LEAD_GAS_TRANSITION_DATE_CHANGE,
  IS_LEAD_GAS_TRANSITION_TEN_PERCENT_CHANGE,
  LEAD_GAS_TRANSITION_TEN_PERCENT_DATE_CHANGE,
  GAS_POTS_ID_CHANGE,
  SET_ACTIVE_PRODUCT_TAB,

  //	LEAD CONTRACTS
  ACCEPT_OFFER,
  CLOSE_DELETE_CONTRACT,
  CLOSE_LEAD_CONTRACTS_DIALOG,
  CLOSE_LEAD_CONTRACT_OFFER_ACCEPT_DIALOG,
  CONFIRM_ACCEPT_OFFER,
  DELETE_CONTRACT_CONFIRM,
  EDIT_LEAD_CONTRACT,
  GET_CONTRACTS,
  SET_ACTIVE_LEAD_CONTRACT_TAB,
  SET_CONTRACT_ERR_MSG,
  UPDATE_CONTRACT_EXTERNALS,

  //	LEAD CONTRACT CONTRACT
  LEAD_CONTRACT_DIVISIONS_ID_CHANGE,
  LEAD_CONTRACT_IS_BIO5_CHANGE,
  LEAD_CONTRACT_IS_BIO10_CHANGE,
  LEAD_CONTRACT_BIO5_START_DATE_CHANGE,
  LEAD_CONTRACT_BIO10_START_DATE_CHANGE,
  LEAD_CONTRACT_IS_CLIMATE_PLUS_CHANGE,
  LEAD_CONTRACT_IS_CO2_CHANGE,
  LEAD_CONTRACT_PERIOD_CONSUMPTION_CHANGE,
  LEAD_CONTRACT_POTS_ID_CHANGE,
  LEAD_CONTRACT_PRODUCTS_ID_CHANGE,
  LEAD_CONTRACT_VALID_FROM_CHANGE,
  LEAD_CONTRACT_VALID_TO_CHANGE,
  IS_LEAD_CONTRACT_TRANSITION_CHANGE,
  LEAD_CONTRACT_TRANSITION_DATE_CHANGE,
  IS_LEAD_CONTRACT_TRANSITION_TEN_PERCENT_CHANGE,
  LEAD_CONTRACT_TRANSITION_TEN_PERCENT_DATE_CHANGE,

  //	LEAD CONTRACT COUNTER
  LEAD_COUNTER_BHKW,
  LEAD_COUNTER_CONTRACT_ACCOUNT_CHANGE,
  LEAD_COUNTER_ID_CHANGE,
  LEAD_COUNTER_INTENDED_USE_CHANGE,
  LEAD_COUNTER_MEDIUM_VOLTAGE_CHANGE,
  LEAD_COUNTER_NUMBER_CURRENT_CHANGE,
  SET_ACTIVE_CONTRACT_COUNTER_TAB,

  //	LEAD CONTRACT COUNTER CHANGE
  LEAD_COUNTER_CHANGE_REASON_ID_CHANGE,
  LEAD_COUNTER_CHANGE_SUPPLY_START_CHANGE,
  LEAD_COUNTER_FORMER_SUPPLIER_ID_CHANGE,
  LEAD_COUNTER_INSTALLMENT_CYCLE_CHANGE,
  LEAD_COUNTER_LOAD_PROFILES_ID_CHANGE,
  LEAD_COUNTER_NETWORKS_ID_CHANGE,
  LEAD_COUNTER_VALUE_CHANGE,

  //	LEAD CONTRACT COUNTER LOCATION
  LEAD_COUNTRY_LOCATION_ADDITIONAL_CHANGE,
  LEAD_COUNTER_LOCATION_CITY_CHANGE,
  LEAD_COUNTER_LOCATION_COUNTRY_CHANGE,
  LEAD_COUNTER_LOCATION_DISTRICT_CHANGE,
  LEAD_COUNTER_LOCATION_HOUSE_NUMBER_CHANGE,
  LEAD_COUNTER_LOCATION_MARKET_LOCATION_CHANGE,
  LEAD_COUNTER_LOCATION_MEASURE_LOCATION_CHANGE,
  LEAD_COUNTER_LOCATION_POSTAL_CODE_CHANGE,
  LEAD_COUNTER_LOCATION_STREET_CHANGE,

  //	LEAD CONTRACT COUNTER BANK
  LEAD_COUNTER_ACCOUNT_NO_CHANGE,
  LEAD_COUNTER_ACCOUNT_OWNER_CHANGE,
  LEAD_COUNTER_BANK_CODE_CHANGE,
  LEAD_COUNTER_IBAN_CHANGE,

  //	LEAD CONTRACT BILLING
  LEAD_CONTRACT_BILLING_ADDITIONAL_CHANGE,
  LEAD_CONTRACT_BILLING_CITY_CHANGE,
  LEAD_CONTRACT_BILLING_COUNTRY_CHANGE,
  LEAD_CONTRACT_BILLING_DISTRICT_CHANGE,
  LEAD_CONTRACT_BILLING_HOUSE_NUMBER_CHANGE,
  LEAD_CONTRACT_BILLING_NAME1_CHANGE,
  LEAD_CONTRACT_BILLING_NAME2_CHANGE,
  LEAD_CONTRACT_BILLING_NAME3_CHANGE,
  LEAD_CONTRACT_BILLING_POSTAL_CODE_CHANGE,
  LEAD_CONTRACT_BILLING_POSTBOX_CITY_CHANGE,
  LEAD_CONTRACT_BILLING_POSTBOX_NO_CHANGE,
  LEAD_CONTRACT_BILLING_POSTBOX_POSTAL_CODE_CHANGE,
  LEAD_CONTRACT_BILLING_STREET_CHANGE,
  LEAD_CONTRACT_CUSTOMER_TYPES_ID_CHANGE,
  LEAD_CONTRACT_SALUTATIONS_ID_CHANGE,
  LEAD_CONTRACT_BILLING_ADDRESS_SAVED,
  LEAD_CONTRACT_PASTE_BILLING_ADDRESS,
  CLOSE_LEAD_CONTRACT_BILLING_ADDRESS_SAVED_DIALOG,
  CLOSE_LEAD_CONTRACT_BILLING_ADDRESS_PASTE_DIALOG,
  LEAD_CONTRACT_DELETE_BILLING_ADDRESS,
  LEAD_CONTRACT_TAKEOVER_BILLING_ADDRESS,

  //	LEAD CONTACT HISTORY
  CHANGE_CONTACT_HISTORY_DETAIL_OBJECT_ID,
  CHANGE_CONTACT_HISTORY_DETAIL_REMARKS,
  CHANGE_CONTACT_HISTORY_DETAIL_TOPIC,
  CLOSE_CONTACT_HISTORY_DIALOG,
  CLOSE_DELETE_LEAD_CONTACT_HISTORY_CONFIRM,
  CONTACT_HISTORY_DETAIL_DOCUMENT_CHANGE,
  CONTACT_HISTORY_DETAIL_DOCUMENT_DELETE,
  DELETE_LEAD_CONTACT_HISTORY_CONFIRM,
  EDIT_CONTACT_HISTORY,
  GET_CONTACT_HISTORIES,
  SET_CONTACT_HISTORY_ERROR,

  //	COUNTER DOUBLINGS
  LEAD_COUNTER_DOUBLINGS_LIST,
  LEAD_DOUBLE_COUNTER_DIALOG_CLOSE,
} from "../types";

const LeadsState = (props) => {
  const initialState = {
    //  LEADS
    activeLeadId: -1,
    deleteLeadConfirmName: "",
    deleteLeadConfirmState: false,
    leadDetailDialogActiveTab: 1,
    leadDetailDialogError: "",
    leadDetailDialogState: false,
    leadDetailDialogTitle: "",
    leads: [],

    //  LEADS ADDRESS
    abc: [],
    abcId: -1,
    activeAddressTab: 1,
    businessPartnerTypes: [],
    businessPartnerTypesId: -1,
	copiedCustomer: "",
    followUpDate: "",
    followUpDone: false,
    isPortalAccessAdministration: false,
    supervisors: [],
    supervisorsId: -1,
	supervisorsPv: [],
	supervisorsPvId: -1,

    //	LEAD OBJECT LIST
    leadObjectListDialogState: false,
    leadObjectListIsFinished: false,
	leadObjectListIsEmpty: false,

    //  LEADS ADDRESS ADDRESS
    addressAddition: "",
    city: "",
    country: "DE",
    district: "",
    houseNumber: "",
    name1: "",
    name2: "",
    name3: "",
	legalFormsId: -1,
	legalForms: [],
    postalCode: "",
    salutations: [],
    salutationsId: -1,
    street: "",

    //  LEADS ADDRESS CONTACT
    email: "",
    fax: "",
    phone: "",
    url: "",

    //  LEAD ADDRESS POSTBOX
    postbox: "",
    postboxCity: "",
    postboxPostalCode: "",

    //  LEAD ADDRESS STANDARD
    administrations1: [],
    administrations1Id: -1,
    administrations2: [],
    administrations2Id: -1,
    administrations3: [],
    administrations3Id: -1,
    communityCode: "",
    ownerId: -1,
    yesNos: [],

    //  LEAD CONTACT PERSONS
    activeContactPerson: 0,
    contactPersons: [],
    deleteModalEntityName: "",
    deleteModalState: false,
    deleteModalTitle: "",

    //  LEAD CONTACT PERSONS DETAILS
    contactPersonDetailsDialogError: "",
    contactPersonDetailsDialogState: false,
    contactPersonDetailsDialogTitle: "",
    contactPersonDivision: "",
    contactPersonEmail: "",
    contactPersonFax: "",
    contactPersonFirstname: "",
    contactPersonFunction: "",
    contactPersonIsPortalAccess: false,
    contactPersonLastname: "",
    contactPersonMobile: "",
    contactPersonPhone: "",
    contactPersonPortalAccessValidTo: "",
    contactPersonSalutationsId: -1,
    contactPersonTitle: "",

    //  LEAD BANK ACCOUNT
    accountNo: "",
    accountOwner: "",
    bankCode: "",
    iban: "",

    //  LEAD PRODUCTS
    activeProductTab: 1,
    isOfferAccepted: false,

    //  LEAD PRODUCTS OFFERED
    energyCampaigns: [],
    energyCampaignsId: 0,
	isTransition: 0,
	bIsLeadEnergyTransitionChange: false,
	isLeadEnergyTransitionOpen: 0,
	isLeadEnergyTransitionInitialized: 0, 
	isLeadEnergyInitialTransition: 0,
	isLeadEnergyTransition: 0,
	leadEnergyTransitionDate: '',
	bIsLeadEnergyTransitionTenPercentChange: false,
	isLeadEnergyTransitionTenPercentOpen: 0,
	isLeadEnergyTransitionTenPercent: 0,
	leadEnergyTransitionTenPercentDate: '',
    energyPots: [],
    energyPotsId: 0,
    gasCampaigns: [],
    gasCampaignsId: 0,
	bIsLeadGasTransitionChange: false,
	isLeadGasTransitionOpen: 0,
	isLeadGasTransitionInitialized: 0,
	isLeadGasInitialTransition: 0,
	isLeadGasTransition: 0,
	leadGasTransitionDate: '',
	bIsLeadGasTransitionTenPercentChange: false,
	isLeadGasTransitionTenPercentOpen: 0,
	isLeadGasTransitionTenPercent: 0,
	leadGasTransitionTenPercentDate: '',
    gasPots: [],
    gasPotsId: 0,

    //  LEAD PRODUCTS HISTORY
    historyContacts: [],

    //  LEAD CONTRACTS
    activeLeadContract: 0,
    activeLeadContractTab: 1,
    leadContracts: [],
	leadContractsDialogError: "",
    leadContractsDialogState: false,
    leadContractsDialogTitle: "",
    leadContractOfferAcceptDialogState: false,

    //  LEAD CONTRACT CONTRACT
    divisions: [],
    leadContractDivisionsId: -1,
    leadContractIsBio5: false,
    leadContractBio5StartDate: "",
    leadContractIsBio10: false,
    leadContractBio10StartDate: "",
    leadContractIsClimatePlus: false,
    leadContractIsCo2: false,
    leadContractPeriodConsumption: "",
    leadContractPotsId: -1,
    leadContractProducts: [{ id: -1, value: "Keine Sparte gewählt" }],
    leadContractProductsId: -1,
    leadContractValidFrom: "Kein Produkt gewählt",
    leadContractValidTo: "Kein Produkt gewählt",
	isLeadContractTransitionOpen: 0,
	isLeadContractTransitionInitialized: 0,
	isLeadContractInitialTransition: 0,
	isLeadContractTransition: 0,
	leadContractTransitionDate: '',
	isLeadContractTransitionTenPercentOpen: 0,
	isLeadContractTransitionTenPercent: 0,
	leadContractTransitionTenPercentDate: '',
    isPots: 0,
    isProduct: 0,
    isValidFrom: 0,
    isValidTo: 0,
    isOptionen: 0,
    divisionsId: 0,
    pots: [{ id: -1, value: "Kein Produkt gewählt" }],

    //  LEAD CONTRACT COUNTER
    activeContractCounterTab: 1,
    leadCounterBhkw: false,
    leadCounterContractAccount: "",
    leadCounterId: -1,
    leadCounterIntendedUse: "*",
    leadCounterMediumVoltage: false,
    leadCounterNumberCurrent: "",
    leadCounterNumberDoubling: 0,

    //  LEAD CONTRACT COUNTER CHANGE
    changeReasons: [],
    leadCounterChangeReasonsId: -1,
    leadCounterChangeSupplyStart: "",
    leadCounterFormerSupplierId: -1,
    leadCounterInstallmentCycle: "",
    leadCounterLoadProfilesId: -1,
    leadCountryLocationAdditional: "",
    leadCounterLocationCity: "",
    leadCounterLocationCountry: "DE",
    leadCounterLocationDistrict: "",
    leadCounterLocationHouseNumber: "",
    leadCounterLocationMarketLocation: "",
    leadCounterLocationMeasureLocation: "",
    leadCounterLocationPostalCode: "",
    leadCounterLocationStreet: "",
    leadCounterNetworksId: -1,
    leadCounterValue: "",
    loadProfiles: [],
    networks: [],
    suppliers: [],

    //  LEAD COUNTER LOCATION BANK ACCOUNT
    leadCounterAccountNo: "",
    leadCounterAccountOwner: "",
    leadCounterBankCode: "",
    leadCounterIban: "",

    //  LEAD CONTRACT BILLING
    customerTypes: [],
    leadContractBillingAdditional: "",
    leadContractBillingCity: "",
    leadContractBillingCountry: "DE",
    leadContractBillingDistrict: "",
    leadContractBillingName1: "",
    leadContractBillingName2: "",
    leadContractBillingName3: "",
    leadContractBillingStreet: "",
    leadContractBillingHouseNumber: "",
    leadContractBillingPostalCode: "",
    leadContractBillingPostboxNo: "",
    leadContractBillingPostboxPostalCode: "",
    leadContractBillingPostboxCity: "",
    leadContractCustomerTypesId: -1,
    leadContractSalutationsId: -1,
	leadContractBillingAddressSavedDialogState: false,
	leadContractBillingAddressPasteDialogState: false,

    //	LEAD CONTRACT DELETE
    deleteContractName: "",
    deleteContractState: false,

    //  LEAD CONTACT HISTORY
    activeContactHistory: -1,
    contactHistories: [],

    //  LEAD CONTACT HISTORY DETAILS
    contactHistoryDetailDocument: "",
    contactHistoryDetailObjectId: -1,
    contactHistoryDetailRemarks: "",
    contactHistoryDetailTopic: "",
    contactHistoryDialogState: false,
    contactHistoryDialogTitle: "",
    contactHistoryError: "",
    contactHistoryIsUser: true,
    leadObjects: [],

    //	LEAD CONTACT HISTORY DELETE
    deleteLeadContactHistoryConfirmName: "",
    deleteLeadContactHistoryConfirmState: false,

    //	COUNTER DOUBLINGS
    leadCounterDoublingsList: [],
    leadDoubleCounterDialogState: false,

	leadContractBillingAddresses: [],
  };
  const [state, dispatch] = useReducer(leadsReducer, initialState);

  //-------------------------------------------
  //  LEADS GENERAL
  //-------------------------------------------

  //	CANCEL THE LEAD DETAIL DIALOG
  const closeLeadDetailDialog = () => {

	setErrMsg("", 1, 1);

    //	Delete the temp values
    const params = new URLSearchParams();
    params.append("jwt", sessionStorage.jwt);
    params.append("id", state.activeLeadId);

    let url = process.env.REACT_APP_API_URI + "/leads/clear_temps.php";
    let axiosConfig = {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    };

    axios
      .post(url, params, axiosConfig)
      .then((response) => {
        if (response.status === 200) {
          // console.log(response.data);

          dispatch({
            type: CLOSE_LEAD_DETAIL_DIALOG,
            payload: {
              dialogTitle: "",
            },
          });
        } else if (response.status === 401) {
          window.location.href = "#/logout";
        } else {
          console.log("Anderer Fehler");
        }
      })
      .catch(function (error) {
        if (error.response) {
          // The server answered with an error
          if (error.response.status === 401) {
            window.location.href = "#/logout";
          } else {
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          }
        } else if (error.request) {
          //  The request was made but the server didn't respond
          console.log(error.request);
        } else {
          //  Something else went wrong
          console.log("Error", error.message);
        }
      });
  };

  //	CONFIRM DIALOG FOR DELETING A LEAD
  const deleteLeadConfirm = (id) => {
    const params = new URLSearchParams();
    params.append("jwt", sessionStorage.jwt);
    params.append("id", id);

    let axiosConfig = {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    };

    let url = process.env.REACT_APP_API_URI + "/leads/get_lead.php";

    axios
      .post(url, params, axiosConfig)
      .then((response) => {
        if (response.status === 200) {
        //   console.log(response.data);

          const entityName =
            "Möchten Sie den Potenzial-Kunden mit der Id " +
            response.data.id +
            ' namens "' +
            response.data.lead.name1 +
            '" endgültig löschen?';

          dispatch({
            type: DELETE_LEAD_CONFIRM,
            payload: {
              entityName: entityName,
              id: id,
            },
          });
        } else if (response.status === 401) {
          window.location.href = "#/logout";
        } else {
          console.log("Anderer Fehler");
        }
      })
      .catch(function (error) {
        if (error.response) {
          // The server answered with an error
          if (error.response.status === 401) {
            window.location.href = "#/logout";
          } else {
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          }
        } else if (error.request) {
          //  The request was made but the server didn't respond
          console.log(error.request);
        } else {
          //  Something else went wrong
          console.log("Error", error.message);
        }
      });
  };

  //	EDIT A LEAD
  const editLead = (id) => {

    const lead = { id: id };
    let dialogTitle = "Neuen Potenzialkunden anlegen";

    const params = new URLSearchParams();
    params.append("jwt", sessionStorage.jwt);
    params.append("id", id);

    let axiosConfig = {
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
    };
    let url = process.env.REACT_APP_API_URI + "/leads/get_lead.php";

    axios
      .post(url, params, axiosConfig)
      .then((response) => {
        if (response.status === 200) {
        //   console.log(response.data);

          let followUpDate = "";
          if (
            response.data.lead.followUpDate !== "" &&
            response.data.lead.followUpDate !== null
          ) {
            followUpDate = new Date(response.data.lead.followUpDate);
          }

          let followUpDone = false;
          if (response.data.lead.followUpDone === "1") {
            followUpDone = true;
          }

		  let leadEnergyTransitionDate = "";
		  if(
			  response.data.lead.leadEnergyTransitionDate !== "" &&
			  response.data.lead.leadEnergyTransitionDate !== null
		  ) {
			  leadEnergyTransitionDate = new Date(response.data.lead.leadEnergyTransitionDate);
		  }

		  let leadEnergyTransitionTenPercentDate = "";
		  if(
			response.data.lead.leadEnergyTransitionTenPercentDate !== "" &&
			response.data.lead.leadEnergyTransitionTenPercentDate !== null
		  ) {
			leadEnergyTransitionTenPercentDate = new Date(response.data.lead.leadEnergyTransitionTenPercentDate);
		  }

		  let leadGasTransitionDate = "";
		  if(
			  response.data.lead.leadGasTransitionDate !== "" &&
			  response.data.lead.leadGasTransitionDate !== null
		  ) {
			  leadGasTransitionDate = new Date(response.data.lead.leadGasTransitionDate);
		  }

		  let leadGasTransitionTenPercentDate = "";
		  if(
			response.data.lead.leadGasTransitionTenPercentDate !== "" &&
			response.data.lead.leadGasTransitionTenPercentDate !== null
		  ) {
			leadGasTransitionTenPercentDate = new Date(response.data.lead.leadGasTransitionTenPercentDate);
		  }

          let isPortalAccessAdministration = false;
          if (response.data.lead.isPortalAccessAdministration === true) {
            isPortalAccessAdministration = true;
          }

          //  Get the data for the selected lead
          dialogTitle = "Potenzialkunde bearbeiten";
          lead.businessPartnerTypesId =
            response.data.lead.businessPartnerTypesId;
          lead.followUpDate = followUpDate;
          lead.followUpDone = followUpDone;
          lead.abcId = response.data.lead.abcId;
          lead.isPortalAccessAdministration = isPortalAccessAdministration;
          lead.supervisorsId = response.data.lead.supervisorsId;
		  lead.supervisorsPvId = response.data.lead.supervisorsPvId;
          lead.salutationsId = response.data.lead.salutationsId;
          lead.name1 = response.data.lead.name1;
          lead.name2 = response.data.lead.name2;
          lead.name3 = response.data.lead.name3;
		  lead.legalFormsId = response.data.lead.legalFormsId;
          lead.street = response.data.lead.street;
          lead.houseNumber = response.data.lead.houseNumber;
          lead.addressAddition = response.data.lead.addressAddition;
          lead.postalCode = response.data.lead.postalCode;
          lead.city = response.data.lead.city;
          lead.district = response.data.lead.district;
          lead.country = response.data.lead.country;
          lead.phone = response.data.lead.phone;
          lead.fax = response.data.lead.fax;
          lead.email = response.data.lead.email;
          lead.url = response.data.lead.url;
          lead.postbox = response.data.lead.postbox;
          lead.postboxPostalCode = response.data.lead.postboxPostalCode;
          lead.postboxCity = response.data.lead.postboxCity;
          lead.ownerId = response.data.lead.ownerId;
          lead.administrations1Id = response.data.lead.administrations1Id;
          lead.administrations2Id = response.data.lead.administrations2Id;
          lead.administrations3Id = response.data.lead.administrations3Id;
          lead.communityCode = response.data.lead.communityCode;
          lead.bankCode = response.data.lead.bankCode;
          lead.accountNo = response.data.lead.accountNo;
          lead.accountOwner = response.data.lead.accountOwner;
          lead.iban = response.data.lead.iban;
          lead.energyCampaignsId = response.data.lead.energyCampaignsId;
		  lead.isLeadEnergyTransitionInitialized = response.data.lead.isLeadEnergyTransitionInitialized;
		  lead.isLeadEnergyTransition = response.data.lead.isLeadEnergyTransition;
		  lead.leadEnergyTransitionDate = leadEnergyTransitionDate;
		  lead.isLeadEnergyTransitionTenPercent = response.data.lead.isLeadEnergyTransitionTenPercent;
		  lead.leadEnergyTransitionTenPercentDate = leadEnergyTransitionTenPercentDate;
          lead.energyPotsId = response.data.lead.energyPotsId;
          lead.gasCampaignsId = response.data.lead.gasCampaignsId;
		  lead.isLeadGasTransitionInitialized = response.data.lead.isLeadGasTransitionInitialized;
		  lead.isLeadGasTransition = response.data.lead.isLeadGasTransition;
		  lead.leadGasTransitionDate = leadGasTransitionDate;
		  lead.isLeadGasTransitionTenPercent = response.data.lead.isLeadGasTransitionTenPercent;
		  lead.leadGasTransitionTenPercentDate = leadGasTransitionTenPercentDate;
          lead.gasPotsId = response.data.lead.gasPotsId;
          lead.isOfferAccepted = response.data.lead.isOfferAccepted;


          dispatch({
            type: EDIT_LEAD,
            payload: {
              lead: lead,
              dialogTitle: dialogTitle,
              contactPersons: response.data.contactPersons,
              leadContracts: response.data.leadContracts,
			  copiedCustomer: response.data.copiedCustomer,
              leadObjects: response.data.leadObjects,
              administrations1: response.data.administrations1,
              administrations2: response.data.administrations2,
              administrations3: response.data.administrations3,
              energyCampaigns: response.data.energyCampaigns,
              gasCampaigns: response.data.gasCampaigns,
			  isTransition: response.data.isTransition,
            },
          });


          const params = new URLSearchParams();
          params.append("jwt", sessionStorage.jwt);
          params.append("leadsId", lead.id);

          let axiosConfig = {
            headers: { "Content-Type": "application/x-www-form-urlencoded" },
          };
          let url = process.env.REACT_APP_API_URI + "/leads/get_contracts.php";

          axios
            .post(url, params, axiosConfig)
            .then((response) => {
              if (response.status === 200) {
                //   console.log(response.data);

                dispatch({
                  type: GET_CONTRACTS,
                  payload: response.data.contracts,
                });
              } else if (response.status === 401) {
                window.location.href = "#/logout";
              } else {
                console.log("Anderer Fehler");
              }
            })
            .catch(function (error) {
              if (error.response) {
                // The server answered with an error
                if (error.response.status === 401) {
                  window.location.href = "#/logout";
                } else {
                  console.log(error.response.data);
                  console.log(error.response.status);
                  console.log(error.response.headers);
                }
              } else if (error.request) {
                //  The request was made but the server didn't respond
                console.log(error.request);
              } else {
                //  Something else went wrong
                console.log("Error", error.message);
              }
            });
        } else if (response.status === 401) {
          window.location.href = "#/logout";
        } else {
          console.log("Anderer Fehler");
        }
      })
      .catch(function (error) {
        if (error.response) {
          // The server answered with an error
          if (error.response.status === 401) {
            window.location.href = "#/logout";
          } else {
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          }
        } else if (error.request) {
          //  The request was made but the server didn't respond
          console.log(error.request);
        } else {
          //  Something else went wrong
          console.log("Error", error.message);
        }
      });
  };

  //	LOAD ALL LEADS
  const getLeads = () => {
    const params = new URLSearchParams();
    params.append("jwt", sessionStorage.jwt);
    params.append("leadsId", state.activeLeadId);

    let url = process.env.REACT_APP_API_URI + "/leads/get_leads.php";
    let axiosConfig = {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    };

    axios
      .post(url, params, axiosConfig)
      .then((response) => {
        if (response.status === 200) {
          // console.log(response.data);
          const leads = response.data.leads;
          for (var i in leads) {
            leads[i].id = isNaN(leads[i].id) ? 0 : Number.parseInt(leads[i].id);
            leads[i].postal_code =
              isNaN(leads[i].postal_code) || leads[i].postal_code === ""
                ? 0
                : leads[i].postal_code;
            // while(leads[i].postal_code.length < 5)
            // 	leads[i].postal_code = "0" + leads[i].postal_code;
          }

          dispatch({
            type: GET_LEADS,
            payload: leads,
          });
        } else if (response.status === 401) {
          window.location.href = "#/logout";
        } else {
          console.log("Anderer Fehler");
        }
      })
      .catch(function (error) {
        if (error.response) {
          // The server answered with an error
          if (error.response.status === 401) {
            window.location.href = "#/logout";
          } else {
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          }
        } else if (error.request) {
          //  The request was made but the server didn't respond
          console.log(error.request);
        } else {
          //  Something else went wrong
          console.log("Error", error.message);
        }
      });
  };

  //	SAVE A LEAD
  const saveLeadDetailDialog = () => {
	
    if (state.businessPartnerTypesId <= 0) {
      return setErrMsg("Bitte wählen Sie einen Typ aus.", 1, state.activeAddressTab);
    }

    if (state.salutationsId <= 0) {
      return setErrMsg("Bitte wählen Sie eine Anrede aus.", 1, 1);
    }

    if (state.name1 === "") {
      return setErrMsg("Bitte geben Sie einen Namensteil 1 ein.", 1, 1);
    }

    if (state.country === "") {
      return setErrMsg("Bitte geben Sie ein Land an.", 1, 1);
    }

    if (state.ownerId < 0) {
      return setErrMsg("Bitte wählen Sie den Gesellschafter-Status aus.", 1, 4);
    }

    if (state.administrations1Id <= 0) {
      return setErrMsg("Bitte wählen Sie eine Verwaltung 1 aus.", 1, 4);
    }

    if (state.abcId <= 0) {
      return setErrMsg("Bitte wählen Sie einen ABC-Wert aus.", 1, state.activeAddressTab);
    }

    if (state.supervisorsId <= 0) {
      return setErrMsg("Bitte wählen Sie einen Betreuer aus.", 1, state.activeAddressTab);
    }

	if (state.isLeadEnergyTransition === 1 && state.leadEnergyTransitionDate === '') {
		return setErrMsg("Bitte geben Sie das Datum für den Kundenübergang (Strom) an", 4, state.activeAddressTab);
	}

	if (state.isLeadEnergyTransitionTenPercent === 1 && state.leadEnergyTransitionTenPercentDate === '') {
		return setErrMsg("Bitte geben Sie das Datum für die 10%-Regel (Strom) an", 4, state.activeAddressTab);
	}

	if (state.isLeadGasTransition === 1 && state.leadGasTransitionDate === '') {
		return setErrMsg("Bitte geben Sie das Datum für den Kundenübergang (Erdgas) an", 4, state.activeAddressTab);
	}

	if (state.isLeadGasTransitionTenPercent === 1 && state.leadGasTransitionTenPercentDate === '') {
		return setErrMsg("Bitte geben Sie das Datum für die 10%-Regel (Erdgas) an", 4, state.activeAddressTab);
	}

    const params = new URLSearchParams();
    params.append("jwt", sessionStorage.jwt);
    params.append("table", "leads");
    params.append("id", state.activeLeadId);
    params.append("business_partner_types_id", state.businessPartnerTypesId);
    params.append("follow_up_done", state.followUpDone);
    params.append("abc_id", state.abcId);
    params.append("is_portal_access_administration",state.isPortalAccessAdministration);
    params.append("supervisors_id", state.supervisorsId);
	params.append("supervisors_pv_id", state.supervisorsPvId);
    params.append("salutations_id", state.salutationsId);
    params.append("name1", state.name1);
    params.append("name2", state.name2);
    params.append("name3", state.name3);
	params.append("legal_forms_id", state.legalFormsId);
    params.append("street", state.street);
    params.append("house_number", state.houseNumber);
    params.append("address_addition", state.addressAddition);
    params.append("postal_code", state.postalCode);
    params.append("city", state.city);
    params.append("district", state.district);
    params.append("country", state.country);
    params.append("phone", state.phone);
    params.append("fax", state.fax);
    params.append("email", state.email);
    params.append("url", state.url);
    params.append("postbox", state.postbox);
    params.append("postbox_postal_code", state.postboxPostalCode);
    params.append("postbox_city", state.postboxCity);
    params.append("is_owner", state.ownerId);
    params.append("administrations_1_id", state.administrations1Id);
    params.append("administrations_2_id", state.administrations2Id);
    params.append("administrations_3_id", state.administrations3Id);
    params.append("community_code", state.communityCode);
    params.append("blz", state.bankCode);
    params.append("account_number", state.accountNo);
    params.append("account_owner", state.accountOwner);
    params.append("iban", state.iban);
    params.append("energy_campaigns_id", state.energyCampaignsId);
    params.append("gas_campaigns_id", state.gasCampaignsId);

	const isLEIT = (state.isLeadEnergyTransitionInitialized > 0) ? 2 : 0;
	params.append("is_lead_energy_transition_initialized", isLEIT);
	const isLET = (state.isLeadEnergyTransition === 1 || state.isLeadEnergyTransition === true) ? true : false;
	params.append("is_lead_energy_transition", isLET);
	const isLetTenPercent = (state.isLeadEnergyTransitionTenPercent === 1 || state.isLeadEnergyTransitionTenPercent === true) ? true : false;
	params.append("is_lead_energy_transition_ten_percent", isLetTenPercent);

	const isLGIT = (state.isLeadGasTransitionInitialized > 0) ? 2 : 0;
	params.append("is_lead_gas_transition_initialized", isLGIT);
	const isLGT = (state.isLeadGasTransition === 1 || state.isLeadGasTransition === true) ? true : false;
	params.append("is_lead_gas_transition", isLGT);
	const isLgtTenPercent = (state.isLeadGasTransitionTenPercent === 1 || state.isLeadGasTransitionTenPercent === true) ? true : false;
	params.append("is_lead_gas_transition_ten_percent", isLgtTenPercent);

    let newFollowUpDate =
      state.followUpDate !== "" && state.followUpDate !== null
        ? new Date(state.followUpDate)
        : "";
    if (newFollowUpDate !== "") {
      const newDay = newFollowUpDate.getDate();
      const newMonth = newFollowUpDate.getMonth() + 1;
      const newYear = newFollowUpDate.getFullYear();
      newFollowUpDate = newYear + "-" + newMonth + "-" + newDay;
    }
    params.append("follow_up_date", newFollowUpDate);

	let newLeadEnergyTransitionDate =
		state.leadEnergyTransitionDate !== "" && state.leadEnergyTransitionDate !== null
			? new Date(state.leadEnergyTransitionDate)
			: "";

	if(newLeadEnergyTransitionDate !== "") {
		const newETDay = newLeadEnergyTransitionDate.getDate();
		const newETMonth = newLeadEnergyTransitionDate.getMonth() + 1;
		const newETYear = newLeadEnergyTransitionDate.getFullYear();
		newLeadEnergyTransitionDate = newETYear + '-' + newETMonth + '-' + newETDay;
	}
	params.append("lead_energy_transition_date", newLeadEnergyTransitionDate);

	let newLeadEnergyTransitionTenPercentDate = 
		state.leadEnergyTransitionTenPercentDate !== "" && state.leadEnergyTransitionTenPercentDate !== null
			? new Date(state.leadEnergyTransitionTenPercentDate)
			: "";

	if(newLeadEnergyTransitionTenPercentDate !== "") {
		const newETTPDay = newLeadEnergyTransitionTenPercentDate.getDate();
		const newETTPMonth = newLeadEnergyTransitionTenPercentDate.getMonth() + 1;
		const newETTPYear = newLeadEnergyTransitionTenPercentDate.getFullYear();
		newLeadEnergyTransitionTenPercentDate = newETTPYear + '-' + newETTPMonth + '-' + newETTPDay;
	}
	params.append("lead_energy_transition_ten_percent_date", newLeadEnergyTransitionTenPercentDate);

	let newLeadGasTransitionDate =
		state.leadGasTransitionDate !== "" && state.leadGasTransitionDate !== null
			? new Date(state.leadGasTransitionDate)
			: "";

	if(newLeadGasTransitionDate !== "") {
		const newGTDay = newLeadGasTransitionDate.getDate();
		const newGTMonth = newLeadGasTransitionDate.getMonth() + 1;
		const newGTYear = newLeadGasTransitionDate.getFullYear();
		newLeadGasTransitionDate = newGTYear + '-' + newGTMonth + '-' + newGTDay;
	}
	params.append("lead_gas_transition_date", newLeadGasTransitionDate);

	let newLeadGasTransitionTenPercentDate = 
		state.leadGasTransitionTenPercentDate !== "" && state.leadGasTransitionTenPercentDate !== null
			? new Date(state.leadGasTransitionTenPercentDate)
			: "";

	if(newLeadGasTransitionTenPercentDate !== "") {
		const newGTTPDay = newLeadGasTransitionTenPercentDate.getDate();
		const newGTTPMonth = newLeadGasTransitionTenPercentDate.getMonth() + 1;
		const newGTTPYear = newLeadGasTransitionTenPercentDate.getFullYear();
		newLeadGasTransitionTenPercentDate = newGTTPYear + '-' + newGTTPMonth + '-' + newGTTPDay;
	}
	params.append("lead_gas_transition_ten_percent_date", newLeadGasTransitionTenPercentDate);

    let axiosConfig = {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    };

    let url = process.env.REACT_APP_API_URI + "/leads/set_lead.php";

    axios
      .post(url, params, axiosConfig)
      .then((response) => {
        if (response.status === 200) {
        //   console.log(response.data);

          closeLeadDetailDialog();
          getLeads();
        } else if (response.status === 401) {
          window.location.href = "#/logout";
        } else {
          console.log("Anderer Fehler");
        }
      })
      .catch(function (error) {
        if (error.response) {
          // The server answered with an error
          if (error.response.status === 401) {
            window.location.href = "#/logout";
          } else {
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          }
        } else if (error.request) {
          //  The request was made but the server didn't respond
          console.log(error.request);
        } else {
          //  Something else went wrong
          console.log("Error", error.message);
        }
      });
  };

  const setErrMsg = (msg, tab, addressTab) => {
    dispatch({
      type: SET_ERR_MSG,
      payload: {
        leadDetailDialogError: msg,
        leadDetailDialogActiveTab: tab,
        activeAddressTab: addressTab,
      },
    });
  };

  const setContractErrMsg = (msg, tab) => {
	  dispatch({
		  type: SET_CONTRACT_ERR_MSG,
		  payload: {
			  leadContractsDialogError: msg,
			  activeLeadContractTab: tab,
		  }
	  });
  }

  //	CHANGE ACTIVE TAB OF LEAD'S DETAIL DIALOG
  const setLeadDetailDialogActiveTab = (tab) => {
    dispatch({
      type: SET_LEAD_DETAIL_DIALOG_ACTIVE_TAB,
      payload: tab,
    });
  };

  //	SET THE FOREIGN VALUES FOR THE LEAD DETAILS DIALOG
  const setForeigns = (
    businessPartnerTypes,
    abc,
    supervisors,
	supervisorsPv,
    salutations,
    yesNos,
    administrations1,
    administrations2,
    administrations3,
    energyCampaigns,
    gasCampaigns,
    divisions,
    changeReasons,
    suppliers,
    networks,
    loadProfiles,
	legalForms,
    customerTypes
  ) => {
    dispatch({
      type: SET_FOREIGNS,
      payload: {
        businessPartnerTypes: businessPartnerTypes,
        abc: abc,
        supervisors: supervisors,
		supervisorsPv: supervisorsPv,
        salutations: salutations,
        yesNos: yesNos,
        administrations1: administrations1,
        administrations2: administrations2,
        administrations3: administrations3,
        energyCampaigns: energyCampaigns,
        gasCampaigns: gasCampaigns,
        divisions: divisions,
        changeReasons: changeReasons,
        suppliers: suppliers,
        networks: networks,
        loadProfiles: loadProfiles,
		legalForms: legalForms,
        customerTypes: customerTypes,
      },
    });
  };

  //------------------------------------------
  //	LEAD'S ADDRESS
  //------------------------------------------

  //	ABC FLAG CHANGE
  const abcIdChange = (e) => {
    dispatch({
      type: ABC_ID_CHANGE,
      payload: e.target.value,
    });
  };

  //	BUSINESS PARTNER TYPE CHANGE
  const businessPartnerTypesIdChange = (e) => {
    dispatch({
      type: BUSINESS_PARTNER_TYPES_ID_CHANGE,
      payload: e.target.value,
    });
  };

  //	CHANGE OF THE FOLLOW UP DATE
  const followUpDateChange = (date) => {
    dispatch({
      type: FOLLOW_UP_DATE_CHANGE,
      payload: date,
    });
  };

  //	CHANGE THE FOLLOW UP STATE
  const followUpDoneChange = (e) => {
    dispatch({
      type: FOLLOW_UP_DONE_CHANGE,
      payload: e.target.checked,
    });
  };

  //	GO TO THE ORIGINAL CUSTOMER OF THE LEAD'S COPY
  const goToOriginal = async (targetRoute) => {
		
	await closeLeadDetailDialog();

	let hrefArr = window.location.href.split("/#");
	let hrefPage = hrefArr[1];
	let hrefSearch = "";
	if (hrefArr[1].indexOf("?") !== -1) {
	  let hrefUrl = hrefArr[1].split("?");
	  hrefPage = hrefUrl[0];
	  hrefSearch = hrefUrl[1];
	}

	let targetPage = targetRoute;
	let targetSearch = "";
	if (targetPage.indexOf("?") !== -1) {
	  let targetUrl = targetPage.split("?");
	  targetPage = targetUrl[0];
	  targetSearch = targetUrl[1];
	}

	if (hrefPage === targetPage) {
	  if (hrefSearch === "")
		window.location.href = window.location.href + "?" + targetSearch;
	  else
		window.location.href = window.location.href.replace(
		  hrefSearch,
		  targetSearch
		);
	  window.location.reload();
	}
}

  const isPortalAccessAdministrationChange = (e) => {
    dispatch({
      type: IS_PORTAL_ACCESS_ADMINISTRATION_CHANGE,
      payload: e.target.checked,
    });
  };

  const setActiveAddressTab = (tab) => {
    dispatch({
      type: SET_ACTIVE_ADDRESS_TAB,
      payload: tab,
    });
  };

  const supervisorsIdChange = (e) => {
    dispatch({
      type: SUPERVISORS_ID_CHANGE,
      payload: e.target.value,
    });
  };

  const supervisorsPvIdChange = (e) => {
	dispatch({
		type: SUPERVISORS_PV_ID_CHANGE,
		payload: e.target.value,
	});
  }

  //-----------------------------------------------
  //	LEAD OBJECT LIST DIALOG
  //-----------------------------------------------
  const closeLeadObjectListDialog = () => {
    dispatch({
      type: LEAD_OBJECT_LIST_DIALOG,
      payload: false,
    });
  };

  const loadObjectList = async (division) => {
    dispatch({
      type: LEAD_OBJECT_LIST_DIALOG,
      payload: true,
    });

    //	Start the export of the object list
    let activeEntries = "single-" + state.activeLeadId;

    const params = new URLSearchParams();
    params.append("jwt", sessionStorage.jwt);
    params.append("activeEntries", activeEntries);
    params.append("divisionsId", division);
    params.append("customerOption", 0);
    params.append("leadOption", 1);
    params.append("file", "");

    let url = process.env.REACT_APP_API_URI + "/export_object_list.php";
    let axiosConfig = {
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
    };

    await axios
      .post(url, params, axiosConfig)
      .then((response) => {
        if (response.status === 200) {
          // console.log(response.data);
          checkObjectList();
        } else if (response.status === 401) {
          window.location.href = "#/logout";
        } else {
          console.log("Anderer Fehler");
        }
      })
      .catch(function (error) {
        if (error.response) {
          //	The server answered with an error
          if (error.response.status === 401) {
            window.location.href = "#/logout";
          } else {
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          }
        } else if (error.request) {
          //	The request was made but the server didn't responsd
          console.log(error.request);
        } else {
          //	Something else went wrong
          console.log("Error", error.message);
        }
      });
  };

  const checkObjectList = async () => {

    const params = new URLSearchParams();
    params.append("jwt", sessionStorage.jwt);
    params.append("customerId", state.activeLeadId);

    let url = process.env.REACT_APP_API_URI + "/check_object_list.php";
    let axiosConfig = {
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
    };

    await axios
      .post(url, params, axiosConfig)
      .then((response) => {
        if (response.status === 200) {
        //   console.log(response.data);

          if (response.data.isFinished === 1) {
            window.clearTimeout();
            dispatch({
              type: LEAD_OBJECT_LIST_IS_FINISHED,
              payload: {
				  leadObjectListIsFinished: true,
				  leadObjectListIsEmpty: response.data.isEmpty === 1 ? true : false,
			  }
            });
          } else window.setTimeout(checkObjectList, 1000);
        } else if (response.status === 401) {
          window.location.href = "#/logout";
        } else {
          console.log("Anderer Fehler");
        }
      })
      .catch(function (error) {
        if (error.response) {
          //	The server answered with an error
          if (error.response.status === 401) {
            window.location.href = "#/logout";
          } else {
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          }
        } else if (error.request) {
          //	The request was made but the server didn't responsd
          console.log(error.request);
        } else {
          //	Something else went wrong
          console.log("Error", error.message);
        }
      });
  };

  //-----------------------------------------------
  //  LEAD ADDRESS ADDRESS
  //-----------------------------------------------

  //	Address additional change
  const addressAdditionChange = (e) => {
    dispatch({
      type: ADDRESS_ADDITION_CHANGE,
      payload: e.target.value,
    });
  };

  const cityChange = (e) => {
    dispatch({
      type: CITY_CHANGE,
      payload: e.target.value,
    });
  };

  const countryChange = (e) => {
    dispatch({
      type: COUNTRY_CHANGE,
      payload: e.target.value,
    });
  };

  const districtChange = (e) => {
    dispatch({
      type: DISTRICT_CHANGE,
      payload: e.target.value,
    });
  };

  const houseNumberChange = (e) => {
    dispatch({
      type: HOUSE_NUMBER_CHANGE,
      payload: e.target.value,
    });
  };

  const name1Change = (e) => {
    dispatch({
      type: NAME_1_CHANGE,
      payload: e.target.value.substring(0, 40),
    });
  };

  const name2Change = (e) => {
    dispatch({
      type: NAME_2_CHANGE,
      payload: e.target.value.substring(0, 40),
    });
  };

  const name3Change = (e) => {
    dispatch({
      type: NAME_3_CHANGE,
      payload: e.target.value.substring(0, 40),
    });
  };

  const legalFormsIdChange = (e) => {
	  dispatch({
		  type: LEGAL_FORMS_ID_CHANGE,
		  payload: e.target.value,
	  });
  }

  const postalCodeChange = (e) => {
    dispatch({
      type: POSTAL_CODE_CHANGE,
      payload: e.target.value,
    });
  };

  const streetChange = (e) => {
    dispatch({
      type: STREET_CHANGE,
      payload: e.target.value,
    });
  };

  const salutationsIdChange = (e) => {
    dispatch({
      type: SALUTATIONS_ID_CHANGE,
      payload: e.target.value,
    });
  };

  //  Lead Address Contact
  const emailChange = (e) => {
    dispatch({
      type: EMAIL_CHANGE,
      payload: e.target.value,
    });
  };

  const faxChange = (e) => {
    dispatch({
      type: FAX_CHANGE,
      payload: e.target.value,
    });
  };

  const phoneChange = (e) => {
    dispatch({
      type: PHONE_CHANGE,
      payload: e.target.value,
    });
  };

  const urlChange = (e) => {
    dispatch({
      type: URL_CHANGE,
      payload: e.target.value,
    });
  };

  //  Lead address postbox
  const postboxChange = (e) => {
    dispatch({
      type: POSTBOX_CHANGE,
      payload: e.target.value,
    });
  };

  const postboxCityChange = (e) => {
    dispatch({
      type: POSTBOX_CITY_CHANGE,
      payload: e.target.value,
    });
  };

  const postboxPostalCodeChange = (e) => {
    dispatch({
      type: POSTBOX_POSTAL_CODE_CHANGE,
      payload: e.target.value,
    });
  };

  //-----------------------------------------------
  //  LEAD ADDRESS STANDARD DATA
  //-----------------------------------------------

  //	Change 1st level of administration
  const administrations1IdChange = (e) => {
    const admin1 = e.target.value;

    dispatch({
      type: ADMINISTRATIONS_1_ID_CHANGE,
      payload: admin1,
    });

    //  Get administrations 2 & 3
    const params = new URLSearchParams();
    params.append("jwt", sessionStorage.jwt);
    params.append("administration1", admin1);

    let axiosConfig = {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    };

    let url = process.env.REACT_APP_API_URI + "/leads/get_administration2.php";

    axios
      .post(url, params, axiosConfig)
      .then((response) => {
        if (response.status === 200) {
          // console.log(response.data);

          dispatch({
            type: GET_ADMINISTRATIONS_2,
            payload: {
              administrations2: response.data.administrations2,
              administrations3: response.data.administrations3,
            },
          });
        } else if (response.status === 401) {
          window.location.href = "#/logout";
        } else {
          console.log("Anderer Fehler");
        }
      })
      .catch(function (error) {
        if (error.response) {
          // The server answered with an error
          if (error.response.status === 401) {
            window.location.href = "#/logout";
          } else {
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          }
        } else if (error.request) {
          //  The request was made but the server didn't respond
          console.log(error.request);
        } else {
          //  Something else went wrong
          console.log("Error", error.message);
        }
      });
  };

  //	Change 2nd level of administration
  const administrations2IdChange = (e) => {
    const admin2 = e.target.value;

    dispatch({
      type: ADMINISTRATIONS_2_ID_CHANGE,
      payload: admin2,
    });

    //  Get administrations 3
    const params = new URLSearchParams();
    params.append("jwt", sessionStorage.jwt);
    params.append("administration2", admin2);

    let axiosConfig = {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    };

    let url = process.env.REACT_APP_API_URI + "/leads/get_administration3.php";

    axios
      .post(url, params, axiosConfig)
      .then((response) => {
        if (response.status === 200) {
          // console.log(response.data);

          dispatch({
            type: GET_ADMINISTRATIONS_3,
            payload: {
              administrations3: response.data.administrations3,
            },
          });
        } else if (response.status === 401) {
          window.location.href = "#/logout";
        } else {
          console.log("Anderer Fehler");
        }
      })
      .catch(function (error) {
        if (error.response) {
          // The server answered with an error
          if (error.response.status === 401) {
            window.location.href = "#/logout";
          } else {
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          }
        } else if (error.request) {
          //  The request was made but the server didn't respond
          console.log(error.request);
        } else {
          //  Something else went wrong
          console.log("Error", error.message);
        }
      });
  };

  //	Change 3rd level of administration
  const administrations3IdChange = (e) => {
    dispatch({
      type: ADMINISTRATIONS_3_ID_CHANGE,
      payload: e.target.value,
    });
  };

  //	Change community code
  const communityCodeChange = (e) => {
    dispatch({
      type: COMMUNITY_CODE_CHANGE,
      payload: e.target.value,
    });
  };

  //	Change ownership
  const ownerIdChange = (e) => {
    const ownerId = e.target.value;

    dispatch({
      type: OWNER_ID_CHANGE,
      payload: ownerId,
    });

    //  Get administrations 1
    const params = new URLSearchParams();
    params.append("jwt", sessionStorage.jwt);
    params.append("ownerId", ownerId);

    let url = process.env.REACT_APP_API_URI + "/leads/get_administration1.php";
    let axiosConfig = {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    };

    axios
      .post(url, params, axiosConfig)
      .then((response) => {
        if (response.status === 200) {
          // console.log(response.data);

          dispatch({
            type: GET_ADMINISTRATIONS_1,
            payload: {
              administrations1: response.data.administrations1,
              administrations2: response.data.administrations2,
              administrations3: response.data.administrations3,
            },
          });
        } else if (response.status === 401) {
          window.location.href = "#/logout";
        } else {
          console.log("Anderer Fehler");
        }
      })
      .catch(function (error) {
        if (error.response) {
          // The server answered with an error
          if (error.response.status === 401) {
            window.location.href = "#/logout";
          } else {
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          }
        } else if (error.request) {
          //  The request was made but the server didn't respond
          console.log(error.request);
        } else {
          //  Something else went wrong
          console.log("Error", error.message);
        }
      });
  };

  //---------------------------------------------
  //	LEAD CONTACT PERSON
  //---------------------------------------------

  //  Lead Contact Persons
  const closeContactPersonDialog = () => {
    dispatch({
      type: CLOSE_CONTACT_PERSON_DIALOG,
    });
  };

  const closeDelete = () => {
    dispatch({
      type: CLOSE_DELETE,
    });
  };

  const contactPersonDivisionChange = (e) => {
    dispatch({
      type: CONTACT_PERSON_DIVISION_CHANGE,
      payload: e.target.value,
    });
  };

  const contactPersonEmailChange = (e) => {
    dispatch({
      type: CONTACT_PERSON_EMAIL_CHANGE,
      payload: e.target.value,
    });
  };

  const contactPersonFaxChange = (e) => {
    dispatch({
      type: CONTACT_PERSON_FAX_CHANGE,
      payload: e.target.value,
    });
  };

  const contactPersonFirstnameChange = (e) => {
    if (
      state.contactPersonDetailsDialogError !== "" &&
      state.contactPersonFirstname === ""
    )
      setContactPersonError("");

    dispatch({
      type: CONTACT_PERSON_FIRSTNAME_CHANGE,
      payload: e.target.value,
    });
  };

  const contactPersonFunctionChange = (e) => {
    dispatch({
      type: CONTACT_PERSON_FUNCTION_CHANGE,
      payload: e.target.value,
    });
  };

  const contactPersonIsPortalAccessChange = (e) => {
    dispatch({
      type: CONTACT_PERSON_IS_PORTAL_ACCESS_CHANGE,
      payload: e.target.checked,
    });
  };

  const contactPersonLastnameChange = (e) => {
    if (
      state.contactPersonDetailsDialogError !== "" &&
      state.contactPersonLastname === ""
    )
      setContactPersonError("");

    dispatch({
      type: CONTACT_PERSON_LASTNAME_CHANGE,
      payload: e.target.value,
    });
  };

  const contactPersonMobileChange = (e) => {
    dispatch({
      type: CONTACT_PERSON_MOBILE_CHANGE,
      payload: e.target.value,
    });
  };

  const contactPersonPhoneChange = (e) => {
    dispatch({
      type: CONTACT_PERSON_PHONE_CHANGE,
      payload: e.target.value,
    });
  };

  const contactPersonPortalAccessValidToChange = (date) => {
    dispatch({
      type: CONTACT_PERSON_PORTAL_ACCESS_VALID_TO_CHANGE,
      payload: date,
    });
  };

  const contactPersonSalutationsIdChange = (e) => {
	if (
		state.contactPersonDetailsDialogError !== "" &&
		state.contactPersonSalutationsId <= 0
	  )
		setContactPersonError("");

    dispatch({
      type: CONTACT_PERSON_SALUTATIONS_ID_CHANGE,
      payload: e.target.value,
    });
  };

  const contactPersonTitleChange = (e) => {
    dispatch({
      type: CONTACT_PERSON_TITLE_CHANGE,
      payload: e.target.value,
    });
  };

  const deleteContactPersonConfirm = (id) => {
    const params = new URLSearchParams();
    params.append("jwt", sessionStorage.jwt);
    params.append("id", id);

    let axiosConfig = {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    };

    let url = process.env.REACT_APP_API_URI + "/leads/get_contact_person.php";

    axios
      .post(url, params, axiosConfig)
      .then((response) => {
        if (response.status === 200) {
          // console.log(response.data);
          const entityName =
            "Möchten Sie den Ansprechpartner " +
            response.data.contactPerson.firstname +
            " " +
            response.data.contactPerson.lastname +
            " endgültig löschen?";
          dispatch({
            type: DELETE_CONTACT_PERSON_CONFIRM,
            payload: {
              entityName: entityName,
              title: "Ansprechpartner löschen",
              id: id,
            },
          });
        } else if (response.status === 401) {
          window.location.href = "#/logout";
        } else {
          console.log("Anderer Fehler");
        }
      })
      .catch(function (error) {
        if (error.response) {
          // The server answered with an error
          if (error.response.status === 401) {
            window.location.href = "#/logout";
          } else {
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          }
        } else if (error.request) {
          //  The request was made but the server didn't respond
          console.log(error.request);
        } else {
          //  Something else went wrong
          console.log("Error", error.message);
        }
      });
  };

  const deleteEntityConfirmed = () => {
    const params = new URLSearchParams();
    params.append("jwt", sessionStorage.jwt);
    params.append("id", state.activeContactPerson);

    let axiosConfig = {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    };

    let url =
      process.env.REACT_APP_API_URI + "/leads/delete_contact_person.php";

    axios
      .post(url, params, axiosConfig)
      .then((response) => {
        if (response.status === 200) {
          // console.log(response.data);
          dispatch({
            type: DELETE_CONTACT_PERSON,
          });
          getContactPersons();
        } else if (response.status === 401) {
          window.location.href = "#/logout";
        } else {
          console.log("Anderer Fehler");
        }
      })
      .catch(function (error) {
        if (error.response) {
          // The server answered with an error
          if (error.response.status === 401) {
            window.location.href = "#/logout";
          } else {
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          }
        } else if (error.request) {
          //  The request was made but the server didn't respond
          console.log(error.request);
        } else {
          //  Something else went wrong
          console.log("Error", error.message);
        }
      });
  };

  //---------------------------------------
  //	CONTACT PERSONS
  //---------------------------------------

  //	EDIT A CONTACT PERSON
  const editContactPerson = (id) => {
    let dialogTitle = "Neue Kontaktperson anlegen";

    if (id > 0) {
      dialogTitle = "Kontaktperson bearbeiten";
      const params = new URLSearchParams();
      params.append("jwt", sessionStorage.jwt);
      params.append("id", id);

      let axiosConfig = {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      };

      let url = process.env.REACT_APP_API_URI + "/leads/get_contact_person.php";

      axios
        .post(url, params, axiosConfig)
        .then((response) => {
          if (response.status === 200) {
            if (response.data.contactPerson.portalAccessValidTo)
              response.data.contactPerson.portalAccessValidTo = new Date(
                response.data.contactPerson.portalAccessValidTo
              );

            // console.log(response.data);
            dispatch({
              type: SET_ACTIVE_CONTACT_PERSON,
              payload: response.data.contactPerson,
            });
          } else if (response.status === 401) {
            window.location.href = "#/logout";
          } else {
            console.log("Anderer Fehler");
          }
        })
        .catch(function (error) {
          if (error.response) {
            // The server answered with an error
            if (error.response.status === 401) {
              window.location.href = "#/logout";
            } else {
              console.log(error.response.data);
              console.log(error.response.status);
              console.log(error.response.headers);
            }
          } else if (error.request) {
            //  The request was made but the server didn't respond
            console.log(error.request);
          } else {
            //  Something else went wrong
            console.log("Error", error.message);
          }
        });
    }

    dispatch({
      type: EDIT_CONTACT_PERSON,
      payload: {
        activeContactPerson: id,
        contactPersonDetailsDialogState: true,
        contactPersonDetailsDialogTitle: dialogTitle,
      },
    });
  };

  //	LOAD THE CONTACT PERSONS
  const getContactPersons = () => {
    const params = new URLSearchParams();
    params.append("jwt", sessionStorage.jwt);
    params.append("leadsId", state.activeLeadId);

    let axiosConfig = {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    };

    let url = process.env.REACT_APP_API_URI + "/leads/get_contact_persons.php";

    axios
      .post(url, params, axiosConfig)
      .then((response) => {
        if (response.status === 200) {
          // console.log(response.data);

          dispatch({
            type: GET_CONTACT_PERSONS,
            payload: response.data.contactPersons,
          });
        } else if (response.status === 401) {
          window.location.href = "#/logout";
        } else {
          console.log("Anderer Fehler");
        }
      })
      .catch(function (error) {
        if (error.response) {
          // The server answered with an error
          if (error.response.status === 401) {
            window.location.href = "#/logout";
          } else {
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          }
        } else if (error.request) {
          //  The request was made but the server didn't respond
          console.log(error.request);
        } else {
          //  Something else went wrong
          console.log("Error", error.message);
        }
      });
  };

  //	SAVE THE CONTACT PERSONS
  const saveContactPerson = () => {

	let newPortalAccessValidTo = "";
    const portalAccessValidTo = state.contactPersonPortalAccessValidTo !== "" ? new Date(state.contactPersonPortalAccessValidTo) : "";

    if (portalAccessValidTo !== "") {

      const newDay = portalAccessValidTo.getDate();
      const newMonth = portalAccessValidTo.getMonth() + 1;
      const newYear = portalAccessValidTo.getFullYear();
      newPortalAccessValidTo = newYear + "-" + newMonth + "-" + newDay;
    }

    if (state.contactPersonFirstname === "") { 

		setContactPersonError( "Bitte geben Sie einen Vornamen für die Kontaktperson ein");
      	return false;
    }

    if (state.contactPersonLastname === "") {
      
		setContactPersonError("Bitte geben Sie einen Nachnamen für die Kontaktperson ein");
      	return false;
    }

	if(state.contactPersonSalutationsId <= 0) {
		setContactPersonError("Bitte wählen Sie eine Anrede für die Kontaktperson aus.");
		return false;
	}

    const params = new URLSearchParams();
    params.append("jwt", sessionStorage.jwt);
    params.append("id", state.activeContactPerson);
    params.append("leadsId", state.activeLeadId);
    params.append("salutationsId", state.contactPersonSalutationsId);
    params.append("title", state.contactPersonTitle);
    params.append("firstname", state.contactPersonFirstname);
    params.append("lastname", state.contactPersonLastname);
    params.append("phone", state.contactPersonPhone);
    params.append("fax", state.contactPersonFax);
    params.append("mobile", state.contactPersonMobile);
    params.append("email", state.contactPersonEmail);
    params.append("function", state.contactPersonFunction);
    params.append("division", state.contactPersonDivision);
    params.append("isPortalAccess", state.contactPersonIsPortalAccess);
    params.append("portalAccessValidTo", newPortalAccessValidTo);

    let axiosConfig = {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    };

    let url = process.env.REACT_APP_API_URI + "/leads/set_contact_person.php";

    axios
      .post(url, params, axiosConfig)
      .then((response) => {
        if (response.status === 200) {
        //   console.log(response.data);
          getContactPersons();

          dispatch({
            type: CLOSE_CONTACT_PERSON_DIALOG,
          });
          return true;
        } else if (response.status === 401) {
          window.location.href = "#/logout";
        } else {
          console.log("Anderer Fehler");
          return false;
        }
      })
      .catch(function (error) {
        if (error.response) {
          // The server answered with an error
          if (error.response.status === 401) {
            window.location.href = "#/logout";
          } else {
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
            return false;
          }
        } else if (error.request) {
          //  The request was made but the server didn't respond
          console.log(error.request);
          return false;
        } else {
          //  Something else went wrong
          console.log("Error", error.message);
          return false;
        }
      });
    return true;
  };

  //	CONTACT PERSON'S DETAIL DIALOG ERROR
  const setContactPersonError = (errMsg) => {
    dispatch({
      type: SET_CONTACT_PERSON_ERROR,
      payload: errMsg,
    });
  };

  //---------------------------------------
  //	BANK ACCOUNT
  //---------------------------------------

  //  BANK ACCOUNT
  const accountNoChange = (e) => {
    dispatch({
      type: ACCOUNT_NO_CHANGE,
      payload: e.target.value,
    });
  };

  const accountOwnerChange = (e) => {
    dispatch({
      type: ACCOUNT_OWNER_CHANGE,
      payload: e.target.value,
    });
  };

  const bankCodeChange = (e) => {
    dispatch({
      type: BANK_CODE_CHANGE,
      payload: e.target.value,
    });
  };

  const ibanChange = (e) => {
    dispatch({
      type: IBAN_CHANGE,
      payload: e.target.value,
    });
  };

  //  PRODUCTS
  const energyCampaignsIdChange = (e) => {

    const energyCampaignsId = e.target.value;

	const isLeadEnergyTransitionInitialized = parseInt(state.isLeadEnergyTransitionInitialized) < 2 ? 1 : state.isLeadEnergyTransitionInitialized;
	const isLeadEnergyInitialTransition = (parseInt(state.isLeadEnergyTransitionInitialized) < 2) ? 1 : state.isLeadEnergyInitialTransition;
	const isLeadEnergyTransition = (isLeadEnergyInitialTransition === 1) ? 1 : state.isLeadEnergyTransition;
	const isLeadEnergyTransitionTenPercent = (isLeadEnergyInitialTransition === 1) ? 1 : state.isLeadEnergyTransitionTenPercent;

    const params = new URLSearchParams();
    params.append("jwt", sessionStorage.jwt);
    params.append("energyCampaignsId", energyCampaignsId);

    let axiosConfig = {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    };

    let url = process.env.REACT_APP_API_URI + "/leads/get_energy_pots.php";

    axios
      .post(url, params, axiosConfig)
      .then((response) => {
        if (response.status === 200) {
          // console.log(response.data);

          dispatch({
            type: ENERGY_CAMPAIGNS_ID_CHANGE,
            payload: {
              energyCampaignsId: energyCampaignsId,
              energyPots: response.data.energyPots,
			  isLeadEnergyTransitionInitialized: isLeadEnergyTransitionInitialized,
			  isLeadEnergyInitialTransition: isLeadEnergyInitialTransition,
			  isLeadEnergyTransition: isLeadEnergyTransition,
			  isLeadEnergyTransitionTenPercent: isLeadEnergyTransitionTenPercent,
            },
          });
        } else if (response.status === 401) {
          window.location.href = "#/logout";
        } else {
          console.log("Anderer Fehler");
          return false;
        }
      })
      .catch(function (error) {
        if (error.response) {
          // The server answered with an error
          if (error.response.status === 401) {
            window.location.href = "#/logout";
          } else {
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
            return false;
          }
        } else if (error.request) {
          //  The request was made but the server didn't respond
          console.log(error.request);
          return false;
        } else {
          //  Something else went wrong
          console.log("Error", error.message);
          return false;
        }
      });
  };

  const energyPotsIdChange = (e) => {
    const energyPotsId = e.target.value;

    const params = new URLSearchParams();
    params.append("jwt", sessionStorage.jwt);
    params.append("energyPotsId", energyPotsId);
    params.append("energyCampaignsId", state.energyCampaignsId);

    let axiosConfig = {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    };

    let url = process.env.REACT_APP_API_URI + "/leads/get_energy_campaign.php";

    axios
      .post(url, params, axiosConfig)
      .then((response) => {
        if (response.status === 200) {
          //   console.log(response.data);

          dispatch({
            type: ENERGY_POTS_ID_CHANGE,
            payload: {
              energyPotsId: energyPotsId,
              energyCampaignsId: response.data.energyCampaignsId,
            },
          });
        } else if (response.status === 401) {
          window.location.href = "#/logout";
        } else {
          console.log("Anderer Fehler");
          return false;
        }
      })
      .catch(function (error) {
        if (error.response) {
          // The server answered with an error
          if (error.response.status === 401) {
            window.location.href = "#/logout";
          } else {
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
            return false;
          }
        } else if (error.request) {
          //  The request was made but the server didn't respond
          console.log(error.request);
          return false;
        } else {
          //  Something else went wrong
          console.log("Error", error.message);
          return false;
        }
      });
  };

  const gasCampaignsIdChange = (e) => {

    const gasCampaignsId = e.target.value;

	const isLeadGasTransitionInitialized = parseInt(state.isLeadGasTransitionInitialized) < 2 ? 1 : state.isLeadGasTransitionInitialized;
	const isLeadGasInitialTransition = (parseInt(state.isLeadGasTransitionInitialized) < 2) ? 1 : state.isLeadGasInitialTransition;
	const isLeadGasTransition = (isLeadGasInitialTransition === 1) ? 1 : state.isLeadGasTransition;
	const isLeadGasTransitionTenPercent = (isLeadGasInitialTransition === 1) ? 1 : state.isLeadGasTransitionTenPercent;

    const params = new URLSearchParams();
    params.append("jwt", sessionStorage.jwt);
    params.append("gasCampaignsId", gasCampaignsId);

    let axiosConfig = {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    };

    let url = process.env.REACT_APP_API_URI + "/leads/get_gas_pots.php";

    axios
      .post(url, params, axiosConfig)
      .then((response) => {
        if (response.status === 200) {
          // console.log(response.data);

          dispatch({
            type: GAS_CAMPAIGNS_ID_CHANGE,
            payload: {
              gasCampaignsId: gasCampaignsId,
              gasPots: response.data.gasPots,
			  isLeadGasTransitionInitialized: isLeadGasTransitionInitialized,
			  isLeadGasInitialTransition: isLeadGasInitialTransition,
			  isLeadGasTransition: isLeadGasTransition,
			  isLeadGasTransitionTenPercent: isLeadGasTransitionTenPercent
            },
          });
        } else if (response.status === 401) {
          window.location.href = "#/logout";
        } else {
          console.log("Anderer Fehler");
          return false;
        }
      })
      .catch(function (error) {
        if (error.response) {
          // The server answered with an error
          if (error.response.status === 401) {
            window.location.href = "#/logout";
          } else {
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
            return false;
          }
        } else if (error.request) {
          //  The request was made but the server didn't respond
          console.log(error.request);
          return false;
        } else {
          //  Something else went wrong
          console.log("Error", error.message);
          return false;
        }
      });
  };

  const gasPotsIdChange = (e) => {
    const gasPotsId = e.target.value;

    const params = new URLSearchParams();
    params.append("jwt", sessionStorage.jwt);
    params.append("gasPotsId", gasPotsId);
    params.append("gasCampaignsId", state.gasCampaignsId);

    let axiosConfig = {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    };

    let url = process.env.REACT_APP_API_URI + "/leads/get_gas_campaign.php";

    axios
      .post(url, params, axiosConfig)
      .then((response) => {
        if (response.status === 200) {
          // console.log(response.data);

          dispatch({
            type: GAS_POTS_ID_CHANGE,
            payload: {
              gasPotsId: gasPotsId,
              gasCampaignsId: response.data.gasCampaignsId,
            },
          });
        } else if (response.status === 401) {
          window.location.href = "#/logout";
        } else {
          console.log("Anderer Fehler");
          return false;
        }
      })
      .catch(function (error) {
        if (error.response) {
          // The server answered with an error
          if (error.response.status === 401) {
            window.location.href = "#/logout";
          } else {
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
            return false;
          }
        } else if (error.request) {
          //  The request was made but the server didn't respond
          console.log(error.request);
          return false;
        } else {
          //  Something else went wrong
          console.log("Error", error.message);
          return false;
        }
      });
  };

  // 	CHANGE THE ENERGY TRANSITION CHECKBOX
  const isLeadEnergyTransitionChange = (e) => {
	const isChange = (e.target.checked === false) ? 0 : 1;
		dispatch({
		type: IS_LEAD_ENERGY_TRANSITION_CHANGE,
		payload: isChange,
	});
  }

  //	CHANGE THE ENERGY TRANSITION DATE
  const leadEnergyTransitionDateChange = (date) => {
	dispatch({
		type: LEAD_ENERGY_TRANSITION_DATE_CHANGE,
		payload: date,
	});
  }

  //	CHANGE THE ENERGY 10 PERCENT TRANSITION CHECKBOX
  const isLeadEnergyTransitionTenPercentChange = (e) => {
	const isChange = (e.target.checked === false) ? 0 : 1;
	dispatch({
		type: IS_LEAD_ENERGY_TRANSITION_TEN_PERCENT_CHANGE,
		payload: isChange
	});
  }

  //	CHANGE THE ENERGY 10 PERCENT TRANSITION DATE
  const leadEnergyTransitionTenPercentDateChange = (date) => {
	dispatch({
		type: LEAD_ENERGY_TRANSITION_TEN_PERCENT_DATE_CHANGE,
		payload: date,
	});
  }

  //	CHANGE THE GAS TRANSITION CHECKBOX
  const isLeadGasTransitionChange = (e) => {
	const isChange = (e.target.checked === false) ? 0 : 1;
	dispatch({
		type: IS_LEAD_GAS_TRANSITION_CHANGE,
		payload: isChange,
	});
  }

  //	CHANGE THE GAS TRANSITION DATE
  const leadGasTransitionDateChange = (date) => {
	dispatch({
		type: LEAD_GAS_TRANSITION_DATE_CHANGE,
		payload: date,
	});
  }

  //	CHANGE THE GAS 10 PERCENT TRANSITION CHECKBOX
  const isLeadGasTransitionTenPercentChange = (e) => {
	const isChange = (e.target.checked === false) ? 0 : 1;
	dispatch({
		type: IS_LEAD_GAS_TRANSITION_TEN_PERCENT_CHANGE,
		payload: isChange,
	});
  }

  //	CHANGE THE GAS 10 PERCENT TRANSITION DATE
  const leadGasTransitionTenPercentDateChange = (date) => {
	dispatch({
		type: LEAD_GAS_TRANSITION_TEN_PERCENT_DATE_CHANGE,
		payload: date,
	});
  }

  const setActiveProductTab = (tabId) => {
    dispatch({
      type: SET_ACTIVE_PRODUCT_TAB,
      payload: tabId,
    });
  };

  //  Contracts
  const acceptOffer = () => {
    const params = new URLSearchParams();
    params.append("jwt", sessionStorage.jwt);
    params.append("id", state.activeLeadId);

    let url = process.env.REACT_APP_API_URI + "/leads/set_offer_accepted.php";
    let axiosConfig = {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    };

    axios
      .post(url, params, axiosConfig)
      .then((response) => {
        if (response.status === 200) {
          // console.log(response.data);
          dispatch({
            type: ACCEPT_OFFER,
          });
        } else if (response.status === 401) {
          window.location.href = "#/logout";
        } else {
          console.log("Anderer Fehler");
          return false;
        }
      })
      .catch(function (error) {
        if (error.response) {
          // The server answered with an error
          if (error.response.status === 401) {
            window.location.href = "#/logout";
          } else {
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
            return false;
          }
        } else if (error.request) {
          //  The request was made but the server didn't respond
          console.log(error.request);
          return false;
        } else {
          //  Something else went wrong
          console.log("Error", error.message);
          return false;
        }
      });
  };

  const closeLeadContractsDialog = () => {
    dispatch({
      type: CLOSE_LEAD_CONTRACTS_DIALOG,
    });
  };

  const closeLeadContractOfferAcceptDialog = () => {
    dispatch({
      type: CLOSE_LEAD_CONTRACT_OFFER_ACCEPT_DIALOG,
    });
  };

  const confirmAcceptOffer = () => {
    dispatch({
      type: CONFIRM_ACCEPT_OFFER,
    });
  };

  const deleteLeadContractConfirm = (id) => {
    const params = new URLSearchParams();
    params.append("jwt", sessionStorage.jwt);
    params.append("id", id);

    let url = process.env.REACT_APP_API_URI + "/leads/get_lead_contract.php";
    let axiosConfig = {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    };

    axios
      .post(url, params, axiosConfig)
      .then((response) => {
        if (response.status === 200) {
          // console.log(response.data);

          const entityName =
            "Möchten Sie die Abnahmestelle mit der Objekt-Id " +
            response.data.id +
            " endgültig löschen?";

          dispatch({
            type: DELETE_CONTRACT_CONFIRM,
            payload: {
              entityName: entityName,
              title: "Abnahmestelle löschen",
              id: id,
            },
          });
        } else if (response.status === 401) {
          window.location.href = "#/logout";
        } else {
          console.log("Anderer Fehler");
        }
      })
      .catch(function (error) {
        if (error.response) {
          // The server answered with an error
          if (error.response.status === 401) {
            window.location.href = "#/logout";
          } else {
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          }
        } else if (error.request) {
          //  The request was made but the server didn't respond
          console.log(error.request);
        } else {
          //  Something else went wrong
          console.log("Error", error.message);
        }
      });
  };

  const leadContractCustomerTypesIdChange = (e) => {
    dispatch({
      type: LEAD_CONTRACT_CUSTOMER_TYPES_ID_CHANGE,
      payload: e.target.value,
    });
  };

  const leadContractDivisionsIdChange = (e) => {

	if(state.leadContractsDialogError !== "" && state.leadContractDivisionsId <= 0)
		setContractErrMsg("", 1);

    const divisionsId = e.target.value;

    const params = new URLSearchParams();
    params.append("jwt", sessionStorage.jwt);
    params.append("divisionsId", divisionsId);
    params.append("energyCampaignsId", state.energyCampaignsId);
    params.append("energyPotsId", state.energyPotsId);
    params.append("gasCampaignsId", state.gasCampaignsId);
    params.append("gasPotsId", state.gasPotsId);
    params.append("leadId", state.activeLeadId);

    let axiosConfig = {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    };

    let url = process.env.REACT_APP_API_URI + "/leads/get_contract_product.php";

    axios
      .post(url, params, axiosConfig)
      .then((response) => {
        if (response.status === 200) {
          // console.log(response.data);

          dispatch({
            type: LEAD_CONTRACT_DIVISIONS_ID_CHANGE,
            payload: {
              divisionsId: divisionsId,
              isPots: response.data.isPots,
              pots: response.data.pots,
              potsId: response.data.potsId,
              isProduct: response.data.isProduct,
              products: response.data.products,
              productsId: response.data.productsId,
              isValidFrom: response.data.isValidFrom,
              validFrom: response.data.validFrom,
              isValidTo: response.data.isValidTo,
              validTo: response.data.validTo,
              isBio5: response.data.isBio5,
              bio5StartDate: response.data.bio5StartDate,
              isBio10: response.data.isBio10,
              bio10StartDate: response.data.bio10StartDate,
              isCo2: response.data.isCo2,
              isClimatePlus: response.data.isClimatePlus,
              loadProfiles: response.data.loadProfiles,
              loadProfilesId: response.data.loadProfilesId,
              isOptionen: response.data.isOptionen,
              suppliers: response.data.suppliers,
              networks: response.data.networks,
            },
          });
        } else if (response.status === 401) {
          window.location.href = "#/logout";
        } else {
          console.log("Anderer Fehler");
        }
      })
      .catch(function (error) {
        if (error.response) {
          // The server answered with an error
          if (error.response.status === 401) {
            window.location.href = "#/logout";
          } else {
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          }
        } else if (error.request) {
          //  The request was made but the server didn't respond
          console.log(error.request);
        } else {
          //  Something else went wrong
          console.log("Error", error.message);
        }
      });
  };

  const leadContractIsBio5Change = (e) => {
    dispatch({
      type: LEAD_CONTRACT_IS_BIO5_CHANGE,
      payload: e.target.value,
    });
  };

  const leadContractBio5StartDateChange = (date) => {
    dispatch({
      type: LEAD_CONTRACT_BIO5_START_DATE_CHANGE,
      payload: date,
    });
  };

  const leadContractIsBio10Change = (e) => {
    dispatch({
      type: LEAD_CONTRACT_IS_BIO10_CHANGE,
      payload: e.target.value,
    });
  };

  const leadContractBio10StartDateChange = (date) => {
    dispatch({
      type: LEAD_CONTRACT_BIO10_START_DATE_CHANGE,
      payload: date,
    });
  };

  const leadContractIsClimatePlusChange = (e) => {
    dispatch({
      type: LEAD_CONTRACT_IS_CLIMATE_PLUS_CHANGE,
      payload: e.target.value,
    });
  };

  const leadContractIsCo2Change = (e) => {
    dispatch({
      type: LEAD_CONTRACT_IS_CO2_CHANGE,
      payload: e.target.value,
    });
  };

  const leadContractBillingName1Change = (e) => {
    dispatch({
      type: LEAD_CONTRACT_BILLING_NAME1_CHANGE,
      payload: e.target.value.substring(0, 40),
    });
  };

  const leadContractBillingName2Change = (e) => {
    dispatch({
      type: LEAD_CONTRACT_BILLING_NAME2_CHANGE,
      payload: e.target.value.substring(0,40),
    });
  };

  const leadContractBillingName3Change = (e) => {
    dispatch({
      type: LEAD_CONTRACT_BILLING_NAME3_CHANGE,
      payload: e.target.value.substring(0,40),
    });
  };

  const leadContractPasteBillingAddress = async () => {
	  
	const params = new URLSearchParams();
    params.append("jwt", sessionStorage.jwt);

    let url = process.env.REACT_APP_API_URI + "/leads/get_billing_addresses.php";
    let axiosConfig = { headers: { "Content-Type": "application/x-www-form-urlencoded" } };

    axios.post(url, params, axiosConfig)
      .then((response) => {
        if (response.status === 200) {

          	//   console.log(response.data);	
          	dispatch({
				type: LEAD_CONTRACT_PASTE_BILLING_ADDRESS,
				payload: response.data.billingAddresses
			});
        } else if (response.status === 401) {
          window.location.href = "#/logout";
        } else {
          console.log("Anderer Fehler");
        }
      })
      .catch(function (error) {
        if (error.response) {
          // The server answered with an error
          if (error.response.status === 401) {
            window.location.href = "#/logout";
          } else {
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          }
        } else if (error.request) {
          //  The request was made but the server didn't respond
          console.log(error.request);
        } else {
          //  Something else went wrong
          console.log("Error", error.message);
        }
      });
  }

  const leadContractPeriodConsumptionChange = (e) => {
    dispatch({
      type: LEAD_CONTRACT_PERIOD_CONSUMPTION_CHANGE,
      payload: e.target.value,
    });
  };

  const leadContractPotsIdChange = (e) => {

	if(state.leadContractsDialogError !== "" && state.leadContractPotsId === 0)
		setContractErrMsg("", 1);

    const potsId = e.target.value;

    const params = new URLSearchParams();
    params.append("jwt", sessionStorage.jwt);
    params.append("divisionsId", state.leadContractDivisionsId);
    params.append("energyCampaignsId", state.energyCampaignsId);
    params.append("gasCampaignsId", state.gasCampaignsId);

    if (state.leadContractDivisionsId === "1") {
      params.append("energyPotsId", state.energyPotsId);
      params.append("gasPotsId", potsId);
    } else {
      params.append("energyPotsId", potsId);
      params.append("gasPotsId", state.gasPotsId);
    }
	params.append("leadId", state.activeLeadId);

    let url = process.env.REACT_APP_API_URI + "/leads/get_contract_product.php";
    let axiosConfig = {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    };

    axios
      .post(url, params, axiosConfig)
      .then((response) => {
        if (response.status === 200) {
            // console.log(response.data);

			//	PRESET - NOTHING CHANGES, JUST CHECK ACTIVATION
			let isLeadContractTransitionInitialized = parseInt(state.isLeadContractTransitionInitialized < 2) ? 1 : state.isLeadContractTransitionInitialized;
			let isLeadContractInitialTransition = (parseInt(state.isLeadContractTransitionInitialized) < 2) ? 1 : state.isLeadContractInitialTransition;
			let isLeadContractTransition = (isLeadContractInitialTransition === 1 ) ? 1 : state.isLeadContractTransition;
			let isLeadContractTransitionTenPercent = (isLeadContractInitialTransition === 1) ? 1 : state.isLeadContractTransitionTenPercent;
			let leadContractTransitionDate = state.leadContractTransitionDate;
			let leadContractTransitionTenPercentDate = state.leadContractTransitionTenPercentDate;

			if(state.activeLeadContract <= 0) {

				//	NEW CONTRACT - HERITATE
				isLeadContractTransitionInitialized = parseInt(response.data.isLeadContractTransitionInitialized) < 2 ? 1 : parseInt(response.data.isLeadContractTransitionInitialized);
				isLeadContractInitialTransition = parseInt(response.data.isLeadContractTransitionInitialized) < 2 ? 1 : state.isLeadContractInitialTransition;
				isLeadContractTransition = (isLeadContractInitialTransition === 1) ? 1 : response.data.isLeadContractTransition;
				if (response.data.leadContractTransitionDate !== "") {
					leadContractTransitionDate = new Date(response.data.leadContractTransitionDate);
				}

				isLeadContractTransitionTenPercent = (isLeadContractInitialTransition === 1 ) ? 1 : response.data.isLeadContractTransitionTenPercent;
				if (response.data.leadContractTransitionTenPercentDate !== "") {
					leadContractTransitionTenPercentDate = new Date(response.data.leadContractTransitionTenPercentDate);
				}
			}

          dispatch({
            type: LEAD_CONTRACT_POTS_ID_CHANGE,
            payload: {
				potsId: potsId,
				isLeadContractTransitionInitialized: isLeadContractTransitionInitialized,
				isLeadContractInitialTransition: isLeadContractInitialTransition,
				isLeadContractTransition: isLeadContractTransition,
				leadContractTransitionDate: leadContractTransitionDate,
				isLeadContractTransitionTenPercent: isLeadContractTransitionTenPercent,
				leadContractTransitionTenPercentDate: leadContractTransitionTenPercentDate,
			}
          });

          dispatch({
            type: LEAD_CONTRACT_DIVISIONS_ID_CHANGE,
            payload: {
              divisionsId: state.leadContractDivisionsId,
              isPots: 1,
              pots: state.pots,
              potsId: potsId,
              isProduct: response.data.isProduct,
              products: response.data.products,
              productsId: response.data.productsId,
              isValidFrom: response.data.isValidFrom,
              validFrom: response.data.validFrom,
              isValidTo: response.data.isValidTo,
              validTo: response.data.validTo,
              isBio5: response.data.isBio5,
              bio5StartDate: response.data.bio5StartDate,
              isBio10: response.data.isBio10,
              bio10StartDate: response.data.bio10StartDate,
              isCo2: response.data.isCo2,
              isClimatePlus: response.data.isClimatePlus,
              loadProfiles: response.data.loadProfiles,
              loadProfilesId: response.data.loadProfilesId,
              isOptionen: response.data.isOptionen,
              suppliers: response.data.suppliers,
              networks: response.data.networks,
            },
          });
        } else if (response.status === 401) {
          window.location.href = "#/logout";
        } else {
          console.log("Anderer Fehler");
        }
      })
      .catch(function (error) {
        if (error.response) {
          // The server answered with an error
          if (error.response.status === 401) {
            window.location.href = "#/logout";
          } else {
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          }
        } else if (error.request) {
          //  The request was made but the server didn't respond
          console.log(error.request);
        } else {
          //  Something else went wrong
          console.log("Error", error.message);
        }
      });
  };

  const leadContractProductsIdChange = (e) => {
    dispatch({
      type: LEAD_CONTRACT_PRODUCTS_ID_CHANGE,
      payload: e.target.value,
    });
  };

  const leadContractSalutationsIdChange = (e) => {
    dispatch({
      type: LEAD_CONTRACT_SALUTATIONS_ID_CHANGE,
      payload: e.target.value,
    });
  };

  const leadContractSaveBillingAddress = () => {

	const params = new URLSearchParams();
    params.append("jwt", sessionStorage.jwt);
	params.append('leadContractCustomerTypesId', state.leadContractCustomerTypesId);
	params.append('leadContractSalutationsId', state.leadContractSalutationsId);
	params.append('leadContractBillingName1', state.leadContractBillingName1);
	params.append('leadContractBillingName2', state.leadContractBillingName2);
	params.append('leadContractBillingName3', state.leadContractBillingName3);
	params.append('leadContractBillingStreet', state.leadContractBillingStreet);
	params.append('leadContractBillingHouseNumber', state.leadContractBillingHouseNumber);
	params.append('leadContractBillingPostalCode', state.leadContractBillingPostalCode);
	params.append('leadContractBillingCity', state.leadContractBillingCity);
	params.append('leadContractBillingDistrict', state.leadContractBillingDistrict);
	params.append('leadContractBillingCountry', state.leadContractBillingCountry);
	params.append('leadContractBillingAdditional', state.leadContractBillingAdditional);
	params.append('leadContractBillingPostboxNo', state.leadContractBillingPostboxNo);
	params.append('leadContractBillingPostboxPostalCode', state.leadContractBillingPostboxPostalCode);
	params.append('leadContractBillingPostboxCity', state.leadContractBillingPostboxCity);

    let url = process.env.REACT_APP_API_URI + "/leads/save_billing_address.php";
    let axiosConfig = { headers: { "Content-Type": "application/x-www-form-urlencoded" } };

    axios.post(url, params, axiosConfig)
      .then((response) => {
        if (response.status === 200) {
            // console.log(response.data);

			dispatch({
				type: LEAD_CONTRACT_BILLING_ADDRESS_SAVED
			});

        } else if (response.status === 401) {
          window.location.href = "#/logout";
        } else {
          console.log("Anderer Fehler");
        }
      })
      .catch(function (error) {
        if (error.response) {
          // The server answered with an error
          if (error.response.status === 401) {
            window.location.href = "#/logout";
          } else {
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          }
        } else if (error.request) {
          //  The request was made but the server didn't respond
          console.log(error.request);
        } else {
          //  Something else went wrong
          console.log("Error", error.message);
        }
      });
  }

  const leadContractValidFromChange = (e) => {
    dispatch({
      type: LEAD_CONTRACT_VALID_FROM_CHANGE,
      payload: e.target.value,
    });
  };

  const leadContractValidToChange = (e) => {
    dispatch({
      type: LEAD_CONTRACT_VALID_TO_CHANGE,
      payload: e.target.value,
    });
  };

  // 	CHANGE THE CONTRACT TRANSITION CHECKBOX
  const isLeadContractTransitionChange = (e) => {
	const isChange = (e.target.checked === false) ? 0 : 1;
		dispatch({
		type: IS_LEAD_CONTRACT_TRANSITION_CHANGE,
		payload: isChange,
	});
  }

  //	CHANGE THE CONTRACT TRANSITION DATE
  const leadContractTransitionDateChange = (date) => {
	dispatch({
		type: LEAD_CONTRACT_TRANSITION_DATE_CHANGE,
		payload: date,
	});
  }

  // 	CHANGE THE CONTRACT 10% TRANSITION CHECKBOX
  const isLeadContractTransitionTenPercentChange = (e) => {
	const isChange = (e.target.checked === false) ? 0 : 1;
		dispatch({
		type: IS_LEAD_CONTRACT_TRANSITION_TEN_PERCENT_CHANGE,
		payload: isChange,
	});
  }

  //	CHANGE THE CONTRACT 10% TRANSITION DATE
  const leadContractTransitionTenPercentDateChange = (date) => {
	dispatch({
		type: LEAD_CONTRACT_TRANSITION_TEN_PERCENT_DATE_CHANGE,
		payload: date,
	});
  }

  const leadCounterAccountNoChange = (e) => {
    dispatch({
      type: LEAD_COUNTER_ACCOUNT_NO_CHANGE,
      payload: e.target.value,
    });
  };

  const leadCounterAccountOwnerChange = (e) => {
    dispatch({
      type: LEAD_COUNTER_ACCOUNT_OWNER_CHANGE,
      payload: e.target.value,
    });
  };

  const leadCounterBankCodeChange = (e) => {
    dispatch({
      type: LEAD_COUNTER_BANK_CODE_CHANGE,
      payload: e.target.value,
    });
  };

  const leadCounterBhkwChange = (e) => {
    dispatch({
      type: LEAD_COUNTER_BHKW,
      payload: e.target.checked,
    });
  };

  const leadCounterContractAccountChange = (e) => {
    dispatch({
      type: LEAD_COUNTER_CONTRACT_ACCOUNT_CHANGE,
      payload: e.target.value.substring(0, 35),
    });
  };

  const leadCounterChangeReasonsIdChange = (e) => {

	if(state.leadContractsDialogError !== "" && state.leadCounterChangeReasonsId < 0)
		setContractErrMsg("", 2);

    dispatch({
      type: LEAD_COUNTER_CHANGE_REASON_ID_CHANGE,
      payload: e.target.value,
    });
  };

  const leadCounterChangeSupplyStartChange = (date) => {

	if(state.leadContractsDialogError !== "" && state.leadCounterChangeSupplyStart === "")
		setContractErrMsg("", 2);

	if(!date)
		date = "";

    dispatch({
      type: LEAD_COUNTER_CHANGE_SUPPLY_START_CHANGE,
      payload: date,
    });
  };

  const leadCounterFormerSupplierIdChange = (e) => {
    dispatch({
      type: LEAD_COUNTER_FORMER_SUPPLIER_ID_CHANGE,
      payload: e.target.value,
    });
  };

  const leadCounterIbanChange = (e) => {
    dispatch({
      type: LEAD_COUNTER_IBAN_CHANGE,
      payload: e.target.value,
    });
  };

  const leadCounterIdChange = (e) => {
    dispatch({
      type: LEAD_COUNTER_ID_CHANGE,
      payload: e.target.value,
    });
  };

  const leadCounterInstallmentCycleChange = (e) => {
    dispatch({
      type: LEAD_COUNTER_INSTALLMENT_CYCLE_CHANGE,
      payload: e.target.value,
    });
  };

  const leadCounterIntendedUseChange = (e) => {
	  let intendedUse = e.target.value === '' ? '*' : e.target.value.substring(0, 27);
    dispatch({
      type: LEAD_COUNTER_INTENDED_USE_CHANGE,
      payload: intendedUse,
    });
  };

  const leadCounterMediumVoltageChange = (e) => {
    dispatch({
      type: LEAD_COUNTER_MEDIUM_VOLTAGE_CHANGE,
      payload: e.target.checked,
    });
  };

  const leadCounterNetworksIdChange = (e) => {

	if(state.leadContractsDialogError !== "" && state.leadCounterNetworksId < 0)
		setContractErrMsg("", 2);

    dispatch({
      type: LEAD_COUNTER_NETWORKS_ID_CHANGE,
      payload: e.target.value,
    });
  };

  const leadCounterNumberCurrentChange = (e) => {
    dispatch({
      type: LEAD_COUNTER_NUMBER_CURRENT_CHANGE,
      payload: e.target.value,
    });
  };

  const leadCountryLocationAdditionalChange = (e) => {
    dispatch({
      type: LEAD_COUNTRY_LOCATION_ADDITIONAL_CHANGE,
      payload: e.target.value,
    });
  };

  const leadCounterLocationCityChange = (e) => {
    dispatch({
      type: LEAD_COUNTER_LOCATION_CITY_CHANGE,
      payload: e.target.value,
    });
  };

  const leadCounterLocationCountryChange = (e) => {
    dispatch({
      type: LEAD_COUNTER_LOCATION_COUNTRY_CHANGE,
      payload: e.target.value,
    });
  };

  const leadCounterLocationDistrictChange = (e) => {
    dispatch({
      type: LEAD_COUNTER_LOCATION_DISTRICT_CHANGE,
      payload: e.target.value,
    });
  };

  const leadCounterLocationHouseNumberChange = (e) => {
    dispatch({
      type: LEAD_COUNTER_LOCATION_HOUSE_NUMBER_CHANGE,
      payload: e.target.value,
    });
  };

  const leadCounterLocationMarketLocationChange = (e) => {
    dispatch({
      type: LEAD_COUNTER_LOCATION_MARKET_LOCATION_CHANGE,
      payload: e.target.value,
    });
  };

  const leadCounterLocationMeasureLocationChange = (e) => {
    dispatch({
      type: LEAD_COUNTER_LOCATION_MEASURE_LOCATION_CHANGE,
      payload: e.target.value,
    });
  };

  const leadCounterLocationPostalCodeChange = (e) => {
    dispatch({
      type: LEAD_COUNTER_LOCATION_POSTAL_CODE_CHANGE,
      payload: e.target.value,
    });
  };

  const leadCounterLocationStreetChange = (e) => {
    dispatch({
      type: LEAD_COUNTER_LOCATION_STREET_CHANGE,
      payload: e.target.value,
    });
  };

  const leadCounterLoadProfilesIdChange = (e) => {
    dispatch({
      type: LEAD_COUNTER_LOAD_PROFILES_ID_CHANGE,
      payload: e.target.value,
    });
  };

  const leadCounterValueChange = (e) => {
    dispatch({
      type: LEAD_COUNTER_VALUE_CHANGE,
      payload: e.target.value,
    });
  };

  const editLeadContract = (id) => {
    let divisionsId = -1;

	if(state.bIsLeadEnergyTransitionChange === true || state.bIsLeadEnergyTransitionTenPercentChange === true || state.bIsLeadGasTransitionChange === true || state.bIsLeadGasTransitionTenPercentChange === true) {
		return setErrMsg(
			"Kundenübergang wurde verändert. Bitte den Potenzialkunden erst speichern oder die Änderungen verwerfen, damit die Werte auf die Abnahmestellen vererbt werden können. Erst dann ist eine Bearbeitung möglich",
			4,
			state.activeAddressTab,
		);
	}

    if (id > 0) {
      const params = new URLSearchParams();
      params.append("jwt", sessionStorage.jwt);
      params.append("id", id);
      params.append("gasCampaignsId", state.gasCampaignsId);
      params.append("energyCampaignsId", state.energyCampaignsId);

      let url = process.env.REACT_APP_API_URI + "/leads/get_lead_contract.php";
      let axiosConfig = {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      };

      axios
        .post(url, params, axiosConfig)
        .then((response) => {
          if (response.status === 200) {
            // console.log(response.data);

			let leadContractTransitionDate = "";
			if (response.data.leadContract.leadContractTransitionDate !== "") {
				leadContractTransitionDate = new Date(response.data.leadContract.leadContractTransitionDate);
			}

			let leadContractTransitionTenPercentDate = "";
			if (response.data.leadContract.leadContractTransitionTenPercentDate !== "") {
				leadContractTransitionTenPercentDate = new Date(response.data.leadContract.leadContractTransitionTenPercentDate);
			}

            let supplyStart = "";
            if (response.data.leadContract.leadCounterChangeSupplyStart !== "") {
              supplyStart = new Date(response.data.leadContract.leadCounterChangeSupplyStart);
            }

            let bio5StartDate = "";
            if (response.data.leadContract.bio5StartDate !== "") {
              bio5StartDate = new Date(response.data.leadContract.bio5StartDate);
            }

            let bio10StartDate = "";
            if (response.data.leadContract.bio10StartDate !== "") {
              bio10StartDate = new Date(response.data.leadContract.bio10StartDate);
            }

            divisionsId = response.data.leadContract.divisionsId;

            dispatch({
              type: EDIT_LEAD_CONTRACT,
              payload: {
                id: id,
                title: "Abnahmestelle bearbeiten",
                divisionsId: response.data.leadContract.divisionsId,
                leadContractProductsId: response.data.leadContract.productsId,
                leadContractValidFrom: response.data.leadContract.validFrom,
                leadContractValidTo: response.data.leadContract.validTo,
				isLeadContractTransitionInitialized: response.data.leadContract.isLeadContractTransitionInitialized,
				isLeadContractTransition: response.data.leadContract.isLeadContractTransition,
				leadContractTransitionDate: leadContractTransitionDate,
				isLeadContractTransitionTenPercent: response.data.leadContract.isLeadContractTransitionTenPercent,
				leadContractTransitionTenPercentDate: leadContractTransitionTenPercentDate,

				leadContractPotsId: response.data.leadContract.potsId,
                leadContractIsClimatePlus:
                  response.data.leadContract.isClimatePlus,
                leadContractIsBio5: response.data.leadContract.isBio5,
                leadContractBio5StartDate: bio5StartDate,
                leadContractIsBio10: response.data.leadContract.isBio10,
                leadContractBio10StartDate: bio10StartDate,
                leadContractIsCo2: response.data.leadContract.isCo2,
                leadContractPeriodConsumption:
                  response.data.leadContract.periodConsumption,
                leadCounterId: response.data.leadContract.id,
                leadCounterNumberCurrent:
                  response.data.leadContract.leadCounterNumberCurrent,
                leadCounterNumberDoubling:
                  response.data.leadContract.leadCounterNumberDoubling,
                leadCounterMediumVoltage:
                  response.data.leadContract.isLeadCounterMediumVoltage,
                leadCounterBhkw: response.data.leadContract.isLeadCounterBhkw,
                leadCounterContractAccount:
                  response.data.leadContract.leadCounterContractAccount,
                leadCounterIntendedUse:
                  response.data.leadContract.leadCounterIntendedUse,

                leadCounterChangeReasonsId:
                  response.data.leadContract.leadCounterChangeReasonsId,
                leadCounterChangeSupplyStart: supplyStart,
                leadCounterFormerSuppliersId:
                  response.data.leadContract.leadCounterFormerSuppliersId,
                leadCounterInstallmentCycle:
                  response.data.leadContract.leadCounterInstallmentCycle,
                leadCounterNetworksId:
                  response.data.leadContract.leadCounterNetworksId,
                leadCounterLoadProfilesId: response.data.loadProfilesId,
                leadCounterValue: response.data.leadContract.leadCounterValue,
                leadCounterLocationStreet:
                  response.data.leadContract.leadCounterLocationStreet,
                leadCounterLocationHouseNumber:
                  response.data.leadContract.leadCounterLocationHouseNumber,
                leadCounterLocationPostalCode:
                  response.data.leadContract.leadCounterLocationPostalCode,
                leadCounterLocationCity:
                  response.data.leadContract.leadCounterLocationCity,
                leadCounterLocationDistrict:
                  response.data.leadContract.leadCounterLocationDistrict,
                leadCounterLocationCountry:
                  response.data.leadContract.leadCounterLocationCountry,
                leadCounterLocationAdditional:
                  response.data.leadContract.leadCounterLocationAdditional,
                leadCounterLocationMarketLocation:
                  response.data.leadContract.leadCounterLocationMarketLocation,
                leadCounterLocationMeasureLocation:
                  response.data.leadContract.leadCounterLocationMeasureLocation,
                leadCounterAccountNo:
                  response.data.leadContract.leadCounterAccountNo,
                leadCounterAccountOwner:
                  response.data.leadContract.leadCounterAccountOwner,
                leadCounterBankCode:
                  response.data.leadContract.leadCounterBankCode,
                leadCounterIban: response.data.leadContract.leadCounterIban,
                leadContractSalutationsId:
                  response.data.leadContract.salutationsId,
                leadContractCustomerTypesId:
                  response.data.leadContract.billingCustomerTypesId,
                leadContractBillingName1:
                  response.data.leadContract.billingName1,
                leadContractBillingName2:
                  response.data.leadContract.billingName2,
                leadContractBillingName3:
                  response.data.leadContract.billingName3,
                leadContractBillingStreet:
                  response.data.leadContract.billingStreet,
                leadContractBillingHouseNumber:
                  response.data.leadContract.billingHouseNumber,
                leadContractBillingPostalCode:
                  response.data.leadContract.billingPostalCode,
                leadContractBillingCity: response.data.leadContract.billingCity,
                leadContractBillingDistrict:
                  response.data.leadContract.billingDistrict,
                leadContractBillingCountry:
                  response.data.leadContract.billingCountry,
                leadContractBillingAdditional:
                  response.data.leadContract.billingAdditional,
                leadContractBillingPostboxNo:
                  response.data.leadContract.billingPostboxNo,
                leadContractBillingPostboxPostalCode:
                  response.data.leadContract.billingPostboxPostalCode,
                leadContractBillingPostboxCity:
                  response.data.leadContract.billingPostboxCity,

                isPots: response.data.isPots,
                pots: response.data.pots,
                potsid: response.data.potsId,
                isProduct: response.data.isProduct,
                products: response.data.products,
                productsId: response.data.productsId,
                isValidFrom: response.data.isValidFrom,
                validFrom: response.data.validFrom,
                isValidTo: response.data.isValidTo,
                validTo: response.data.validTo,
                isBio5: response.data.isBio5,
                isBio10: response.data.isBio10,
                isCo2: response.data.isCo2,
                isClimatePlus: response.data.isClimatePlus,
                loadProfiles: response.data.loadProfiles,
                loadProfilesId: response.data.loadProfilesId,
                isOptionen: response.data.isOptionen,
              },
            });
            //	Get suppliers and networks
            let url =
              process.env.REACT_APP_API_URI +
              "/leads/get_lead_contract_foreigns.php";
            let axiosConfig = {
              headers: { "Content-Type": "application/x-www-form-urlencoded" },
            };
            const params = new URLSearchParams();
            params.append("jwt", sessionStorage.jwt);
            params.append("divisionsId", divisionsId);

            axios
              .post(url, params, axiosConfig)
              .then((response) => {
                if (response.status === 200) {
                  // console.log(response.data);

                  dispatch({
                    type: UPDATE_CONTRACT_EXTERNALS,
                    payload: {
                      suppliers: response.data.suppliers,
                      networks: response.data.networks,
                    },
                  });
                } else if (response.status === 401) {
                  window.location.href = "#/logout";
                } else {
                  console.log("Anderer Fehler");
                }
              })
              .catch(function (error) {
                if (error.response) {
                  // The server answered with an error
                  if (error.response.status === 401) {
                    window.location.href = "#/logout";
                  } else {
                    console.log(error.response.data);
                    console.log(error.response.status);
                    console.log(error.response.headers);
                  }
                } else if (error.request) {
                  //  The request was made but the server didn't respond
                  console.log(error.request);
                } else {
                  //  Something else went wrong
                  console.log("Error", error.message);
                }
              });
          } else if (response.status === 401) {
            window.location.href = "#/logout";
          } else {
            console.log("Anderer Fehler");
          }
        })
        .catch(function (error) {
          if (error.response) {
            // The server answered with an error
            if (error.response.status === 401) {
              window.location.href = "#/logout";
            } else {
              console.log(error.response.data);
              console.log(error.response.status);
              console.log(error.response.headers);
            }
          } else if (error.request) {
            //  The request was made but the server didn't respond
            console.log(error.request);
          } else {
            //  Something else went wrong
            console.log("Error", error.message);
          }
        });
    } else {
      dispatch({
        type: EDIT_LEAD_CONTRACT,
        payload: {
          id: id,
          title: "Neue Abnahmestelle",
          divisionsId: -1,
          leadContractProductsId: -1,
          leadContractValidFrom: "Kein Produkt gewählt",
          leadContractValidTo: "Kein Produkt gewählt",
		  isLeadContractTransitionInitialized: 0, 
		  isLeadContractInitialTransition: 0,
		  isLeadContractTransition: 0,
		  leadContractTransitionDate: '',
		  isLeadContractTransitionTenPercent: 0,
		  leadContractTransitionTenPercentDate: '',
          leadContractPotsId: -1,
          leadContractIsClimatePlus: false,
          leadContractIsBio5: false,
          leadContractBio5StartDate: "",
          leadContractIsBio10: false,
          leadContractBio10StartDate: "",
          leadContractIsCo2: false,
          leadContractPeriodConsumption: "",
          leadCounterId: -1,
          leadCounterNumberCurrent: "",
          leadCounterNumberDoubling: 0,
          leadCounterMediumVoltage: false,
          leadCounterBhkw: false,
          leadCounterContractAccount: "",
          leadCounterIntendedUse: "*",
          leadCounterChangeReasonsId: -1,
          leadCounterChangeSupplyStart: "",
          leadCounterFormerSuppliersId: -1,
          leadCounterInstallmentCycle: "",
          leadCounterNetworksId: -1,
          leadCounterLoadProfilesId: -1,
          leadCounterValue: "",
          leadCounterLocationStreet: "",
          leadCounterLocationHouseNumber: "",
          leadCounterLocationPostalCode: "",
          leadCounterLocationCity: "",
          leadCounterLocationDistrict: "",
          leadCounterLocationCountry: "DE",
          leadCounterLocationAdditional: "",
          leadCounterLocationMarketLocation: "",
          leadCounterLocationMeasureLocation: "",
          leadCounterAccountNo: "",
          leadCounterAccountOwner: "",
          leadCounterBankCode: "",
          leadCounterIban: "",
          leadContractSalutationsId: -1,
          leadContractCustomerTypesId: -1,
          leadContractBillingName1: "",
          leadContractBillingName2: "",
          leadContractBillingName3: "",
          leadContractBillingStreet: "",
          leadContractBillingHouseNumber: "",
          leadContractBillingPostalCode: "",
          leadContractBillingCity: "",
          leadContractBillingDistrict: "",
          leadContractBillingCountry: "DE",
          leadContractBillingAdditional: "",
          leadContractBillingPostboxNo: "",
          leadContractBillingPostboxPostalCode: "",
          leadContractBillingPostboxCity: "",

          isPots: 0,
          pots: [],
          potsid: 0,
          isProduct: 0,
          products: [{ id: -1, value: "Keine Sparte gewählt" }],
          productsId: -1,
          isValidFrom: 0,
          validFrom: "",
          isValidTo: 0,
          validTo: "",
          isBio5: false,
          isBio10: false,
          isCo2: false,
          isClimatePlus: false,
          loadProfiles: [{ id: -1, value: "Keine Sparte gewählt" }],
          loadProfilesId: -1,
          isOptionen: 0,
        },
      });

      //	Get suppliers and networks
      let url =
        process.env.REACT_APP_API_URI + "/leads/get_lead_contract_foreigns.php";
      let axiosConfig = {
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
      };
      const params = new URLSearchParams();
      params.append("jwt", sessionStorage.jwt);
      params.append("divisionsId", divisionsId);

      axios
        .post(url, params, axiosConfig)
        .then((response) => {
          if (response.status === 200) {
            // console.log(response.data);

            dispatch({
              type: UPDATE_CONTRACT_EXTERNALS,
              payload: {
                suppliers: response.data.suppliers,
                networks: response.data.networks,
              },
            });
          } else if (response.status === 401) {
            window.location.href = "#/logout";
          } else {
            console.log("Anderer Fehler");
          }
        })
        .catch(function (error) {
          if (error.response) {
            // The server answered with an error
            if (error.response.status === 401) {
              window.location.href = "#/logout";
            } else {
              console.log(error.response.data);
              console.log(error.response.status);
              console.log(error.response.headers);
            }
          } else if (error.request) {
            //  The request was made but the server didn't respond
            console.log(error.request);
          } else {
            //  Something else went wrong
            console.log("Error", error.message);
          }
        });
    }

    // //	Get suppliers and networks
    // let url 		= process.env.REACT_APP_API_URI + '/leads/get_lead_contract_foreigns.php';
    // let axiosConfig = { headers: { "Content-Type": "application/x-www-form-urlencoded" } };
    // const params 	= new URLSearchParams();
    // params.append('jwt', sessionStorage.jwt);
    // params.append('divisionsId', divisionsId);

    // axios.post(url,params,axiosConfig)
    // .then(response => {
    // 	if(response.status === 200) {

    // 		console.log(response.data);

    // 		dispatch({
    // 			type: UPDATE_CONTRACT_EXTERNALS,
    // 			payload: {
    // 				suppliers: response.data.suppliers,
    // 				networks: response.data.networks,
    // 			}
    // 		});

    // 	} else if(response.status === 401) {
    // 		window.location.href ='#/logout';
    // 	} else {
    // 		console.log('Anderer Fehler');
    // 	}
    // })
    // .catch(function(error) {
    // 	if(error.response){
    // 		// The server answered with an error
    // 		if(error.response.status === 401) {
    // 			window.location.href = '#/logout';
    // 		} else {
    // 			console.log(error.response.data);
    // 			console.log(error.response.status);
    // 			console.log(error.response.headers);
    // 		}
    // 	} else if(error.request) {
    // 		//  The request was made but the server didn't respond
    // 		console.log(error.request);
    // 	} else {
    // 		//  Something else went wrong
    // 		console.log('Error', error.message);
    // 	}
    // })
  };

  const saveLeadContractDetails = () => {

	//	First check if network operator is needed
	const params1st = new URLSearchParams();
	params1st.append('jwt', sessionStorage.jwt);
	params1st.append('transactionReasonsId', state.leadCounterChangeReasonsId);

	let axiosConfig1st = { headers: { "Content-Type": "application/x-www-form-urlencoded" } };
	let url1st = process.env.REACT_APP_API_URI + '/leads/check_network_operator.php';

	axios.post(url1st,params1st,axiosConfig1st)
	.then(response => {
		if(response.status === 200) {

			// console.log(response.data);

			if(parseInt(response.data.isNetworkOperatorNeeded) === 1) {
				
				//	Lieferbeginn gesetzt, Netzbetreiber fehlt
				//	Grund gesetzt, Netzbetreiber fehlt
				if((state.leadCounterChangeSupplyStart !== "" || state.leadCounterChangeReasonsId > 0) && state.leadCounterNetworksId < 0) {

					setContractErrMsg("Bitte wählen Sie einen Netzbetreiber für den Lieferantenwechsel aus", 2);
					return false;
				}

				//	Grund gesetzt, Lieferbeginn fehlt
				//	Netzbetreiber gesetzt, Lieferbeginn fehlt
				if((state.leadCounterChangeReasonsId > 0 || state.leadCounterNetworksId > 0) && state.leadCounterChangeSupplyStart === "") {

					setContractErrMsg("Bitte geben Sie einen Lieferbeginn beim Lieferantenwechsel ein.", 2);
					return false;
				}

			} else {
				
				//	Lieferbeginn fehlt
				if(state.leadCounterChangeReasonsId > 0 && state.leadCounterChangeSupplyStart === "") {

					setContractErrMsg("Bitte geben Sie einen Lieferbeginn beim Lieferantenwechsel ein.", 2);
					return false;
				}

				//	Grund fehlt
				if(state.leadCounterChangeSupplyStart !== "" && state.leadCounterChangeReasonsId < 0) {

					setContractErrMsg("Bitte wählen Sie einen Grund für den Lieferantenwechsel aus.", 2);
					return false;
				}
			}

			if(state.leadContractDivisionsId <= 0) {
				setContractErrMsg("Bitte wählen Sie eine Sparte für die Abnahmestelle aus", 1);
				return false;
			}

			if(state.leadContractPotsId === 0) {
				setContractErrMsg("Bitte wählen Sie einen Energietopf/ein Produkt aus", 1);
				return false;
			}

			if (state.isLeadContractTransition === 1 && state.leadContractTransitionDate === '') {
				setContractErrMsg("Bitte geben Sie ein Datum für den Kundenübergang an",1);
				return false;
			}
		
			if (state.isLeadContractTransitionTenPercent === 1 && state.leadContractTransitionTenPercentDate === '') {
				setContractErrMsg("Bitte geben Sie ein Datum für den 10%-Kundenübergang an.", 1);
				return false;
			}

			const params = new URLSearchParams();
			params.append("jwt", sessionStorage.jwt);
			params.append("id", state.activeLeadContract);
			params.append("table", "lead_contracts");
			params.append("leads_id", state.activeLeadId);
			params.append("divisions_id", state.leadContractDivisionsId);
			params.append("products_id", state.leadContractProductsId);
			params.append(
			"valid_from",
			state.leadContractValidFrom === "Kein Produkt gewählt"
				? ""
				: state.leadContractValidFrom
			);
			params.append(
			"valid_to",
			state.leadContractValidTo === "Kein Produkt gewählt"
				? ""
				: state.leadContractValidTo
			);

			const isLCIT = (state.isLeadContractTransitionInitialized > 0) ? 2 : 0;
			params.append("is_lead_contract_transition_initialized", isLCIT);
			const isLCT = (state.isLeadContractTransition === 1 || state.isLeadContractTransition === true) ? true : false;
			params.append("is_lead_contract_transition", isLCT);
			const isLetTenPercent = (state.isLeadContractTransitionTenPercent === 1 || state.isLeadContractTransitionTenPercent === true) ? true : false;
			params.append("is_lead_contract_transition_ten_percent", isLetTenPercent);		
			params.append("pots_id", state.leadContractPotsId);
			params.append("is_climate_plus", state.leadContractIsClimatePlus);
			params.append("is_bio5", state.leadContractIsBio5);
			params.append("is_bio10", state.leadContractIsBio10);
			params.append("is_co2", state.leadContractIsCo2);
			params.append("period_consumption", state.leadContractPeriodConsumption);
			params.append(
			"lead_counter_number_current",
			state.leadCounterNumberCurrent
			);
			params.append(
			"is_lead_counter_medium_voltage",
			state.leadCounterMediumVoltage
			);
			params.append("is_lead_counter_bhkw", state.leadCounterBhkw);
			params.append(
			"lead_counter_contract_account",
			state.leadCounterContractAccount
			);
			params.append("lead_counter_intended_use", state.leadCounterIntendedUse);
			params.append(
			"lead_counter_change_reasons_id",
			state.leadCounterChangeReasonsId
			);
			params.append(
			"lead_counter_former_suppliers_id",
			state.leadCounterFormerSupplierId
			);
			params.append(
			"lead_counter_installment_cycle",
			state.leadCounterInstallmentCycle
			);
			params.append("lead_counter_networks_id", state.leadCounterNetworksId);
			params.append(
			"lead_counter_load_profiles_id",
			state.leadCounterLoadProfilesId
			);
			params.append("lead_counter_value", state.leadCounterValue);

			params.append(
			"lead_counter_location_additional",
			state.leadCountryLocationAdditional
			);
			params.append("lead_counter_location_city", state.leadCounterLocationCity);
			params.append(
			"lead_counter_location_country",
			state.leadCounterLocationCountry
			);
			params.append(
			"lead_counter_location_district",
			state.leadCounterLocationDistrict
			);
			params.append(
			"lead_counter_location_house_number",
			state.leadCounterLocationHouseNumber
			);
			params.append(
			"lead_counter_location_market_location",
			state.leadCounterLocationMarketLocation
			);
			params.append(
			"lead_counter_location_measure_location",
			state.leadCounterLocationMeasureLocation
			);
			params.append(
			"lead_counter_location_postal_code",
			state.leadCounterLocationPostalCode
			);
			params.append(
			"lead_counter_location_street",
			state.leadCounterLocationStreet
			);
			params.append("lead_counter_account_no", state.leadCounterAccountNo);
			params.append("lead_counter_account_owner", state.leadCounterAccountOwner);
			params.append("lead_counter_bank_code", state.leadCounterBankCode);
			params.append("lead_counter_iban", state.leadCounterIban);

			params.append("billing_name1", state.leadContractBillingName1);
			params.append("billing_name2", state.leadContractBillingName2);
			params.append("billing_name3", state.leadContractBillingName3);
			params.append(
			"billing_customer_types_id",
			state.leadContractCustomerTypesId
			);
			params.append("salutations_id", state.leadContractSalutationsId);
			params.append("billing_street", state.leadContractBillingStreet);
			params.append("billing_house_number", state.leadContractBillingHouseNumber);
			params.append("billing_postal_code", state.leadContractBillingPostalCode);
			params.append("billing_city", state.leadContractBillingCity);
			params.append("billing_district", state.leadContractBillingDistrict);
			params.append("billing_country", state.leadContractBillingCountry);
			params.append("billing_additional", state.leadContractBillingAdditional);
			params.append("billing_postbox_no", state.leadContractBillingPostboxNo);
			params.append(
			"billing_postbox_postal_code",
			state.leadContractBillingPostboxPostalCode
			);
			params.append("billing_postbox_city", state.leadContractBillingPostboxCity);

			const leadContractTransitionDate = state.leadContractTransitionDate !== "" ? new Date(state.leadContractTransitionDate) : "";
			let newLeadContractTransitionDate = "";	
			if(leadContractTransitionDate !== "") {
				const newCTDay = leadContractTransitionDate.getDate();
				const newCTMonth = leadContractTransitionDate.getMonth() + 1;
				const newCTYear = leadContractTransitionDate.getFullYear();
				newLeadContractTransitionDate = newCTYear + '-' + newCTMonth + '-' + newCTDay;
			}
			params.append("lead_contract_transition_date", newLeadContractTransitionDate);
		
			const leadContractTransitionTenPercentDate = state.leadContractTransitionTenPercentDate !== "" ? new Date(state.leadContractTransitionTenPercentDate) : "";
			let newLeadContractTransitionTenPercentDate = "";		
			if(leadContractTransitionTenPercentDate !== "") {
				const newCTTPDay = leadContractTransitionTenPercentDate.getDate();
				const newCTTPMonth = leadContractTransitionTenPercentDate.getMonth() + 1;
				const newCTTPYear = leadContractTransitionTenPercentDate.getFullYear();
				newLeadContractTransitionTenPercentDate = newCTTPYear + '-' + newCTTPMonth + '-' + newCTTPDay;
			}
			params.append("lead_contract_transition_ten_percent_date", newLeadContractTransitionTenPercentDate);

			const leadCounterChangeSupplyStart =
			state.leadCounterChangeSupplyStart !== ""
				? new Date(state.leadCounterChangeSupplyStart)
				: "";
			let newLeadCounterChangeSupplyStart = "";
			if (leadCounterChangeSupplyStart !== "") {
				const newDay = leadCounterChangeSupplyStart.getDate();
				const newMonth = leadCounterChangeSupplyStart.getMonth() + 1;
				const newYear = leadCounterChangeSupplyStart.getFullYear();
				newLeadCounterChangeSupplyStart = newYear + "-" + newMonth + "-" + newDay;
			}
			params.append(
			"lead_counter_change_supply_start",
			newLeadCounterChangeSupplyStart
			);

			const leadContractBio5StartDate =
			state.leadContractBio5StartDate !== ""
				? new Date(state.leadContractBio5StartDate)
				: "";
			let newLeadContractBio5StartDate = "";
			if (leadContractBio5StartDate !== "") {
				const newBio5Day = leadContractBio5StartDate.getDate();
				const newBio5Month = leadContractBio5StartDate.getMonth() + 1;
				const newBio5Year = leadContractBio5StartDate.getFullYear();
				newLeadContractBio5StartDate =
					newBio5Year + "-" + newBio5Month + "-" + newBio5Day;
			}
			params.append("bio5_start_date", newLeadContractBio5StartDate);

			const leadContractBio10StartDate =
			state.leadContractBio10StartDate !== ""
				? new Date(state.leadContractBio10StartDate)
				: "";
			let newLeadContractBio10StartDate = "";
			if (leadContractBio10StartDate !== "") {
				const newBio10Day = leadContractBio10StartDate.getDate();
				const newBio10Month = leadContractBio10StartDate.getMonth() + 1;
				const newBio10Year = leadContractBio10StartDate.getFullYear();
				newLeadContractBio10StartDate =
					newBio10Year + "-" + newBio10Month + "-" + newBio10Day;
			}
			params.append("bio10_start_date", newLeadContractBio10StartDate);

			let axiosConfig = {
			headers: {
				"Content-Type": "application/x-www-form-urlencoded",
			},
			};

			let url = process.env.REACT_APP_API_URI + "/leads/set_lead_contract.php";

			axios
			.post(url, params, axiosConfig)
			.then((response) => {
				if (response.status === 200) {

					// console.log(response.data);

					closeLeadContractsDialog();
					getContracts();

				} else if (response.status === 401) {
					window.location.href = "#/logout";
				} else {
					console.log("Anderer Fehler");
				}
			})
			.catch(function (error) {
				if (error.response) {
				// The server answered with an error
				if (error.response.status === 401) {
					window.location.href = "#/logout";
				} else {
					console.log(error.response.data);
					console.log(error.response.status);
					console.log(error.response.headers);
				}
				} else if (error.request) {
				//  The request was made but the server didn't respond
				console.log(error.request);
				} else {
				//  Something else went wrong
				console.log("Error", error.message);
				}
			});


		} else if(response.status === 401) {
			window.location.href ='#/logout';
		} else {
			console.log('Anderer Fehler');
			return false;
		}
	})
	.catch(function(error) {
		if(error.response){
			// The server answered with an error
			if(error.response.status === 401) {
				window.location.href = '#/logout';
			} else {
				console.log(error.response.data);
				console.log(error.response.status);
				console.log(error.response.headers);
				return false;
			}
		} else if(error.request) {
			//  The request was made but the server didn't respond
			console.log(error.request);
			return false;
		} else {
			//  Something else went wrong
			console.log('Error', error.message);
			return false;
		}
	})

	
  };

  const setActiveContractCounterTab = (tabIndex) => {
    dispatch({
      type: SET_ACTIVE_CONTRACT_COUNTER_TAB,
      payload: tabIndex,
    });
  };

  const setActiveLeadContractTab = (tabIndex) => {
    dispatch({
      type: SET_ACTIVE_LEAD_CONTRACT_TAB,
      payload: tabIndex,
    });
  };

  const leadContractBillingStreetChange = (e) => {
    dispatch({
      type: LEAD_CONTRACT_BILLING_STREET_CHANGE,
      payload: e.target.value,
    });
  };

  const leadContractBillingHouseNumberChange = (e) => {
    dispatch({
      type: LEAD_CONTRACT_BILLING_HOUSE_NUMBER_CHANGE,
      payload: e.target.value,
    });
  };

  const leadContractBillingPostalCodeChange = (e) => {
    dispatch({
      type: LEAD_CONTRACT_BILLING_POSTAL_CODE_CHANGE,
      payload: e.target.value,
    });
  };

  const leadContractBillingCityChange = (e) => {
    dispatch({
      type: LEAD_CONTRACT_BILLING_CITY_CHANGE,
      payload: e.target.value,
    });
  };

  const leadContractBillingDistrictChange = (e) => {
    dispatch({
      type: LEAD_CONTRACT_BILLING_DISTRICT_CHANGE,
      payload: e.target.value,
    });
  };

  const leadContractBillingCountryChange = (e) => {
    dispatch({
      type: LEAD_CONTRACT_BILLING_COUNTRY_CHANGE,
      payload: e.target.value,
    });
  };

  const leadContractBillingAdditionalChange = (e) => {
    dispatch({
      type: LEAD_CONTRACT_BILLING_ADDITIONAL_CHANGE,
      payload: e.target.value,
    });
  };

  const leadContractBillingPostboxNoChange = (e) => {
    dispatch({
      type: LEAD_CONTRACT_BILLING_POSTBOX_NO_CHANGE,
      payload: e.target.value,
    });
  };

  const leadContractBillingPostboxPostalCodeChange = (e) => {
    dispatch({
      type: LEAD_CONTRACT_BILLING_POSTBOX_POSTAL_CODE_CHANGE,
      payload: e.target.value,
    });
  };

  const leadContractBillingPostboxCityChange = (e) => {
    dispatch({
      type: LEAD_CONTRACT_BILLING_POSTBOX_CITY_CHANGE,
      payload: e.target.value,
    });
  };

  //----------------------------------------------
  //  CONTACT HISTORY
  //----------------------------------------------

  const changeContactHistoryDetailObjectId = (e) => {
    dispatch({
      type: CHANGE_CONTACT_HISTORY_DETAIL_OBJECT_ID,
      payload: e.target.value,
    });
  };

  const changeContactHistoryDetailRemarks = (e) => {
    //  Check for error message clearance
    if (
      state.contactHistoryError !== "" &&
      state.contactHistoryDetailRemarks === ""
    )
      setContactHistoryError("");

    dispatch({
      type: CHANGE_CONTACT_HISTORY_DETAIL_REMARKS,
      payload: e.target.value,
    });
  };

  const changeContactHistoryDetailTopic = (e) => {
    //  Check for clearance of error message
    if (
      state.contactHistoryError !== "" &&
      state.contactHistoryDetailTopic === ""
    )
      setContactHistoryError("");

    dispatch({
      type: CHANGE_CONTACT_HISTORY_DETAIL_TOPIC,
      payload: e.target.value,
    });
  };

  const contactHistoryDetailDocumentChange = (e) => {
    //  File Upload
    const fd = new FormData();
    fd.append("image", e.target.files[0], e.target.files[0].name);
    fd.append("jwt", sessionStorage.jwt);

    // const fType = e.target.files[0].name.split('.').pop().toLowerCase();
    // if (fType !== 'pdf' && fType !== 'svg' && fType !== 'jpg' && fType !== 'gif' && fType !== 'png' && fType !== 'jpeg') {
    //     return setError('Bitte verwenden Sie nur die erlaubten Dateitypen JPG, JPEG, GIF, PNG, SVG oder PDF.');
    // }

    let url =
      process.env.REACT_APP_API_URI + "/upload/upload_form_document.php";

    axios
      .post(url, fd, {
        headers: {
          "content-type": "multipart/form-data",
        },
      })
      .then((res) => {
        // console.log(res.data);

        dispatch({
          type: CONTACT_HISTORY_DETAIL_DOCUMENT_CHANGE,
          payload: res.data,
        });
      })
      .catch(function (error) {
        if (error.response) {
          // The server answered with an error
          if (error.response.status === 401) {
            window.location.href = "#/logout";
          } else {
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          }
        } else if (error.request) {
          //  The request was made but the server didn't respond
          console.log(error.request);
        } else {
          //  Something else went wrong
          console.log("Error", error.message);
        }
      });
  };

  const contactHistoryDetailDocumentDelete = () => {
    dispatch({
      type: CONTACT_HISTORY_DETAIL_DOCUMENT_DELETE,
    });
  };

  const closeContactHistoryDialog = () => {
    dispatch({
      type: CLOSE_CONTACT_HISTORY_DIALOG,
    });
  };

  //	EDIT A CONTACT HISTORY ENTRY
  const editContactHistory = (id) => {
    if (id > 0) {
      const params = new URLSearchParams();
      params.append("jwt", sessionStorage.jwt);
      params.append("id", id);

      let url =
        process.env.REACT_APP_API_URI + "/leads/get_lead_contact_history.php";
      let axiosConfig = {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      };

      axios
        .post(url, params, axiosConfig)
        .then((response) => {
          if (response.status === 200) {
            // console.log(response.data);

            dispatch({
              type: EDIT_CONTACT_HISTORY,
              payload: {
                id: id,
                title: "Kontakt-Eintrag bearbeiten",
                topic: response.data.leadContactHistory.topic,
                remarks: response.data.leadContactHistory.remarks,
                objectId: response.data.leadContactHistory.objectId,
                document: response.data.leadContactHistory.document,
                isUser: response.data.leadContactHistory.isUser,
              },
            });
          } else if (response.status === 401) {
            window.location.href = "#/logout";
          } else {
            console.log("Anderer Fehler");
          }
        })
        .catch(function (error) {
          if (error.response) {
            // The server answered with an error
            if (error.response.status === 401) {
              window.location.href = "#/logout";
            } else {
              console.log(error.response.data);
              console.log(error.response.status);
              console.log(error.response.headers);
            }
          } else if (error.request) {
            //  The request was made but the server didn't respond
            console.log(error.request);
          } else {
            //  Something else went wrong
            console.log("Error", error.message);
          }
        });
    } else {
      dispatch({
        type: EDIT_CONTACT_HISTORY,
        payload: {
          id: id,
          title: "Neuer Kontakt-Eintrag",
          topic: "",
          remarks: "",
          objectId: -1,
          document: "",
          isUser: "1",
        },
      });
    }
  };

  //	SAVE THE CONTACT HISTORY DETAIL DIALOG
  const saveContactHistoryDialog = () => {
    //  Check for mandatory fields
    if (state.contactHistoryDetailTopic === "")
      return setContactHistoryError("Bitte geben Sie ein Thema an!");

    if (state.contactHistoryDetailRemarks === "")
      return setContactHistoryError("Bitte geben Sie eine Bemerkung ein!");

    const params = new URLSearchParams();
    params.append("jwt", sessionStorage.jwt);
    params.append("id", state.activeContactHistory);
    params.append("leadsId", state.activeLeadId);
    params.append("topic", state.contactHistoryDetailTopic);
    params.append("remarks", state.contactHistoryDetailRemarks);
    params.append("objectId", state.contactHistoryDetailObjectId);
    params.append("document", state.contactHistoryDetailDocument);
    params.append("isUser", 1);

    let url = process.env.REACT_APP_API_URI + "/leads/set_contact_history.php";
    let axiosConfig = {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    };

    axios
      .post(url, params, axiosConfig)
      .then((response) => {
        if (response.status === 200) {
          // console.log(response.data);

          dispatch({
            type: CLOSE_CONTACT_HISTORY_DIALOG,
          });

          getContactHistories();
        } else if (response.status === 401) {
          window.location.href = "#/logout";
        } else {
          console.log("Anderer Fehler");
        }
      })
      .catch(function (error) {
        if (error.response) {
          // The server answered with an error
          if (error.response.status === 401) {
            window.location.href = "#/logout";
          } else {
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          }
        } else if (error.request) {
          //  The request was made but the server didn't respond
          console.log(error.request);
        } else {
          //  Something else went wrong
          console.log("Error", error.message);
        }
      });
  };

  //	SET THE ERROR MESSAGE IN THE CONTACT HISTORY DETAIL DIALOG
  const setContactHistoryError = (errMsg) => {
    dispatch({
      type: SET_CONTACT_HISTORY_ERROR,
      payload: errMsg,
    });
  };

  //	GET THE CONTACT HISTORY ENTRIES FOR THE LEAD
  const getContactHistories = () => {
    const params = new URLSearchParams();
    params.append("jwt", sessionStorage.jwt);
    params.append("leadsId", state.activeLeadId);

    let axiosConfig = {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    };

    let url =
      process.env.REACT_APP_API_URI + "/leads/get_lead_contact_histories.php";

    axios
      .post(url, params, axiosConfig)
      .then((response) => {
        if (response.status === 200) {
          // console.log(response.data);

          dispatch({
            type: GET_CONTACT_HISTORIES,
            payload: response.data.leadContactHistories,
          });
        } else if (response.status === 401) {
          window.location.href = "#/logout";
        } else {
          console.log("Anderer Fehler");
        }
      })
      .catch(function (error) {
        if (error.response) {
          // The server answered with an error
          if (error.response.status === 401) {
            window.location.href = "#/logout";
          } else {
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          }
        } else if (error.request) {
          //  The request was made but the server didn't respond
          console.log(error.request);
        } else {
          //  Something else went wrong
          console.log("Error", error.message);
        }
      });
  };

  //	ASK FOR THE DELETION OF A CONTACT HISTORY ENTRY
  const confirmDeleteContactHistory = (id) => {
    const entityName =
      "Möchten Sie den Kontakt-Eintrag #" + id + " endgültig löschen?";

    dispatch({
      type: DELETE_LEAD_CONTACT_HISTORY_CONFIRM,
      payload: {
        entityName: entityName,
        id: id,
      },
    });
  };

  const closeLeadContractBillingAddressPasteDialog = () => {
	  dispatch({
		  type: CLOSE_LEAD_CONTRACT_BILLING_ADDRESS_PASTE_DIALOG
	  });
  }

  const closeLeadContractBillingAddressSavedDialog = () => {
	  dispatch({
		  type: CLOSE_LEAD_CONTRACT_BILLING_ADDRESS_SAVED_DIALOG
	  });
  }

  //	CLOSE CONTRACT HISTORY DELETE DIALOG
  const closeLeadContactHistoryConfirm = () => {
    dispatch({
      type: CLOSE_DELETE_LEAD_CONTACT_HISTORY_CONFIRM,
    });
  };

  //	DELETE THE CONTACT HISTORY ENTRY
  const deleteLeadContactHistory = () => {
    const params = new URLSearchParams();
    params.append("jwt", sessionStorage.jwt);
    params.append("id", state.activeContactHistory);

    let axiosConfig = {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    };

    let url =
      process.env.REACT_APP_API_URI + "/leads/delete_lead_contact_history.php";

    axios
      .post(url, params, axiosConfig)
      .then((response) => {
        if (response.status === 200) {
          // console.log(response.data);
          closeLeadContactHistoryConfirm();
          getContactHistories();
        } else if (response.status === 401) {
          window.location.href = "#/logout";
        } else {
          console.log("Anderer Fehler");
        }
      })
      .catch(function (error) {
        if (error.response) {
          // The server answered with an error
          if (error.response.status === 401) {
            window.location.href = "#/logout";
          } else {
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          }
        } else if (error.request) {
          //  The request was made but the server didn't respond
          console.log(error.request);
        } else {
          //  Something else went wrong
          console.log("Error", error.message);
        }
      });
  };

  //-------------------------------------------
  //	CONTRACTS
  //-------------------------------------------

  const getContracts = () => {
    const params = new URLSearchParams();
    params.append("jwt", sessionStorage.jwt);
    params.append("leadsId", state.activeLeadId);

    let axiosConfig = {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    };

    let url = process.env.REACT_APP_API_URI + "/leads/get_contracts.php";

    axios
      .post(url, params, axiosConfig)
      .then((response) => {
        if (response.status === 200) {
          // console.log(response.data);

          const contracts = response.data.contracts;
          for (var i in contracts) {
            contracts[i].id =
              isNaN(contracts[i].id) || contracts[i].id === ""
                ? 0
                : Number.parseInt(contracts[i].id);
            contracts[i].postal_code =
              isNaN(contracts[i].postal_code) || contracts[i].postal_code === ""
                ? 0
                : Number.parseInt(contracts[i].postal_code);
          }

          dispatch({
            type: GET_CONTRACTS,
            payload: contracts,
          });
        } else if (response.status === 401) {
          window.location.href = "#/logout";
        } else {
          console.log("Anderer Fehler");
        }
      })
      .catch(function (error) {
        if (error.response) {
          // The server answered with an error
          if (error.response.status === 401) {
            window.location.href = "#/logout";
          } else {
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          }
        } else if (error.request) {
          //  The request was made but the server didn't respond
          console.log(error.request);
        } else {
          //  Something else went wrong
          console.log("Error", error.message);
        }
      });
  };

  const closeDeleteContract = () => {
    dispatch({
      type: CLOSE_DELETE_CONTRACT,
    });
  };

  const deleteContract = () => {
    const params = new URLSearchParams();
    params.append("jwt", sessionStorage.jwt);
    params.append("id", state.activeLeadContract);

    let axiosConfig = {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    };

    let url = process.env.REACT_APP_API_URI + "/leads/delete_contract.php";

    axios
      .post(url, params, axiosConfig)
      .then((response) => {
        if (response.status === 200) {
          // console.log(response.data);
          closeDeleteContract();
          getContracts();
        } else if (response.status === 401) {
          window.location.href = "#/logout";
        } else {
          console.log("Anderer Fehler");
        }
      })
      .catch(function (error) {
        if (error.response) {
          // The server answered with an error
          if (error.response.status === 401) {
            window.location.href = "#/logout";
          } else {
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          }
        } else if (error.request) {
          //  The request was made but the server didn't respond
          console.log(error.request);
        } else {
          //  Something else went wrong
          console.log("Error", error.message);
        }
      });
  };

  const closeLeadConfirm = () => {
    dispatch({
      type: CLOSE_DELETE_LEAD_CONFIRM,
    });
  };

  const deleteLead = () => {
    const params = new URLSearchParams();
    params.append("jwt", sessionStorage.jwt);
    params.append("id", state.activeLeadId);

    let axiosConfig = {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    };

    let url = process.env.REACT_APP_API_URI + "/leads/delete_lead.php";

    axios
      .post(url, params, axiosConfig)
      .then((response) => {
        if (response.status === 200) {
          // console.log(response.data);
          closeLeadConfirm();
          getLeads();
        } else if (response.status === 401) {
          window.location.href = "#/logout";
        } else {
          console.log("Anderer Fehler");
        }
      })
      .catch(function (error) {
        if (error.response) {
          // The server answered with an error
          if (error.response.status === 401) {
            window.location.href = "#/logout";
          } else {
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          }
        } else if (error.request) {
          //  The request was made but the server didn't respond
          console.log(error.request);
        } else {
          //  Something else went wrong
          console.log("Error", error.message);
        }
      });
  };

  const closeLeadDoubleCounterDialog = () => {
    dispatch({
      type: LEAD_DOUBLE_COUNTER_DIALOG_CLOSE,
      payload: false,
    });
  };

  const getCounterDoublings = (counterNumber) => {
    const params = new URLSearchParams();
    params.append("jwt", sessionStorage.jwt);
    params.append("counterNumber", counterNumber);

    let url =
      process.env.REACT_APP_API_URI + "/customers/get_counter_doublings.php";
    let axiosConfig = {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    };

    axios
      .post(url, params, axiosConfig)
      .then((response) => {
        if (response.status === 200) {
          //   console.log(response.data);
          dispatch({
            type: LEAD_COUNTER_DOUBLINGS_LIST,
            payload: response.data.counters,
          });
        } else if (response.status === 401) {
          window.location.href = "#/logout";
        } else {
          console.log("Anderer Fehler");
        }
      })
      .catch(function (error) {
        if (error.response) {
          // The server answered with an error
          if (error.response.status === 401) {
            window.location.href = "#/logout";
          } else {
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          }
        } else if (error.request) {
          //  The request was made but the server didn't respond
          console.log(error.request);
        } else {
          //  Something else went wrong
          console.log("Error", error.message);
        }
      });
  };

  const leadContractTakeoverBillingAddress = (id) => {

	const params = new URLSearchParams();
    params.append("jwt", sessionStorage.jwt);
    params.append("id", id);

    let url =
      process.env.REACT_APP_API_URI + "/leads/get_billing_address.php";
    let axiosConfig = { headers: { "Content-Type": "application/x-www-form-urlencoded" } };

    axios.post(url, params, axiosConfig)
      .then((response) => {
        if (response.status === 200) {

            // console.log(response.data);
          dispatch({
            type: LEAD_CONTRACT_TAKEOVER_BILLING_ADDRESS,
            payload: {
				id: response.data.billingAddress.id,
				customerTypesId: response.data.billingAddress.customerTypesId,
				salutationsId: response.data.billingAddress.salutationsId,
				billingName1: response.data.billingAddress.billingName1,
				billingName2: response.data.billingAddress.billingName2,
				billingName3: response.data.billingAddress.billingName3,
				billingStreet: response.data.billingAddress.billingStreet,
				billingHouseNumber: response.data.billingAddress.billingHouseNumber,
				billingPostalCode: response.data.billingAddress.billingPostalCode,
				billingCity: response.data.billingAddress.billingCity,
				billingDistrict: response.data.billingAddress.billingDistrict,
				billingCountry: response.data.billingAddress.billingCountry,
				billingAdditional: response.data.billingAddress.billingAdditional,
				billingPostboxNo: response.data.billingAddress.billingPostboxNo,
				billingPostboxPostalCode: response.data.billingAddress.billingPostboxPostalCode,
				billingPostboxCity: response.data.billingAddress.billingPostboxCity
			},
          });

        } else if (response.status === 401) {
          window.location.href = "#/logout";
        } else {
          console.log("Anderer Fehler");
        }
      })
      .catch(function (error) {
        if (error.response) {
          // The server answered with an error
          if (error.response.status === 401) {
            window.location.href = "#/logout";
          } else {
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          }
        } else if (error.request) {
          //  The request was made but the server didn't respond
          console.log(error.request);
        } else {
          //  Something else went wrong
          console.log("Error", error.message);
        }
      });
	
  }

  const leadContractDeleteBillingAddress = (id) => {
	  const reducedAddresses = state.leadContractBillingAddresses.filter(address => address.id !== id)
	  const params = new URLSearchParams();
		params.append("jwt", sessionStorage.jwt);
		params.append("id", id);

		let url = process.env.REACT_APP_API_URI + "/leads/delete_billing_address.php";
		let axiosConfig = { headers: { "Content-Type": "application/x-www-form-urlencoded" } };

		axios.post(url, params, axiosConfig)
		.then((response) => {
			if (response.status === 200) {

			//   console.log(response.data);
			dispatch({
				type: LEAD_CONTRACT_DELETE_BILLING_ADDRESS,
				payload: reducedAddresses,
			});

			} else if (response.status === 401) {
				window.location.href = "#/logout";
			} else {
				console.log("Anderer Fehler");
			}
		})
		.catch(function (error) {
			if (error.response) {
			// The server answered with an error
			if (error.response.status === 401) {
				window.location.href = "#/logout";
			} else {
				console.log(error.response.data);
				console.log(error.response.status);
				console.log(error.response.headers);
			}
			} else if (error.request) {
			//  The request was made but the server didn't respond
			console.log(error.request);
			} else {
			//  Something else went wrong
			console.log("Error", error.message);
			}
		});
  }

  return (
    <LeadsContext.Provider
      value={{
        //  LEADS
        activeLeadId: state.activeLeadId,
        deleteLeadConfirmName: state.deleteLeadConfirmName,
        deleteLeadConfirmState: state.deleteLeadConfirmState,
        leadDetailDialogActiveTab: state.leadDetailDialogActiveTab,
        leadDetailDialogError: state.leadDetailDialogError,
        leadDetailDialogState: state.leadDetailDialogState,
        leadDetailDialogTitle: state.leadDetailDialogTitle,
        leads: state.leads,

        closeLeadConfirm,
        closeLeadDetailDialog,
        deleteLead,
        deleteLeadConfirm,
        editLead,
        getLeads,
        saveLeadDetailDialog,
		setContractErrMsg,
        setForeigns,
        setLeadDetailDialogActiveTab,
        setErrMsg,

        //  LEADS ADDRESS
        abc: state.abc,
        abcId: state.abcId,
        activeAddressTab: state.activeAddressTab,
        businessPartnerTypes: state.businessPartnerTypes,
        businessPartnerTypesId: state.businessPartnerTypesId,
		copiedCustomer: state.copiedCustomer,
        followUpDate: state.followUpDate,
        followUpDone: state.followUpDone,
        isPortalAccessAdministration: state.isPortalAccessAdministration,
        supervisors: state.supervisors,
        supervisorsId: state.supervisorsId,
		supervisorsPv: state.supervisorsPv,
		supervisorsPvId: state.supervisorsPvId,

        abcIdChange,
        businessPartnerTypesIdChange,
        followUpDateChange,
        followUpDoneChange,
		goToOriginal,
        isPortalAccessAdministrationChange,
        setActiveAddressTab,
        supervisorsIdChange,
		supervisorsPvIdChange,

        //	LEAD OBJECT LIST LOADING DIALOG
        leadObjectListDialogState: state.leadObjectListDialogState,
        leadObjectListIsFinished: state.leadObjectListIsFinished,
		leadObjectListIsEmpty: state.leadObjectListIsEmpty,

        closeLeadObjectListDialog,
        loadObjectList,

        //  LEADS ADDRESS ADDRESS
        addressAddition: state.addressAddition,
        city: state.city,
        country: state.country,
        district: state.district,
        houseNumber: state.houseNumber,
        name1: state.name1,
        name2: state.name2,
        name3: state.name3,
		legalForms: state.legalForms,
		legalFormsId: state.legalFormsId,
        postalCode: state.postalCode,
        salutations: state.salutations,
        salutationsId: state.salutationsId,
        street: state.street,

        addressAdditionChange,
        cityChange,
        countryChange,
        districtChange,
        houseNumberChange,
        name1Change,
        name2Change,
        name3Change,
		legalFormsIdChange,
        postalCodeChange,
        salutationsIdChange,
        streetChange,

        //  LEADS ADDRESS CONTACT
        email: state.email,
        fax: state.fax,
        phone: state.phone,
        url: state.url,

        emailChange,
        faxChange,
        phoneChange,
        urlChange,

        //  LEAD ADDRESS POSTBOX
        postbox: state.postbox,
        postboxCity: state.postboxCity,
        postboxPostalCode: state.postboxPostalCode,

        postboxChange,
        postboxCityChange,
        postboxPostalCodeChange,

        //  LEAD ADDRESS STANDARD
        administrations1: state.administrations1,
        administrations1Id: state.administrations1Id,
        administrations2: state.administrations2,
        administrations2Id: state.administrations2Id,
        administrations3: state.administrations3,
        administrations3Id: state.administrations3Id,
        communityCode: state.communityCode,
        ownerId: state.ownerId,
        yesNos: state.yesNos,

        administrations1IdChange,
        administrations2IdChange,
        administrations3IdChange,
        communityCodeChange,
        ownerIdChange,

        //  LEAD CONTACT PERSONS
        activeContactPerson: state.activeContactPerson,
        contactPersons: state.contactPersons,
        deleteModalEntityName: state.deleteModalEntityName,
        deleteModalState: state.deleteModalState,
        deleteModalTitle: state.deleteModalTitle,

        closeDelete,
        deleteContactPersonConfirm,
        deleteEntityConfirmed,
        editContactPerson,
        getContactPersons,

        //  LEAD CONTACT PERSONS DETAILS
        contactPersonDetailsDialogError: state.contactPersonDetailsDialogError,
        contactPersonDetailsDialogState: state.contactPersonDetailsDialogState,
        contactPersonDetailsDialogTitle: state.contactPersonDetailsDialogTitle,
        contactPersonDivision: state.contactPersonDivision,
        contactPersonEmail: state.contactPersonEmail,
        contactPersonFax: state.contactPersonFax,
        contactPersonFirstname: state.contactPersonFirstname,
        contactPersonFunction: state.contactPersonFunction,
        contactPersonIsPortalAccess: state.contactPersonIsPortalAccess,
        contactPersonLastname: state.contactPersonLastname,
        contactPersonMobile: state.contactPersonMobile,
        contactPersonPhone: state.contactPersonPhone,
        contactPersonPortalAccessValidTo:
          state.contactPersonPortalAccessValidTo,
        contactPersonSalutationsId: state.contactPersonSalutationsId,
        contactPersonTitle: state.contactPersonTitle,

        closeContactPersonDialog,
        contactPersonDivisionChange,
        contactPersonEmailChange,
        contactPersonFaxChange,
        contactPersonFirstnameChange,
        contactPersonFunctionChange,
        contactPersonIsPortalAccessChange,
        contactPersonLastnameChange,
        contactPersonMobileChange,
        contactPersonPhoneChange,
        contactPersonPortalAccessValidToChange,
        contactPersonSalutationsIdChange,
        contactPersonTitleChange,
        saveContactPerson,

        //  LEAD BANK ACCOUNT
        accountNo: state.accountNo,
        accountOwner: state.accountOwner,
        bankCode: state.bankCode,
        iban: state.iban,

        accountNoChange,
        accountOwnerChange,
        bankCodeChange,
        ibanChange,

        //  LEAD PRODUCTS
        activeProductTab: state.activeProductTab,
        isOfferAccepted: state.isOfferAccepted,

        setActiveProductTab,

        //  LEAD PRODUCTS OFFERED
		isTransition: state.isTransition,
        energyCampaigns: state.energyCampaigns,
        energyCampaignsId: state.energyCampaignsId,
		bIsLeadEnergyTransitionChange: state.bIsLeadEnergyTransitionChange,
		isLeadEnergyTransitionOpen: state.isLeadEnergyTransitionOpen,
		isLeadEnergyTransitionInitialized: state.isLeadEnergyTransitionInitialized,
		isLeadEnergyInitialTransition: state.isLeadEnergyInitialTransition,
		isLeadEnergyTransition: state.isLeadEnergyTransition,
		leadEnergyTransitionDate: state.leadEnergyTransitionDate,
		bIsLeadEnergyTransitionTenPercentChange: state.bIsLeadEnergyTransitionTenPercentChange,
		isLeadEnergyTransitionTenPercentOpen: state.isLeadEnergyTransitionTenPercentOpen,
		isLeadEnergyTransitionTenPercent: state.isLeadEnergyTransitionTenPercent,
		leadEnergyTransitionTenPercentDate: state.leadEnergyTransitionTenPercentDate,
        energyPots: state.energyPots,
        energyPotsId: state.energyPotsId,
        gasCampaigns: state.gasCampaigns,
        gasCampaignsId: state.gasCampaignsId,
		bIsLeadGasTransitionChange: state.bIsLeadGasTransitionChange,
		isLeadGasTransitionOpen: state.isLeadGasTransitionOpen,
		isLeadGasTransitionInitialized: state.isLeadGasTransitionInitialized,
		isLeadGasInitialTransition: state.isLeadGasInitialTransition,
		isLeadGasTransition: state.isLeadGasTransition,
		leadGasTransitionDate: state.leadGasTransitionDate,
		bIsLeadGasTransitionTenPercentChange: state.bIsLeadGasTransitionTenPercentChange,
		isLeadGasTransitionTenPercentOpen: state.isLeadGasTransitionTenPercentOpen,
		isLeadGasTransitionTenPercent: state.isLeadGasTransitionTenPercent,
		leadGasTransitionTenPercentDate: state.leadGasTransitionTenPercentDate,
        gasPots: state.gasPots,
        gasPotsId: state.gasPotsId,

        energyCampaignsIdChange,
		isLeadEnergyTransitionChange,
		leadEnergyTransitionDateChange,
		isLeadEnergyTransitionTenPercentChange,
		leadEnergyTransitionTenPercentDateChange,
        energyPotsIdChange,
        gasCampaignsIdChange,
		isLeadGasTransitionChange,
		leadGasTransitionDateChange,
		isLeadGasTransitionTenPercentChange,
		leadGasTransitionTenPercentDateChange,
        gasPotsIdChange,

        //  LEAD PRODUCTS HISTORY
        historyContacts: state.historyContacts,

        //  LEAD CONTRACTS
        activeLeadContract: state.activeLeadContract,
        activeLeadContractTab: state.activeLeadContractTab,
        leadContracts: state.leadContracts,
		leadContractsDialogError:	state.leadContractsDialogError,
        leadContractsDialogState: state.leadContractsDialogState,
        leadContractsDialogTitle: state.leadContractsDialogTitle,
        leadContractOfferAcceptDialogState:
          state.leadContractOfferAcceptDialogState,

        acceptOffer,
        closeDeleteContract,
        closeLeadContractsDialog,
        closeLeadContractOfferAcceptDialog,
        confirmAcceptOffer,
        deleteContract,
        deleteLeadContractConfirm,
        editLeadContract,
        saveLeadContractDetails,
        setActiveLeadContractTab,

        //  LEAD CONTRACT CONTRACT
        divisions: state.divisions,
        leadContractDivisionsId: state.leadContractDivisionsId,
        leadContractIsBio5: state.leadContractIsBio5,
        leadContractBio5StartDate: state.leadContractBio5StartDate,
        leadContractIsBio10: state.leadContractIsBio10,
        leadContractBio10StartDate: state.leadContractBio10StartDate,
        leadContractIsClimatePlus: state.leadContractIsClimatePlus,
        leadContractIsCo2: state.leadContractIsCo2,
        leadContractPeriodConsumption: state.leadContractPeriodConsumption,
        leadContractPotsId: state.leadContractPotsId,
        leadContractProducts: state.leadContractProducts,
        leadContractProductsId: state.leadContractProductsId,
        leadContractValidFrom: state.leadContractValidFrom,
        leadContractValidTo: state.leadContractValidTo,
		isLeadContractTransitionOpen: state.isLeadContractTransitionOpen,
		isLeadContractTransitionInitialized: state.isLeadContractTransitionInitialized,
		isLeadContractInitialTransition: state.isLeadContractInitialTransition,
		isLeadContractTransition: state.isLeadContractTransition,
		leadContractTransitionDate: state.leadContractTransitionDate,
		isLeadContractTransitionTenPercentOpen: state.isLeadContractTransitionTenPercentOpen,
		isLeadContractTransitionTenPercent: state.isLeadContractTransitionTenPercent,
		leadContractTransitionTenPercentDate: state.leadContractTransitionTenPercentDate,
        isPots: state.isPots,
        isProduct: state.isProduct,
        isValidFrom: state.isValidFrom,
        isValidTo: state.isValidTo,
        isOptionen: state.isOptionen,
        divisionsId: state.divisionsId,
        pots: state.pots,

        leadContractDivisionsIdChange,
        leadContractIsBio5Change,
        leadContractBio5StartDateChange,
        leadContractIsBio10Change,
        leadContractBio10StartDateChange,
        leadContractIsClimatePlusChange,
        leadContractIsCo2Change,
        leadContractPeriodConsumptionChange,
        leadContractPotsIdChange,
        leadContractProductsIdChange,
        leadContractValidFromChange,
        leadContractValidToChange,
		isLeadContractTransitionChange,
		leadContractTransitionDateChange,
		isLeadContractTransitionTenPercentChange,
		leadContractTransitionTenPercentDateChange,

        //  LEAD CONTRACT COUNTER
        activeContractCounterTab: state.activeContractCounterTab,
        leadCounterBhkw: state.leadCounterBhkw,
        leadCounterContractAccount: state.leadCounterContractAccount,
        leadCounterId: state.leadCounterId,
        leadCounterIntendedUse: state.leadCounterIntendedUse,
        leadCounterMediumVoltage: state.leadCounterMediumVoltage,
        leadCounterNumberCurrent: state.leadCounterNumberCurrent,
        leadCounterNumberDoubling: state.leadCounterNumberDoubling,

        leadCounterBhkwChange,
        leadCounterContractAccountChange,
        leadCounterIdChange,
        leadCounterIntendedUseChange,
        leadCounterMediumVoltageChange,
        leadCounterNumberCurrentChange,
        getCounterDoublings,
        setActiveContractCounterTab,

        //  LEAD CONTRACT COUNTER CHANGE
        changeReasons: state.changeReasons,
        leadCounterChangeReasonsId: state.leadCounterChangeReasonsId,
        leadCounterChangeSupplyStart: state.leadCounterChangeSupplyStart,
        leadCounterFormerSupplierId: state.leadCounterFormerSupplierId,
        leadCounterInstallmentCycle: state.leadCounterInstallmentCycle,
        leadCounterLoadProfilesId: state.leadCounterLoadProfilesId,
        leadCountryLocationAdditional: state.leadCountryLocationAdditional,
        leadCounterLocationCity: state.leadCounterLocationCity,
        leadCounterLocationCountry: state.leadCounterLocationCountry,
        leadCounterLocationDistrict: state.leadCounterLocationDistrict,
        leadCounterLocationHouseNumber: state.leadCounterLocationHouseNumber,
        leadCounterLocationMarketLocation:
          state.leadCounterLocationMarketLocation,
        leadCounterLocationMeasureLocation:
          state.leadCounterLocationMeasureLocation,
        leadCounterLocationPostalCode: state.leadCounterLocationPostalCode,
        leadCounterLocationStreet: state.leadCounterLocationStreet,
        leadCounterNetworksId: state.leadCounterNetworksId,
        leadCounterValue: state.leadCounterValue,
        loadProfiles: state.loadProfiles,
        networks: state.networks,
        suppliers: state.suppliers,

        leadCounterChangeReasonsIdChange,
        leadCounterChangeSupplyStartChange,
        leadCounterFormerSupplierIdChange,
        leadCounterInstallmentCycleChange,
        leadCounterLoadProfilesIdChange,
        leadCountryLocationAdditionalChange,
        leadCounterLocationCityChange,
        leadCounterLocationCountryChange,
        leadCounterLocationDistrictChange,
        leadCounterLocationHouseNumberChange,
        leadCounterLocationMarketLocationChange,
        leadCounterLocationMeasureLocationChange,
        leadCounterLocationPostalCodeChange,
        leadCounterLocationStreetChange,
        leadCounterNetworksIdChange,
        leadCounterValueChange,

        //  LEAD COUNTER LOCATION BANK ACCOUNT
        leadCounterAccountNo: state.leadCounterAccountNo,
        leadCounterAccountOwner: state.leadCounterAccountOwner,
        leadCounterBankCode: state.leadCounterBankCode,
        leadCounterIban: state.leadCounterIban,

        leadCounterAccountNoChange,
        leadCounterAccountOwnerChange,
        leadCounterBankCodeChange,
        leadCounterIbanChange,

        //  LEAD CONTRACT BILLING
        customerTypes: state.customerTypes,
        leadContractBillingAdditional: state.leadContractBillingAdditional,
        leadContractBillingCity: state.leadContractBillingCity,
        leadContractBillingCountry: state.leadContractBillingCountry,
        leadContractBillingDistrict: state.leadContractBillingDistrict,
        leadContractBillingName1: state.leadContractBillingName1,
        leadContractBillingName2: state.leadContractBillingName2,
        leadContractBillingName3: state.leadContractBillingName3,
        leadContractBillingStreet: state.leadContractBillingStreet,
        leadContractBillingHouseNumber: state.leadContractBillingHouseNumber,
        leadContractBillingPostalCode: state.leadContractBillingPostalCode,
        leadContractBillingPostboxNo: state.leadContractBillingPostboxNo,
        leadContractBillingPostboxPostalCode:
          state.leadContractBillingPostboxPostalCode,
        leadContractBillingPostboxCity: state.leadContractBillingPostboxCity,
        leadContractCustomerTypesId: state.leadContractCustomerTypesId,
        leadContractSalutationsId: state.leadContractSalutationsId,
		leadContractBillingAddressSavedDialogState: state.leadContractBillingAddressSavedDialogState,
		leadContractBillingAddressPasteDialogState: state.leadContractBillingAddressPasteDialogState,
		leadContractBillingAddresses: state.leadContractBillingAddresses,

        leadContractBillingAdditionalChange,
        leadContractBillingCityChange,
        leadContractBillingCountryChange,
        leadContractBillingDistrictChange,
        leadContractBillingName1Change,
        leadContractBillingName2Change,
        leadContractBillingName3Change,
        leadContractBillingHouseNumberChange,
        leadContractBillingPostalCodeChange,
        leadContractBillingPostboxNoChange,
        leadContractBillingPostboxPostalCodeChange,
        leadContractBillingPostboxCityChange,
        leadContractBillingStreetChange,
        leadContractCustomerTypesIdChange,
        leadContractSalutationsIdChange,
		leadContractPasteBillingAddress,
		leadContractSaveBillingAddress,
		closeLeadContractBillingAddressSavedDialog,
		closeLeadContractBillingAddressPasteDialog,
		leadContractTakeoverBillingAddress,
		leadContractDeleteBillingAddress,

        //	LEAD CONTRACT DELETE
        deleteContractName: state.deleteContractName,
        deleteContractState: state.deleteContractState,

        //  LEAD CONTACT HISTORY
        activeContactHistory: state.activeContactHistory,
        contactHistories: state.contactHistories,

        confirmDeleteContactHistory,
        editContactHistory,

        //  LEAD CONTACT HISTORY DETAILS
        contactHistoryDetailDocument: state.contactHistoryDetailDocument,
        contactHistoryDetailObjectId: state.contactHistoryDetailObjectId,
        contactHistoryDetailRemarks: state.contactHistoryDetailRemarks,
        contactHistoryDetailTopic: state.contactHistoryDetailTopic,
        contactHistoryDialogState: state.contactHistoryDialogState,
        contactHistoryDialogTitle: state.contactHistoryDialogTitle,
        contactHistoryError: state.contactHistoryError,
        contactHistoryIsUser: state.contactHistoryIsUser,
        leadObjects: state.leadObjects,

        changeContactHistoryDetailObjectId,
        changeContactHistoryDetailRemarks,
        changeContactHistoryDetailTopic,
        closeContactHistoryDialog,
        contactHistoryDetailDocumentChange,
        contactHistoryDetailDocumentDelete,
        getContactHistories,
        saveContactHistoryDialog,

        //	LEAD CONTACT HISTORY DELETE
        deleteLeadContactHistoryConfirmName:
          state.deleteLeadContactHistoryConfirmName,
        deleteLeadContactHistoryConfirmState:
          state.deleteLeadContactHistoryConfirmState,

        closeLeadContactHistoryConfirm,
        deleteLeadContactHistory,

        //	COUNTER DOUBLINGS
        leadCounterDoublingsList: state.leadCounterDoublingsList,
        leadDoubleCounterDialogState: state.leadDoubleCounterDialogState,

        closeLeadDoubleCounterDialog,
      }}
    >
      {props.children}
    </LeadsContext.Provider>
  );
};

export default LeadsState;
