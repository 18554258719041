/**
 *      File:       ./src/context/exports/exportPGsContext.js
 * 
 *      Subject:    Product Groups export context
 */
import { createContext } from "react";

const exportPGsContext = createContext();

export default exportPGsContext;
