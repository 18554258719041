/**
 * 		File:		./src/context/exports/NavigationReducer.js
 * 
 * 		Subject:	Navigation export state reducer
 */
import {
	GET_EXPORTS_NAVIGATION,
} from "../types";

export default (state, action) => {
  
  switch (action.type) {
    
    case GET_EXPORTS_NAVIGATION:
      return{
		...state,
		dataNavigation: action.payload.navigationExports,
		dataLeadNavigation: action.payload.leadNavigationExports,
      }

    //  Default Path
    default:
      return state;
  }
};
