/**
 *      File:       ./src/context/administration/system/usersReducer.js
 * 
 *      Subject:    Users' State Reducer
 */
import {

	CUSTOMER_PORTAL_ID_CHANGE,
    EDIT_ENTITY,
    EMAIL_CHANGE,
    FIRSTNAME_CHANGE,
    GENDERS_ID_CHANGE,
    GET_ENTITIES,
    IS_SUPPORTER_CHANGE,
	IS_SUPPORTER_PV_CHANGE,
    LASTNAME_CHANGE,
    PW1_CHANGE,
    PW2_CHANGE,
    PW_CHANGE_DIALOG,
    SET_ACTIVE_ENTITY,
    SET_DELETE_ENTITY,
    SET_DELETE_MODAL_ENTITY_NAME,
    SET_ERROR,
    SET_PW_ERROR,
    SET_USER_FOREIGNS,
    USER_ROLES_ID_CHANGE,

} from '../../types';

export default(state, action) => {

    switch(action.type)
    {
		case CUSTOMER_PORTAL_ID_CHANGE:
			return {
				...state,
				editDialogCustomerPortalId: action.payload,
			}

        case EDIT_ENTITY:
            return{
                ...state,
                activeUser: action.payload.id,
                userDetailDialogError: '',
                userDetailDialogState: action.payload.editState,
                userDetailDialogTitle: action.payload.title,
            }

        case EMAIL_CHANGE:
            return {
                ...state,
                editDialogEmail: action.payload,
            }

        case FIRSTNAME_CHANGE:
            return {
                ...state,
                editDialogFirstname: action.payload,
            }

        case GENDERS_ID_CHANGE:
            return{
                ...state,
                editDialogGendersId: action.payload,
            }

        case GET_ENTITIES:
            return {
                ...state,
                entities: action.payload
            }

        case IS_SUPPORTER_CHANGE:
            return {
                ...state,
                editDialogIsSupporter: action.payload,
            }

		case IS_SUPPORTER_PV_CHANGE:
			return {
				...state,
				editDialogIsSupporterPv: action.payload,
			}

        case PW1_CHANGE:
            return {
                ...state,
                pw1: action.payload
            }

        case PW2_CHANGE:
            return {
                ...state,
                pw2: action.payload,
            }

        case PW_CHANGE_DIALOG:
            return {
                ...state,
                pwChangeDialogError: '',
                pwChangeDialogState: action.payload.pwChangeDialogState,
                pw1: '',
                pw2: '',
                activePwUser: action.payload.id,
            }

        case SET_ACTIVE_ENTITY:
            return {
                ...state,
                editDialogGendersId: action.payload.gendersId,
                editDialogFirstname: action.payload.firstname,
                editDialogLastname: action.payload.lastname,
                editDialogEmail: action.payload.email,
                editDialogUserRolesId: action.payload.userRolesId,
				editDialogIsSupporter: action.payload.isSupporter,
				editDialogIsSupporterPv: action.payload.isSupporterPv,
				editDialogCustomerPortalId: action.payload.customerPortalId,
            }

        case SET_DELETE_ENTITY:
            return {
                ...state,
                deleteActiveUser: action.payload.id,
                deleteUserConfirmState: action.payload.deleteModalState,
            }

        case SET_DELETE_MODAL_ENTITY_NAME:
            return {
                ...state,
                deleteUserConfirmName: action.payload,
            }

        case LASTNAME_CHANGE:
            return {
                ...state,
                editDialogLastname: action.payload,
            }

        case SET_ERROR:
            return {
                ...state,
                userDetailDialogError: action.payload,
            }

        case SET_PW_ERROR:
            return{
                ...state,
                pwChangeDialogError: action.payload,
            }
    
        case SET_USER_FOREIGNS:
            return {
                ...state,
                genders: action.payload.genders,
                userRoles: action.payload.userRoles,
            }

        case USER_ROLES_ID_CHANGE:
            return{
                ...state,
                editDialogUserRolesId: action.payload,
            }
            
        //  Default Path
        default:
            return state;
    }
}