/**
 *      File:       ./src/context/administration/products/ProductAssignmentsState.js
 * 
 *      Subject:    Product Assignments' State
 */
import React, { useReducer, useEffect } from 'react';
import axios from 'axios';
import ProductAssignmentsContext from './productAssignmentsContext';
import ProductAssignmentsReducer from './productAssignmentsReducer';
import FormSelect from '../../../Components/FormSelect';

import {

    GET_ENTITIES,
    SET_ACTIVE_ENTITY,
    SET_DELETE_ENTITY,
    SET_DELETE_MODAL_ENTITY_NAME,
    SET_EDIT_DIALOG_TITLE,
    SET_EDIT_ENTITY,
    SET_ENTITY_DIALOG_FIELDS,
    SET_ERROR,

    SET_PRODUCT_ASSIGNMENT_FOREIGNS,
    PRODUCTS_ID_CHANGE,
    PRODUCT_GROUPS_ID_CHANGE

} from '../../types';

const ProductAssignmentsState = props => {

    const initialState = {

        entities:               [],
        activeEntity:           0,
        deleteModalEntityName:  '',
        deleteModalState:       false,
        deleteModalTitle:       'Zuordnung löschen',
        entityDeleteDialog:     false,
        entityDeleteEntityName: '',
        editDialogTitle:        'Neue Zuordnung anlegen',
        editDialogError:        '',
        editDialogFields:       [],
        editState:              false,
        

        tableCols: [
            {
                Header: "Produkt",
                accessor: "product_name"
            },
            {
                Header: "Produktgruppe",
                accessor: "product_group_name"
            }
        ],

        editDialogProductsId: -1,
        editDialogProductGroupsId: -1,
        products: [],
        productGroups: []

    }
    const [state,dispatch] = useReducer(ProductAssignmentsReducer, initialState);

    useEffect(() => {

        async function fetchData() {
            
            const params = new URLSearchParams();
            params.append('jwt', sessionStorage.jwt);

            let axiosConfig = {
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded"
                }
            };

            let url = process.env.REACT_APP_API_URI + '/administration/products/get_product_assignment_foreigns.php';

            axios.post(url,params,axiosConfig)
            .then(response => {
                if(response.status === 200) {
                    // console.log(response.data);

                    setForeigns(response.data.productGroups, response.data.products);

                } else if(response.status === 401) {
                    window.location.href ='#/logout';
                } else {
                    console.log('Anderer Fehler');
                }
            })
            .catch(function(error) {
                if(error.response){
                    // The server answered with an error
                    if(error.response.status === 401) {
                        window.location.href = '#/logout';
                    } else {
                        console.log(error.response.data);
                        console.log(error.response.status);
                        console.log(error.response.headers);
                    }
                } else if(error.request) {
                    //  The request was made but the server didn't respond
                    console.log(error.request);
                } else {
                    //  Something else went wrong
                    console.log('Error', error.message);
                }
            })
        }
        fetchData();

        const edf = [
            {
                id: 1,
                fieldType: <FormSelect
                    context             = {ProductAssignmentsContext}
                    id                  = "products_id"
                    label               = "Produkt"
                    onChangeFunction    = {productsIdChange}
                    placeholder         = "Produkt"
                    required    
                    selectOptions       = "products"
                    value               = "editDialogProductsId"
                />
            },
            {
                id: 2,
                fieldType: <FormSelect
                    context             = {ProductAssignmentsContext}
                    id                  = "product_groups_id"
                    label               = "Produktgruppe"
                    onChangeFunction    = {productGroupsIdChange}
                    placeholder         = "Produktgruppe"
                    required       
                    selectOptions       = "productGroups"
                    value               = "editDialogProductGroupsId"
                />
            }
        ];

        dispatch({
            type: SET_ENTITY_DIALOG_FIELDS,
            payload: edf
        });

        getEntities();

        // eslint-disable-next-line
    }, [state.editDialogError]);


    const getEntities = () => {

        const params = new URLSearchParams();
        params.append('jwt', sessionStorage.jwt);

        let axiosConfig = {
            headers: {
                "Content-Type": "application/x-www-form-urlencoded"
            }
        };

        let url = process.env.REACT_APP_API_URI + '/administration/products/get_product_assignments.php';

        axios.post(url,params,axiosConfig)
        .then(response => {
            if(response.status === 200) {

                // console.log(response.data);
                
                dispatch({
                    type: GET_ENTITIES,
                    payload: response.data.entities
                });

            } else if(response.status === 401) {
                window.location.href ='#/logout';
            } else {
                console.log('Anderer Fehler');
            }
        })
        .catch(function(error) {
            if(error.response){
                // The server answered with an error
                if(error.response.status === 401) {
                    window.location.href = '#/logout';
                } else {
                    console.log(error.response.data);
                    console.log(error.response.status);
                    console.log(error.response.headers);
                }
            } else if(error.request) {
                //  The request was made but the server didn't respond
                console.log(error.request);
            } else {
                //  Something else went wrong
                console.log('Error', error.message);
            }
        })
    }

    const editEntity = (id) => {

        if(id > 0)
        {
            const params = new URLSearchParams();
            params.append('jwt', sessionStorage.jwt);
            params.append('id', id);

            let axiosConfig = {
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded"
                }
            };

            let url = process.env.REACT_APP_API_URI + '/administration/products/get_product_assignment.php';

            axios.post(url,params,axiosConfig)
            .then(response => {
                if(response.status === 200) {
                    
                    console.log(response.data);
                    
                    dispatch({
                        type: SET_ACTIVE_ENTITY,
                        payload: response.data.productAssignment
                    });

                    dispatch({
                        type: SET_EDIT_ENTITY,
                        payload: {
                            id: id,
                            editState: true
                        }
                    });

                    dispatch({
                        type: SET_EDIT_DIALOG_TITLE,
                        payload: 'Zuordnungen Produkte - Produktgruppen bearbeiten'
                    });

                } else if(response.status === 401) {
                    window.location.href ='#/logout';
                } else {
                    console.log('Anderer Fehler');
                }
            })
            .catch(function(error) {
                if(error.response){
                    // The server answered with an error
                    if(error.response.status === 401) {
                        window.location.href = '#/logout';
                    } else {
                        console.log(error.response.data);
                        console.log(error.response.status);
                        console.log(error.response.headers);
                    }
                } else if(error.request) {
                    //  The request was made but the server didn't respond
                    console.log(error.request);
                } else {
                    //  Something else went wrong
                    console.log('Error', error.message);
                }
            })
        } else {
            dispatch({
                type: SET_EDIT_DIALOG_TITLE,
                payload: 'Neues Zuordnung Produkte - Produktgruppen anlegen'
            });
        }

        dispatch({
            type: SET_EDIT_ENTITY,
            payload: {
                id: id,
                editState: true
            }
        });        
    }

    const setError = (errMsg) => {
        dispatch({
            type: SET_ERROR,
            payload: errMsg
        });
    }

    const saveEntity = async () => {

        if(state.editDialogProductsId === -1) {
            setError('Bitte wählen Sie ein Produkt für die Zuordnung aus.');
            return false;
        }
        
        if(state.editDialogProductGroupsId === -1) {
            setError('Bitte wählen Sie eine Produktgruppe für die Zuordnung aus.');
            return false;
        }

        const params = new URLSearchParams();
        params.append('jwt', sessionStorage.jwt);
        params.append('id', state.activeEntity);
        params.append('productsId', state.editDialogProductsId);
        params.append('productGroupsId', state.editDialogProductGroupsId);

        let axiosConfig = {
            headers: {
                "Content-Type": "application/x-www-form-urlencoded"
            }
        };

        let url = process.env.REACT_APP_API_URI + '/administration/products/set_product_assignment.php';

        axios.post(url,params,axiosConfig)
        .then(response => {
            if(response.status === 200) {

                // console.log(response.data);

                const productAssignment = {
                    products_id: -1,
                    product_groups_id: -1
                };
                getEntities();

                dispatch({
                    type: SET_ACTIVE_ENTITY,
                    payload: productAssignment
                });
                dispatch({
                    type: SET_EDIT_ENTITY,
                    payload: {
                        id: 0,
                        editState: false
                    }
                });
                return true;

            } else if(response.status === 401) {
                window.location.href ='#/logout';
            } else {
                console.log('Anderer Fehler');
                return false;
            }
        })
        .catch(function(error) {
            if(error.response){
                // The server answered with an error
                if(error.response.status === 401) {
                    window.location.href = '#/logout';
                } else {
                    console.log(error.response.data);
                    console.log(error.response.status);
                    console.log(error.response.headers);
                    return false;
                }
            } else if(error.request) {
                //  The request was made but the server didn't respond
                console.log(error.request);
                return false;
            } else {
                //  Something else went wrong
                console.log('Error', error.message);
                return false;
            }
        })
        return true;
    }

    const closeEdit = () => {
        dispatch({
            type: SET_ACTIVE_ENTITY,
            payload: {
                products_id: -1,
                product_groups_id: -1
            }
        });
        
        dispatch({
            type: SET_EDIT_ENTITY,
            payload: {
                id: 0,
                editState: false
            }
        });
    }

    const deleteEntity = (id) => {
        
        const params = new URLSearchParams();
        params.append('jwt', sessionStorage.jwt);
        params.append('id', id);

        let axiosConfig = {
            headers: {
                "Content-Type": "application/x-www-form-urlencoded"
            }
        };

        let url = process.env.REACT_APP_API_URI + '/administration/products/get_product_assignment_named.php';

        axios.post(url,params,axiosConfig)
        .then(response => {
            if(response.status === 200) {

                // console.log(response.data);
                
                dispatch({
                    type: SET_ACTIVE_ENTITY,
                    payload: response.data.productAssignment
                });

                dispatch({
                    type: SET_DELETE_ENTITY,
                    payload: {
                        id: id,
                        deleteModalState: true
                    }
                });

                dispatch({
                    type: SET_DELETE_MODAL_ENTITY_NAME,
                    payload: 'Möchten Sie die Zuordnung "' + response.data.productAssignment.product_name + ' - ' + response.data.productAssignment.product_group_name + '" wirklich löschen?'
                });

            } else if(response.status === 401) {
                window.location.href ='#/logout';
            } else {
                console.log('Anderer Fehler');
            }
        })
        .catch(function(error) {
            if(error.response){
                // The server answered with an error
                if(error.response.status === 401) {
                    window.location.href = '#/logout';
                } else {
                    console.log(error.response.data);
                    console.log(error.response.status);
                    console.log(error.response.headers);
                }
            } else if(error.request) {
                //  The request was made but the server didn't respond
                console.log(error.request);
            } else {
                //  Something else went wrong
                console.log('Error', error.message);
            }
        })
    }

    const closeDelete = () => {

        dispatch({
            type: SET_ACTIVE_ENTITY,
            payload: {
                products_id: -1,
                product_groups_id: -1
            }
        });
        
        dispatch({
            type: SET_EDIT_ENTITY,
            payload: {
                id: 0,
                editState: false
            }
        });

        dispatch({
            type: SET_DELETE_ENTITY,
            payload: {
                id: 0,
                deleteModalState: false
            }
        });

        dispatch({
            type: SET_DELETE_MODAL_ENTITY_NAME,
            payload: ''
        });
    }

    const deleteEntityConfirmed = () => {

        const params = new URLSearchParams();
        params.append('jwt', sessionStorage.jwt);
        params.append('id', state.activeEntity);

        let axiosConfig = {
            headers: {
                "Content-Type": "application/x-www-form-urlencoded"
            }
        };

        let url = process.env.REACT_APP_API_URI + '/administration/products/delete_product_assignment.php';

        axios.post(url,params,axiosConfig)
        .then(response => {
            if(response.status === 200) {
                // console.log(response.data);
                
                getEntities();
                closeDelete();

            } else if(response.status === 401) {
                window.location.href ='#/logout';
            } else {
                console.log('Anderer Fehler');
            }
        })
        .catch(function(error) {
            if(error.response){
                // The server answered with an error
                if(error.response.status === 401) {
                    window.location.href = '#/logout';
                } else {
                    console.log(error.response.data);
                    console.log(error.response.status);
                    console.log(error.response.headers);
                }
            } else if(error.request) {
                //  The request was made but the server didn't respond
                console.log(error.request);
            } else {
                //  Something else went wrong
                console.log('Error', error.message);
            }
        })
    }

    const setForeigns = (productGroups, products) => {
                            
        dispatch({
            type: SET_PRODUCT_ASSIGNMENT_FOREIGNS,
            payload: {
                products: products,
                productGroups: productGroups
            }
        });
    }

    /*-------------------------------------
    //  PAGE SPECIFIC
    -------------------------------------*/

    const productsIdChange = (e) => {
        if(state.editDialogProductsId === -1 && state.editDialogError !== '') {
            setError('');
        }

        dispatch({
            type: PRODUCTS_ID_CHANGE,
            payload: e.target.value
        });
    }

    const productGroupsIdChange = (e) => {
        if(state.editDialogProductGroupsId === -1 && state.editDialogError !== '') {
            setError('');
        }

        dispatch({
            type: PRODUCT_GROUPS_ID_CHANGE,
            payload: e.target.value
        });
    }

    return <ProductAssignmentsContext.Provider value={{

        tableCols: state.tableCols,
        entities: state.entities,
        editDialogFields: state.editDialogFields,
        editDialogError: state.editDialogError,
        activeEntity: state.activeEntity,
        editState: state.editState,
        userRoleLabel: state.userRoleLabel,
        editDialogTitle: state.editDialogTitle,
        deleteModalTitle: state.deleteModalTitle,
        deleteModalEntityName: state.deleteModalEntityName,
        deleteModalState: state.deleteModalState,

        editDialogProductsId: state.editDialogProductsId,
        editDialogProductGroupsId: state.editDialogProductGroupsId,
        products: state.products,
        productGroups: state.productGroups,

        editEntity,
        setError,
        saveEntity,
        getEntities,
        closeEdit,
        deleteEntity,
        closeDelete,
        deleteEntityConfirmed,
        setForeigns,

        productsIdChange,
        productGroupsIdChange

}}>
        {props.children}
    </ProductAssignmentsContext.Provider>
}

export default ProductAssignmentsState;