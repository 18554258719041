import React, { useReducer } from 'react';
import ImportsContext from './importsContext';
import ImportsReducer from './importsReducer';
import axios from 'axios';

import {
	GET_ENTITIES
} from '../types';

const ImportsState = props => {

	const initialState = {
		entitites: [],
	}

	const [state, dispatch] = useReducer(ImportsReducer, initialState);

	const getEntities = () => {

		const params = new URLSearchParams();
		params.append('jwt', sessionStorage.jwt);

		let axiosConfig = {
			headers: {
				"Content-Type": "application/x-www-form-urlencoded"
			}
		}

		let url = process.env.REACT_APP_API_URI + '/imports/get_imports.php';

		axios.post(url, params, axiosConfig)
			.then(response => {
				if (response.status === 200) {
					console.log(response.data);

					dispatch({
						type: GET_ENTITIES,
						payload: response.data.entities,
					})
				} else if (response.status === 401) {
					window.location.href = '#/logout';
				} else {
					console.log('Anderer Fehler');
				}
			})
			.catch(function (error) {
				if (error.response) {
					// The server answered with an error
					if (error.response.status === 401) {
						window.location.href = '#/logout';
					} else {
						console.log(error.response.data);
						console.log(error.response.status);
						console.log(error.response.headers);
					}
				} else if (error.request) {
					//	The request was made but the server didn't respond
					console.log(error.request);
				} else {
					//	Something else went wrong
					console.log('Error', error.message);
				}
			})
	}

	return <ImportsContext.Provider value={{
		entities: state.entities,

		getEntities,
	}}>
		{props.children}
	</ImportsContext.Provider>
}

export default ImportsState;