/**
 *      File:       ./src/context/administration/order-criterias/salutationsReducer.js
 * 
 *      Subject:    Salutations' State Reducer
 */
import {
    GET_ENTITIES,
    SET_ACTIVE_ENTITY,
    SET_DELETE_ENTITY,
    SET_DELETE_MODAL_ENTITY_NAME,
    SET_EDIT_DIALOG_TITLE,
    SET_EDIT_ENTITY,
    SET_ENTITY_DIALOG_FIELDS,
	SET_ERROR,
	SET_USER_FOREIGNS,

    SALUTATION_CHANGE,
	SALUTATION_INDEX_SAP_CHANGE,
	BUSINESS_PARTNER_TYPES_ID_CHANGE,

} from '../../types';

export default(state, action) => {

    switch(action.type)
    {
        //  General section

        case GET_ENTITIES:
            return {
                ...state,
                entities: action.payload
            }

        case SET_ACTIVE_ENTITY:
            return {
				...state,
				editDialogBusinessPartnerTypesId: action.payload.businessPartnerTypesId,
                editDialogSalutation: action.payload.salutation,
                editDialogSalutationIndexSap: action.payload.salutationIndexSap,
            }

        case SET_DELETE_ENTITY:
            return {
                ...state,
                activeEntity: action.payload.id,
                deleteModalState: action.payload.deleteModalState
            }

        case SET_DELETE_MODAL_ENTITY_NAME:
            return {
                ...state,
                deleteModalEntityName: action.payload
            }

        case SET_EDIT_DIALOG_TITLE:
            return {
                ...state,
                editDialogTitle: action.payload
            }

        case SET_EDIT_ENTITY:
            return {
                ...state,
                activeEntity: action.payload.id,
                editState: action.payload.editState
            }

        case SET_ENTITY_DIALOG_FIELDS:
            return {
                ...state,
                editDialogFields: action.payload
            }

        case SET_ERROR:
            return {
                ...state,
                editDialogError: action.payload
			}
			
		case SET_USER_FOREIGNS:
			return {
				...state,
				businessPartnerTypes: action.payload.businessPartnerTypes,
			}


        //  Individual Section

        case SALUTATION_CHANGE:
            return {
                ...state,
                editDialogSalutation: action.payload
            }

        case SALUTATION_INDEX_SAP_CHANGE:
            return {
                ...state,
                editDialogSalutationIndexSap: action.payload,
			}
			
		case BUSINESS_PARTNER_TYPES_ID_CHANGE:
			return {
				...state,
				editDialogBusinessPartnerTypesId: action.payload,
			}
            
        //  Default Path
        default:
            return state;
    }
}