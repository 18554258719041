/**
 *      File:       ./src/context/campaigns/campaignsContext.js
 * 
 *      Subject:    Context for the campaigns
 */

import { createContext } from "react";

const campaignsContext = createContext();

export default campaignsContext;
