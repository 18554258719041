/**
 * 		File:		./src/context/exports/ExportProductsState.js
 * 
 * 		Subject:	State for products' exports
 */

import React, { useReducer } from "react";
import axios from "axios";
import ExportProductsContext from "./exportProductsContext";
import ExportProductsReducer from "./exportProductsReducer";

import {
	GET_EXPORTS_PRODUCTS
} from "../types";

const ExportProductsState = (props) => {

	const initialState = {
		dataProducts: [],
	};
	const [state, dispatch] = useReducer(ExportProductsReducer, initialState);


	//------------------------------
	//	GET THE EXPORTS
	//------------------------------
	const getExports = () => {

		//	Set call
		const params = new URLSearchParams();
		params.append('jwt', sessionStorage.jwt);
		params.append('exportPage', "products");

		let url = process.env.REACT_APP_API_URI + '/exports/get_exports.php';
		let axiosConfig = {
			headers: {
				"Content-Type": "application/x-www-form-urlencoded"
			}
		};

		//	Call backend
		axios.post(url, params, axiosConfig)
			.then(response => {
				if (response.status === 200) {

					// console.log(response.data);

					dispatch({
						type: GET_EXPORTS_PRODUCTS,
						payload: {
							productsExports: response.data.productsExports,
						},
					});

				} else if (response.status === 401) {
					window.location.href = '#/logout';
				} else {
					console.log('Anderer Fehler');
				}
			})
			.catch(function (error) {
				if (error.response) {
					// The server answered with an error
					if (error.response.status === 401) {
						window.location.href = '#/logout';
					} else {
						console.log(error.response.data);
						console.log(error.response.status);
						console.log(error.response.headers);
					}
				} else if (error.request) {
					//  The request was made but the server didn't respond
					console.log(error.request);
				} else {
					//  Something else went wrong
					console.log('Error', error.message);
				}
			})
	}

	const exportProducts = () => {

		//	Set call
		const params = new URLSearchParams();
		params.append('jwt', sessionStorage.jwt);

		let url = process.env.REACT_APP_API_URI + '/exports/start_export_products.php';
		let axiosConfig = {
			headers: {
				"Content-Type": "application/x-www-form-urlencoded"
			}
		};

		let productsInProgress = state.dataProducts;
		productsInProgress[0].id = 0;
		productsInProgress[0].filename = "Export wird erstellt...";
		dispatch({
			type: GET_EXPORTS_PRODUCTS,
			payload: {
				productsExports: productsInProgress,
			},
		});

		//	Call backend
		axios.post(url, params, axiosConfig)
			.then(response => {
				if (response.status === 200) {

					// console.log(response.data);
					window.setTimeout(getExports, 1000);

				} else if (response.status === 401) {
					window.location.href = '#/logout';
				} else {
					console.log('Anderer Fehler');
				}
			})
			.catch(function (error) {
				if (error.response) {
					// The server answered with an error
					if (error.response.status === 401) {
						window.location.href = '#/logout';
					} else {
						console.log(error.response.data);
						console.log(error.response.status);
						console.log(error.response.headers);
					}
				} else if (error.request) {
					//  The request was made but the server didn't respond
					console.log(error.request);
				} else {
					//  Something else went wrong
					console.log('Error', error.message);
				}
			})
	}

	return (
		<ExportProductsContext.Provider
			value={{
				dataProducts: state.dataProducts,

				getExports,
				exportProducts,
			}}
		>
			{props.children}
		</ExportProductsContext.Provider>
	);
};

export default ExportProductsState;
