/**
 *      File:       ./src/context/administration/products/productTypesReducer.js
 * 
 *      Subject:    Product Types' State Reducer
 */
import {

    DESCRIPTION_CHANGE,
    DIVISIONS_ID_CHANGE,
	GET_ENTITIES,
	IS_BIO_5_CHANGE,
    IS_BIO_10_CHANGE,
    IS_CLIMATE_PLUS_CHANGE,
    IS_CO2_CHANGE,
    PRODUCT_TYPE_NAME_CHANGE,
    SET_ACTIVE_ENTITY,
    SET_DELETE_ENTITY,
    SET_DELETE_MODAL_ENTITY_NAME,
    SET_EDIT_DIALOG_TITLE,
    SET_EDIT_ENTITY,
    SET_ENTITY_DIALOG_FIELDS,
    SET_ERROR,
    SET_FOREIGNS,

} from '../../types';

export default(state, action) => {
    
    switch(action.type)
    {
        case DESCRIPTION_CHANGE:
            return {
                ...state,
                editDialogDescription: action.payload,
            }

        case DIVISIONS_ID_CHANGE:
            return {
                ...state,
                editDialogDivisionsId: action.payload,
            }

        case GET_ENTITIES:
            return {
                ...state,
                entities: action.payload,
			}
			
		case IS_BIO_5_CHANGE:
			return {
				...state,
				editDialogIsBio5: action.payload,
			}

        case IS_BIO_10_CHANGE:
            return {
                ...state,
                editDialogIsBio10: action.payload,
            }

        case IS_CLIMATE_PLUS_CHANGE:
            return {
                ...state,
                editDialogIsClimatePlus: action.payload,
            }

        case IS_CO2_CHANGE:
            return {
                ...state,
                editDialogIsCo2: action.payload,
            }

        case PRODUCT_TYPE_NAME_CHANGE:
            return {
                ...state,
                editDialogProductTypeName: action.payload,
            }

        case SET_ACTIVE_ENTITY:
            return {
                ...state,
                activeEntity: action.payload.id,
                editDialogProductTypeName: action.payload.productTypeName,
                editDialogDescription: action.payload.description,
                editDialogDivisionsId: action.payload.divisionsId,
				editDialogIsClimatePlus: action.payload.isClimatePlus,
				editDialogIsBio5: action.payload.isBio5,
                editDialogIsBio10: action.payload.isBio10,
                editDialogIsCo2: action.payload.isCo2,
            }

        case SET_DELETE_ENTITY:
            return {
                ...state,
                activeEntity: action.payload.id,
                deleteModalState: action.payload.deleteModalState
            }

        case SET_DELETE_MODAL_ENTITY_NAME:
            return {
                ...state,
                deleteModalEntityName: action.payload
            }

        case SET_EDIT_DIALOG_TITLE:
            return {
                ...state,
                editDialogTitle: action.payload
            }

        case SET_EDIT_ENTITY:
            return {
                ...state,
                editState: action.payload.editState,
            }

        case SET_ENTITY_DIALOG_FIELDS:
            return {
                ...state,
                editDialogFields: action.payload
            }

        case SET_ERROR:
            return {
                ...state,
                editDialogError: action.payload
            }

        case SET_FOREIGNS:
            return {
                ...state,
                divisions: action.payload.divisions
            }

        //  Default Path
        default:
            return state;
    }
}