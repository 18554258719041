/**
 *      File:       ./src/context/administration/energy/networksReducer.js
 * 
 *      Subject:    Networks' State Reducer
 */

import {
    GET_ENTITIES,
    SET_ACTIVE_ENTITY,
    SET_DELETE_ENTITY,
    SET_DELETE_MODAL_ENTITY_NAME,
    SET_EDIT_DIALOG_TITLE,
    SET_EDIT_ENTITY,
    SET_ENTITY_DIALOG_FIELDS,
    SET_ERROR,

    SET_NETWORK_FOREIGNS,
    NETWORK_SHORTCUT_CHANGE,
    NETWORK_NAME_CHANGE,
    REGULATION_ZONES_ID_CHANGE,
    DIVISIONS_ID_CHANGE,
    MARKETS_ID_CHANGE,

} from '../../types';

export default(state, action) => {

    switch(action.type)
    {
        //  General section

        case GET_ENTITIES:
            return {
                ...state,
                entities: action.payload
            }

        case SET_ACTIVE_ENTITY:
            return {
                ...state,
                editDialogNetworkShortcut: action.payload.network_shortcut,
                editDialogNetworkName: action.payload.network_name,
                editDialogRegulationZonesId: action.payload.regulation_zones_id,
                editDialogDivisionsId: action.payload.divisions_id,
                editDialogMarketsId: action.payload.markets_id,
            }

        case SET_DELETE_ENTITY:
            return {
                ...state,
                activeEntity: action.payload.id,
                deleteModalState: action.payload.deleteModalState
            }

        case SET_DELETE_MODAL_ENTITY_NAME:
            return {
                ...state,
                deleteModalEntityName: action.payload
            }

        case SET_EDIT_DIALOG_TITLE:
            return {
                ...state,
                editDialogTitle: action.payload
            }

        case SET_EDIT_ENTITY:
            return {
                ...state,
                activeEntity: action.payload.id,
                editState: action.payload.editState
            }

        case SET_ENTITY_DIALOG_FIELDS:
            return {
                ...state,
                editDialogFields: action.payload
            }

        case SET_ERROR:
            return {
                ...state,
                editDialogError: action.payload
            }


        //  Individual Section

        case SET_NETWORK_FOREIGNS:
            return {
                ...state,
                regulationZones: action.payload.regulationZones,
                divisions: action.payload.divisions,
                markets: action.payload.markets,
            }
            
        case NETWORK_SHORTCUT_CHANGE:
            return {
                ...state,
                editDialogNetworkShortcut: action.payload
            }

        case NETWORK_NAME_CHANGE:
            return {
                ...state,
                editDialogNetworkName: action.payload
            }

        case REGULATION_ZONES_ID_CHANGE:
            return {
                ...state,
                editDialogRegulationZonesId: action.payload
            }

        case DIVISIONS_ID_CHANGE:
            return {
                ...state,
                editDialogDivisionsId: action.payload,
            }

        case MARKETS_ID_CHANGE:
            return {
                ...state,
                editDialogMarketsId: action.payload,
            }

            
        //  Default Path
        default:
            return state;
    }
}