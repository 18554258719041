import {
	GET_ENTITIES
} from '../types';

export default (state, action) => {
	switch (action.type) {

		case GET_ENTITIES:
			return {
				...state,
				entities: action.payload
			}

		default:
			return state;
	}
}