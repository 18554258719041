/**
 *      File:       ./src/Components/FormInput.js
 * 
 *      Subject:    Common input field for universal use
 */

import React, { useContext } from 'react'
import {
    FormGroup,
    Label, Input
} from 'reactstrap';

const FormInput = (props) => {

    const {
        context,
        id,
        label,
        onChangeFunction,
        placeholder,
        required,
        type,
        value,
    } = props;

    const innerContext = useContext(context);
    const contextValue = innerContext[value];

    return (
        <FormGroup>
            <Label for={id}>
                {label}
                <span>&nbsp;<sup>{required ? '*' : ''}</sup></span>
            </Label>
            <Input type={type} id={id}
                placeholder={placeholder} value={contextValue} onChange={onChangeFunction} />
        </FormGroup>
    )
}

export default FormInput
