/**
 * 		File:		./src/context/exports/TerminatesState.js
 * 
 * 		Subject:	State for contract termination exports
 */
import React, { useReducer } from "react";
import axios from "axios";
import TerminatesContext from "./terminatesContext";
import TerminatesReducer from "./terminatesReducer";

import {
	GET_EXPORTS_TERMINATES
} from "../types";

const TerminatesState = (props) => {

  const initialState = {

	dataTerminate: [],
    
  };
  const [state, dispatch] = useReducer(TerminatesReducer, initialState);


  //------------------------------
  //	GET THE EXPORTS
  //------------------------------
  const getExports = () => {

	//	Set call
	const params = new URLSearchParams();
    params.append('jwt', sessionStorage.jwt);
    params.append('exportPage', "terminates");

	let url = process.env.REACT_APP_API_URI + '/exports/get_exports.php';
    let axiosConfig = {
        headers: {
            "Content-Type": "application/x-www-form-urlencoded"
        }
    };

	//	Call backend
    axios.post(url,params,axiosConfig)
    .then(response => {
        if(response.status === 200) {

            // console.log(response.data);
            
            dispatch({
                type: GET_EXPORTS_TERMINATES,
                payload: {
					terminateExports : response.data.terminateExports,
				},
            });

        } else if(response.status === 401) {
            window.location.href ='#/logout';
        } else {
            console.log('Anderer Fehler');
        }
    })
    .catch(function(error) {
        if(error.response){
            // The server answered with an error
            if(error.response.status === 401) {
                window.location.href = '#/logout';
            } else {
                console.log(error.response.data);
                console.log(error.response.status);
                console.log(error.response.headers);
            }
        } else if(error.request) {
            //  The request was made but the server didn't respond
            console.log(error.request);
        } else {
            //  Something else went wrong
            console.log('Error', error.message);
        }
    })
  }

  const exportTerminate = () => {

	//	Set call
	const params = new URLSearchParams();
    params.append('jwt', sessionStorage.jwt);

	let url = process.env.REACT_APP_API_URI + '/exports/start_export_terminate.php';
    let axiosConfig = {
        headers: {
            "Content-Type": "application/x-www-form-urlencoded"
        }
	};
	
	let terminateInProgress = state.dataTerminate;
	terminateInProgress[0].id = 0;
	terminateInProgress[0].filename = "Export wird erstellt...";
	dispatch({
		type: GET_EXPORTS_TERMINATES,
		payload: {
			terminateExports: terminateInProgress,
		},
	});

	//	Call backend
    axios.post(url,params,axiosConfig)
    .then(response => {
        if(response.status === 200) {

			// console.log(response.data);
			window.setTimeout(getExports, 1000);

        } else if(response.status === 401) {
            window.location.href ='#/logout';
        } else {
            console.log('Anderer Fehler');
        }
    })
    .catch(function(error) {
        if(error.response){
            // The server answered with an error
            if(error.response.status === 401) {
                window.location.href = '#/logout';
            } else {
                console.log(error.response.data);
                console.log(error.response.status);
                console.log(error.response.headers);
            }
        } else if(error.request) {
            //  The request was made but the server didn't respond
            console.log(error.request);
        } else {
            //  Something else went wrong
            console.log('Error', error.message);
        }
    })
  }

  return (
    <TerminatesContext.Provider
      value={{
		  dataTerminate: state.dataTerminate,

		  getExports,
		  exportTerminate,
      }}
    >
      {props.children}
    </TerminatesContext.Provider>
  );
};

export default TerminatesState;
