/**
 * 		File:		./src/context/exports/NavigationState.js
 * 
 * 		Subject:	State for navigation export
 */
import React, { useReducer } from "react";
import axios from "axios";
import NavigationContext from "./navigationContext";
import NavigationReducer from "./navigationReducer";

import {
	GET_EXPORTS_NAVIGATION,
} from "../types";

const NavigationState = (props) => {

  const initialState = {
	  dataNavigation: [],
	  dataLeadNavigation: [],
  };
  const [state, dispatch] = useReducer(NavigationReducer, initialState);


  //------------------------------
  //	GET THE EXPORTS
  //------------------------------
  const getExports = () => {

	//	Set call
	const params = new URLSearchParams();
    params.append('jwt', sessionStorage.jwt);
    params.append('exportPage', "navigation");

	let url = process.env.REACT_APP_API_URI + '/exports/get_exports.php';
    let axiosConfig = {
        headers: {
            "Content-Type": "application/x-www-form-urlencoded"
        }
    };

	//	Call backend
    axios.post(url,params,axiosConfig)
    .then(response => {
        if(response.status === 200) {

            // console.log(response.data);
            
            dispatch({
                type: GET_EXPORTS_NAVIGATION,
                payload: {
					navigationExports : response.data.navigationExports,
					leadNavigationExports: response.data.leadNavigationExports,
				},
            });

        } else if(response.status === 401) {
            window.location.href ='#/logout';
        } else {
            console.log('Anderer Fehler');
        }
    })
    .catch(function(error) {
        if(error.response){
            // The server answered with an error
            if(error.response.status === 401) {
                window.location.href = '#/logout';
            } else {
                console.log(error.response.data);
                console.log(error.response.status);
                console.log(error.response.headers);
            }
        } else if(error.request) {
            //  The request was made but the server didn't respond
            console.log(error.request);
        } else {
            //  Something else went wrong
            console.log('Error', error.message);
        }
    })
  }

  const exportNavigation = () => {

	//	Set call
	const params = new URLSearchParams();
    params.append('jwt', sessionStorage.jwt);

	let url = process.env.REACT_APP_API_URI + '/exports/start_export_navigation.php';
    let axiosConfig = {
        headers: {
            "Content-Type": "application/x-www-form-urlencoded"
        }
	};
	
	let navigationInProgress = state.dataNavigation;
	navigationInProgress[0].id = 0;
	navigationInProgress[0].filename = "Export wird erstellt...";
	dispatch({
		type: GET_EXPORTS_NAVIGATION,
		payload: {
			navigationExports: navigationInProgress,
			leadNavigationExports: state.leadNavigationExports,
		},
	});

	//	Call backend
    axios.post(url,params,axiosConfig)
    .then(response => {
        if(response.status === 200) {

			// console.log(response.data);
			window.setTimeout(getExports, 1000);

        } else if(response.status === 401) {
            window.location.href ='#/logout';
        } else {
            console.log('Anderer Fehler');
        }
    })
    .catch(function(error) {
        if(error.response){
            // The server answered with an error
            if(error.response.status === 401) {
                window.location.href = '#/logout';
            } else {
                console.log(error.response.data);
                console.log(error.response.status);
                console.log(error.response.headers);
            }
        } else if(error.request) {
            //  The request was made but the server didn't respond
            console.log(error.request);
        } else {
            //  Something else went wrong
            console.log('Error', error.message);
        }
    })
  }

  const exportLeadNavigation = () => {

	//	Set call
	const params = new URLSearchParams();
    params.append('jwt', sessionStorage.jwt);

	let url = process.env.REACT_APP_API_URI + '/exports/start_export_lead_navigation.php';
    let axiosConfig = {
        headers: {
            "Content-Type": "application/x-www-form-urlencoded"
        }
	};
	
	let navigationInProgress = state.dataLeadNavigation;
	navigationInProgress[0].id = 0;
	navigationInProgress[0].filename = "Export wird erstellt...";
	dispatch({
		type: GET_EXPORTS_NAVIGATION,
		payload: {
			navigationExports: state.navigationExports,
			leadNavigationExports: navigationInProgress,
		},
	});

	//	Call backend
    axios.post(url,params,axiosConfig)
    .then(response => {
        if(response.status === 200) {

			// console.log(response.data);
			window.setTimeout(getExports, 1000);

        } else if(response.status === 401) {
            window.location.href ='#/logout';
        } else {
            console.log('Anderer Fehler');
        }
    })
    .catch(function(error) {
        if(error.response){
            // The server answered with an error
            if(error.response.status === 401) {
                window.location.href = '#/logout';
            } else {
                console.log(error.response.data);
                console.log(error.response.status);
                console.log(error.response.headers);
            }
        } else if(error.request) {
            //  The request was made but the server didn't respond
            console.log(error.request);
        } else {
            //  Something else went wrong
            console.log('Error', error.message);
        }
    })
  }

  return (
    <NavigationContext.Provider
      value={{
		  dataNavigation: state.dataNavigation,
		  dataLeadNavigation: state.dataLeadNavigation,

		  getExports,
		  exportNavigation,
		  exportLeadNavigation, 
      }}
    >
      {props.children}
    </NavigationContext.Provider>
  );
};

export default NavigationState;
