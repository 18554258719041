/**
 *      File:       ./src/context/administration/transactions/contractTerminationReasonsContext.js
 * 
 *      Subject:    Contract Termination Reasons' Context
 */

import { createContext } from 'react';

const contractTerminationReasonsContext = createContext();

export default contractTerminationReasonsContext;