/**
 *      File:       ./src/context/administration/products/productAssignmentsReducer.js
 * 
 *      Subject:    Product Assignments' State Reducer
 */
import {
    GET_ENTITIES,
    SET_ACTIVE_ENTITY,
    SET_DELETE_ENTITY,
    SET_DELETE_MODAL_ENTITY_NAME,
    SET_EDIT_DIALOG_TITLE,
    SET_EDIT_ENTITY,
    SET_ENTITY_DIALOG_FIELDS,
    SET_ERROR,

    SET_PRODUCT_ASSIGNMENT_FOREIGNS,
    PRODUCTS_ID_CHANGE,
    PRODUCT_GROUPS_ID_CHANGE

} from '../../types';

export default(state, action) => {

    switch(action.type)
    {
        //  General section

        case GET_ENTITIES:
            return {
                ...state,
                entities: action.payload
            }

        case SET_ACTIVE_ENTITY:
            return {
                ...state,
                editDialogProductsId: action.payload.products_id,
                editDialogProductGroupsId: action.payload.product_groups_id
            }

        case SET_DELETE_ENTITY:
            return {
                ...state,
                activeEntity: action.payload.id,
                deleteModalState: action.payload.deleteModalState
            }

        case SET_DELETE_MODAL_ENTITY_NAME:
            return {
                ...state,
                deleteModalEntityName: action.payload
            }

        case SET_EDIT_DIALOG_TITLE:
            return {
                ...state,
                editDialogTitle: action.payload
            }

        case SET_EDIT_ENTITY:
            return {
                ...state,
                activeEntity: action.payload.id,
                editState: action.payload.editState
            }

        case SET_ENTITY_DIALOG_FIELDS:
            return {
                ...state,
                editDialogFields: action.payload
            }

        case SET_ERROR:
            return {
                ...state,
                editDialogError: action.payload
            }


        //  Individual Section

        case SET_PRODUCT_ASSIGNMENT_FOREIGNS:
            return {
                ...state,
                products: action.payload.products,
                productGroups: action.payload.productGroups
            }
            
        case PRODUCTS_ID_CHANGE:
            return {
                ...state,
                editDialogProductsId: action.payload
            }

        case PRODUCT_GROUPS_ID_CHANGE:
            return {
                ...state,
                editDialogProductGroupsId: action.payload
            }

            
        //  Default Path
        default:
            return state;
    }
}