/**
 *      File:       ./src/context/administration/energy/administrationsContext.js
 * 
 *      Subject:    Energy Administration's context
 */
import { createContext } from "react";

const administrationsContext = createContext();

export default administrationsContext;
