/**
 *      File:       ./src/context/administration/products/TarifTypesState.js
 * 
 *      Subject:    Tarif Types' State
 */
import React, { useReducer, useEffect } from 'react';
import axios from 'axios';
import TarifTypesContext from './tarifTypesContext';
import TarifTypesReducer from './tarifTypesReducer';
import FormInput from '../../../Components/FormInput';

import {

	DESCRIPTION_CHANGE,
	TARIF_TYPE_NAME_CHANGE,
	SHORTCUT_CHANGE,
	GET_ENTITIES,
    SET_ACTIVE_ENTITY,
    SET_DELETE_ENTITY,
    SET_DELETE_MODAL_ENTITY_NAME,
    SET_EDIT_DIALOG_TITLE,
    SET_EDIT_ENTITY,
    SET_ENTITY_DIALOG_FIELDS,
    SET_ERROR,

} from '../../types';

const TarifTypesState = props => {

    const initialState = {

        activeEntity: 0,
        deleteModalEntityName: '',
		deleteModalState: false,
		editDialogTarifTypeName: '',
		editDialogDescription: '',
		editDialogShortcut: '',
        editDialogError: '',
		editDialogFields: [],
        editDialogTitle: '',
        editState: false,
        entities: [],
        tableCols: [
            {
                Header: "Tariftyp",
                accessor: "tarif_type_name"
            },
            {
                Header: "Beschreibung",
                accessor: "description"
            },
            {
                Header: "Schlüssel",
                accessor: "shortcut",
                width: 140,
            }
        ],

    }
	const [state,dispatch] = useReducer(TarifTypesReducer, initialState);
	
    useEffect(() => {

        const edf = [
            {
                id: 1,
                fieldType: <FormInput
                    context             = {TarifTypesContext}
                    id                  = "tarif_type_name"
                    label               = "Name des Tariftyps"
                    onChangeFunction    = {tarifTypeNameChange}
                    placeholder         = "Tariftyp"
                    required
                    type                = "text"
                    value               = "editDialogTarifTypeName"
                />
            },
            {
                id: 2,
                fieldType: <FormInput
                    context             = {TarifTypesContext}
                    id                  = "description"
                    label               = "Beschreibung"
                    onChangeFunction    = {descriptionChange}
                    placeholder         = "Beschreibung"
                    type                = "text"
                    value               = "editDialogDescription"
                />
            },
            {
                id: 3,
                fieldType: <FormInput
                    context             = {TarifTypesContext}
                    id                  = "shortcut"
                    label               = "Schlüssel"
                    onChangeFunction    = {shortcutChange}
					placeholder         = "Schlüssel"
					required
                    type                = "text"
                    value               = "editDialogShortcut"
                />
            }
        ];

        dispatch({
            type: SET_ENTITY_DIALOG_FIELDS,
            payload: edf
        });

        getEntities();

        // eslint-disable-next-line
    }, [state.editDialogError]);


    const getEntities = () => {

        const params = new URLSearchParams();
        params.append('jwt', sessionStorage.jwt);
		
		let url = process.env.REACT_APP_API_URI + '/administration/products/get_tarif_types.php';
        let axiosConfig = {
            headers: {
                "Content-Type": "application/x-www-form-urlencoded"
            }
        };


        axios.post(url,params,axiosConfig)
        .then(response => {
            if(response.status === 200) {

                // console.log(response.data.entities);
                
                dispatch({
                    type: GET_ENTITIES,
                    payload: response.data.entities
                });

            } else if(response.status === 401) {
                window.location.href ='#/logout';
            } else {
                console.log('Anderer Fehler');
            }
        })
        .catch(function(error) {
            if(error.response){
                // The server answered with an error
                if(error.response.status === 401) {
                    window.location.href = '#/logout';
                } else {
                    console.log(error.response.data);
                    console.log(error.response.status);
                    console.log(error.response.headers);
                }
            } else if(error.request) {
                //  The request was made but the server didn't respond
                console.log(error.request);
            } else {
                //  Something else went wrong
                console.log('Error', error.message);
            }
        })
    }

    const editEntity = (id) => {

        if(id > 0)
        {
            const params = new URLSearchParams();
            params.append('jwt', sessionStorage.jwt);
            params.append('id', id);
			
			let url = process.env.REACT_APP_API_URI + '/administration/products/get_tarif_type.php';
            let axiosConfig = {
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded"
                }
            };

            axios.post(url,params,axiosConfig)
            .then(response => {
                if(response.status === 200) {
                    // console.log(response.data);

                    dispatch({
                        type: SET_ACTIVE_ENTITY,
                        payload: response.data.tarifType
                    });

                    dispatch({
                        type: SET_EDIT_ENTITY,
                        payload: {
                            editState: true
                        }
                    });

                    dispatch({
                        type: SET_EDIT_DIALOG_TITLE,
                        payload: 'Tariftyp bearbeiten'
					});
					
                } else if(response.status === 401) {
                    window.location.href ='#/logout';
                } else {
                    console.log('Anderer Fehler');
                }
            })
            .catch(function(error) {
                if(error.response){
                    // The server answered with an error
                    if(error.response.status === 401) {
                        window.location.href = '#/logout';
                    } else {
                        console.log(error.response.data);
                        console.log(error.response.status);
                        console.log(error.response.headers);
                    }
                } else if(error.request) {
                    //  The request was made but the server didn't respond
                    console.log(error.request);
                } else {
                    //  Something else went wrong
                    console.log('Error', error.message);
                }
            })
        } 
        else {
            dispatch({
                type: SET_EDIT_DIALOG_TITLE,
                payload: 'Neuen Tariftyp anlegen'
            });
        }

        dispatch({
            type: SET_EDIT_ENTITY,
            payload: {
                id: id,
                editState: true
            }
        });
        
    }

    const setError = (errMsg) => {
        dispatch({
            type: SET_ERROR,
            payload: errMsg
        });
    }

    const saveEntity = async () => {

        if(state.editDialogTarifTypeName === '') {
            setError('Bitte geben Sie eine Bezeichnung für den Tariftyp ein.');
            return false;
		}
		
		if(state.editDialogShortcut === '') {
			setError('Bitte geben Sie einen Typ-Schlüssel an.');
			return false;
		}

        const params = new URLSearchParams();
        params.append('jwt', sessionStorage.jwt);
        params.append('id', state.activeEntity);
        params.append('tarifTypeName', state.editDialogTarifTypeName);
		params.append('description', state.editDialogDescription);
		params.append('shortcut', state.editDialogShortcut);

		let url = process.env.REACT_APP_API_URI + '/administration/products/set_tarif_type.php';
        let axiosConfig = {
            headers: {
                "Content-Type": "application/x-www-form-urlencoded"
            }
        };


        axios.post(url,params,axiosConfig)
        .then(response => {
            if(response.status === 200) {

                // console.log(response.data);

                const tarifType = {
                    tarifTypeName: '',
					description: '',
					shortcut: ''
                };
                getEntities();

                dispatch({
                    type: SET_ACTIVE_ENTITY,
                    payload: tarifType
                });
                dispatch({
                    type: SET_EDIT_ENTITY,
                    payload: {
                        editState: false
                    }
                });
                return true;

            } else if(response.status === 401) {
                window.location.href ='#/logout';
            } else {
                console.log('Anderer Fehler');
                return false;
            }
        })
        .catch(function(error) {
            if(error.response){
                // The server answered with an error
                if(error.response.status === 401) {
                    window.location.href = '#/logout';
                } else {
                    console.log(error.response.data);
                    console.log(error.response.status);
                    console.log(error.response.headers);
                    return false;
                }
            } else if(error.request) {
                //  The request was made but the server didn't respond
                console.log(error.request);
                return false;
            } else {
                //  Something else went wrong
                console.log('Error', error.message);
                return false;
            }
        })
        return true;
    }

    const closeEdit = () => {
        dispatch({
            type: SET_ACTIVE_ENTITY,
            payload: {
                tarifTypeName: '',
				description: '',
				shortcut: ''
            }
        });
        
        dispatch({
            type: SET_EDIT_ENTITY,
            payload: {
                editState: false
            }
        });
    }

    const deleteEntity = (id) => {
        
        const params = new URLSearchParams();
        params.append('jwt', sessionStorage.jwt);
        params.append('id', id);
		
		let url = process.env.REACT_APP_API_URI + '/administration/products/get_tarif_type.php';
        let axiosConfig = {
            headers: {
                "Content-Type": "application/x-www-form-urlencoded"
            }
		};
		
        axios.post(url,params,axiosConfig)
        .then(response => {
            if(response.status === 200) {
                // console.log(response.data);
                
                dispatch({
                    type: SET_ACTIVE_ENTITY,
                    payload: response.data.tarifType
                });

                dispatch({
                    type: SET_DELETE_ENTITY,
                    payload: {
                        id: id,
                        deleteModalState: true
                    }
                });

                dispatch({
                    type: SET_DELETE_MODAL_ENTITY_NAME,
                    payload: 'Möchten Sie den Tariftyp "' + response.data.tarifType.tarifTypeName + '" wirklich löschen?'
                });

            } else if(response.status === 401) {
                window.location.href ='#/logout';
            } else {
                console.log('Anderer Fehler');
            }
        })
        .catch(function(error) {
            if(error.response){
                // The server answered with an error
                if(error.response.status === 401) {
                    window.location.href = '#/logout';
                } else {
                    console.log(error.response.data);
                    console.log(error.response.status);
                    console.log(error.response.headers);
                }
            } else if(error.request) {
                //  The request was made but the server didn't respond
                console.log(error.request);
            } else {
                //  Something else went wrong
                console.log('Error', error.message);
            }
        })
    }

    const closeDelete = () => {

        dispatch({
            type: SET_ACTIVE_ENTITY,
            payload: {
                tarifTypeName: '',
				description: '',
				shortcut: ''
            }
        });
        
        dispatch({
            type: SET_EDIT_ENTITY,
            payload: {
                editState: false
            }
        });

        dispatch({
            type: SET_DELETE_ENTITY,
            payload: {
                id: 0,
                deleteModalState: false
            }
        });

        dispatch({
            type: SET_DELETE_MODAL_ENTITY_NAME,
            payload: ''
        });
    }

    const deleteEntityConfirmed = () => {

        const params = new URLSearchParams();
        params.append('jwt', sessionStorage.jwt);
        params.append('id', state.activeEntity);

		let url = process.env.REACT_APP_API_URI + '/administration/products/delete_tarif_type.php';
        let axiosConfig = {
            headers: {
                "Content-Type": "application/x-www-form-urlencoded"
            }
        };

        axios.post(url,params,axiosConfig)
        .then(response => {
            if(response.status === 200) {
                // console.log(response.data);
                
                getEntities();
                closeDelete();

            } else if(response.status === 401) {
                window.location.href ='#/logout';
            } else {
                console.log('Anderer Fehler');
            }
        })
        .catch(function(error) {
            if(error.response){
                // The server answered with an error
                if(error.response.status === 401) {
                    window.location.href = '#/logout';
                } else {
                    console.log(error.response.data);
                    console.log(error.response.status);
                    console.log(error.response.headers);
                }
            } else if(error.request) {
                //  The request was made but the server didn't respond
                console.log(error.request);
            } else {
                //  Something else went wrong
                console.log('Error', error.message);
            }
        })
    }

    /*-------------------------------------
    //  PAGE SPECIFIC
    -------------------------------------*/

    const descriptionChange = (e) => {
        dispatch({
            type: DESCRIPTION_CHANGE,
            payload: e.target.value,
        });
    }

    const tarifTypeNameChange = (e) => {
        if(state.editDialogError !== '' && state.editDialogTarifTypeName === '')
            setError('');

        dispatch({
            type: TARIF_TYPE_NAME_CHANGE,
            payload: e.target.value,
        });
	}
	
	const shortcutChange = (e) => {
		if(state.editDialogError !== '' && state.editDialogShortcut === '')
			setError('');

		dispatch({
			type: SHORTCUT_CHANGE,
			payload: e.target.value,
		});
	}

    return <TarifTypesContext.Provider value={{

        activeEntity: state.activeEntity,
        deleteModalEntityName: state.deleteModalEntityName,
        deleteModalState: state.deleteModalState,
        editDialogDescription: state.editDialogDescription,
        editDialogError: state.editDialogError,
		editDialogFields: state.editDialogFields,
		editDialogTarifTypeName: state.editDialogTarifTypeName,
		editDialogShortcut: state.editDialogShortcut,
        editDialogTitle: state.editDialogTitle,
        editState: state.editState,
        entities: state.entities,
        tableCols: state.tableCols,

        getEntities,
        editEntity,
        setError,
        saveEntity,
        closeEdit,
        deleteEntity,
        closeDelete,
        deleteEntityConfirmed,
		descriptionChange,
		tarifTypeNameChange,
		shortcutChange,

}}>
        {props.children}
    </TarifTypesContext.Provider>
}

export default TarifTypesState;