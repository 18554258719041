/**
 * 		File:		./src/context/customers/customersReducers.js
 *
 * 		Subject:	Customer's state reducer
 */
import {
	IS_READING_TYPE_CHANGE_COMMENT, 

	//	CUSTOMERS
	CLOSE_CUSTOMER_DETAIL_DIALOG,
	DELETE_CUSTOMER_CONFIRM,
	EDIT_CUSTOMER,
	GET_CUSTOMERS,
	SET_ERR_MSG,
	SET_FOREIGNS,

	//	CUSTOMER ADDRESS
	ABC_ID_CHANGE,
	BUSINESS_PARTNER_TYPES_ID_CHANGE,
	FOLLOW_UP_DATE_CHANGE,
	FOLLOW_UP_DONE_CHANGE,
	IS_PORTAL_ACCESS_ADMINISTRATION_CHANGE,
	SET_ACTIVE_ADDRESS_TAB,
	SUPERVISORS_ID_CHANGE,
	SUPERVISORS_PV_ID_CHANGE,
	READING_TYPES_ID_CHANGE,

	//	CUSTOMER OBJECT LIST DIALOG
	CUSTOMER_OBJECT_LIST_DIALOG,
	CUSTOMER_OBJECT_LIST_IS_FINISHED,

	//	CUSTOMER ADDRESS ADDRESS
	ADDRESS_ADDITION_CHANGE,
	CITY_CHANGE,
	COUNTRY_CHANGE,
	DISTRICT_CHANGE,
	HOUSE_NUMBER_CHANGE,
	NAME_1_CHANGE,
	NAME_2_CHANGE,
	NAME_3_CHANGE,
	LEGAL_FORMS_ID_CHANGE,
	POSTAL_CODE_CHANGE,
	SALUTATIONS_ID_CHANGE,
	STREET_CHANGE,

	//	CUSTOMER ADDRESS CONTACT
	EMAIL_CHANGE,
	FAX_CHANGE,
	PHONE_CHANGE,
	URL_CHANGE,

	//	CUSTOMER ADDRESS POSTBOX
	POSTBOX_CHANGE,
	POSTBOX_CITY_CHANGE,
	POSTBOX_POSTAL_CODE_CHANGE,

	//	CUSTOMER ADDRESS ADMINISTRATION
	ADMINISTRATIONS_1_ID_CHANGE,
	ADMINISTRATIONS_2_ID_CHANGE,
	ADMINISTRATIONS_3_ID_CHANGE,
	COMMUNITY_CODE_CHANGE,
	GET_ADMINISTRATIONS_1,
	GET_ADMINISTRATIONS_2,
	GET_ADMINISTRATIONS_3,
	OWNER_ID_CHANGE,

	//	CUSTOMER CONTACT PERSONS
	CLOSE_CONTACT_PERSON_DIALOG,
	CLOSE_DELETE,
	CONTACT_PERSON_DIVISION_CHANGE,
	CONTACT_PERSON_EMAIL_CHANGE,
	CONTACT_PERSON_FAX_CHANGE,
	CONTACT_PERSON_FIRSTNAME_CHANGE,
	CONTACT_PERSON_FUNCTION_CHANGE,
	CONTACT_PERSON_IS_INFO_READING_CHANGE,
	CONTACT_PERSON_IS_PORTAL_ACCESS_CHANGE,
	CONTACT_PERSON_LASTNAME_CHANGE,
	CONTACT_PERSON_MOBILE_CHANGE,
	CONTACT_PERSON_PHONE_CHANGE,
	CONTACT_PERSON_PORTAL_ACCESS_VALID_TO_CHANGE,
	CONTACT_PERSON_SALUTATIONS_ID_CHANGE,
	CONTACT_PERSON_TITLE_CHANGE,
	DELETE_CONTACT_PERSON,
	DELETE_CONTACT_PERSON_CONFIRM,
	EDIT_CONTACT_PERSON,
	GET_CONTACT_PERSONS,
	SET_ACTIVE_CONTACT_PERSON,
	SET_CONTACT_PERSON_ERROR,

	//	CUSTOMER BANK
	ACCOUNT_NO_CHANGE,
	ACCOUNT_OWNER_CHANGE,
	BANK_CODE_CHANGE,
	IBAN_CHANGE,

	//	CUSTOMER PRODUCTS
	SET_ACTIVE_PRODUCT_TAB,

	//	CUSTOMER PRODUCT ACTIVE
	ACTIVE_ENERGY_CAMPAIGNS_ID_CHANGE,
	ACTIVE_GAS_CAMPAIGNS_ID_CHANGE,
	OFFERED_ENERGY_CAMPAIGNS_ID_CHANGE,
	OFFERED_GAS_CAMPAIGNS_ID_CHANGE,
	IS_CUSTOMER_ENERGY_TRANSITION_CHANGE,
	CUSTOMER_ENERGY_TRANSITION_DATE_CHANGE,
	IS_CUSTOMER_ENERGY_TRANSITION_TEN_PERCENT_CHANGE,
	CUSTOMER_ENERGY_TRANSITION_TEN_PERCENT_DATE_CHANGE,
	IS_CUSTOMER_GAS_TRANSITION_CHANGE,
	CUSTOMER_GAS_TRANSITION_DATE_CHANGE,
	IS_CUSTOMER_GAS_TRANSITION_TEN_PERCENT_CHANGE,
	CUSTOMER_GAS_TRANSITION_TEN_PERCENT_DATE_CHANGE,

	//	CUSTOMER PRODUCTS HISTORY
	GET_PRODUCT_HISTORIES,

	//	CUSTOMER CONTRACTS
	CLOSE_CONTRACTS_DIALOG,
	CLOSE_DELETE_CONTRACT,
	DELETE_CONTRACT_CONFIRM,
	EDIT_CUSTOMER_CONTRACT,
	GET_CONTRACTS,
	SET_ACTIVE_CONTRACT_TAB,
	SET_CONTRACT_ERR_MSG,
	SET_CUSTOMER_DETAIL_DIALOG_ACTIVE_TAB,
	UPDATE_CONTRACT_EXTERNALS,

	//	CUSTOMER CONTRACTS CONTRACT
	CUSTOMER_CONTRACT_DIVISIONS_ID_CHANGE,
	CUSTOMER_CONTRACT_GROSS_BILL_AMOUNT_CHANGE,
	CUSTOMER_CONTRACT_IS_BIO10_CHANGE,
	CUSTOMER_CONTRACT_BIO10_START_DATE_CHANGE,
	CUSTOMER_CONTRACT_IS_BIO5_CHANGE,
	CUSTOMER_CONTRACT_BIO5_START_DATE_CHANGE,
	CUSTOMER_CONTRACT_IS_CLIMATE_PLUS_CHANGE,
	CUSTOMER_CONTRACT_PERIOD_CONSUMPTION_CHANGE,
	CUSTOMER_CONTRACT_POTS_ID_CHANGE,
	CUSTOMER_CONTRACT_PRODUCTS_ID_CHANGE,
	CUSTOMER_CONTRACT_PROFILE_TYPE_CHANGE,
	CUSTOMER_CONTRACT_VALID_FROM_CHANGE,
	CUSTOMER_CONTRACT_VALID_TO_CHANGE,
	IS_CUSTOMER_CONTRACT_TRANSITION_CHANGE,
	CUSTOMER_CONTRACT_TRANSITION_DATE_CHANGE,
	IS_CUSTOMER_CONTRACT_TRANSITION_TEN_PERCENT_CHANGE,
	CUSTOMER_CONTRACT_TRANSITION_TEN_PERCENT_DATE_CHANGE,

	//	CUSTOMER CONTRACT COUNTER
	CUSTOMER_COUNTER_BHKW_CHANGE,
	CUSTOMER_COUNTER_CONTRACT_ACCOUNT_CHANGE,
	CUSTOMER_COUNTER_CONTRACT_ACCOUNT_DESCRIPTION_CHANGE,
	CUSTOMER_COUNTER_INTENDED_USE_CHANGE,
	CUSTOMER_COUNTER_MEDIUM_VOLTAGE_CHANGE,
	CUSTOMER_COUNTER_NUMBER_CURRENT_CHANGE,
	CUSTOMER_COUNTER_NUMBER_FORMER_CHANGE,
	IS_CUSTOMER_COUNTER_LEAVE_DONE_CHANGE,
	SET_ACTIVE_CONTRACT_COUNTER_TAB,

	//	CUSTOMER CONTRACT COUNTER CHANGE
	CUSTOMER_COUNTER_CHANGE_REASONS_ID_CHANGE,
	CUSTOMER_COUNTER_CHANGE_SUPPLY_START_CHANGE,
	CUSTOMER_COUNTER_FORMER_SUPPLIER_ID_CHANGE,
	CUSTOMER_COUNTER_INSTALLMENT_CYCLE_CHANGE,
	CUSTOMER_COUNTER_LOAD_PROFILES_ID_CHANGE,

	//	CUSTOMER CONTRACT COUNTER LOCATION
	CUSTOMER_COUNTER_LOCATION_ADDITIONAL_CHANGE,
	CUSTOMER_COUNTER_LOCATION_CITY_CHANGE,
	CUSTOMER_COUNTER_LOCATION_COUNTRY_CHANGE,
	CUSTOMER_COUNTER_LOCATION_DISTRICT_CHANGE,
	CUSTOMER_COUNTER_LOCATION_HOUSE_NUMBER_CHANGE,
	CUSTOMER_COUNTER_LOCATION_MARKET_LOCATION_CHANGE,
	CUSTOMER_COUNTER_LOCATION_MEASURE_LOCATION_CHANGE,
	CUSTOMER_COUNTER_LOCATION_POSTAL_CODE_CHANGE,
	CUSTOMER_COUNTER_LOCATION_STREET_CHANGE,

	//	CUSTOMER CONTRACT COUNTER BANK
	CUSTOMER_COUNTER_ACCOUNT_NO_CHANGE,
	CUSTOMER_COUNTER_ACCOUNT_OWNER_CHANGE,
	CUSTOMER_COUNTER_BANK_CODE_CHANGE,
	CUSTOMER_COUNTER_IBAN_CHANGE,

	//	CUSTOMER CONTRACT COUNTER END
	CUSTOMER_COUNTER_CONTRACT_END,
	CUSTOMER_COUNTER_CONTRACT_TERMINATION_REASONS_ID_CHANGE,
	CUSTOMER_COUNTER_NETWORKS_ID_CHANGE,
	CUSTOMER_COUNTER_VALUE_CHANGE,
	CUSTOMER_COUNTER_VALUE_NT_CHANGE,

	//	CUSTOMER CONTRACT BILLING
	CUSTOMER_CONTRACT_BILLING_ADDITIONAL_CHANGE,
	CUSTOMER_CONTRACT_BILLING_CITY_CHANGE,
	CUSTOMER_CONTRACT_BILLING_COUNTRY_CHANGE,
	CUSTOMER_CONTRACT_BILLING_DISTRICT_CHANGE,
	CUSTOMER_CONTRACT_BILLING_HOUSE_NUMBER_CHANGE,
	CUSTOMER_CONTRACT_BILLING_NAME1_CHANGE,
	CUSTOMER_CONTRACT_BILLING_NAME2_CHANGE,
	CUSTOMER_CONTRACT_BILLING_NAME3_CHANGE,
	CUSTOMER_CONTRACT_BILLING_POSTAL_CODE_CHANGE,
	CUSTOMER_CONTRACT_BILLING_POSTBOX_CITY_CHANGE,
	CUSTOMER_CONTRACT_BILLING_POSTBOX_NO_CHANGE,
	CUSTOMER_CONTRACT_BILLING_POSTBOX_POSTAL_CODE_CHANGE,
	CUSTOMER_CONTRACT_BILLING_STREET_CHANGE,
	CUSTOMER_CONTRACT_SALUTATIONS_ID_CHANGE,
	CUSTOMER_CONTRACT_CUSTOMER_TYPES_ID_CHANGE,

	//	CUSTOMER CONTACT HISTORY
	CHANGE_CONTACT_HISTORY_DETAIL_OBJECT_ID,
	CHANGE_CONTACT_HISTORY_DETAIL_REMARKS,
	CHANGE_CONTACT_HISTORY_DETAIL_TOPIC,
	CLOSE_CONTACT_HISTORY_DIALOG,
	CLOSE_DELETE_CUSTOMER_CONTACT_HISTORY_CONFIRM,
	CONTACT_HISTORY_DETAIL_DOCUMENT_CHANGE,
	CONTACT_HISTORY_DETAIL_DOCUMENT_DELETE,
	DELETE_LEAD_CONTACT_HISTORY_CONFIRM,
	EDIT_CONTACT_HISTORY,
	GET_CONTACT_HISTORIES,
	SET_CONTACT_HISTORY_ERROR,

	//	CUSTOMER DELETE
	CLOSE_DELETE_CUSTOMER_CONFIRM,

	//	COUNTER DOUBLINGS
	CUSTOMER_COUNTER_DOUBLINGS_LIST,
	CUSTOMER_DOUBLE_COUNTER_DIALOG_CLOSE,

	//	remember billing address
	CUSTOMER_CONTRACT_BILLING_ADDRESS_SAVED,
	CLOSE_CUSTOMER_CONTRACT_BILLING_ADDRESS_SAVED_DIALOG,
	CLOSE_CUSTOMER_CONTRACT_BILLING_ADDRESS_PASTE_DIALOG,
	CUSTOMER_CONTRACT_PASTE_BILLING_ADDRESS,
	CUSTOMER_CONTRACT_DELETE_BILLING_ADDRESS,
	CUSTOMER_CONTRACT_TAKEOVER_BILLING_ADDRESS,

	//	CUSTOMER COPY DIALOG
	CUSTOMER_COPY_DIALOG,

	//	CUSTOMER ACTIVE CAMPAIGN CHANGE DIALOG
	CUSTOMER_ACTIVE_CAMPAIGN_CHANGE_DIALOG,
} from "../types";

export default (state, action) => {
	switch (action.type) {

		case IS_READING_TYPE_CHANGE_COMMENT:
			return {
				...state,
				isReadingTypeChangeComment: action.payload,
			}
		//  CUSTOMERS
		case CLOSE_CUSTOMER_DETAIL_DIALOG:
			return {
				...state,
				customerDetailDialogState: false,
				customerDetailDialogTitle: "",
				activeCustomerId: -1,
				customerDetailDialogActiveTab: 1,
				isReadingTypeChangeComment: false,
				isCustomerEnergyTransitionInitialized: 2,
				isCustomerEnergyInitialTransition: 0,
				isCustomerGasTransitionInitialized: 2,
				isCustomerGasInitialTransition: 0,
				isCustomerEnergyTransitionOpen: 0,
				isCustomerGasTransitionOpen: 0,
				isCustomerEnergyTransitionTenPercentOpen: 0,
				isCustomerGasTransitionTenPercentOpen: 0,
				bIsCustomerEnergyTransitionChange: false,
				bIsCustomerEnergyTransitionTenPercentChange: false,
				bIsCustomerGasTransitionChange: false,
				bIsCustomerGasTransitionTenPercentChange: false,
			};

		case DELETE_CUSTOMER_CONFIRM:
			return {
				...state,
				deleteCustomerConfirmState: true,
				deleteCustomerConfirmName: action.payload.entityName,
				activeCustomerId: action.payload.id,
			};

		case EDIT_CUSTOMER:
			// console.log(action.payload);
			return {
				...state,
				customerDetailDialogState: true,
				customerDetailDialogTitle: action.payload.dialogTitle,
				activeCustomerId: action.payload.customer.id,
				gpNumber: action.payload.customer.gpNumber,
				businessPartnerTypesId: action.payload.customer.businessPartnerTypesId,
				followUpDate: action.payload.customer.followUpDate,
				followUpDone: action.payload.customer.followUpDone,
				abcId: action.payload.customer.abcId,
				isPortalAccessAdministration:
					action.payload.customer.isPortalAccessAdministration,
				supervisorsId: action.payload.customer.supervisorsId,
				supervisorsPvId: action.payload.customer.supervisorsPvId,
				readingTypesId: action.payload.customer.readingTypesId,
				salutationsId: action.payload.customer.salutationsId,
				name1: action.payload.customer.name1,
				name2: action.payload.customer.name2,
				name3: action.payload.customer.name3,
				legalFormsId: action.payload.customer.legalFormsId,
				street: action.payload.customer.street,
				houseNumber: action.payload.customer.houseNumber,
				addressAddition: action.payload.customer.addressAddition,
				postalCode: action.payload.customer.postalCode,
				city: action.payload.customer.city,
				district: action.payload.customer.district,
				country: action.payload.customer.country,
				phone: action.payload.customer.phone,
				fax: action.payload.customer.fax,
				email: action.payload.customer.email,
				url: action.payload.customer.url,
				postbox: action.payload.customer.postbox,
				postboxPostalCode: action.payload.customer.postboxPostalCode,
				postboxCity: action.payload.customer.postboxCity,
				ownerId: action.payload.customer.ownerId,
				administrations1Id: action.payload.customer.administrations1Id,
				administrations2Id: action.payload.customer.administrations2Id,
				administrations3Id: action.payload.customer.administrations3Id,
				communityCode: action.payload.customer.communityCode,
				bankCode: action.payload.customer.bankCode,
				accountNo: action.payload.customer.accountNo,
				accountOwner: action.payload.customer.accountOwner,
				iban: action.payload.customer.iban,
				activeEnergyCampaignsId:
					action.payload.customer.activeEnergyCampaignsId,
				activeGasCampaignsId: action.payload.customer.activeGasCampaignsId,
				isTransition: action.payload.isTransition,
				isCustomerEnergyTransitionInitialized: action.payload.customer.isCustomerEnergyTransitionInitialized,
				isCustomerEnergyTransition: action.payload.customer.isCustomerEnergyTransition,
				customerEnergyTransitionDate: action.payload.customer.customerEnergyTransitionDate,
				isCustomerEnergyTransitionTenPercent: action.payload.customer.isCustomerEnergyTransitionTenPercent,
				customerEnergyTransitionTenPercentDate: action.payload.customer.customerEnergyTransitionTenPercentDate,
				isCustomerGasTransitionInitialized: action.payload.customer.isCustomerGasTransitionInitialized,
				isCustomerGasTransition: action.payload.customer.isCustomerGasTransition,
				customerGasTransitionDate: action.payload.customer.customerGasTransitionDate,
				isCustomerGasTransitionTenPercent: action.payload.customer.isCustomerGasTransitionTenPercent,
				customerGasTransitionTenPercentDate: action.payload.customer.customerGasTransitionTenPercentDate,
				offeredEnergyCampaignsId:
					action.payload.customer.offeredEnergyCampaignsId,
				offeredGasCampaignsId: action.payload.customer.offeredGasCampaignsId,

				contactPersons: action.payload.contactPersons,
				contract: action.payload.customerContracts,
				customerCopies: action.payload.customerCopies,
				customerObjects: action.payload.customerObjects,
				administrations1: action.payload.administrations1,
				administrations2: action.payload.administrations2,
				administrations3: action.payload.administrations3,
				energyCampaigns: action.payload.energyCampaigns,
				gasCampaigns: action.payload.gasCampaigns,
			};

		case GET_CUSTOMERS:
			return {
				...state,
				customers: action.payload,
			};

		case SET_ERR_MSG:
			return {
				...state,
				customerDetailDialogError: action.payload.customerDetailDialogError,
				customerDetailDialogActiveTab: action.payload.leadDetailDialogActiveTab,
				activeAddressTab: action.payload.activeAddressTab,
			};

		case SET_FOREIGNS:
			return {
				...state,
				businessPartnerTypes: action.payload.businessPartnerTypes,
				abc: action.payload.abc,
				supervisors: action.payload.supervisors,
				supervisorsPv: action.payload.supervisorsPv,
				salutations: action.payload.salutations,
				yesNos: action.payload.yesNos,
				administrations1: action.payload.administrations1,
				administrations2: action.payload.administrations2,
				administrations3: action.payload.administrations3,
				energyCampaigns: action.payload.energyCampaigns,
				gasCampaigns: action.payload.gasCampaigns,
				divisions: action.payload.divisions,
				changeReasons: action.payload.changeReasons,
				suppliers: action.payload.suppliers,
				networks: action.payload.networks,
				loadProfiles: action.payload.loadProfiles,
				legalForms: action.payload.legalForms,
				readingTypes: action.payload.readingTypes,
				customerTypes: action.payload.customerTypes,
				terminationReasons: action.payload.terminationReasons,
			};

		//	CUSTOMER ADDRESS
		case ABC_ID_CHANGE:
			return {
				...state,
				abcId: action.payload,
				customerDetailDialogError: "",
			};

		case BUSINESS_PARTNER_TYPES_ID_CHANGE:
			return {
				...state,
				businessPartnerTypesId: action.payload,
				customerDetailDialogError: "",
			};

		case FOLLOW_UP_DATE_CHANGE:
			return {
				...state,
				followUpDate: action.payload,
			};

		case FOLLOW_UP_DONE_CHANGE:
			return {
				...state,
				followUpDone: action.payload,
			};

		case IS_PORTAL_ACCESS_ADMINISTRATION_CHANGE:
			return {
				...state,
				isPortalAccessAdministration: action.payload,
			};

		case SET_ACTIVE_ADDRESS_TAB:
			return {
				...state,
				activeAddressTab: action.payload,
			};

		case SUPERVISORS_ID_CHANGE:
			return {
				...state,
				supervisorsId: action.payload,
				customerDetailDialogError: "",
			};

		case SUPERVISORS_PV_ID_CHANGE:
			return {
				...state,
				supervisorsPvId: action.payload,
			};

		case READING_TYPES_ID_CHANGE:
			return {
				...state,
				readingTypesId: action.payload,
			}

		//	CUSTOMER OBJECT LIST DIALOG
		case CUSTOMER_OBJECT_LIST_DIALOG:
			return {
				...state,
				customerObjectListDialogState: action.payload,
				customerObjectListIsFinished: false,
			};

		case CUSTOMER_OBJECT_LIST_IS_FINISHED:
			return {
				...state,
				customerObjectListIsFinished: action.payload.customerObjectListIsFinished,
				customerObjectListIsEmpty: action.payload.customerObjectListIsEmpty,
				customerObjectListDivision: action.payload.customerObjectListDivision,
			};

		//	CUSTOMER ADDRESS ADDRESS
		case ADDRESS_ADDITION_CHANGE:
			return {
				...state,
				addressAdditional: action.payload,
			};

		case CITY_CHANGE:
			return {
				...state,
				city: action.payload,
			};

		case COUNTRY_CHANGE:
			return {
				...state,
				country: action.payload,
				customerDetailDialogError: "",
			};

		case DISTRICT_CHANGE:
			return {
				...state,
				district: action.payload,
			};

		case HOUSE_NUMBER_CHANGE:
			return {
				...state,
				houseNumber: action.payload,
			};

		case NAME_1_CHANGE:
			return {
				...state,
				name1: action.payload,
				customerDetailDialogError: "",
			};

		case NAME_2_CHANGE:
			return {
				...state,
				name2: action.payload,
			};

		case NAME_3_CHANGE:
			return {
				...state,
				name3: action.payload,
			};

		case LEGAL_FORMS_ID_CHANGE:
			return {
				...state,
				legalFormsId: action.payload
			}

		case POSTAL_CODE_CHANGE:
			return {
				...state,
				postalCode: action.payload,
			};

		case SALUTATIONS_ID_CHANGE:
			return {
				...state,
				salutationsId: action.payload,
				customerDetailDialogError: "",
			};

		case STREET_CHANGE:
			return {
				...state,
				street: action.payload,
			};

		//	CUSTOMER ADDRESS CONTACT
		case EMAIL_CHANGE:
			return {
				...state,
				email: action.payload,
			};

		case FAX_CHANGE:
			return {
				...state,
				fax: action.payload,
			};

		case PHONE_CHANGE:
			return {
				...state,
				phone: action.payload,
			};

		case URL_CHANGE:
			return {
				...state,
				url: action.payload,
			};

		//	CUSTOMER ADDRESS POSTBOX
		case POSTBOX_CHANGE:
			return {
				...state,
				postbox: action.payload,
			};

		case POSTBOX_CITY_CHANGE:
			return {
				...state,
				postboxCity: action.payload,
			};

		case POSTBOX_POSTAL_CODE_CHANGE:
			return {
				...state,
				postboxPostalCode: action.payload,
			};

		//	CUSTOMER ADDRESS ADMINISTRATION
		case ADMINISTRATIONS_1_ID_CHANGE:
			return {
				...state,
				administrations1Id: action.payload,
				customerDetailDialogError: "",
			};

		case ADMINISTRATIONS_2_ID_CHANGE:
			return {
				...state,
				administrations2Id: action.payload,
			};

		case ADMINISTRATIONS_3_ID_CHANGE:
			return {
				...state,
				administrations3Id: action.payload,
			};

		case COMMUNITY_CODE_CHANGE:
			return {
				...state,
				communityCode: action.payload,
			};

		case GET_ADMINISTRATIONS_1:
			return {
				...state,
				administrations1: action.payload.administrations1,
				administrations2: action.payload.administrations2,
				administrations3: action.payload.administrations3,
				administrations1Id: -1,
				administrations2Id: -1,
				administrations3Id: -1,
			};

		case GET_ADMINISTRATIONS_2:
			return {
				...state,
				administrations2: action.payload.administrations2,
				administrations3: action.payload.administrations3,
				administrations2Id: -1,
				administrations3Id: -1,
			};

		case GET_ADMINISTRATIONS_3:
			return {
				...state,
				administrations3: action.payload.administrations3,
				administrations3Id: -1,
			};

		case OWNER_ID_CHANGE:
			return {
				...state,
				ownerId: action.payload,
				customerDetailDialogError: "",
			};

		//	CUSTOMER CONTACT PERSONS
		case CLOSE_CONTACT_PERSON_DIALOG:
			return {
				...state,
				contactPersonDetailsDialogState: false,
				activeContactPerson: 0,
				contactPersonDetailsDialogError: "",
				contactPersonDivision: "",
				contactPersonEmail: "",
				contactPersonFax: "",
				contactPersonFirstname: "",
				contactPersonFunction: "",
				contactPersonIsInfoReading: false,
				contactPersonIsPortalAccess: false,
				contactPersonLastname: "",
				contactPersonMobile: "",
				contactPersonPhone: "",
				contactPersonPortalAccessValidTo: "",
				contactPersonSalutationsId: -1,
				contactPersonTitle: "",
			};

		case CLOSE_DELETE:
			return {
				...state,
				deleteModalState: false,
			};

		case CONTACT_PERSON_DIVISION_CHANGE:
			return {
				...state,
				contactPersonDivision: action.payload,
			};

		case CONTACT_PERSON_EMAIL_CHANGE:
			return {
				...state,
				contactPersonEmail: action.payload,
			};

		case CONTACT_PERSON_FAX_CHANGE:
			return {
				...state,
				contactPersonFax: action.payload,
			};

		case CONTACT_PERSON_FIRSTNAME_CHANGE:
			return {
				...state,
				contactPersonFirstname: action.payload,
			};

		case CONTACT_PERSON_FUNCTION_CHANGE:
			return {
				...state,
				contactPersonFunction: action.payload,
			};

		case CONTACT_PERSON_IS_INFO_READING_CHANGE:
			return {
				...state,
				contactPersonIsInfoReading: action.payload,
			}

		case CONTACT_PERSON_IS_PORTAL_ACCESS_CHANGE:
			return {
				...state,
				contactPersonIsPortalAccess: action.payload,
			};

		case CONTACT_PERSON_LASTNAME_CHANGE:
			return {
				...state,
				contactPersonLastname: action.payload,
			};

		case CONTACT_PERSON_MOBILE_CHANGE:
			return {
				...state,
				contactPersonMobile: action.payload,
			};

		case CONTACT_PERSON_PHONE_CHANGE:
			return {
				...state,
				contactPersonPhone: action.payload,
			};

		case CONTACT_PERSON_PORTAL_ACCESS_VALID_TO_CHANGE:
			return {
				...state,
				contactPersonPortalAccessValidTo: action.payload,
			};

		case CONTACT_PERSON_SALUTATIONS_ID_CHANGE:
			return {
				...state,
				contactPersonSalutationsId: action.payload,
			};

		case CONTACT_PERSON_TITLE_CHANGE:
			return {
				...state,
				contactPersonTitle: action.payload,
			};

		case DELETE_CONTACT_PERSON:
			return {
				...state,
				deleteModalState: false,
				deleteModalTitle: "",
				deleteModalEntityName: "",
				activeContactPerson: 0,
			};

		case DELETE_CONTACT_PERSON_CONFIRM:
			return {
				...state,
				deleteModalState: true,
				deleteModalTitle: action.payload.title,
				deleteModalEntityName: action.payload.entityName,
				activeContactPerson: action.payload.id,
			};

		case EDIT_CONTACT_PERSON:
			return {
				...state,
				activeContactPerson: action.payload.activeContactPerson,
				contactPersonDetailsDialogState:
					action.payload.contactPersonDetailsDialogState,
				contactPersonDetailsDialogTitle:
					action.payload.contactPersonDetailsDialogTitle,
			};

		case GET_CONTACT_PERSONS:
			return {
				...state,
				contactPersons: action.payload,
			};

		case SET_ACTIVE_CONTACT_PERSON:
			return {
				...state,
				activeContactPerson: action.payload.id,
				contactPersonSalutationsId: action.payload.salutationsId,
				contactPersonTitle: action.payload.title,
				contactPersonFirstname: action.payload.firstname,
				contactPersonLastname: action.payload.lastname,
				contactPersonFunction: action.payload.function,
				contactPersonDivision: action.payload.division,
				contactPersonEmail: action.payload.email,
				contactPersonPhone: action.payload.phone,
				contactPersonFax: action.payload.fax,
				contactPersonMobile: action.payload.mobile,
				contactPersonIsInfoReading: action.payload.isInfoReading,
				contactPersonIsPortalAccess: action.payload.isPortalAccess,
				contactPersonPortalAccessValidTo: action.payload.portalAccessValidTo,
			};

		case SET_CONTACT_PERSON_ERROR:
			return {
				...state,
				contactPersonDetailsDialogError: action.payload,
			};

		//	CUSTOMER BANK
		case ACCOUNT_NO_CHANGE:
			return {
				...state,
				accountNo: action.payload,
			};

		case ACCOUNT_OWNER_CHANGE:
			return {
				...state,
				accountOwner: action.payload,
			};

		case BANK_CODE_CHANGE:
			return {
				...state,
				bankCode: action.payload,
			};

		case IBAN_CHANGE:
			return {
				...state,
				iban: action.payload,
			};

		//	CUSTOMER PRODUCTS
		case SET_ACTIVE_PRODUCT_TAB:
			return {
				...state,
				activeProductTab: action.payload,
			};

		//	CUSTOMER PRODUCT ACTIVE
		case ACTIVE_ENERGY_CAMPAIGNS_ID_CHANGE:
			return {
				...state,
				activeEnergyCampaignsId: action.payload.newEnergyCampaignId,
				customerActiveCampaignChangeLoading: action.payload.customerActiveCampaignChangeLoading,
				customerActiveCampaignChangeContracts: action.payload.customerActiveCampaignChangeContracts,
				customerActiveCampaignChangeFileName: action.payload.customerActiveCampaignChangeFileName,
				isCustomerEnergyTransitionInitialized: action.payload.isCustomerEnergyTransitionInitialized,
				isCustomerEnergyInitialTransition: action.payload.isCustomerEnergyInitialTransition,
				isCustomerEnergyTransition: action.payload.isCustomerEnergyTransition,
				isCustomerEnergyTransitionTenPercent: action.payload.isCustomerEnergyTransitionTenPercent,
			};

		case ACTIVE_GAS_CAMPAIGNS_ID_CHANGE:
			return {
				...state,
				activeGasCampaignsId: action.payload.newGasCampaignId,
				customerActiveCampaignChangeLoading: action.payload.customerActiveCampaignChangeLoading,
				customerActiveCampaignChangeContracts: action.payload.customerActiveCampaignChangeContracts,
				customerActiveCampaignChangeFileName: action.payload.customerActiveCampaignChangeFileName,
				isCustomerGasTransitionInitialized: action.payload.isCustomerGasTransitionInitialized,
				isCustomerGasInitialTransition: action.payload.isCustomerGasInitialTransition,
				isCustomerGasTransition: action.payload.isCustomerGasTransition,
				isCustomerGasTransitionTenPercent: action.payload.isCustomerGasTransitionTenPercent,
			};

		case IS_CUSTOMER_ENERGY_TRANSITION_CHANGE:
			return {
				...state,
				isCustomerEnergyTransition: action.payload,
				customerEnergyTransitionDate: '',
				bIsCustomerEnergyTransitionChange: true,
			}

		case CUSTOMER_ENERGY_TRANSITION_DATE_CHANGE:
			return {
				...state,
				customerEnergyTransitionDate: action.payload,
				bIsCustomerEnergyTransitionChange: true,
			}

		case IS_CUSTOMER_ENERGY_TRANSITION_TEN_PERCENT_CHANGE:
			return {
				...state,
				isCustomerEnergyTransitionTenPercent: action.payload,
				customerEnergyTransitionTenPercentDate: '',
				bIsCustomerEnergyTransitionTenPercentChange: true,
			}

		case CUSTOMER_ENERGY_TRANSITION_TEN_PERCENT_DATE_CHANGE:
			return {
				...state,
				customerEnergyTransitionTenPercentDate: action.payload,
				bIsCustomerEnergyTransitionTenPercentChange: true,
			}

		case IS_CUSTOMER_GAS_TRANSITION_CHANGE:
			return {
				...state,
				isCustomerGasTransition: action.payload,
				customerGasTransitionDate: '',
				bIsCustomerGasTransitionChange: true,
			}

		case CUSTOMER_GAS_TRANSITION_DATE_CHANGE:
			return {
				...state,
				customerGasTransitionDate: action.payload,
				bIsCustomerGasTransitionChange: true,
			}

		case IS_CUSTOMER_GAS_TRANSITION_TEN_PERCENT_CHANGE:
			return {
				...state,
				isCustomerGasTransitionTenPercent: action.payload,
				customerGasTransitionTenPercentDate: '',
				bIsCustomerGasTransitionTenPercentChange: true,
			}

		case CUSTOMER_GAS_TRANSITION_TEN_PERCENT_DATE_CHANGE:
			return {
				...state,
				customerGasTransitionTenPercentDate: action.payload,
				bIsCustomerGasTransitionTenPercentChange: true,
			}

		case GET_PRODUCT_HISTORIES:
			return {
				...state,
				productHistories: action.payload,
			};

		case OFFERED_ENERGY_CAMPAIGNS_ID_CHANGE:
			return {
				...state,
				offeredEnergyCampaignsId: action.payload,
			};

		case OFFERED_GAS_CAMPAIGNS_ID_CHANGE:
			return {
				...state,
				offeredGasCampaignsId: action.payload,
			};

		//	CUSTOMER CONTRACTS
		case CLOSE_CONTRACTS_DIALOG:
			return {
				...state,
				customerContractsDialogError: "",
				customerContractsDialogState: false,
				activeContractTab: 1,
				activeContractCounterTab: 1,
				customerContractDivisionsId: -1,
				customerContractProducts: [{ id: -1, value: "Keine Sparte gewählt" }],
				customerContractProductsId: -1,
				customerContractValidFrom: "Kein Produkt gewählt",
				customerContractValidTo: "Kein Produkt gewählt",
				isCustomerContractTransitionOpen: 0,
				isCustomerContractTransitionInitialized: 0,
				isCustomerContractInitialTransition: 0,
				isCustomerContractTransition: 0,
				customerContractTransitionDate: '',
				isCustomerContractTransitionTenPercentOpen: 0,
				isCustomerContractTransitionTenPercent: 0,
				customerContractTransitionTenPercentDate: '',
				customerProfileType: "",
				customerBillTurnStart: "",
				customerBillTurnEnd: "",
				pots: [{ id: 0, value: "Kein Produkt gewählt" }],
				customerContractPotsId: 0,
				customerContractIsClimatePlus: false,
				customerContractIsBio5: false,
				customerContractBio5StartDate: "",
				customerContractIsBio10: false,
				customerContractBio10StartDate: "",
				customerContractIsCo2: false,
				customerContractPeriodConsumption: "",
				customerContractGrossBillAmount: "",
				customerCounterNumberCurrent: "",
				customerCounterNumberDoubling: 0,
				customerCounterNumberFormer: "",
				customerCounterMediumVoltage: false,
				customerCounterBhkw: false,
				customerCounterContractAccount: "",
				customerCounterContractDescription: "",
				customerCounterIntendedUse: "*",
				customerCounterChangeReasonsId: -1,
				customerCounterChangeSupplyStart: "",
				customerCounterFormerSupplierId: -1,
				customerCounterNetworksId: -1,
				customerCounterInstallmentCycle: "",
				customerCounterLoadProfilesId: -1,
				customerCounterValue: "",
				customerCounterValueNt: "",
				isCustomerCounterLeaveDone: false,
				customerCounterLocationStreet: "",
				customerCounterLocationHouseNumber: "",
				customerCounterLocationPostalCode: "",
				customerCounterLocationCity: "",
				customerCounterLocationAdditional: "",
				customerCounterLocationCountry: "DE",
				customerCounterLocationDistrict: "",
				customerCounterLocationMarketLocation: "",
				customerCounterLocationMeasureLocation: "",
				customerCounterAccountNo: "",
				customerCounterAccountOwner: "",
				customerCounterBankCode: "",
				customerCounterIban: "",
				customerCounterContractEnd: "",
				customerCounterContractTerminationReasonsId: -1,
				customerContractSalutationsId: -1,
				customerContractCustomerTypesId: -1,
				customerContractBillingName1: "",
				customerContractBillingName2: "",
				customerContractBillingName3: "",
				customerContractBillingStreet: "",
				customerContractBillingHouseNumber: "",
				customerContractBillingPostalCode: "",
				customerContractBillingCity: "",
				customerContractBillingDistrict: "",
				customerContractBillingCountry: "",
				customerContractBillingAdditional: "",
				customerContractBillingPostboxNo: "",
				customerContractBillingPostboxPostalCode: "",
				customerContractBillingPostboxCity: "",
			};

		case CLOSE_DELETE_CONTRACT:
			return {
				...state,
				deleteContractState: false,
				deleteContractName: "",
				activeCustomerContract: 0,
				isContractEdit: false,
			};

		case DELETE_CONTRACT_CONFIRM:
			return {
				...state,
				deleteContractState: true,
				deleteContractName: action.payload.entityName,
				activeCustomerContract: action.payload.id,
			};

		case EDIT_CUSTOMER_CONTRACT:

			return {
				...state,
				activeCustomerContract: action.payload.id,
				activeCustomerContractTab: 1,
				contractDialogTitle: action.payload.title,
				customerContractsDialogState: true,
				isContractEdit: action.payload.isContractEdit,
				customerContractLastImportDate: action.payload.customerContractLastImportDate,
				customerContractDivisionsId: action.payload.divisionsId,
				customerContractProductsId: action.payload.customerContractProductsId,
				customerContractValidFrom: action.payload.customerContractValidFrom,
				customerContractValidTo: action.payload.customerContractValidTo,
				isCustomerContractTransitionInitialized: action.payload.isCustomerContractTransitionInitialized,
				isCustomerContractInitialTransition: action.payload.isCustomerContractInitialTransition,
				isCustomerContractTransition: action.payload.isCustomerContractTransition,
				customerContractTransitionDate: action.payload.customerContractTransitionDate,
				isCustomerContractTransitionTenPercent: action.payload.isCustomerContractTransitionTenPercent,
				customerContractTransitionTenPercentDate: action.payload.customerContractTransitionTenPercentDate,
				customerContractProfileType: action.payload.customerContractProfileType,
				customerContractBillTurnStart:
					action.payload.customerContractBillTurnStart,
				customerContractBillTurnEnd: action.payload.customerContractBillTurnEnd,
				customerContractPotsId: action.payload.customerContractPotsId,
				customerContractIsClimatePlus:
					action.payload.customerContractIsClimatePlus,
				customerContractIsBio5: action.payload.customerContractIsBio5,
				customerContractBio5StartDate:
					action.payload.customerContractBio5StartDate,
				customerContractIsBio10: action.payload.customerContractIsBio10,
				customerContractBio10StartDate:
					action.payload.customerContractBio10StartDate,
				customerContractIsCo2: action.payload.customerContractIsCo2,
				customerContractPeriodConsumption:
					action.payload.customerContractPeriodConsumption,
				customerContractGrossBillAmount:
					action.payload.customerContractGrossBillAmount,
				customerCounterId: action.payload.customerCounterId,
				customerCounterNumberCurrent:
					action.payload.customerCounterNumberCurrent,
				customerCounterNumberDoubling:
					action.payload.customerCounterNumberDoubling,
				customerCounterLoadProfileIms: action.payload.customerCounterLoadProfileIms,
				customerCounterNumberFormer: action.payload.customerCounterNumberFormer,
				customerCounterBhkw: action.payload.customerCounterBhkw,
				customerCounterMediumVoltage:
					action.payload.customerCounterMediumVoltage,
				customerCounterContractAccount:
					action.payload.customerCounterContractAccount,
				customerCounterContractAccountDescription:
					action.payload.customerCounterContractDescription,
				customerCounterIntendedUse: action.payload.customerCounterIntendedUse,

				customerCounterChangeReasonsId:
					action.payload.customerCounterChangeReasonsId,
				customerCounterChangeSupplyStart:
					action.payload.customerCounterChangeSupplyStart,
				customerCounterFormerSupplierId:
					action.payload.customerCounterFormerSuppliersId,
				customerCounterInstallmentCycle:
					action.payload.customerCounterInstallmentCycle,
				customerCounterNetworksId: action.payload.customerCounterNetworksId,
				customerCounterLoadProfilesId:
					action.payload.customerCounterLoadProfilesId,
				customerCounterIsNetworkConfirmed:
					action.payload.customerCounterIsNetworkConfirmed,
				customerCounterIsNetworkRequest:
					action.payload.customerCounterIsNetworkRequest,
				customerCounterValue: action.payload.customerCounterValue,
				customerCounterValueNt: action.payload.customerCounterValueNt,
				isCustomerCounterLeaveDone: action.payload.isCustomerCounterLeaveDone,
				customerCounterLocationStreet:
					action.payload.customerCounterLocationStreet,
				customerCounterLocationHouseNumber:
					action.payload.customerCounterLocationHouseNumber,
				customerCounterLocationPostalCode:
					action.payload.customerCounterLocationPostalCode,
				customerCounterLocationCity: action.payload.customerCounterLocationCity,
				customerCounterLocationDistrict:
					action.payload.customerCounterLocationDistrict,
				customerCounterLocationCountry:
					action.payload.customerCounterLocationCountry,
				customerCounterLocationAdditional:
					action.payload.customerCounterLocationAdditional,
				customerCounterLocationMarketLocation:
					action.payload.customerCounterLocationMarketLocation,
				customerCounterLocationMeasureLocation:
					action.payload.customerCounterLocationMeasureLocation,
				customerCounterAccountNo: action.payload.customerCounterAccountNo,
				customerCounterAccountOwner: action.payload.customerCounterAccountOwner,
				customerCounterBankCode: action.payload.customerCounterBankCode,
				customerCounterIban: action.payload.customerCounterIban,
				customerCounterContractEnd: action.payload.customerCounterContractEnd,
				customerCounterContractTerminationReasonsId:
					action.payload.customerCounterContractTerminationReasonsId,
				customerContractSalutationsId:
					action.payload.customerContractSalutationsId,
				customerContractCustomerTypesId:
					action.payload.customerContractCustomerTypesId,
				customerContractBillingMail1: action.payload.customerContractBillingMail1,
				customerContractBillingMail2: action.payload.customerContractBillingMail2,
				customerContractBillingMail3: action.payload.customerContractBillingMail3,
				customerContractBillingName1:
					action.payload.customerContractBillingName1,
				customerContractBillingName2:
					action.payload.customerContractBillingName2,
				customerContractBillingName3:
					action.payload.customerContractBillingName3,
				customerContractBillingStreet:
					action.payload.customerContractBillingStreet,
				customerContractBillingHouseNumber:
					action.payload.customerContractBillingHouseNumber,
				customerContractBillingPostalCode:
					action.payload.customerContractBillingPostalCode,
				customerContractBillingCity: action.payload.customerContractBillingCity,
				customerContractBillingDistrict:
					action.payload.customerContractBillingDistrict,
				customerContractBillingCountry:
					action.payload.customerContractBillingCountry,
				customerContractBillingAdditional:
					action.payload.customerContractBillingAdditional,
				customerContractBillingPostboxNo:
					action.payload.customerContractBillingPostboxNo,
				customerContractBillingPostboxPostalCode:
					action.payload.customerContractBillingPostboxPostalCode,
				customerContractBillingPostboxCity:
					action.payload.customerContractBillingPostboxCity,

				isPots: action.payload.isPots,
				pots: action.payload.pots,
				potsId: action.payload.potsId,
				isProduct: action.payload.isProduct,
				customerContractProducts: action.payload.products,
				productsId: action.payload.productsId,
				isValidFrom: action.payload.isValidFrom,
				validFrom: action.payload.validFrom,
				isValidTo: action.payload.isValidTo,
				validTo: action.payload.validTo,
				isBio5: action.payload.isBio5,
				isBio10: action.payload.isBio10,
				isCo2: action.payload.isCo2,
				isClimatePlus: action.payload.isClimatePlus,
				loadProfiles: action.payload.loadProfiles,
				loadProfilesId: action.payload.loadProfilesId,
				isOptionen: action.payload.isOptionen,
				divisionsId: action.payload.divisionsId,
			};

		case GET_CONTRACTS:
			return {
				...state,
				contracts: action.payload,
			};

		case SET_ACTIVE_CONTRACT_TAB:
			return {
				...state,
				activeContractTab: action.payload,
			};

		case SET_CONTRACT_ERR_MSG:
			return {
				...state,
				customerContractsDialogError: action.payload.customerContractsDialogError,
				activeContractTab: action.payload.activeContractTab
			}

		case SET_CUSTOMER_DETAIL_DIALOG_ACTIVE_TAB:
			return {
				...state,
				customerDetailDialogActiveTab: action.payload,
			};

		case UPDATE_CONTRACT_EXTERNALS:
			return {
				...state,
				suppliers: action.payload.suppliers,
				networks: action.payload.networks,
			};

		//	CUSTOMER CONTRACTS CONTRACT
		case CUSTOMER_CONTRACT_DIVISIONS_ID_CHANGE:
			return {
				...state,
				customerContractDivisionsId: action.payload.divisionsId,
				isPots: action.payload.isPots,
				pots: action.payload.pots,
				customerContractPotsId: action.payload.potsId,
				isProduct: action.payload.isProduct,
				customerContractProducts: action.payload.products,
				customerContractProductsId: action.payload.productsId,
				isValidFrom: action.payload.isValidFrom,
				customerContractValidFrom: action.payload.validFrom,
				isValidTo: action.payload.isValidTo,
				customerContractValidTo: action.payload.validTo,
				customerContractIsBio5: action.payload.isBio5,
				customerContractBio5StartDate: action.payload.bio5StartDate,
				customerContractIsBio10: action.payload.isBio10,
				customerContractBio10StartDate: action.payload.bio10StartDate,
				customerContractIsCo2: action.payload.isCo2,
				customerContractIsClimatePlus: action.payload.isClimatePlus,
				loadProfiles: action.payload.loadProfiles,
				customerCounterLoadProfilesId: action.payload.loadProfilesId,
				isOptionen: action.payload.isOptionen,
				divisionsId: action.payload.divisionsId,
				suppliers: action.payload.suppliers,
				networks: action.payload.networks,
			};

		case CUSTOMER_CONTRACT_GROSS_BILL_AMOUNT_CHANGE:
			return {
				...state,
				customerContractGrossBillAmount: action.payload,
			};

		case CUSTOMER_CONTRACT_IS_BIO10_CHANGE:
			return {
				...state,
				customerContractIsBio10: action.payload,
			};

		case CUSTOMER_CONTRACT_BIO10_START_DATE_CHANGE:
			return {
				...state,
				customerContractBio10StartDate: action.payload,
			};

		case CUSTOMER_CONTRACT_IS_BIO5_CHANGE:
			return {
				...state,
				customerContractIsBio5: action.payload,
			};

		case CUSTOMER_CONTRACT_BIO5_START_DATE_CHANGE:
			return {
				...state,
				customerContractBio5StartDate: action.payload,
			};

		case CUSTOMER_CONTRACT_IS_CLIMATE_PLUS_CHANGE:
			return {
				...state,
				customerContractIsClimatePlus: action.payload,
			};

		case CUSTOMER_CONTRACT_PERIOD_CONSUMPTION_CHANGE:
			return {
				...state,
				customerContractPeriodConsumption: action.payload,
			};

		case CUSTOMER_CONTRACT_POTS_ID_CHANGE:
			return {
				...state,
				customerContractPotsId: action.payload.potsId,
				isCustomerContractTransitionInitialized: action.payload.isCustomerContractTransitionInitialized,
				isCustomerContractInitialTransition: action.payload.isCustomerContractInitialTransition,
				isCustomerContractTransition: action.payload.isCustomerContractTransition,
				isCustomerContractTransitionOpen: action.payload.idCustomerContractTransitionOpen,
				customerContractTransitionDate: action.payload.customerContractTransitionDate,
				isCustomerContractTransitionTenPercent: action.payload.isCustomerContractTransitionTenPercent,
				isCustomerContractTransitionTenPercentOpen: action.payload.isCustomerContractTransitionTenPercentOpen,
				customerContractTransitionTenPercentDate: action.payload.customerContractTransitionTenPercentDate,
			};

		case CUSTOMER_CONTRACT_PRODUCTS_ID_CHANGE:
			return {
				...state,
				customerContractProductsId: action.payload,
			};

		case CUSTOMER_CONTRACT_PROFILE_TYPE_CHANGE:
			return {
				...state,
				customerContractProfileType: action.payload,
			};

		case CUSTOMER_CONTRACT_VALID_FROM_CHANGE:
			return {
				...state,
				customerContractValidFrom: action.payload,
			};

		case CUSTOMER_CONTRACT_VALID_TO_CHANGE:
			return {
				...state,
				customerContractValidTo: action.payload,
			};

		case IS_CUSTOMER_CONTRACT_TRANSITION_CHANGE:
			return {
				...state,
				isCustomerContractTransition: action.payload,
				customerContractTransitionDate: '',
			}

		case CUSTOMER_CONTRACT_TRANSITION_DATE_CHANGE:
			return {
				...state,
				customerContractTransitionDate: action.payload,
			}

		case IS_CUSTOMER_CONTRACT_TRANSITION_TEN_PERCENT_CHANGE: 
			return {
				...state,
				isCustomerContractTransitionTenPercent: action.payload,
				customerContractTransitionTenPercentDate: '',
			}

		case CUSTOMER_CONTRACT_TRANSITION_TEN_PERCENT_DATE_CHANGE:
			return {
				...state,
				customerContractTransitionTenPercentDate: action.payload,
			}

		//	CUSTOMER CONTRACT COUNTER
		case CUSTOMER_COUNTER_BHKW_CHANGE:
			return {
				...state,
				customerCounterBhkw: action.payload,
			};

		case CUSTOMER_COUNTER_CONTRACT_ACCOUNT_CHANGE:
			return {
				...state,
				customerCounterContractAccount: action.payload,
			};

		case CUSTOMER_COUNTER_CONTRACT_ACCOUNT_DESCRIPTION_CHANGE:
			return {
				...state,
				customerCounterContractAccountDescription: action.payload,
			};

		case CUSTOMER_COUNTER_INTENDED_USE_CHANGE:
			return {
				...state,
				customerCounterIntendedUse: action.payload,
			};

		case CUSTOMER_COUNTER_MEDIUM_VOLTAGE_CHANGE:
			return {
				...state,
				customerCounterMediumVoltage: action.payload,
			};

		case CUSTOMER_COUNTER_NUMBER_CURRENT_CHANGE:
			return {
				...state,
				customerCounterNumberCurrent: action.payload,
			};

		case CUSTOMER_COUNTER_NUMBER_FORMER_CHANGE:
			return {
				...state,
				customerCounterNumberFormer: action.payload,
			};

		case IS_CUSTOMER_COUNTER_LEAVE_DONE_CHANGE:
			return {
				...state,
				isCustomerCounterLeaveDone: action.payload,
			};

		case SET_ACTIVE_CONTRACT_COUNTER_TAB:
			return {
				...state,
				activeContractCounterTab: action.payload,
			};

		//	CUSTOMER CONTRACT COUNTER CHANGE
		case CUSTOMER_COUNTER_CHANGE_REASONS_ID_CHANGE:
			return {
				...state,
				customerCounterChangeReasonsId: action.payload,
			};

		case CUSTOMER_COUNTER_CHANGE_SUPPLY_START_CHANGE:
			return {
				...state,
				customerCounterChangeSupplyStart: action.payload,
			};

		case CUSTOMER_COUNTER_FORMER_SUPPLIER_ID_CHANGE:
			return {
				...state,
				customerCounterFormerSupplierId: action.payload,
			};

		case CUSTOMER_COUNTER_INSTALLMENT_CYCLE_CHANGE:
			return {
				...state,
				customerCounterInstallmentCycle: action.payload,
			};

		case CUSTOMER_COUNTER_LOAD_PROFILES_ID_CHANGE:
			return {
				...state,
				customerCounterLoadProfilesId: action.payload,
			};

		case CUSTOMER_COUNTER_LOCATION_ADDITIONAL_CHANGE:
			return {
				...state,
				customerCounterLocationAdditional: action.payload,
			};

		case CUSTOMER_COUNTER_LOCATION_CITY_CHANGE:
			return {
				...state,
				customerCounterLocationCity: action.payload,
			};

		case CUSTOMER_COUNTER_LOCATION_COUNTRY_CHANGE:
			return {
				...state,
				customerCounterLocationCountry: action.payload,
			};

		case CUSTOMER_COUNTER_LOCATION_DISTRICT_CHANGE:
			return {
				...state,
				customerCounterLocationDistrict: action.payload,
			};

		case CUSTOMER_COUNTER_LOCATION_HOUSE_NUMBER_CHANGE:
			return {
				...state,
				customerCounterLocationHouseNumber: action.payload,
			};

		case CUSTOMER_COUNTER_LOCATION_MARKET_LOCATION_CHANGE:
			return {
				...state,
				customerCounterLocationMarketLocation: action.payload,
			};

		case CUSTOMER_COUNTER_LOCATION_MEASURE_LOCATION_CHANGE:
			return {
				...state,
				customerCounterLocationMeasureLocation: action.payload,
			};

		case CUSTOMER_COUNTER_LOCATION_POSTAL_CODE_CHANGE:
			return {
				...state,
				customerCounterLocationPostalCode: action.payload,
			};

		case CUSTOMER_COUNTER_LOCATION_STREET_CHANGE:
			return {
				...state,
				customerCounterLocationStreet: action.payload,
			};

		case CUSTOMER_COUNTER_NETWORKS_ID_CHANGE:
			return {
				...state,
				customerCounterNetworksId: action.payload,
			};

		//	CUSTOMER CONTRACT COUNTER BANK
		case CUSTOMER_COUNTER_ACCOUNT_NO_CHANGE:
			return {
				...state,
				customerCounterAccountNo: action.payload,
			};

		case CUSTOMER_COUNTER_ACCOUNT_OWNER_CHANGE:
			return {
				...state,
				customerCounterAccountOwner: action.payload,
			};

		case CUSTOMER_COUNTER_BANK_CODE_CHANGE:
			return {
				...state,
				customerCounterBankCode: action.payload,
			};

		case CUSTOMER_COUNTER_IBAN_CHANGE:
			return {
				...state,
				customerCounterIban: action.payload,
			};

		//	CUSTOMER CONTRACT COUNTER END
		case CUSTOMER_COUNTER_CONTRACT_END:
			return {
				...state,
				customerCounterContractEnd: action.payload,
			};

		case CUSTOMER_COUNTER_CONTRACT_TERMINATION_REASONS_ID_CHANGE:
			return {
				...state,
				customerCounterContractTerminationReasonsId: action.payload,
			};

		case CUSTOMER_COUNTER_VALUE_CHANGE:
			return {
				...state,
				customerCounterValue: action.payload,
			};

		case CUSTOMER_COUNTER_VALUE_NT_CHANGE:
			return {
				...state,
				customerCounterValueNt: action.payload,
			};

		//	CUSTOMER CONTRACT BILLING
		case CUSTOMER_CONTRACT_BILLING_ADDITIONAL_CHANGE:
			return {
				...state,
				customerContractBillingAdditional: action.payload,
			};

		case CUSTOMER_CONTRACT_BILLING_CITY_CHANGE:
			return {
				...state,
				customerContractBillingCity: action.payload,
			};

		case CUSTOMER_CONTRACT_BILLING_COUNTRY_CHANGE:
			return {
				...state,
				customerContractBillingCountry: action.payload,
			};

		case CUSTOMER_CONTRACT_BILLING_DISTRICT_CHANGE:
			return {
				...state,
				customerContractBillingDistrict: action.payload,
			};

		case CUSTOMER_CONTRACT_BILLING_HOUSE_NUMBER_CHANGE:
			return {
				...state,
				customerContractBillingHouseNumber: action.payload,
			};

		case CUSTOMER_CONTRACT_BILLING_NAME1_CHANGE:
			return {
				...state,
				customerContractBillingName1: action.payload,
			};

		case CUSTOMER_CONTRACT_BILLING_NAME2_CHANGE:
			return {
				...state,
				customerContractBillingName2: action.payload,
			};

		case CUSTOMER_CONTRACT_BILLING_NAME3_CHANGE:
			return {
				...state,
				customerContractBillingName3: action.payload,
			};

		case CUSTOMER_CONTRACT_BILLING_POSTAL_CODE_CHANGE:
			return {
				...state,
				customerContractBillingPostalCode: action.payload,
			};

		case CUSTOMER_CONTRACT_BILLING_POSTBOX_CITY_CHANGE:
			return {
				...state,
				customerContractBillingPostboxCity: action.payload,
			};

		case CUSTOMER_CONTRACT_BILLING_POSTBOX_NO_CHANGE:
			return {
				...state,
				customerContractBillingPostboxNo: action.payload,
			};

		case CUSTOMER_CONTRACT_BILLING_POSTBOX_POSTAL_CODE_CHANGE:
			return {
				...state,
				customerContractBillingPostboxPostalCode: action.payload,
			};

		case CUSTOMER_CONTRACT_BILLING_STREET_CHANGE:
			return {
				...state,
				customerContractBillingStreet: action.payload,
			};

		case CUSTOMER_CONTRACT_SALUTATIONS_ID_CHANGE:
			return {
				...state,
				customerContractSalutationsId: action.payload,
			};

		case CUSTOMER_CONTRACT_CUSTOMER_TYPES_ID_CHANGE:
			return {
				...state,
				customerContractCustomerTypesId: action.payload,
			};

		//	CUSTOMER CONTACT HISTORY
		case CHANGE_CONTACT_HISTORY_DETAIL_OBJECT_ID:
			return {
				...state,
				contactHistoryDetailObjectId: action.payload,
			};

		case CHANGE_CONTACT_HISTORY_DETAIL_REMARKS:
			return {
				...state,
				contactHistoryDetailRemarks: action.payload,
			};

		case CHANGE_CONTACT_HISTORY_DETAIL_TOPIC:
			return {
				...state,
				contactHistoryDetailTopic: action.payload,
			};

		case CLOSE_CONTACT_HISTORY_DIALOG:
			return {
				...state,
				contactHistoryDialogState: false,
				activeContactHistory: 0,
				contactHistoryError: "",
				contactHistoryDetailObjectId: -1,
				contactHistoryDetailRemarks: "",
				contactHistoryDetailTopic: "",
				contactHistoryDetailDocument: "",
			};

		case CLOSE_DELETE_CUSTOMER_CONTACT_HISTORY_CONFIRM:
			return {
				...state,
				deleteCustomerContactHistoryConfirmState: false,
				deleteCustomerContactHistoryConfirmName: "",
				activeContactHistory: -1,
			};

		case CONTACT_HISTORY_DETAIL_DOCUMENT_CHANGE:
			return {
				...state,
				contactHistoryDetailDocument: action.payload,
			};

		case CONTACT_HISTORY_DETAIL_DOCUMENT_DELETE:
			return {
				...state,
				contactHistoryDetailDocument: "",
			};

		case DELETE_LEAD_CONTACT_HISTORY_CONFIRM:
			return {
				...state,
				deleteCustomerContactHistoryConfirmName: action.payload.entityName,
				deleteCustomerContactHistoryConfirmState: true,
				activeContactHistory: action.payload.id,
			};

		case EDIT_CONTACT_HISTORY:
			return {
				...state,
				contactHistoryDialogState: true,
				contactHistoryError: "",
				contactHistoryDialogTitle: action.payload.title,
				activeContactHistory: action.payload.id,
				contactHistoryDetailObjectId: action.payload.objectId,
				contactHistoryDetailRemarks: action.payload.remarks,
				contactHistoryDetailTopic: action.payload.topic,
				contactHistoryDetailDocument: action.payload.document,
				contactHistoryIsUser: action.payload.isUser === "1" ? true : false,
			};

		case GET_CONTACT_HISTORIES:
			return {
				...state,
				contactHistories: action.payload,
			};

		case SET_CONTACT_HISTORY_ERROR:
			return {
				...state,
				contactHistoryError: action.payload,
			};

		//	DELETE CUSTOMER
		case CLOSE_DELETE_CUSTOMER_CONFIRM:
			return {
				...state,
				deleteCustomerConfirmState: false,
				deleteCustomerConfirmName: "",
				activeCustomerId: -1,
			};

		//	COUNTER DOUBLINGS
		case CUSTOMER_COUNTER_DOUBLINGS_LIST:
			return {
				...state,
				customerCounterDoublingsList: action.payload,
				customerDoubleCounterDialogState: true,
			};

		case CUSTOMER_DOUBLE_COUNTER_DIALOG_CLOSE:
			return {
				...state,
				customerDoubleCounterDialogState: action.payload,
				customerCounterDoublingsList: [],
			};

		case CLOSE_CUSTOMER_CONTRACT_BILLING_ADDRESS_SAVED_DIALOG:
			return {
				...state,
				customerContractBillingAddressSavedDialogState: false
			}
	
		case CLOSE_CUSTOMER_CONTRACT_BILLING_ADDRESS_PASTE_DIALOG:
			return {
				...state,
				customerContractBillingAddressPasteDialogState: false
			}
	
		case CUSTOMER_CONTRACT_PASTE_BILLING_ADDRESS:
			return {
				...state,
				customerContractBillingAddressPasteDialogState: true,
				customerContractBillingAddresses: action.payload
			}
	
		case CUSTOMER_CONTRACT_DELETE_BILLING_ADDRESS:
			return {
				...state,
				customerContractBillingAddresses: action.payload
			}
	
		case CUSTOMER_CONTRACT_TAKEOVER_BILLING_ADDRESS:
			return {
				...state,
				customerContractCustomerTypesId: action.payload.customerTypesId,
				customerContractSalutationsId: action.payload.salutationsId,
				customerContractBillingName1: action.payload.billingName1,
				customerContractBillingName2: action.payload.billingName2,
				customerContractBillingName3: action.payload.billingName3,
				customerContractBillingStreet: action.payload.billingStreet,
				customerContractBillingHouseNumber: action.payload.billingHouseNumber,
				customerContractBillingPostalCode: action.payload.billingPostalCode,
				customerContractBillingCity: action.payload.billingCity,
				customerContractBillingDistrict: action.payload.billingDistrict,
				customerContractBillingCountry: action.payload.billingCountry,
				customerContractBillingAdditional: action.payload.billingAdditional,
				customerContractBillingPostboxNo: action.payload.billingPostboxNo,
				customerContractBillingPostboxPostalCode: action.payload.billingPostboxPostalCode,
				customerContractBillingPostboxCity: action.payload.billingPostboxCity,
				customerContractBillingAddressPasteDialogState: false,
			}

		case CUSTOMER_CONTRACT_BILLING_ADDRESS_SAVED:
			return {
				...state,
				customerContractBillingAddressSavedDialogState: true
			}

		case CUSTOMER_COPY_DIALOG:
			return {
				...state,
				customerCopyDialogState: action.payload.customerCopyDialogState,
				customerCopyDialogId: action.payload.customerCopyDialogId,
				contactPersons: action.payload.contactPersons
			}

		case CUSTOMER_ACTIVE_CAMPAIGN_CHANGE_DIALOG:
			return {
				...state,
				customerActiveCampaignChangeDialogState: action.payload.customerActiveCampaignChangeDialogState,
			}

		//  Default Path
		default:
			return state;
	}
};
