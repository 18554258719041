/**
 *      File:       ./src/context/administration/energy/loadProfilesContext.js
 * 
 *      Subject:    Load Profiles' Context
 */
import { createContext } from "react";

const loadProfilesContext = createContext();

export default loadProfilesContext;
