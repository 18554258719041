/**
 *      File:       ./src/context/exports/exportProductsContext.js
 * 
 *      Subject:    Product export context
 */
import { createContext } from "react";

const exportProductsContext = createContext();

export default exportProductsContext;
