/**
 *      File:       ./src/context/exports/NavigationContext .js
 * 
 *      Subject:    Navigation export context
 */
import { createContext } from "react";

const NavigationContext  = createContext();

export default NavigationContext ;
