/**
 * 		File:		./src/administration/energy/administrationsReducer.js
 * 
 * 		Subject:	Energy Administration's State Reducer
 */

import {
  GET_ENTITIES,
  SET_ACTIVE_ENTITY,
  SET_DELETE_ENTITY,
  SET_DELETE_MODAL_ENTITY_NAME,
  SET_EDIT_DIALOG_TITLE,
  SET_EDIT_ENTITY,
  SET_ENTITY_DIALOG_FIELDS,
  SET_ERROR,
  SET_ADMINISTRATION_FOREIGNS,
  ADMINISTRATION_NAME_CHANGE,
  CUSTOMERS_ID_CHANGE,
  IS_OWNER_CHANGE,
  ADMINISTRATION_TYPES_ID_CHANGE,
  ADMINISTRATIONS_ID_TOP_CHANGE,
} from "../../types";

export default (state, action) => {
  switch (action.type) {
    //  General section

    case GET_ENTITIES:
      return {
        ...state,
        entities: action.payload,
      };

    case SET_ACTIVE_ENTITY:
      return {
        ...state,
        editDialogAdministrationName: action.payload.administration_name,
        editDialogCustomersId: action.payload.customers_id,
		editDialogIsOwner: action.payload.is_owner,
        editDialogAdministrationTypesId: action.payload.administration_types_id,
        editDialogAdministrationsIdTop: action.payload.administrations_id_top,
      };

    case SET_DELETE_ENTITY:
      return {
        ...state,
        activeEntity: action.payload.id,
        deleteModalState: action.payload.deleteModalState,
      };

    case SET_DELETE_MODAL_ENTITY_NAME:
      return {
        ...state,
        deleteModalEntityName: action.payload,
      };

    case SET_EDIT_DIALOG_TITLE:
      return {
        ...state,
        editDialogTitle: action.payload,
      };

    case SET_EDIT_ENTITY:
      return {
        ...state,
        activeEntity: action.payload.id,
        editState: action.payload.editState,
      };

    case SET_ENTITY_DIALOG_FIELDS:
      return {
        ...state,
        editDialogFields: action.payload,
      };

    case SET_ERROR:
      return {
        ...state,
        editDialogError: action.payload,
      };

    //  Individual Section

    case SET_ADMINISTRATION_FOREIGNS:
      return {
        ...state,
        administrationTypes: 	action.payload.administrationTypes,
        topAdministrations: 	action.payload.topAdministrations,
        yesNos:         		action.payload.yesNos,
      };

    case ADMINISTRATION_NAME_CHANGE:
      return {
        ...state,
        editDialogAdministrationName: action.payload,
      };

    case CUSTOMERS_ID_CHANGE:
      return {
        ...state,
        editDialogCustomersId: action.payload,
      };

    case IS_OWNER_CHANGE:
      return {
        ...state,
        editDialogIsOwner: action.payload,
      }

    case ADMINISTRATION_TYPES_ID_CHANGE:
      return {
        ...state,
        editDialogAdministrationTypesId: action.payload,
      };

    case ADMINISTRATIONS_ID_TOP_CHANGE:
      return {
        ...state,
        editDialogAdministrationsIdTop: action.payload,
      };

    //  Default Path
    default:
      return state;
  }
};
