/**
 * 		File:		./src/context/administration/energy/AdministrationsState.js
 * 
 * 		Subject:	Energy Administrations' State
 */
import React, { useReducer, useEffect } from "react";
import axios from "axios";
import AdministrationsContext from "./administrationsContext";
import AdministrationsReducer from "./administrationsReducer";
import FormInput from "../../../Components/FormInput";
import FormSelect from "../../../Components/FormSelect";

import {
  GET_ENTITIES,
  SET_ACTIVE_ENTITY,
  SET_DELETE_ENTITY,
  SET_DELETE_MODAL_ENTITY_NAME,
  SET_EDIT_DIALOG_TITLE,
  SET_EDIT_ENTITY,
  SET_ENTITY_DIALOG_FIELDS,
  SET_ERROR,
  SET_ADMINISTRATION_FOREIGNS,
  ADMINISTRATION_NAME_CHANGE,
  CUSTOMERS_ID_CHANGE,
  IS_OWNER_CHANGE,
  ADMINISTRATION_TYPES_ID_CHANGE,
  ADMINISTRATIONS_ID_TOP_CHANGE,
} from "../../types";

const AdministrationsState = (props) => {
	
  const initialState = {
    entities: [],
    activeEntity: 0,
    deleteModalEntityName: "",
    deleteModalState: false,
    deleteModalTitle: "Verwaltung löschen",
    entityDeleteDialog: false,
    entityDeleteEntityName: "",
    editDialogTitle: "Neue Verwaltung anlegen",
    editDialogError: "",
    editDialogFields: [],
    editState: false,
    editDialogAdministrationName: "",
    editDialogCustomersId: "",
    editDialogIsOwner: -1,
    editDialogAdministrationTypesId: 1,
    editDialogAdministrationsIdTop: -1,
    administrationTypes: [],
	topAdministrations: [],
	yesNos: [],
  };
  const [state, dispatch] = useReducer(AdministrationsReducer, initialState);

  useEffect(() => {

    async function fetchData() {
      const params = new URLSearchParams();
      params.append("jwt", sessionStorage.jwt);

	  let url = process.env.REACT_APP_API_URI + "/administration/energy/get_administration_foreigns.php";
      let axiosConfig = {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      };

      axios
        .post(url, params, axiosConfig)
        .then((response) => {
          if (response.status === 200) {
            // console.log(response.data);

            dispatch({
              type: SET_ADMINISTRATION_FOREIGNS,
              payload: {
                administrationTypes: 	response.data.administrationTypes,
				topAdministrations: 	response.data.topAdministrations,
				yesNos:					response.data.yesNos,
              },
            });
          } else if (response.status === 401) {
            window.location.href = "#/logout";
          } else {
            console.log("Anderer Fehler");
          }
        })
        .catch(function(error) {
          if (error.response) {
            // The server answered with an error
            if (error.response.status === 401) {
              window.location.href = "#/logout";
            } else {
              console.log(error.response.data);
              console.log(error.response.status);
              console.log(error.response.headers);
            }
          } else if (error.request) {
            //  The request was made but the server didn't respond
            console.log(error.request);
          } else {
            //  Something else went wrong
            console.log("Error", error.message);
          }
        });
    }
	fetchData();
	
    const edf = [
      {
        id: 1,
        fieldType: (
          <FormInput
            context={AdministrationsContext}
            id="administration_name"
            label="Verwaltung"
            onChangeFunction={administrationNameChange}
            placeholder="Verwaltung"
            required
            type="text"
            value="editDialogAdministrationName"
          />
        ),
      },
      {
        id: 2,
        fieldType: (
          <FormInput
            context={AdministrationsContext}
            id="customers_id"
            label="ID Kunde"
            onChangeFunction={customersIdChange}
            placeholder="ID Kunde"
            type="text"
            value="editDialogCustomersId"
          />
        ),
      },
      {
        id: 3,
        fieldType: (
          <FormSelect
            context={AdministrationsContext}
            id="is_owner"
            label="Gesellschafter"
            onChangeFunction={isOwnerChange}
            placeholder="Gesellschafter"
            required
            selectOptions="yesNos"
            value="editDialogIsOwner"
          />
        ),
      },
      {
        id: 4,
        fieldType: (
          <FormSelect
            context={AdministrationsContext}
            id="administration_types_id"
            label="Versorger-Typ"
            onChangeFunction={administrationTypesIdChange}
            placeholder="Versorger-Typ"
            required
            selectOptions="administrationTypes"
            value="editDialogAdministrationTypesId"
          />
        ),
      },
      {
        id: 5,
        fieldType: (
          <FormSelect
            context={AdministrationsContext}
            id="administrations_id_top"
            label="ID Top-Verwaltung"
            onChangeFunction={administrationsIdTopChange}
            placeholder="ID Top-Verwaltung"
            selectOptions="topAdministrations"
            required
            value="editDialogAdministrationsIdTop"
          />
        ),
      },
    ];

    dispatch({
      type: SET_ENTITY_DIALOG_FIELDS,
      payload: edf,
    });

    getEntities();

    // eslint-disable-next-line
  }, [state.editDialogError]);


  //	GET ALL ADMINISTRATIONS FOR THE OVERVIEW
  const getEntities = () => {

    const params = new URLSearchParams();
    params.append("jwt", sessionStorage.jwt);

	let url = process.env.REACT_APP_API_URI + "/administration/energy/get_administrations.php";
    let axiosConfig = {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    };

    axios
      .post(url, params, axiosConfig)
      .then((response) => {
        if (response.status === 200) {

		  //   console.log(response.data);
		  const entities = response.data.entities;
		  for(var i in entities){
			  entities[i].id = (isNaN(entities[i].id) || entities[i].id === "") ? 0 : Number.parseInt(entities[i].id);
			  entities[i].customers_id = (isNaN(entities[i].customers_id) || entities[i].customers_id === "") ? 0 : Number.parseInt(entities[i].customers_id);
			  entities[i].administrations_id_top = (isNaN(entities[i].administrations_id_top) || entities[i].administrations_id_top === "") ? 0 : Number.parseInt(entities[i].administrations_id_top);
		  }
          dispatch({
            type: GET_ENTITIES,
            payload: entities,
		  });
		  
        } else if (response.status === 401) {
          window.location.href = "#/logout";
        } else {
          console.log("Anderer Fehler");
        }
      })
      .catch(function(error) {
        if (error.response) {
          // The server answered with an error
          if (error.response.status === 401) {
            window.location.href = "#/logout";
          } else {
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          }
        } else if (error.request) {
          //  The request was made but the server didn't respond
          console.log(error.request);
        } else {
          //  Something else went wrong
          console.log("Error", error.message);
        }
      });
  };

  /*--------------------------------
  //    function editEntity()
  --------------------------------*/
  const editEntity = (id) => {
	
    if (id > 0) {
      const params = new URLSearchParams();
      params.append("jwt", sessionStorage.jwt);
      params.append("id", id);

      let axiosConfig = {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      };

      let url =
        process.env.REACT_APP_API_URI +
        "/administration/energy/get_administration.php";

      axios
        .post(url, params, axiosConfig)
        .then((response) => {
          if (response.status === 200) {
            // console.log(response.data);

            dispatch({
              type: SET_ACTIVE_ENTITY,
              payload: response.data.administration,
            });

            dispatch({
              type: SET_EDIT_ENTITY,
              payload: {
                id: id,
                editState: true,
              },
            });

            if (response.data.administration.administration_types_id === "2")
              document.getElementById(
                "administrations_id_top"
              ).parentNode.parentNode.style.display = "none";

            dispatch({
              type: SET_EDIT_DIALOG_TITLE,
              payload: "Verwaltung bearbeiten",
            });
          } else if (response.status === 401) {
            window.location.href = "#/logout";
          } else {
            console.log("Anderer Fehler");
          }
        })
        .catch(function(error) {
          if (error.response) {
            // The server answered with an error
            if (error.response.status === 401) {
              window.location.href = "#/logout";
            } else {
              console.log(error.response.data);
              console.log(error.response.status);
              console.log(error.response.headers);
            }
          } else if (error.request) {
            //  The request was made but the server didn't respond
            console.log(error.request);
          } else {
            //  Something else went wrong
            console.log("Error", error.message);
          }
        });
    } else {
      dispatch({
        type: SET_EDIT_DIALOG_TITLE,
        payload: "Neue Verwaltung anlegen",
      });
    }

    dispatch({
      type: SET_EDIT_ENTITY,
      payload: {
        id: id,
        editState: true,
      },
    });
  };

  const setError = (errMsg) => {
    dispatch({
      type: SET_ERROR,
      payload: errMsg,
    });
  };

  /*------------------------------
  //    function saveEntity()
  ------------------------------*/
  const saveEntity = async () => {
    if (state.editDialogAdministrationName === "") {
      setError("Bitte geben Sie eine Bezeichnung für die Verwaltung ein.");
      return false;
    }
    if (
      state.editDialogAdministrationTypesId === 1 &&
      state.editDialogAdministrationsIdTop === -1
    ) {
      setError("Bitte wählen Sie die ID der Top-Verwaltung aus");
      return false;
	}
	if(state.editDialogIsOwner === -1) {
		setError("Bitte wählen Sie aus, ob die Verwaltung Gesellschafter ist oder nicht.");
		return false;
	}
	
    const params = new URLSearchParams();
    params.append("jwt", 					sessionStorage.jwt);
    params.append("id", 					state.activeEntity);
    params.append("administrationName", 	state.editDialogAdministrationName);
	params.append("customersId", 			state.editDialogCustomersId);
	params.append("isOwner", 				state.editDialogIsOwner);
    params.append("administrationTypesId",	state.editDialogAdministrationTypesId);
	params.append("administrationsIdTop", 	state.editDialogAdministrationsIdTop);
	
    const typeId = state.editDialogAdministrationTypesId;
    const name = state.editDialogAdministrationName;

    let axiosConfig = {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    };

    let url =
      process.env.REACT_APP_API_URI +
      "/administration/energy/set_administration.php";

    axios
      .post(url, params, axiosConfig)
      .then((response) => {
        if (response.status === 200) {
          //   console.log(response.data);

          const administration = {
            administration_name: "",
            customers_id: "",
            administration_types_id: 1,
            administrations_id_top: -1,
          };
          getEntities();

          dispatch({
            type: SET_ACTIVE_ENTITY,
            payload: administration,
          });
          dispatch({
            type: SET_EDIT_ENTITY,
            payload: {
              id: 0,
              editState: false,
            },
          });

          if (typeId === "2") {

			//	check if it is already a top administration
			let bIsTop = 0;

			for(let i=0; i<state.topAdministrations.length; i++) {
				if(state.topAdministrations[i].key === response.data.id) {
					bIsTop = 1;
					break;
				}
			}

			if(bIsTop === 0) {

				let topAdministrations = state.topAdministrations;
				topAdministrations.push({
				  key: response.data.id,
				  value: name,
				});

				dispatch({
				  type: SET_ADMINISTRATION_FOREIGNS,
				  payload: {
					administrationTypes: state.administrationTypes,
					topAdministrations: topAdministrations,
				  },
				});
			}
          }

          setError("");
          return true;
        } else if (response.status === 401) {
          window.location.href = "#/logout";
        } else {
          console.log("Anderer Fehler");
          return false;
        }
      })
      .catch(function(error) {
        if (error.response) {
          // The server answered with an error
          if (error.response.status === 401) {
            window.location.href = "#/logout";
          } else {
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
            return false;
          }
        } else if (error.request) {
          //  The request was made but the server didn't respond
          console.log(error.request);
          return false;
        } else {
          //  Something else went wrong
          console.log("Error", error.message);
          return false;
        }
      });
    return true;
  };

  const closeEdit = () => {
    dispatch({
      type: SET_ACTIVE_ENTITY,
      payload: {
        administration_name: "",
        customers_id: "",
        administration_types_id: 1,
        administrations_id_top: -1,
      },
    });

    dispatch({
      type: SET_EDIT_ENTITY,
      payload: {
        id: 0,
        editState: false,
      },
    });
    setError("");
  };

  const deleteEntity = (id) => {
    const params = new URLSearchParams();
    params.append("jwt", sessionStorage.jwt);
    params.append("id", id);

    let axiosConfig = {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    };

    let url =
      process.env.REACT_APP_API_URI +
      "/administration/energy/get_administration.php";

    axios
      .post(url, params, axiosConfig)
      .then((response) => {
        if (response.status === 200) {
          // console.log(response.data);

          dispatch({
            type: SET_ACTIVE_ENTITY,
            payload: response.data.administration,
          });

          dispatch({
            type: SET_DELETE_ENTITY,
            payload: {
              id: id,
              deleteModalState: true,
            },
          });

          dispatch({
            type: SET_DELETE_MODAL_ENTITY_NAME,
            payload:
              'Möchten Sie die Verwaltung "' +
              response.data.administration.administration_name +
              '" wirklich löschen?',
          });
        } else if (response.status === 401) {
          window.location.href = "#/logout";
        } else {
          console.log("Anderer Fehler");
        }
      })
      .catch(function(error) {
        if (error.response) {
          // The server answered with an error
          if (error.response.status === 401) {
            window.location.href = "#/logout";
          } else {
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          }
        } else if (error.request) {
          //  The request was made but the server didn't respond
          console.log(error.request);
        } else {
          //  Something else went wrong
          console.log("Error", error.message);
        }
      });
  };

  const closeDelete = () => {
    dispatch({
      type: SET_ACTIVE_ENTITY,
      payload: {
        administration_name: "",
        customers_id: "",
        administration_types_id: 1,
        administrations_id_top: -1,
      },
    });

    dispatch({
      type: SET_EDIT_ENTITY,
      payload: {
        id: 0,
        editState: false,
      },
    });

    dispatch({
      type: SET_DELETE_ENTITY,
      payload: {
        id: 0,
        deleteModalState: false,
      },
    });

    dispatch({
      type: SET_DELETE_MODAL_ENTITY_NAME,
      payload: "",
    });
  };

  const deleteEntityConfirmed = () => {
    const params = new URLSearchParams();
    params.append("jwt", sessionStorage.jwt);
    params.append("id", state.activeEntity);

    let axiosConfig = {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    };

    let url =
      process.env.REACT_APP_API_URI +
      "/administration/energy/delete_administration.php";

    axios
      .post(url, params, axiosConfig)
      .then((response) => {
        if (response.status === 200) {
          // console.log(response.data);

          if (state.editDialogAdministrationTypesId === "2") {
            let topAdministrations = [];
            for (let i = 0; i < state.topAdministrations.length; i++) {
              if (state.topAdministrations[i].key !== state.activeEntity)
                topAdministrations.push(state.topAdministrations[i]);
            }
            dispatch({
              type: SET_ADMINISTRATION_FOREIGNS,
              payload: {
                administrationTypes: state.administrationTypes,
                topAdministrations: topAdministrations,
              },
            });
          }

          getEntities();
          closeDelete();
        } else if (response.status === 401) {
          window.location.href = "#/logout";
        } else {
          console.log("Anderer Fehler");
        }
      })
      .catch(function(error) {
        if (error.response) {
          // The server answered with an error
          if (error.response.status === 401) {
            window.location.href = "#/logout";
          } else {
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          }
        } else if (error.request) {
          //  The request was made but the server didn't respond
          console.log(error.request);
        } else {
          //  Something else went wrong
          console.log("Error", error.message);
        }
      });
  };

  /*-------------------------------------
    //  PAGE SPECIFIC
    -------------------------------------*/

  const administrationNameChange = (e) => {
    if (
      state.editDialogError !== "" &&
      state.editDialogAdministrationName === ""
    )
      setError("");

    dispatch({
      type: ADMINISTRATION_NAME_CHANGE,
      payload: e.target.value,
    });
  };

  const customersIdChange = (e) => {
    dispatch({
      type: CUSTOMERS_ID_CHANGE,
      payload: e.target.value,
    });
  };

  const isOwnerChange = (e) => {

	if(state.editDialogError !== '' && state.editDialogIsOwner === -1) {
		setError('');
	}

    dispatch({
      type: IS_OWNER_CHANGE,
      payload: e.target.value,
    });
  }

  const administrationTypesIdChange = (e) => {
    if (e.target.value === "1")
      document.getElementById(
        "administrations_id_top"
      ).parentNode.parentNode.style.display = "block";
    else
      document.getElementById(
        "administrations_id_top"
      ).parentNode.parentNode.style.display = "none";
    dispatch({
      type: ADMINISTRATION_TYPES_ID_CHANGE,
      payload: e.target.value,
    });
  };

  const administrationsIdTopChange = (e) => {
    if (
      state.editDialogError !== "" &&
      state.editDialogAdministrationTypesId === 1 &&
      state.editDialogAdministrationsIdTop === -1
    )
      setError("");
    dispatch({
      type: ADMINISTRATIONS_ID_TOP_CHANGE,
      payload: e.target.value,
    });
  };

  return (
    <AdministrationsContext.Provider
      value={{
        entities: state.entities,
        editDialogFields: state.editDialogFields,
        editDialogError: state.editDialogError,
        activeEntity: state.activeEntity,
        editState: state.editState,
        userRoleLabel: state.userRoleLabel,
        editDialogTitle: state.editDialogTitle,
        deleteModalTitle: state.deleteModalTitle,
        deleteModalEntityName: state.deleteModalEntityName,
        deleteModalState: state.deleteModalState,

        editDialogAdministrationName: 		state.editDialogAdministrationName,
        editDialogCustomersId: 				state.editDialogCustomersId,
        editDialogIsOwner:                	state.editDialogIsOwner,
        editDialogAdministrationTypesId:	state.editDialogAdministrationTypesId,
        editDialogAdministrationsIdTop: 	state.editDialogAdministrationsIdTop,
        administrationTypes:            	state.administrationTypes,
		topAdministrations: 				state.topAdministrations,
		yesNos:								state.yesNos,

        editEntity,
        setError,
        saveEntity,
        getEntities,
        closeEdit,
        deleteEntity,
        closeDelete,
        deleteEntityConfirmed,

        administrationNameChange,
        customersIdChange,
        administrationTypesIdChange,
        administrationsIdTopChange,
        isOwnerChange,
      }}
    >
      {props.children}
    </AdministrationsContext.Provider>
  );
};

export default AdministrationsState;
