/**
 *      File:       ./src/context/administation/system/parametersReducer.js
 * 
 *      Subject:    System Parameters' State Reducer
 */

import {
    GET_ENTITIES,
    SET_ACTIVE_ENTITY,
    SET_DELETE_ENTITY,
    SET_DELETE_MODAL_ENTITY_NAME,
    SET_EDIT_DIALOG_TITLE,
    SET_EDIT_ENTITY,
    SET_ENTITY_DIALOG_FIELDS,
    SET_ERROR,

    PARAMETER_NAME_CHANGE,
    URL_CHANGE

} from '../../types';

export default(state, action) => {

    switch(action.type)
    {
        //  General section

        case GET_ENTITIES:
            return {
                ...state,
                entities: action.payload
            }

        case SET_ACTIVE_ENTITY:
            return {
                ...state,
                editDialogParameterName: action.payload.parameter_name,
                editDialogUrl: action.payload.url
            }

        case SET_DELETE_ENTITY:
            return {
                ...state,
                activeEntity: action.payload.id,
                deleteModalState: action.payload.deleteModalState
            }

        case SET_DELETE_MODAL_ENTITY_NAME:
            return {
                ...state,
                deleteModalEntityName: action.payload
            }

        case SET_EDIT_DIALOG_TITLE:
            return {
                ...state,
                editDialogTitle: action.payload
            }

        case SET_EDIT_ENTITY:
            return {
                ...state,
                activeEntity: action.payload.id,
                editState: action.payload.editState
            }

        case SET_ENTITY_DIALOG_FIELDS:
            return {
                ...state,
                editDialogFields: action.payload
            }

        case SET_ERROR:
            return {
                ...state,
                editDialogError: action.payload
            }


        //  Individual Section

        case PARAMETER_NAME_CHANGE:
            return {
                ...state,
                editDialogParameterName: action.payload
            }

        case URL_CHANGE:
            return {
                ...state,
                editDialogUrl: action.payload
            }

            
        //  Default Path
        default:
            return state;
    }
}