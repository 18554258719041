/**
 *      File:       ./src/context/administration/system/UserRolesState.js
 *
 *      Subject:    User roles' state
 */
import React, { useReducer, useEffect } from "react";
import axios from "axios";
import UserRolesContext from "./userRolesContext";
import UserRolesReducer from "./userRolesReducer";

import {
  CLOSE_USER_ROLE_DETAIL_DIALOG,
  EDIT_ENTITY,
  GET_ENTITIES,
  SET_DELETE_ENTITY,
  SET_DELETE_MODAL_ENTITY_NAME,
  SET_ERROR,
  SET_IS_READING_RIGHT,
  SET_IS_WRITING_RIGHT,
  USER_ROLE_NAME_CHANGE,
} from "../../types";

const UserRolesState = (props) => {
  //---------------
  //	INITIAL STATE
  const initialState = {
    activeUserRole: 0,
    deleteCustomerConfirmName: "",
    deleteCustomerConfirmState: false,
    editDialogUserRoleName: "",
    entities: [],
    funcs: [],
    isReadingRight: {},
    isWritingRight: {},
    userRoleDetailDialogError: "",
    userRoleDetailDialogState: false,
    userRoleDetailDialogTitle: "",
  };
  const [state, dispatch] = useReducer(UserRolesReducer, initialState);

  //---------
  //	STARTUP
  useEffect(
    () => {
      getEntities();

      // eslint-disable-next-line
    },
    [state.editDialogError],
    [state.userRoleLabel]
  );

  //-----------------------------
  //	GET ALL USER ROLES DEFINED
  const getEntities = () => {
    const params = new URLSearchParams();
    params.append("jwt", sessionStorage.jwt);

    let axiosConfig = {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    };

    let url =
      process.env.REACT_APP_API_URI +
      "/administration/system/get_user_roles.php";

    axios
      .post(url, params, axiosConfig)
      .then((response) => {
        if (response.status === 200) {
        //   console.log(response.data);

          dispatch({
            type: GET_ENTITIES,
            payload: response.data.entities,
          });
        } else if (response.status === 401) {
          window.location.href = "#/logout";
        } else {
          console.log("Anderer Fehler");
        }
      })
      .catch(function (error) {
        if (error.response) {
          // The server answered with an error
          if (error.response.status === 401) {
            window.location.href = "#/logout";
          } else {
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          }
        } else if (error.request) {
          //  The request was made but the server didn't respond
          console.log(error.request);
        } else {
          //  Something else went wrong
          console.log("Error", error.message);
        }
      });
  };

  //-----------------------------
  //	EDIT USER ROLE
  const editEntity = (id) => {
    const params = new URLSearchParams();
    params.append("jwt", sessionStorage.jwt);
    params.append("id", id);

    let axiosConfig = {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    };

    let url =
      process.env.REACT_APP_API_URI +
      "/administration/system/get_user_role.php";

    axios
      .post(url, params, axiosConfig)
      .then((response) => {
        if (response.status === 200) {
        //   console.log(response.data);

          let title = "Neue Benutzerrolle anlegen";
          let userRoleName = "";
          let funcs = [];
          let readingRights = {};
          let writingRights = {};

          for (var i in response.data.flattenTemp) {
            funcs.push(response.data.flattenTemp[i]);
            readingRights[response.data.flattenTemp[i].id] = response.data.flattenTemp[i].right > 0 ? true : false;
            writingRights[response.data.flattenTemp[i].id] = response.data.flattenTemp[i].right > 1 ? true : false;
          }

          if (id > 0) {
            title = "Benutzerrolle bearbeiten";
            userRoleName = response.data.userRole.userRoleName;
          }

          dispatch({
            type: SET_IS_READING_RIGHT,
            payload: readingRights,
          });

          dispatch({
            type: SET_IS_WRITING_RIGHT,
            payload: writingRights,
          });

          dispatch({
            type: EDIT_ENTITY,
            payload: {
              id: id,
              title: title,
              editState: true,
              funcs: funcs,
            },
          });

          dispatch({
            type: USER_ROLE_NAME_CHANGE,
            payload: userRoleName,
          });
        } else if (response.status === 401) {
          window.location.href = "#/logout";
        } else {
          console.log("Anderer Fehler");
        }
      })
      .catch(function (error) {
        if (error.response) {
          // The server answered with an error
          if (error.response.status === 401) {
            window.location.href = "#/logout";
          } else {
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          }
        } else if (error.request) {
          //  The request was made but the server didn't respond
          console.log(error.request);
        } else {
          //  Something else went wrong
          console.log("Error", error.message);
        }
      });
  };

  //--------------------------------------------
  //	SET THE ERROR MESSAGE OF THE DETAIL DIALOG
  const setError = (errMsg) => {
    dispatch({
      type: SET_ERROR,
      payload: errMsg,
    });
  };

  //------------------------
  //	SAVE THE DETAIL DIALOG
  const saveUserRoleDetailDialog = async () => {

    if (state.editDialogUserRoleName === "") {
      setError("Bitte geben Sie einen Namen für die Benutzerrolle ein");
      return false;
    }

    const params = new URLSearchParams();
    params.append("jwt", sessionStorage.jwt);
    params.append("id", state.activeUserRole);
    params.append("userRoleName", state.editDialogUserRoleName);

    for (var i in state.isReadingRight) {
      params.append("read-" + i, state.isReadingRight[i]);
    }

    for (i in state.isWritingRight) {
      params.append("write-" + i, state.isWritingRight[i]);
    }

    let axiosConfig = {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    };

    let url =
      process.env.REACT_APP_API_URI +
      "/administration/system/set_user_role.php";


    axios
      .post(url, params, axiosConfig)
      .then((response) => {
        if (response.status === 200) {
        //   console.log(response.data);

          closeUserRoleDetailDialog();
          getEntities();
          return true;
        } else if (response.status === 401) {
          window.location.href = "#/logout";
        } else {
          console.log("Anderer Fehler");
          return false;
        }
      })
      .catch(function (error) {
        if (error.response) {
          // The server answered with an error
          if (error.response.status === 401) {
            window.location.href = "#/logout";
          } else {
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
            return false;
          }
        } else if (error.request) {
          //  The request was made but the server didn't respond
          console.log(error.request);
          return false;
        } else {
          //  Something else went wrong
          console.log("Error", error.message);
          return false;
        }
      });
    return true;
  };

  //-------------------------
  //	CLOSE THE DETAIL DIALOG
  const closeUserRoleDetailDialog = () => {
    dispatch({
      type: CLOSE_USER_ROLE_DETAIL_DIALOG,
    });
  };

  //------------------------------------
  //	PROVIDE DELETE CONFIRMATION DIALOG
  const deleteEntity = (id) => {
    const params = new URLSearchParams();
    params.append("jwt", sessionStorage.jwt);
    params.append("id", id);

    let axiosConfig = {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    };

    let url =
      process.env.REACT_APP_API_URI +
      "/administration/system/get_user_role.php";

    axios
      .post(url, params, axiosConfig)
      .then((response) => {
        if (response.status === 200) {
          // console.log(response.data);

          dispatch({
            type: SET_DELETE_ENTITY,
            payload: {
              id: id,
              deleteModalState: true,
            },
          });

          dispatch({
            type: SET_DELETE_MODAL_ENTITY_NAME,
            payload: response.data.userRole.userRoleName,
          });
        } else if (response.status === 401) {
          window.location.href = "#/logout";
        } else {
          console.log("Anderer Fehler");
        }
      })
      .catch(function (error) {
        if (error.response) {
          // The server answered with an error
          if (error.response.status === 401) {
            window.location.href = "#/logout";
          } else {
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          }
        } else if (error.request) {
          //  The request was made but the server didn't respond
          console.log(error.request);
        } else {
          //  Something else went wrong
          console.log("Error", error.message);
        }
      });
  };

  //---------------------
  //	ABORT DELETE DIALOG
  const closeCustomerConfirm = () => {
    dispatch({
      type: SET_DELETE_ENTITY,
      payload: {
        id: 0,
        deleteModalState: false,
      },
    });

    dispatch({
      type: SET_DELETE_MODAL_ENTITY_NAME,
      payload: "",
    });
  };

  //----------------------
  //	DELETE THE USER ROLE
  const deleteCustomer = () => {
    const params = new URLSearchParams();
    params.append("jwt", sessionStorage.jwt);
    params.append("id", state.activeUserRole);

    let axiosConfig = {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    };

    let url =
      process.env.REACT_APP_API_URI +
      "/administration/system/delete_user_role.php";

    axios
      .post(url, params, axiosConfig)
      .then((response) => {
        if (response.status === 200) {
          // console.log(response.data);

          getEntities();
          closeCustomerConfirm();
        } else if (response.status === 401) {
          window.location.href = "#/logout";
        } else {
          console.log("Anderer Fehler");
        }
      })
      .catch(function (error) {
        if (error.response) {
          // The server answered with an error
          if (error.response.status === 401) {
            window.location.href = "#/logout";
          } else {
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          }
        } else if (error.request) {
          //  The request was made but the server didn't respond
          console.log(error.request);
        } else {
          //  Something else went wrong
          console.log("Error", error.message);
        }
      });
  };

  // /*-------------------------------------
  // //  PAGE SPECIFIC
  // -------------------------------------*/

  //--------------------------
  //	CHANGE NAME OF USER ROLE
  const userRoleNameChange = (e) => {
    if (
      state.userRoleDetailDialogError !== "" &&
      state.editDialogUserRoleName === ""
    )
      setError("");

    dispatch({
      type: USER_ROLE_NAME_CHANGE,
      payload: e.target.value,
    });
  };

  //-------------------------
  //	CHANGE READING RIGHT(S)
  const changeReadingRight = (e, id) => {
    const newState = e.target.checked;
    const isReadingRight = state.isReadingRight;
    isReadingRight[id] = newState;
    const isWritingRight = state.isWritingRight;
    if (newState === false) isWritingRight[id] = newState;

    //	Check for childs to check or uncheck
    let curItem = document.getElementById(id + "Row");
    let i = 0,
      nextRound = false,
      nextLevel;
    const levelIn = Number.parseInt(
      document.getElementById(id + "Row").className.split("-")[1]
    );

    do {
      if (curItem.nextSibling && curItem.nextSibling.id.indexOf("Row") !== -1) {
        curItem = curItem.nextSibling;
        nextLevel = Number.parseInt(curItem.className.split("-")[1]);

        if (nextLevel <= levelIn) {
          nextRound = false;
        } else {
          nextRound = true;
          isReadingRight[curItem.id.replace("Row", "")] = newState;

          if (newState === false)
            isWritingRight[curItem.id.replace("Row", "")] = newState;
        }
      } else {
        nextRound = false;
      }

      i++;
    } while (i < 100 && nextRound === true);

    dispatch({
      type: SET_IS_READING_RIGHT,
      payload: isReadingRight,
    });

    dispatch({
      type: SET_IS_WRITING_RIGHT,
      payload: isWritingRight,
    });
  };

  //-------------------------
  //	CHANGE WRITING RIGHT(S)
  const changeWritingRight = (e, id) => {
    const newState = e.target.checked;
    const isWritingRight = state.isWritingRight;
    isWritingRight[id] = newState;
    const isReadingRight = state.isReadingRight;
    if (newState === true) isReadingRight[id] = newState;

    //	Check for childs to check or uncheck
    let curItem = document.getElementById(id + "Row");
    let i = 0,
      nextRound = false,
      nextLevel;
    const levelIn = Number.parseInt(
      document.getElementById(id + "Row").className.split("-")[1]
    );

    do {
      if (curItem.nextSibling && curItem.nextSibling.id.indexOf("Row") !== -1) {
        curItem = curItem.nextSibling;
        nextLevel = Number.parseInt(curItem.className.split("-")[1]);

        if (nextLevel <= levelIn) {
          nextRound = false;
        } else {
          nextRound = true;
          isWritingRight[curItem.id.replace("Row", "")] = newState;

          if (newState === true)
            isReadingRight[curItem.id.replace("Row", "")] = newState;
        }
      } else {
        nextRound = false;
      }

      i++;
    } while (i < 100 && nextRound === true);

    dispatch({
      type: SET_IS_WRITING_RIGHT,
      payload: isWritingRight,
    });

    dispatch({
      type: SET_IS_READING_RIGHT,
      payload: isReadingRight,
    });
  };

  return (
    <UserRolesContext.Provider
      value={{
        activeUserRole: state.activeUserRole,
        deleteCustomerConfirmName: state.deleteCustomerConfirmName,
        deleteCustomerConfirmState: state.deleteCustomerConfirmState,
        editDialogUserRoleName: state.editDialogUserRoleName,
        entities: state.entities,
        funcs: state.funcs,
        isReadingRight: state.isReadingRight,
        isWritingRight: state.isWritingRight,
        userRoleDetailDialogError: state.userRoleDetailDialogError,
        userRoleDetailDialogState: state.userRoleDetailDialogState,
        userRoleDetailDialogTitle: state.userRoleDetailDialogTitle,

        changeReadingRight,
        changeWritingRight,
        closeCustomerConfirm,
        closeUserRoleDetailDialog,
        deleteCustomer,
        deleteEntity,
        editEntity,
        saveUserRoleDetailDialog,
        userRoleNameChange,
      }}
    >
      {props.children}
    </UserRolesContext.Provider>
  );
};

export default UserRolesState;
