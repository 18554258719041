/**
 * 		File:		./src/context/exports/ExportPGsState.js
 * 
 * 		Subject:	State for product groups' exports
 */
import React, { useReducer } from "react";
import axios from "axios";
import ExportPGsContext from "./exportPGsContext";
import ExportPGsReducer from "./exportPGsReducer";

import {
	GET_EXPORTS_PRODUCT_GROUPS
} from "../types";

const ExportPGsState = (props) => {

  const initialState = {
	  dataProductGroups: [],
  };
  const [state, dispatch] = useReducer(ExportPGsReducer, initialState);


  //------------------------------
  //	GET THE EXPORTS
  //------------------------------
  const getExports = () => {

	//	Set call
	const params = new URLSearchParams();
    params.append('jwt', sessionStorage.jwt);
    params.append('exportPage', "product-groups");

	let url = process.env.REACT_APP_API_URI + '/exports/get_exports.php';
    let axiosConfig = {
        headers: {
            "Content-Type": "application/x-www-form-urlencoded"
        }
    };

	//	Call backend
    axios.post(url,params,axiosConfig)
    .then(response => {
        if(response.status === 200) {

            // console.log(response.data);
            
            dispatch({
                type: GET_EXPORTS_PRODUCT_GROUPS,
                payload: {
					productGroupsExports : response.data.productGroupsExports,
				},
            });

        } else if(response.status === 401) {
            window.location.href ='#/logout';
        } else {
            console.log('Anderer Fehler');
        }
    })
    .catch(function(error) {
        if(error.response){
            // The server answered with an error
            if(error.response.status === 401) {
                window.location.href = '#/logout';
            } else {
                console.log(error.response.data);
                console.log(error.response.status);
                console.log(error.response.headers);
            }
        } else if(error.request) {
            //  The request was made but the server didn't respond
            console.log(error.request);
        } else {
            //  Something else went wrong
            console.log('Error', error.message);
        }
    })
  }

  const exportProductGroups = () => {

	//	Set call
	const params = new URLSearchParams();
    params.append('jwt', sessionStorage.jwt);

	let url = process.env.REACT_APP_API_URI + '/exports/start_export_product_groups.php';
    let axiosConfig = {
        headers: {
            "Content-Type": "application/x-www-form-urlencoded"
        }
	};
	
	let productGroupsInProgress = state.dataProductGroups;
	productGroupsInProgress[0].id = 0;
	productGroupsInProgress[0].filename = "Export wird erstellt...";
	dispatch({
		type: GET_EXPORTS_PRODUCT_GROUPS,
		payload: {
			productGroupsExports: productGroupsInProgress,
		},
	});

	//	Call backend
    axios.post(url,params,axiosConfig)
    .then(response => {
        if(response.status === 200) {

			// console.log(response.data);
			window.setTimeout(getExports, 1000);

        } else if(response.status === 401) {
            window.location.href ='#/logout';
        } else {
            console.log('Anderer Fehler');
        }
    })
    .catch(function(error) {
        if(error.response){
            // The server answered with an error
            if(error.response.status === 401) {
                window.location.href = '#/logout';
            } else {
                console.log(error.response.data);
                console.log(error.response.status);
                console.log(error.response.headers);
            }
        } else if(error.request) {
            //  The request was made but the server didn't respond
            console.log(error.request);
        } else {
            //  Something else went wrong
            console.log('Error', error.message);
        }
    })
  }

  return (
    <ExportPGsContext.Provider
      value={{
		  dataProductGroups: state.dataProductGroups,

		  getExports,
		  exportProductGroups,
      }}
    >
      {props.children}
    </ExportPGsContext.Provider>
  );
};

export default ExportPGsState;
