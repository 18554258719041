/**
 *  File: ./src/index.js
 * 
 *  Meaning:    The entry point for the whole application
 */

import './polyfills';
import React from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';

import { HashRouter } from 'react-router-dom';
import './assets/base.scss';
import Main from './Pages/Main';
import configureStore from './config/configureStore';
import { Provider } from 'react-redux';

const store = configureStore();
const rootElement = document.getElementById('root');

if (typeof window.__REACT_DEVTOOLS_GLOBAL_HOOK__ === 'object') {
	window.__REACT_DEVTOOLS_GLOBAL_HOOK__.inject = function () { };
}

const renderApp = Component => {
	ReactDOM.render(
		<Provider store={store}>
			<HashRouter>
				<Component />
			</HashRouter>
		</Provider>,
		rootElement
	);
};


renderApp(Main);

if (module.hot) {
	module.hot.accept('./Pages/Main', () => {
		const NextApp = require('./Pages/Main').default;
		renderApp(NextApp);
	});
}
serviceWorker.unregister();