/**
 * 		File:		./src/context/exports/PortalReducer.js
 * 
 * 		Subject:	Customer portal export state reducer
 */
import {
	GET_EXPORTS_PORTAL,
} from "../types";

export default (state, action) => {
  
  switch (action.type) {
    
    case GET_EXPORTS_PORTAL:
		return {
			...state,
			dataAdministration: action.payload.administrationExports,
			dataCustomer: action.payload.customerExports,
			dataObject: action.payload.objectExports,
			dataCustomerContactPerson: action.payload.customerContactPersonExports,
			dataLeadContactPerson: action.payload.leadContactPersonExports,
		}

    //  Default Path
    default:
      return state;
  }
};
