/**
 *      File:       ./src/context/administration/order-criterias/businessPartnerTypesReducer.js
 * 
 *      Subject:    Business Partner Types' State Reducer
 */
import {
    GET_ENTITIES,
    SET_ACTIVE_ENTITY,
    SET_DELETE_ENTITY,
    SET_DELETE_MODAL_ENTITY_NAME,
    SET_EDIT_DIALOG_TITLE,
    SET_EDIT_ENTITY,
    SET_ENTITY_DIALOG_FIELDS,
    SET_ERROR,

    TYPE_INDEX_SAP_CHANGE,
    TYPE_NAME_CHANGE,
    TYPE_ABBREVIATION_CHANGE

} from '../../types';

export default(state, action) => {

    switch(action.type)
    {
        //  General section

        case GET_ENTITIES:
            return {
                ...state,
                entities: action.payload
            }

        case SET_ACTIVE_ENTITY:
            return {
                ...state,
                editDialogTypeIndexSap: action.payload.type_index_sap,
                editDialogTypeName: action.payload.type_name,
                editDialogTypeAbbreviation: action.payload.type_abbreviation
            }

        case SET_DELETE_ENTITY:
            return {
                ...state,
                activeEntity: action.payload.id,
                deleteModalState: action.payload.deleteModalState
            }

        case SET_DELETE_MODAL_ENTITY_NAME:
            return {
                ...state,
                deleteModalEntityName: action.payload
            }

        case SET_EDIT_DIALOG_TITLE:
            return {
                ...state,
                editDialogTitle: action.payload
            }

        case SET_EDIT_ENTITY:
            return {
                ...state,
                activeEntity: action.payload.id,
                editState: action.payload.editState
            }

        case SET_ENTITY_DIALOG_FIELDS:
            return {
                ...state,
                editDialogFields: action.payload
            }

        case SET_ERROR:
            return {
                ...state,
                editDialogError: action.payload
            }


        //  Individual Section

        case TYPE_INDEX_SAP_CHANGE:
            return{
                ...state,
                editDialogTypeIndexSap: action.payload,
            }

        case TYPE_NAME_CHANGE:
            return {
                ...state,
                editDialogTypeName: action.payload
            }
            
        case TYPE_ABBREVIATION_CHANGE:
            return {
                ...state,
                editDialogTypeAbbreviation: action.payload
            }

            
        //  Default Path
        default:
            return state;
    }
}