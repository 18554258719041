/**
 *      File:       ./src/context/administration/system/userRolesReducer.js
 * 
 *      Subject:    User Roles' State Reducer
 */
import {
    
    CLOSE_USER_ROLE_DETAIL_DIALOG,
    EDIT_ENTITY,
    GET_ENTITIES,
    SET_DELETE_ENTITY,
    SET_DELETE_MODAL_ENTITY_NAME,
    SET_ERROR,
    SET_IS_READING_RIGHT,
    SET_IS_WRITING_RIGHT,
    USER_ROLE_NAME_CHANGE,

} from '../../types';

export default(state, action) => {

    switch(action.type)
    {
        case CLOSE_USER_ROLE_DETAIL_DIALOG:
            return {
                ...state,
                activeUserRole: 0,
                userRoleDetailDialogError: '',
                userRoleDetailDialogState: false,
                userRoleDetailDialogTitle: '',
				editDialogUserRoleName: '',
            }

        case EDIT_ENTITY:
            return {
                ...state,
                userRoleDetailDialogState: true,
                userRoleDetailDialogError: '',
                userRoleDetailDialogTitle: action.payload.title,
				activeUserRole: action.payload.id,
				funcs: action.payload.funcs,
            }

        case GET_ENTITIES:
            return {
                ...state,
                entities: action.payload,
            }

        case SET_DELETE_ENTITY:
            return {
                ...state,
                activeUserRole: action.payload.id,
                deleteCustomerConfirmState: action.payload.deleteModalState,
            }

        case SET_DELETE_MODAL_ENTITY_NAME:
            return {
                ...state,
                deleteCustomerConfirmName: action.payload
            }

        case SET_ERROR:
            return {
                ...state,
                userRoleDetailDialogError: action.payload,
            }

        case SET_IS_READING_RIGHT:
            return {
                ...state,
                isReadingRight: action.payload,
            }

        case SET_IS_WRITING_RIGHT:
            return {
                ...state,
                isWritingRight: action.payload,
            }

        case USER_ROLE_NAME_CHANGE:
        return {
                ...state,
                editDialogUserRoleName: action.payload,
            }
            
        //  Default Path
        default:
            return state;
    }
}