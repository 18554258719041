/**
 * 		File:		./src/context/administration/energy/loadProfilesReducer.js
 * 
 * 		Subject:	Load Profiles' State Reducer
 */

import {
  GET_ENTITIES,
  SET_ACTIVE_ENTITY,
  SET_DELETE_ENTITY,
  SET_DELETE_MODAL_ENTITY_NAME,
  SET_EDIT_DIALOG_TITLE,
  SET_EDIT_ENTITY,
  SET_ENTITY_DIALOG_FIELDS,
  SET_ERROR,
  LOAD_PROFILE_NAME_CHANGE,
  LOAD_PROFILE_SHORTCUT_CHANGE,
} from "../../types";

export default (state, action) => {
  switch (action.type) {
    //  General section

    case GET_ENTITIES:
      return {
        ...state,
        entities: action.payload,
      };

    case SET_ACTIVE_ENTITY:
      return {
        ...state,
        editDialogLoadProfileName: action.payload.load_profile_name,
        editDialogLoadProfileShortcut: action.payload.load_profile_shortcut,
      };

    case SET_DELETE_ENTITY:
      return {
        ...state,
        activeEntity: action.payload.id,
        deleteModalState: action.payload.deleteModalState,
      };

    case SET_DELETE_MODAL_ENTITY_NAME:
      return {
        ...state,
        deleteModalEntityName: action.payload,
      };

    case SET_EDIT_DIALOG_TITLE:
      return {
        ...state,
        editDialogTitle: action.payload,
      };

    case SET_EDIT_ENTITY:
      return {
        ...state,
        activeEntity: action.payload.id,
        editState: action.payload.editState,
      };

    case SET_ENTITY_DIALOG_FIELDS:
      return {
        ...state,
        editDialogFields: action.payload,
      };

    case SET_ERROR:
      return {
        ...state,
        editDialogError: action.payload,
      };

    //  Individual Section

    case LOAD_PROFILE_NAME_CHANGE:
      return {
        ...state,
        editDialogLoadProfileName: action.payload,
      };

    case LOAD_PROFILE_SHORTCUT_CHANGE:
      return {
        ...state,
        editDialogLoadProfileShortcut: action.payload,
      };

    //  Default Path
    default:
      return state;
  }
};
