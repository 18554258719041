/**
 *      File:       ./src/context/administration/energy/energyAssignmentsReducer.js
 * 
 *      Subject:    Energy Assignments' State Reducer
 */

import {
    GET_ENTITIES,
    SET_ACTIVE_ENTITY,
    SET_DELETE_ENTITY,
    SET_DELETE_MODAL_ENTITY_NAME,
    SET_EDIT_DIALOG_TITLE,
    SET_EDIT_ENTITY,
    SET_ENTITY_DIALOG_FIELDS,
    SET_ERROR,

    SET_ENERGY_ASSIGNMENT_FOREIGNS,
    ENERGY_SUPPLIER_ID_CHANGE,
    MARKETS_ID_CHANGE,
    BALANCING_GROUPS_ID_CHANGE

} from '../../types';

export default(state, action) => {

    switch(action.type)
    {
        //  General section

        case GET_ENTITIES:
            return {
                ...state,
                entities: action.payload
            }

        case SET_ACTIVE_ENTITY:
            return {
                ...state,
                editDialogEnergySupplierId: action.payload.energy_supplier_id,
                editDialogMarketsId: action.payload.markets_id,
                editDialogBalancingGroupsId: action.payload.balancing_groups_id
            }

        case SET_DELETE_ENTITY:
            return {
                ...state,
                activeEntity: action.payload.id,
                deleteModalState: action.payload.deleteModalState
            }

        case SET_DELETE_MODAL_ENTITY_NAME:
            return {
                ...state,
                deleteModalEntityName: action.payload
            }

        case SET_EDIT_DIALOG_TITLE:
            return {
                ...state,
                editDialogTitle: action.payload
            }

        case SET_EDIT_ENTITY:
            return {
                ...state,
                activeEntity: action.payload.id,
                editState: action.payload.editState
            }

        case SET_ENTITY_DIALOG_FIELDS:
            return {
                ...state,
                editDialogFields: action.payload
            }

        case SET_ERROR:
            return {
                ...state,
                editDialogError: action.payload
            }


        //  Individual Section

        case SET_ENERGY_ASSIGNMENT_FOREIGNS:
            return {
                ...state,
                energySuppliers: action.payload.energySuppliers,
                markets: action.payload.markets,
                balancingGroups: action.payload.balancingGroups
            }

        case ENERGY_SUPPLIER_ID_CHANGE:
            return {
                ...state,
                editDialogEnergySupplierId: action.payload
            }
            
        case MARKETS_ID_CHANGE:
            return {
                ...state,
                editDialogMarketsId: action.payload
            }

        case BALANCING_GROUPS_ID_CHANGE:
            return {
                ...state,
                editDialogBalancingGroupsId: action.payload
            }

            
        //  Default Path
        default:
            return state;
    }
}