/**
 *      File:       ./src/context/administration/energy/SuppliersState.js
 * 
 *      Subject:    Suppliers' State
 */
import React, { useReducer, useEffect } from 'react';
import axios                            from 'axios';

import SuppliersContext from './suppliersContext';
import SuppliersReducer from './suppliersReducer';

import FormInput    from '../../../Components/FormInput';
import FormSelect   from '../../../Components/FormSelect';

import {

    GET_ENTITIES,
    SET_ACTIVE_ENTITY,
    SET_DELETE_ENTITY,
    SET_DELETE_MODAL_ENTITY_NAME,
    SET_EDIT_DIALOG_TITLE,
    SET_EDIT_ENTITY,
    SET_ENTITY_DIALOG_FIELDS,
    SET_ERROR,

    SET_SUPPLIER_FOREIGNS,
    SUPPLIER_SHORTCUT_CHANGE,
    SUPPLIER_NAME_CHANGE,
    NAME_ADDITION_1_CHANGE,
    NAME_ADDITION_2_CHANGE,
    DIVISIONS_ID_CHANGE,
    STREET_CHANGE,
    POSTAL_CODE_CHANGE,
    CITY_CHANGE

} from '../../types';

const SuppliersState = props => {

    const initialState = {

        entities:               [],
        activeEntity:           0,
        deleteModalEntityName:  '',
        deleteModalState:       false,
        deleteModalTitle:       'Lieferant löschen',
        entityDeleteDialog:     false,
        entityDeleteEntityName: '',
        editDialogTitle:        'Neuen Lieferanten anlegen',
        editDialogError:        '',
        editDialogFields:       [],
        editState:              false,
        editDialogSupplierShortcut: '',
        editDialogSupplierName: '',
        editDialogNameAddition1: '',
        editDialogNameAddition2: '',
        editDialogDivisionsId: -1,
        divisions: [],
        editDialogStreet: '',
        editDialogPostalCode: '',
        editDialogCity: ''

    }
    const [state,dispatch] = useReducer(SuppliersReducer, initialState);

    useEffect(() => {

        async function fetchData() {
            
            const params = new URLSearchParams();
            params.append('jwt', sessionStorage.jwt);

            let axiosConfig = {
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded"
                }
            };

            let url = process.env.REACT_APP_API_URI + '/administration/energy/get_network_foreigns.php';

            axios.post(url,params,axiosConfig)
            .then(response => {
                if(response.status === 200) {
                    // console.log(response.data);
                    
                    dispatch({
                        type: SET_SUPPLIER_FOREIGNS,
                        payload: {
                            divisions: response.data.divisions,
                        }
                    });

                } else if(response.status === 401) {
                    window.location.href ='#/logout';
                } else {
                    console.log('Anderer Fehler');
                }
            })
            .catch(function(error) {
                if(error.response){
                    // The server answered with an error
                    if(error.response.status === 401) {
                        window.location.href = '#/logout';
                    } else {
                        console.log(error.response.data);
                        console.log(error.response.status);
                        console.log(error.response.headers);
                    }
                } else if(error.request) {
                    //  The request was made but the server didn't respond
                    console.log(error.request);
                } else {
                    //  Something else went wrong
                    console.log('Error', error.message);
                }
            })
        }
        fetchData();

        const edf = [
            {
                id: 1,
                fieldType: <FormInput
                    context             = {SuppliersContext}
                    id                  = "supplier_shortcut"
                    label               = "Kurzbezeichnung"
                    onChangeFunction    = {supplierShortcutChange}
                    placeholder         = "Kurzbezeichnung"
                    type                = "text"
                    value               = "editDialogSupplierShortcut"
                />
            },
            {
                id: 2,
                fieldType: <FormInput
                    context             = {SuppliersContext}
                    id                  = "supplier_name"
                    label               = "Lieferant"
                    onChangeFunction    = {supplierNameChange}
                    placeholder         = "Lieferant"
                    required
                    type                = "text"
                    value               = "editDialogSupplierName"
                />
            },
            {
                id: 3,
                fieldType: <FormInput
                    context             = {SuppliersContext}
                    id                  = "name_addition_1"
                    label               = "1. Adresszusatz"
                    onChangeFunction    = {nameAddition1Change}
                    placeholder         = "1. Adresszusatz"
                    type                = "text"
                    value               = "editDialogNameAddition1"
                />
            },
            {
                id: 4,
                fieldType: <FormInput
                    context             = {SuppliersContext}
                    id                  = "name_addition_2"
                    label               = "2. Adresszusatz"
                    onChangeFunction    = {nameAddition2Change}
                    placeholder         = "2. Adresszusatz"
                    type                = "text"
                    value               = "editDialogNameAddition2"
                />
            },
            {
                id: 5,
                fieldType: <FormSelect
                    context             = {SuppliersContext}
                    id                  = "divisions_id"
                    label               = "Sparte"
                    onChangeFunction    = {divisionsIdChange}
                    placeholder         = "Sparte"
                    selectOptions       = "divisions"
                    value               = "editDialogDivisionsId"
                />
            },
            {
                id: 6,
                fieldType: <FormInput
                    context             = {SuppliersContext}
                    id                  = "street"
                    label               = "Straße"
                    onChangeFunction    = {streetChange}
                    placeholder         = "Straße"
                    type                = "text"
                    value               = "editDialogStreet"
                />
            },
            {
                id: 7,
                fieldType: <FormInput
                    context             = {SuppliersContext}
                    id                  = "postal_code"
                    label               = "Postleitzahl"
                    onChangeFunction    = {postalCodeChange}
                    placeholder         = "Postleitzahl"
                    type                = "text"
                    value               = "editDialogPostalCode"
                />
            },
            {
                id: 8,
                fieldType: <FormInput
                    context             = {SuppliersContext}
                    id                  = "city"
                    label               = "Ort"
                    onChangeFunction    = {cityChange}
                    placeholder         = "Ort"
                    type                = "text"
                    value               = "editDialogCity"
                />
            }
        ];

        dispatch({
            type: SET_ENTITY_DIALOG_FIELDS,
            payload: edf
        });

        getEntities();

        // eslint-disable-next-line
    }, [state.editDialogError]);


    const getEntities = () => {

        const params = new URLSearchParams();
        params.append('jwt', sessionStorage.jwt);

        let axiosConfig = {
            headers: {
                "Content-Type": "application/x-www-form-urlencoded"
            }
        };

        let url = process.env.REACT_APP_API_URI + '/administration/energy/get_suppliers.php';

        axios.post(url,params,axiosConfig)
        .then(response => {
            if(response.status === 200) {

				// console.log(response.data);
				
				const entities = response.data.entities;
				for(var i in entities) {
					entities[i].postal_code = (isNaN(entities[i].postal_code) || entities[i].postal_code === "") ? 0 : Number.parseInt(entities[i].postal_code);
				}
                
                dispatch({
                    type: GET_ENTITIES,
                    payload: entities
                });

            } else if(response.status === 401) {
                window.location.href ='#/logout';
            } else {
                console.log('Anderer Fehler');
            }
        })
        .catch(function(error) {
            if(error.response){
                // The server answered with an error
                if(error.response.status === 401) {
                    window.location.href = '#/logout';
                } else {
                    console.log(error.response.data);
                    console.log(error.response.status);
                    console.log(error.response.headers);
                }
            } else if(error.request) {
                //  The request was made but the server didn't respond
                console.log(error.request);
            } else {
                //  Something else went wrong
                console.log('Error', error.message);
            }
        })
    }

    const editEntity = (id) => {

        if(id > 0)
        {
            const params = new URLSearchParams();
            params.append('jwt', sessionStorage.jwt);
            params.append('id', id);

            let axiosConfig = {
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded"
                }
            };

            let url = process.env.REACT_APP_API_URI + '/administration/energy/get_supplier.php';

            axios.post(url,params,axiosConfig)
            .then(response => {
                if(response.status === 200) {
                    
                    // console.log(response.data);
                    
                    dispatch({
                        type: SET_ACTIVE_ENTITY,
                        payload: response.data.supplier
                    });

                    dispatch({
                        type: SET_EDIT_ENTITY,
                        payload: {
                            id: id,
                            editState: true
                        }
                    });

                    dispatch({
                        type: SET_EDIT_DIALOG_TITLE,
                        payload: 'Lieferant bearbeiten'
                    });

                } else if(response.status === 401) {
                    window.location.href ='#/logout';
                } else {
                    console.log('Anderer Fehler');
                }
            })
            .catch(function(error) {
                if(error.response){
                    // The server answered with an error
                    if(error.response.status === 401) {
                        window.location.href = '#/logout';
                    } else {
                        console.log(error.response.data);
                        console.log(error.response.status);
                        console.log(error.response.headers);
                    }
                } else if(error.request) {
                    //  The request was made but the server didn't respond
                    console.log(error.request);
                } else {
                    //  Something else went wrong
                    console.log('Error', error.message);
                }
            })
        } else {
            dispatch({
                type: SET_EDIT_DIALOG_TITLE,
                payload: 'Neuen Lieferanten anlegen'
            });
        }

        dispatch({
            type: SET_EDIT_ENTITY,
            payload: {
                id: id,
                editState: true
            }
        });        
    }

    const setError = (errMsg) => {
        dispatch({
            type: SET_ERROR,
            payload: errMsg
        });
    }

    const saveEntity = async () => {

        if(state.editDialogSupplierName === '') {
            setError('Bitte geben Sie eine Bezeichnung für den Lieferanten ein.');
            return false;
        }

        const params = new URLSearchParams();
        params.append('jwt', sessionStorage.jwt);
        params.append('id', state.activeEntity);
        params.append('supplierShortcut', state.editDialogSupplierShortcut);
        params.append('supplierName', state.editDialogSupplierName);
        params.append('nameAddition1', state.editDialogNameAddition1);
        params.append('nameAddition2', state.editDialogNameAddition2);
        params.append('divisionsId', state.editDialogDivisionsId);
        params.append('street', state.editDialogStreet);
        // params.append('houseNumber', state.editDialogHouseNumber);
        params.append('postalCode', state.editDialogPostalCode);
        params.append('city', state.editDialogCity);

        let axiosConfig = {
            headers: {
                "Content-Type": "application/x-www-form-urlencoded"
            }
        };

        let url = process.env.REACT_APP_API_URI + '/administration/energy/set_supplier.php';

        axios.post(url,params,axiosConfig)
        .then(response => {
            if(response.status === 200) {

                // console.log(response.data);

                const supplier = {
                    supplier_shortcut: '',
                    supplier_name: '',
                    name_addition_1: '',
                    name_addition_2: '',
                    divisions_id: -1,
                    street: '',
                    // house_number: '',
                    postal_code: '',
                    city: ''
                };
                getEntities();

                dispatch({
                    type: SET_ACTIVE_ENTITY,
                    payload: supplier
                });
                dispatch({
                    type: SET_EDIT_ENTITY,
                    payload: {
                        id: 0,
                        editState: false
                    }
                });
                return true;

            } else if(response.status === 401) {
                window.location.href ='#/logout';
            } else {
                console.log('Anderer Fehler');
                return false;
            }
        })
        .catch(function(error) {
            if(error.response){
                // The server answered with an error
                if(error.response.status === 401) {
                    window.location.href = '#/logout';
                } else {
                    console.log(error.response.data);
                    console.log(error.response.status);
                    console.log(error.response.headers);
                    return false;
                }
            } else if(error.request) {
                //  The request was made but the server didn't respond
                console.log(error.request);
                return false;
            } else {
                //  Something else went wrong
                console.log('Error', error.message);
                return false;
            }
        })
        return true;
    }

    const closeEdit = () => {
        dispatch({
            type: SET_ACTIVE_ENTITY,
            payload: {
                supplier_shortcut: '',
                supplier_name: '',
                name_addition_1: '',
                name_addition_2: '',
                divisions_id: -1,
                street: '',
                // house_number: '',
                postal_code: '',
                city: ''
            }
        });
        
        dispatch({
            type: SET_EDIT_ENTITY,
            payload: {
                id: 0,
                editState: false
            }
        });
    }

    const deleteEntity = (id) => {
        
        const params = new URLSearchParams();
        params.append('jwt', sessionStorage.jwt);
        params.append('id', id);

        let axiosConfig = {
            headers: {
                "Content-Type": "application/x-www-form-urlencoded"
            }
        };

        let url = process.env.REACT_APP_API_URI + '/administration/energy/get_supplier.php';

        axios.post(url,params,axiosConfig)
        .then(response => {
            if(response.status === 200) {

                // console.log(response.data);
                
                dispatch({
                    type: SET_ACTIVE_ENTITY,
                    payload: response.data.supplier
                });

                dispatch({
                    type: SET_DELETE_ENTITY,
                    payload: {
                        id: id,
                        deleteModalState: true
                    }
                });

                dispatch({
                    type: SET_DELETE_MODAL_ENTITY_NAME,
                    payload: 'Möchten Sie den Lieferant "' + response.data.supplier.supplier_name + '" wirklich löschen?'
                });

            } else if(response.status === 401) {
                window.location.href ='#/logout';
            } else {
                console.log('Anderer Fehler');
            }
        })
        .catch(function(error) {
            if(error.response){
                // The server answered with an error
                if(error.response.status === 401) {
                    window.location.href = '#/logout';
                } else {
                    console.log(error.response.data);
                    console.log(error.response.status);
                    console.log(error.response.headers);
                }
            } else if(error.request) {
                //  The request was made but the server didn't respond
                console.log(error.request);
            } else {
                //  Something else went wrong
                console.log('Error', error.message);
            }
        })
    }

    const closeDelete = () => {

        dispatch({
            type: SET_ACTIVE_ENTITY,
            payload: {
                supplier_shortcut: '',
                supplier_name: '',
                name_addition_1: '',
                name_addition_2: '',
                divisions_id: -1,
                street: '',
                // house_number: '',
                postal_code: '',
                city: ''
            }
        });
        
        dispatch({
            type: SET_EDIT_ENTITY,
            payload: {
                id: 0,
                editState: false
            }
        });

        dispatch({
            type: SET_DELETE_ENTITY,
            payload: {
                id: 0,
                deleteModalState: false
            }
        });

        dispatch({
            type: SET_DELETE_MODAL_ENTITY_NAME,
            payload: ''
        });
    }

    const deleteEntityConfirmed = () => {

        const params = new URLSearchParams();
        params.append('jwt', sessionStorage.jwt);
        params.append('id', state.activeEntity);

        let axiosConfig = {
            headers: {
                "Content-Type": "application/x-www-form-urlencoded"
            }
        };

        let url = process.env.REACT_APP_API_URI + '/administration/energy/delete_supplier.php';

        axios.post(url,params,axiosConfig)
        .then(response => {
            if(response.status === 200) {
                // console.log(response.data);
                
                getEntities();
                closeDelete();

            } else if(response.status === 401) {
                window.location.href ='#/logout';
            } else {
                console.log('Anderer Fehler');
            }
        })
        .catch(function(error) {
            if(error.response){
                // The server answered with an error
                if(error.response.status === 401) {
                    window.location.href = '#/logout';
                } else {
                    console.log(error.response.data);
                    console.log(error.response.status);
                    console.log(error.response.headers);
                }
            } else if(error.request) {
                //  The request was made but the server didn't respond
                console.log(error.request);
            } else {
                //  Something else went wrong
                console.log('Error', error.message);
            }
        })
    }

    /*-------------------------------------
    //  PAGE SPECIFIC
    -------------------------------------*/

    const supplierShortcutChange = (e) => {
        dispatch({
            type: SUPPLIER_SHORTCUT_CHANGE,
            payload: e.target.value
        });
    }

    const supplierNameChange = (e) => {
        if(state.editDialogSupplierName === '' && state.editDialogError !== '') {
            setError('');
        }

        dispatch({
            type: SUPPLIER_NAME_CHANGE,
            payload: e.target.value
        });
    }

    const nameAddition1Change = (e) => {
        dispatch({
            type: NAME_ADDITION_1_CHANGE,
            payload: e.target.value
        });
    }

    const nameAddition2Change = (e) => {
        dispatch({
            type: NAME_ADDITION_2_CHANGE,
            payload: e.target.value
        });
    }

    const divisionsIdChange = (e) => {
        dispatch({
            type: DIVISIONS_ID_CHANGE,
            payload: e.target.value,
        });
    }

    const streetChange = (e) => {
        dispatch({
            type: STREET_CHANGE,
            payload: e.target.value
        });
    }

    // const houseNumberChange = (e) => {
    //     dispatch({
    //         type: HOUSE_NUMBER_CHANGE,
    //         payload: e.target.value
    //     });
    // }

    const postalCodeChange = (e) => {
        dispatch({
            type: POSTAL_CODE_CHANGE,
            payload: e.target.value
        });
    }

    const cityChange = (e) => {
        dispatch({
            type: CITY_CHANGE,
            payload: e.target.value
        });
    }

    return <SuppliersContext.Provider value={{

        entities: state.entities,
        editDialogFields: state.editDialogFields,
        editDialogError: state.editDialogError,
        activeEntity: state.activeEntity,
        editState: state.editState,
        userRoleLabel: state.userRoleLabel,
        editDialogTitle: state.editDialogTitle,
        deleteModalTitle: state.deleteModalTitle,
        deleteModalEntityName: state.deleteModalEntityName,
        deleteModalState: state.deleteModalState,

        editDialogSupplierShortcut: state.editDialogSupplierShortcut,
        editDialogSupplierName: state.editDialogSupplierName,
        editDialogNameAddition1: state.editDialogNameAddition1,
        editDialogNameAddition2: state.editDialogNameAddition2,
        editDialogDivisionsId: state.editDialogDivisionsId,
        divisions: state.divisions,
        editDialogStreet: state.editDialogStreet,
        // editDialogHouseNumber: state.editDialogHouseNumber,
        editDialogPostalCode: state.editDialogPostalCode,
        editDialogCity: state.editDialogCity,

        editEntity,
        setError,
        saveEntity,
        getEntities,
        closeEdit,
        deleteEntity,
        closeDelete,
        deleteEntityConfirmed,

        supplierShortcutChange,
        supplierNameChange,
        nameAddition1Change,
        nameAddition2Change,
        divisionsIdChange,
        streetChange,
        // houseNumberChange,
        postalCodeChange,
        cityChange

}}>
        {props.children}
    </SuppliersContext.Provider>
}

export default SuppliersState;