/**
 *      File:       ./src/context/administration/energy/marketsContext.js
 * 
 *      Subject:    Markets' Context
 */

import { createContext } from 'react';

const marketsContext = createContext();

export default marketsContext;