/**
 *      File:       ./src/context/administration/energy/energySupplierReducer.js
 * 
 *      Subject:    Energy Suppliers' State Reducer
 */
import {
    GET_ENTITIES,
    SET_ACTIVE_ENTITY,
    SET_DELETE_ENTITY,
    SET_DELETE_MODAL_ENTITY_NAME,
    SET_EDIT_DIALOG_TITLE,
    SET_EDIT_ENTITY,
    SET_ENTITY_DIALOG_FIELDS,
    SET_ERROR,

    SET_ENERGY_SUPPLIER_FOREIGNS,
    SUPPLIER_NAME_CHANGE,
    BALANCING_GROUPS_ID_GAS_CHANGE,
    BALANCING_GROUPS_ID_POWER_CHANGE

} from '../../types';

export default(state, action) => {

    switch(action.type)
    {
        //  General section

        case GET_ENTITIES:
            return {
                ...state,
                entities: action.payload
            }

        case SET_ACTIVE_ENTITY:
            return {
                ...state,
                editDialogSupplierName: action.payload.energy_supplier_name,
                editDialogBalancingGroupsIdGas: action.payload.balancing_groups_id_gas,
                editDialogBalancingGroupsIdPower: action.payload.balancing_groups_id_power
            }

        case SET_DELETE_ENTITY:
            return {
                ...state,
                activeEntity: action.payload.id,
                deleteModalState: action.payload.deleteModalState
            }

        case SET_DELETE_MODAL_ENTITY_NAME:
            return {
                ...state,
                deleteModalEntityName: action.payload
            }

        case SET_EDIT_DIALOG_TITLE:
            return {
                ...state,
                editDialogTitle: action.payload
            }

        case SET_EDIT_ENTITY:
            return {
                ...state,
                activeEntity: action.payload.id,
                editState: action.payload.editState
            }

        case SET_ENTITY_DIALOG_FIELDS:
            return {
                ...state,
                editDialogFields: action.payload
            }

        case SET_ERROR:
            return {
                ...state,
                editDialogError: action.payload
            }


        //  Individual Section

        case SET_ENERGY_SUPPLIER_FOREIGNS:
            return {
                ...state,
                balancingGroups: action.payload.balancingGroups
            }

        case SUPPLIER_NAME_CHANGE:
            return {
                ...state,
                editDialogSupplierName: action.payload
            }

        case BALANCING_GROUPS_ID_GAS_CHANGE:
            return {
                ...state,
                editDialogBalancingGroupsIdGas: action.payload
            }

        case BALANCING_GROUPS_ID_POWER_CHANGE:
            return {
                ...state,
                editDialogBalancingGroupsIdPower: action.payload
            }

            
        //  Default Path
        default:
            return state;
    }
}