/**
 *      File:       ./src/context/administration/system/UsersState.js
 * 
 *      Subject:    Users' State
 */
import React, { useReducer } from 'react';
import axios from 'axios';
import UsersContext from './usersContext';
import UsersReducer from './usersReducer';

import {

	CUSTOMER_PORTAL_ID_CHANGE,
    EDIT_ENTITY,
    EMAIL_CHANGE,
    FIRSTNAME_CHANGE,
    GENDERS_ID_CHANGE,
    GET_ENTITIES,
    IS_SUPPORTER_CHANGE,
	IS_SUPPORTER_PV_CHANGE,
    LASTNAME_CHANGE,
    PW1_CHANGE,
    PW2_CHANGE,
    PW_CHANGE_DIALOG,
    SET_ACTIVE_ENTITY,
    SET_DELETE_ENTITY,
    SET_DELETE_MODAL_ENTITY_NAME,
    SET_ERROR,
    SET_PW_ERROR,
    SET_USER_FOREIGNS,
    USER_ROLES_ID_CHANGE

} from '../../types';

const UsersState = props => {

    const initialState = {

        activePwUser: 0,
        activeUser: 0,
        deleteActiveUser: 0,
        deleteUserConfirmName: '',
        deleteUserConfirmState: false,
		editDialogCustomerPortalId: '',
        editDialogEmail: '',
        editDialogFirstname: '',
        editDialogGendersId: -1,
        editDialogIsSupporter: false,
		editDialogIsSupporterPv: false,
        editDialogLastname: '',
		editDialogUserRolesId: -1,
        entities: [],
        genders: [],
        pw1: '',
        pw2: '',
        pwChangeDialogError: '',
        pwChangeDialogState: false,
        userDetailDialogError: '',
        userDetailDialogState: false,
        userDetailDialogTitle: '',
        userRoles: [],

    }
    const [state,dispatch] = useReducer(UsersReducer, initialState);

    const getEntities = () => {

        const params = new URLSearchParams();
        params.append('jwt', sessionStorage.jwt);
		
		let url = process.env.REACT_APP_API_URI + '/administration/system/get_users.php';
        let axiosConfig = {
            headers: {
                "Content-Type": "application/x-www-form-urlencoded"
            }
        };

        axios.post(url,params,axiosConfig)
        .then(response => {
            if(response.status === 200) {

				const entities = response.data.entities;
				for(var i in entities) {
					entities[i]['customer_portal_id'] = (isNaN(entities[i]['customer_portal_id']) || entities[i]['customer_portal_id'] === '') ? 0 : entities[i]['customer_portal_id'];
				}
                
                dispatch({
                    type: GET_ENTITIES,
                    payload: response.data.entities
                });

            } else if(response.status === 401) {
                window.location.href ='#/logout';
            } else {
                console.log('Anderer Fehler');
            }
        })
        .catch(function(error) {
            if(error.response){
                // The server answered with an error
                if(error.response.status === 401) {
                    window.location.href = '#/logout';
                } else {
                    console.log(error.response.data);
                    console.log(error.response.status);
                    console.log(error.response.headers);
                }
            } else if(error.request) {
                //  The request was made but the server didn't respond
                console.log(error.request);
            } else {
                //  Something else went wrong
                console.log('Error', error.message);
            }
        })
    }

    const editEntity = (id) => {

        let title = "Neuen Benutzer anlegen";

        if(id > 0) {

            title = "Benutzer bearbeiten";
            const params = new URLSearchParams();
            params.append('jwt', sessionStorage.jwt);
            params.append('id', id);

            let axiosConfig = {
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded"
                }
            };

            let url = process.env.REACT_APP_API_URI + '/administration/system/get_user.php';

            axios.post(url,params,axiosConfig)
            .then(response => {
                if(response.status === 200) {
                    // console.log(response.data);
                    
                    dispatch({
                        type: SET_ACTIVE_ENTITY,
                        payload: response.data.user
                    });

                    dispatch({
                        type: EDIT_ENTITY,
                        payload: {
                            id: id,
                            title: title,
                            editState: true
                        }
                    });

                } else if(response.status === 401) {
                    window.location.href ='#/logout';
                } else {
                    console.log('Anderer Fehler');
                }
            })
            .catch(function(error) {
                if(error.response){
                    // The server answered with an error
                    if(error.response.status === 401) {
                        window.location.href = '#/logout';
                    } else {
                        console.log(error.response.data);
                        console.log(error.response.status);
                        console.log(error.response.headers);
                    }
                } else if(error.request) {
                    //  The request was made but the server didn't respond
                    console.log(error.request);
                } else {
                    //  Something else went wrong
                    console.log('Error', error.message);
                }
            })
        }

        dispatch({
            type: EDIT_ENTITY,
            payload: {
                id: id,
                title: title,
                editState: true,
            }
        });
    }

    const setError = (errMsg) => {
        dispatch({
            type: SET_ERROR,
            payload: errMsg
        });
    }

    const saveUserDetailDialog = async () => {

        if(state.editDialogGendersId <= 0) {
            setError('Bitte wählen Sie eine Anrede für den Benutzer aus.');
            return false;
        }

        if(state.editDialogFirstname === '') {
            setError('Bitte geben Sie einen Vornamen für den Benutzer an.');
            return false;
        }

        if(state.editDialogLastname === '') {
            setError('Bitte geben Sie einen Nachnamen für den Benutzer an.');
            return false;
        }

        if(state.editDialogEmail === '') {
            setError('Bitte geben Sie eine E-Mail-Adresse für den Benutzer an.');
            return false;
        }

        if(state.editDialogUserRolesId <= 0) {
            setError('Bitte ordnen Sie dem Benutzer eine Benutzerrolle zu.');
            return false;
        }

        const params = new URLSearchParams();
        params.append('jwt', sessionStorage.jwt);
        params.append('id', state.activeUser);
        params.append('gendersId', state.editDialogGendersId);
        params.append('firstname', state.editDialogFirstname);
        params.append('lastname', state.editDialogLastname);
        params.append('email', state.editDialogEmail);
        params.append('userRolesId', state.editDialogUserRolesId);
		params.append('isSupporter', state.editDialogIsSupporter);
		params.append('isSupporterPv', state.editDialogIsSupporterPv);
		params.append('customerPortalId', state.editDialogCustomerPortalId);

        let axiosConfig = {
            headers: {
                "Content-Type": "application/x-www-form-urlencoded"
            }
        };

        let url = process.env.REACT_APP_API_URI + '/administration/system/set_user.php';

        axios.post(url,params,axiosConfig)
        .then(response => {
            if(response.status === 200) {

                // console.log(response.data);

                const user = {
                    gendersId: -1,
                    firstname: '',
                    lastname: '',
                    email: '',
                    userRolesId: -1,
					isSupporter: false,
					isSupporterPv: false,
					customerPortalId: '',
                };
                getEntities();

                dispatch({
                    type: SET_ACTIVE_ENTITY,
                    payload: user
                });
                dispatch({
                    type: EDIT_ENTITY,
                    payload: {
                        id: 0,
                        title: '',
                        editState: false
                    }
                });
                return true;

            } else if(response.status === 401) {
                window.location.href ='#/logout';
            } else {
                console.log('Anderer Fehler');
                return false;
            }
        })
        .catch(function(error) {
            if(error.response){
                // The server answered with an error
                if(error.response.status === 401) {
                    window.location.href = '#/logout';
                } else {
                    console.log(error.response.data);
                    console.log(error.response.status);
                    console.log(error.response.headers);
                    return false;
                }
            } else if(error.request) {
                //  The request was made but the server didn't respond
                console.log(error.request);
                return false;
            } else {
                //  Something else went wrong
                console.log('Error', error.message);
                return false;
            }
        })
        return true;
    }

    const closeUserDetailDialog = () => {

        dispatch({
            type: SET_ACTIVE_ENTITY,
            payload: {
                gendersId: -1,
                firstname: '',
                lastname: '',
                email: '',
                userRolesId: -1,
				isSupporter: false,
				isSupporterPv: false,
				customerPortalId: '',
            }
        });
        
        dispatch({
            type: EDIT_ENTITY,
            payload: {
                id: 0,
                editState: false
            }
        });
    }

    const deleteUserConfirm = (id) => {
        
        const params = new URLSearchParams();
        params.append('jwt', sessionStorage.jwt);
        params.append('id', id);

        let axiosConfig = {
            headers: {
                "Content-Type": "application/x-www-form-urlencoded"
            }
        };

        let url = process.env.REACT_APP_API_URI + '/administration/system/get_user.php';

        axios.post(url,params,axiosConfig)
        .then(response => {
            if(response.status === 200) {
                // console.log(response.data);
                
                dispatch({
                    type: SET_ACTIVE_ENTITY,
                    payload: response.data.user
                });

                dispatch({
                    type: SET_DELETE_ENTITY,
                    payload: {
                        id: id,
                        deleteModalState: true
                    }
                });

                dispatch({
                    type: SET_DELETE_MODAL_ENTITY_NAME,
                    payload: 'Möchten Sie den Benutzer "' + response.data.user.firstname + ' ' + response.data.user.lastname + '" wirklich löschen?'
                });

            } else if(response.status === 401) {
                window.location.href ='#/logout';
            } else {
                console.log('Anderer Fehler');
            }
        })
        .catch(function(error) {
            if(error.response){
                // The server answered with an error
                if(error.response.status === 401) {
                    window.location.href = '#/logout';
                } else {
                    console.log(error.response.data);
                    console.log(error.response.status);
                    console.log(error.response.headers);
                }
            } else if(error.request) {
                //  The request was made but the server didn't respond
                console.log(error.request);
            } else {
                //  Something else went wrong
                console.log('Error', error.message);
            }
        })
    }

    const closeUserConfirm = () => {

        dispatch({
            type: SET_ACTIVE_ENTITY,
            payload: {
                gendersId: -1,
                firstname: '',
                lastname: '',
                email: '',
                userRolesId: -1,
				isSupporter: false,
				isSupporterPv: false,
				customerPortalId: '',
            }
        });
        
        dispatch({
            type: EDIT_ENTITY,
            payload: {
                id: 0,
                title: '',
                editState: false
            }
        });

        dispatch({
            type: SET_DELETE_ENTITY,
            payload: {
                id: 0,
                deleteModalState: false
            }
        });

        dispatch({
            type: SET_DELETE_MODAL_ENTITY_NAME,
            payload: ''
        });
    }

    const deleteUser = () => {

        const params = new URLSearchParams();
        params.append('jwt', sessionStorage.jwt);
        params.append('id', state.deleteActiveUser);

        let axiosConfig = {
            headers: {
                "Content-Type": "application/x-www-form-urlencoded"
            }
        };

        let url = process.env.REACT_APP_API_URI + '/administration/system/delete_user.php';

        axios.post(url,params,axiosConfig)
        .then(response => {
            if(response.status === 200) {
                // console.log(response.data);
                
                getEntities();
                closeUserConfirm();

            } else if(response.status === 401) {
                window.location.href ='#/logout';
            } else {
                console.log('Anderer Fehler');
            }
        })
        .catch(function(error) {
            if(error.response){
                // The server answered with an error
                if(error.response.status === 401) {
                    window.location.href = '#/logout';
                } else {
                    console.log(error.response.data);
                    console.log(error.response.status);
                    console.log(error.response.headers);
                }
            } else if(error.request) {
                //  The request was made but the server didn't respond
                console.log(error.request);
            } else {
                //  Something else went wrong
                console.log('Error', error.message);
            }
        })
    }

    const setForeigns = (genders, userRoles) => {

        dispatch({
            type: SET_USER_FOREIGNS,
            payload: {
                genders: genders,
                userRoles: userRoles
            }
        });
    }

    /*-------------------------------------
    //  PAGE SPECIFIC
    -------------------------------------*/

    const gendersIdChange = (e) => {

        if(state.editDialogError !== '' && state.editDialogGendersId === -1) {
            setError('');
        }

        dispatch({
            type: GENDERS_ID_CHANGE,
            payload: e.target.value
        });
	}
	
	const customerPortalIdChange = (e) => {
		dispatch({
			type: CUSTOMER_PORTAL_ID_CHANGE,
			payload: e.target.value,
		});
	}

    const firstnameChange = (e) => {

        if(state.editDialogError !== '' && state.editDialogFirstname === '') {
            setError('');
        }

        dispatch({
            type: FIRSTNAME_CHANGE,
            payload: e.target.value
        });
    }

    const lastnameChange = (e) => {

        if(state.editDialogError !== '' && state.editDialogLastname === '') {
            setError('');
        }

        dispatch({
            type: LASTNAME_CHANGE,
            payload: e.target.value
        });
    }

    const emailChange = (e) => {

        if(state.editDialogError !== '' && state.editDialogEmail === '') {
            setError('');
        }

        dispatch({
            type: EMAIL_CHANGE,
            payload: e.target.value
        });
    }

    const userRolesIdChange = (e) => {

        if(state.editDialogError !== '' && state.editDialogUserRolesId === -1) {
            setError('');
        }

        dispatch({
            type: USER_ROLES_ID_CHANGE,
            payload: e.target.value
        });
    }

    const isSupporterChange = (e) => {
        dispatch({
            type: IS_SUPPORTER_CHANGE,
            payload: e.target.checked
        });
    }

	const isSupporterPvChange = (e) => {
		dispatch({
			type: IS_SUPPORTER_PV_CHANGE,
			payload: e.target.checked
		});
	}

    const changePassword = (id) => {
        dispatch({
            type: PW_CHANGE_DIALOG,
            payload: {
                pwChangeDialogState: true,
                id: id
            }
        });
    }

    const changePasswordConfirm = () => {
        //  Check for required fields
        if (state.pw1 === "")
            return setPwError("Bitte geben Sie ein neues Passwort ein.");

        if (state.pw1 === "")
            return setPwError("Bitte bestätigen Sie das neue Passwort.");

        if (state.pw1 !== state.pw2)
            return setPwError(
                "Das neue Passwort und die Passwort-Bestätigung stimmen nicht überein. Bitte prüfen Sie die Eingaben."
            );

        //  Data are valid and can be saved
        const params = new URLSearchParams();
        params.append("jwt", sessionStorage.jwt);

        params.append("id", state.activePwUser);
        params.append("pwNew", state.pw1);

        let axiosConfig = {
            headers: {
                "Content-Type": "application/x-www-form-urlencoded"
            }
        };

        let url = process.env.REACT_APP_API_URI + '/administration/system/change_password.php';

        axios.post(url,params,axiosConfig)
        .then(response => {
            if(response.status === 200) {
                // console.log(response.data);
                
                closePwChangeDialog();

            } else if(response.status === 401) {
                window.location.href ='#/logout';
            } else {
                console.log('Anderer Fehler');
            }
        })
        .catch(function(error) {
            if(error.response){
                // The server answered with an error
                if(error.response.status === 401) {
                    window.location.href = '#/logout';
                } else {
                    console.log(error.response.data);
                    console.log(error.response.status);
                    console.log(error.response.headers);
                }
            } else if(error.request) {
                //  The request was made but the server didn't respond
                console.log(error.request);
            } else {
                //  Something else went wrong
                console.log('Error', error.message);
            }
        })
    }

    const closePwChangeDialog = () => {
        dispatch({
            type: PW_CHANGE_DIALOG,
            payload: {
                pwChangeDialogState: false,    
                id: 0,            
            }
        });
    }

    const pw1Change = (e) => {
        dispatch({
            type: PW1_CHANGE,
            payload: e.target.value,
        });
    }

    const pw2Change = (e) => {
        dispatch({
            type: PW2_CHANGE,
            payload: e.target.value,
        });
    }

    const setPwError = (errMsg) => {
        dispatch({
            type: SET_PW_ERROR,
            payload: errMsg
        });
    }


    return <UsersContext.Provider value={{

        activePwUser: state.activePwUser,
        activeUser: state.activeUser,
        deleteActiveUser: state.deleteActiveUser,
        deleteUserConfirmName: state.deleteUserConfirmName,
		deleteUserConfirmState: state.deleteUserConfirmState,
		editDialogCustomerPortalId: state.editDialogCustomerPortalId,
        editDialogEmail: state.editDialogEmail,
        editDialogFirstname: state.editDialogFirstname,
        editDialogGendersId: state.editDialogGendersId,
        editDialogIsSupporter: state.editDialogIsSupporter,
		editDialogIsSupporterPv: state.editDialogIsSupporterPv,
        editDialogLastname: state.editDialogLastname,
        editDialogUserRolesId: state.editDialogUserRolesId,
        entities: state.entities,
        genders: state.genders,
        pw1: state.pw1,
        pw2: state.pw2,
        pwChangeDialogError: state.pwChangeDialogError,
        pwChangeDialogState: state.pwChangeDialogState,
        userDetailDialogError: state.userDetailDialogError,
        userDetailDialogState: state.userDetailDialogState,
        userDetailDialogTitle: state.userDetailDialogTitle,
        userRoles: state.userRoles,

        changePassword,
        changePasswordConfirm,
        closePwChangeDialog,
        closeUserConfirm,
		closeUserDetailDialog,
		customerPortalIdChange,
        deleteUser,
        deleteUserConfirm,
        editEntity,
        emailChange,
        firstnameChange,
        gendersIdChange,
        getEntities,
        isSupporterChange,
		isSupporterPvChange,
        lastnameChange,
        pw1Change,
        pw2Change,
        saveUserDetailDialog,
        setError,
        setForeigns,
        setPwError,
        userRolesIdChange,

}}>
        {props.children}
    </UsersContext.Provider>
}

export default UsersState;