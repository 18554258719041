/**
 *      File:       ./src/context/administration/energy/networksContext.js
 * 
 *      Subject:    Networks' Context
 */

import { createContext } from 'react';

const regulationZonesContext = createContext();

export default regulationZonesContext;