/**
 *      File:       ./src/context/campaigns/individualsContext.js
 * 
 *      Subject:    Context for the individual products
 */

import { createContext } from "react";

const individualsContext = createContext();

export default individualsContext;
